import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@mui/material';
import React, { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { openSessionExpiredDialog } from '../../store/slices/applicationSlice';

const LoggedOutDialog = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const sessionExpiredDialog = useSelector((state) => state.application.sessionExpiredDialog);
  const handleClose = () => {
      dispatch(openSessionExpiredDialog(false))
      window.location.reload('/login');
    }
  return (
    <Dialog open={sessionExpiredDialog} onClose={handleClose}>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 2 }}>
            <InfoOutlinedIcon sx={{ fontSize: 60, color: theme.palette.grey.A700 }}></InfoOutlinedIcon>
          </Box>
          <DialogContentText> Your session is expired. Please Login again.</DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoggedOutDialog;
