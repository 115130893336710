import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Field, Formik, useField } from 'formik';
import React, { useState } from 'react';
import FormikField from '../../visitScheudle/FormikTextField';
import CustomButton from '../../../components/@extended/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CustomPhoneInput from '../../dynamicFormEngine/FormFields/CustomPhoneInput';
import PhoneInput from 'react-phone-number-input';
import { createSiteCro } from '../../../store/slices/financeSlice';
import { openMessage } from '../../../store/slices/showMessageSlice';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useTheme } from '@emotion/react';

const Phone = ({ form, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);
  return (
    <PhoneInput
      {...form}
      {...field}
      placeholder={props.placeholder}
      value={field.value}
      defaultCountry=""
      size="medium"
      onChange={(value) => {
        if (!form.touched[field.name]) form.setFieldTouched(field.name);
        form.setFieldValue(field.name, value);
      }}
      label={props?.label}
      inputComponent={CustomPhoneInput}
    />
  );
};

const AddSiteCro = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const [openCreate, setOpenCreate] = useState(false);
  const [orgCategory, setOrgCategory] = useState([
    { label: 'Pharmaceutical', value: 'Pharmaceutical' },
    { label: 'Biotechnology', value: 'Biotechnology' },
    { label: 'Academic', value: 'Academic' },
    { label: 'Non-Profit', value: 'Non-Profit' },
    { label: 'Government', value: 'Government' },
    { label: 'Contract Research Organization (CRO)', value: 'Contract Research Organization (CRO)' },
    { label: 'Medical Device', value: 'Medical Device' },
    { label: 'Healthcare Provider', value: 'Healthcare Provider' },
    { label: 'Consulting', value: 'Consulting' },
    { label: 'Other', value: 'Other' },
  ]);
  const sites = useSelector((state) => state.finance.sites);
  const [accountVerified, setAccountVerified] = useState(false);
  const accountDetailsValidationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required('Account Holder Name is required'),
    account_holder_email: Yup.string()
      .email('Invalid email')
      .required('Account Holder Email is required')
      .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
    account_holder_number: Yup.string().required('Account Number is required'),
    confirm_account_holder_number: Yup.string()
      .oneOf([Yup.ref('account_holder_number'), null], 'Confirm Account Number must match Account Number')
      .required('Confirm Account Number is required'),
    routingNumber: Yup.string().required('Routing Number is required'),
    confirm_routing_Number: Yup.string()
      .required('Confirm Routing Number is required')
      .oneOf([Yup.ref('routingNumber'), null], 'Confirm Routing Number must match Routing Number'),
    abaNumber: Yup.string()
      .required('ABA number is required')
      .matches(/^[0-9]+$/, 'ABA number must be numeric'),
    swiftNumber: Yup.string()
      .required('Swift Number is required')
      .matches(/^[0-9]+$/, 'Swift Number must be numeric'),
    country: Yup.string().required('Country is required'),
    currency: Yup.string().required('Currency is required'),
  });
  const validationSchema = Yup.object().shape({
    orgName: Yup.string().required('Organization Name is required'),
    orgCategory: Yup.string().required('Organization Category is required'),
    contactEmail: Yup.string()
      .email('Invalid email').required('Contact Email is required')
      .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    postalcode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
    accountDetails: accountDetailsValidationSchema,
  });

  const handleFormSubmit = (values) => {
    if(accountVerified){
      dispatch(createSiteCro({...values, accountStatus:'Verified'}));
    }else{
      dispatch(createSiteCro({...values, accountStatus:'Unverified'}));
    }
  }

  const validateAccountDetails = async (values, setFieldTouched, setFieldError) => {
    try {
      await accountDetailsValidationSchema.validate(values.accountDetails, { abortEarly: false });
      setAccountVerified(true);
      dispatch(openMessage({ messageSeverity: 'success', message: 'Bank Account Details Validated.' }));
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          setFieldTouched(`accountDetails.${err.path}`, true);
          setFieldError(`accountDetails.${err.path}`, err.message);
        });
      }
      setAccountVerified(false);
    }
  };
  return (
    <Dialog scroll={'paper'} open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        initialValues={{
          orgName: '',
          orgCategory: '',
          contactInfo: '',
          contactEmail: '',
          addressLine1: '',
          state: '',
          city: '',
          postalcode: '',
          country: '',
          accountDetails: {
            account_holder_name: '',
            account_holder_email: '',
            account_holder_number: '',
            confirm_account_holder_number: '',
            routingNumber: '',
            confirm_routing_Number: '',
            abaNumber: '',
            swiftNumber: '',
            country: '',
            currency: '',
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleFormSubmit(values);
          onClose();
          setAccountVerified(false);
          // resetForm();
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, resetForm, values, touched, errors, setFieldTouched, setFieldError }) => (
          <>
            <DialogTitle>
              Create Site/CRO
              <IconButton
                aria-label="close"
                onClick={()=>{onClose();setAccountVerified(false);}}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', pb: 3 }}>
                <Grid container spacing={4} sx={{ pt: 2 }}>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.orgName && Boolean(errors.orgName)}>
                      <Field component={FormikField} name="orgName" label="Organization Name" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.orgCategory && Boolean(errors.orgCategory)}>
                      <InputLabel>Organization Category</InputLabel>
                      <Field
                        component={Select}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        label="Organization Category"
                        inputProps={{
                          id: 'orgCategory',
                          name: 'orgCategory',
                        }}
                      >
                        {orgCategory &&
                          orgCategory.map((cat) => (
                            <MenuItem key={cat.value} value={cat.value}>
                              {cat.label}
                            </MenuItem>
                          ))}
                      </Field>
                      {touched.orgCategory && errors.orgCategory && (
                        <FormHelperText>{errors.orgCategory}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.contactEmail && Boolean(errors.contactEmail)}>
                      <Field component={FormikField} name="contactEmail" label="Contact Email" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.contactInfo && Boolean(errors.contactInfo)}>
                      <Field name="contactInfo" component={Phone} label="Contact Number" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.addressLine1 && Boolean(errors.addressLine1)}>
                      <Field component={FormikField} name="addressLine1" label="Address Line 1" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.state && Boolean(errors.state)}>
                      <Field component={FormikField} name="state" label="State" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.city && Boolean(errors.city)}>
                      <Field component={FormikField} name="city" label="City" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.postalcode && Boolean(errors.postalcode)}>
                      <Field component={FormikField} name="postalcode" label="Postal Code" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.country && Boolean(errors.country)}>
                      <Field component={FormikField} name="country" label="Country" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={12}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" mr={2}>Bank Account Information</Typography>
                      {accountVerified ?
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.light,
                            fontSize: 20,
                          }}
                        /> :
                        <WatchLaterIcon
                          sx={{
                            color:  '#FFCB00',
                            fontSize: 20,
                          }}
                        />
                      }
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: accountVerified ? theme.palette.success.light : ' #FFCB00',
                          width: 'fit-content',
                          fontSize:'18px',
                          fontWeight:'bold',
                          marginLeft: theme.spacing(0.5),
                        }}
                      >
                        {accountVerified ? "Verified" : "Unverified"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_name &&
                        Boolean(errors.accountDetails?.account_holder_name)
                      }
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_name"
                        label="Account Holder Name"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_email &&
                        Boolean(errors.accountDetails?.account_holder_email)
                      }
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_email"
                        label="Account Holder Email"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_number &&
                        Boolean(errors.accountDetails?.account_holder_number)
                      }
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_number"
                        label="Account Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.confirm_account_holder_number &&
                        Boolean(errors.accountDetails?.confirm_account_holder_number)
                      }
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.confirm_account_holder_number"
                        label="Confirm Account Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.routingNumber && Boolean(errors.accountDetails?.routingNumber)}
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.routingNumber"
                        label="Routing Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.confirm_routing_Number &&
                        Boolean(errors.accountDetails?.confirm_routing_Number)
                      }
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.confirm_routing_Number"
                        label="Confirm Routing Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.abaNumber && Boolean(errors.accountDetails?.abaNumber)}
                    >
                      <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.abaNumber" label="ABA number" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.swiftNumber && Boolean(errors.accountDetails?.swiftNumber)}
                    >
                      <Field
                        disabled= {accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.swiftNumber"
                        label="Swift Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.country && Boolean(errors.accountDetails?.country)}
                    >
                      <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.country" label="Country" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.currency && Boolean(errors.accountDetails?.currency)}
                    >
                      <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.currency" label="Currency" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton
                      variant="outlined"
                      onClick={()=>{ accountVerified ? 
                        setAccountVerified(false) :
                        validateAccountDetails(values, setFieldTouched, setFieldError);
                      }}
                      color="primary"
                      sx={{ padding: '8px 30px', textTransform: 'none' }}
                    >
                      {!accountVerified ? "Validate" : "Edit"}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 3, py: 1 }}>
                <CustomButton variant="outlined" onClick={()=>{onClose(); setAccountVerified(false);}}>
                  Cancel
                </CustomButton>
                <CustomButton variant="contained" type="save" onClick={handleSubmit}>
                  Save
                </CustomButton>
              </Box>
            </DialogContent>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddSiteCro;
