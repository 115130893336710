import React from 'react'
import { Avatar, Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import visitImg from '../../components/Logo/visit.png';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton from '../../components/@extended/CustomButton';
import moment from 'moment';
import { useCancelVisitSlotMutation, useGetTrialBookingSlotsQuery } from '../../store/slices/apiSlice';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { openMessage } from '../../store/slices/showMessageSlice';

const BookedVisitSlotDetails = () => {
    const navigate = useNavigate();
    const { slotId } = useParams();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.userDetails?.loggedInUser?.id); 
    const { data: trialBookingSlots } = useGetTrialBookingSlotsQuery();
    const [confirmDialog, setConfirmDialog] = useState({
      open: false,
      message: '',
      buttonLabel: 'Confirm',
    });
  const [cancelVisitSlot] = useCancelVisitSlotMutation();
    const bookingSlot = useMemo(() => {
      if (trialBookingSlots) {
        return trialBookingSlots?.find((_slt) => String(_slt.id) === slotId);
      }
    }, [trialBookingSlots]);
      const timeConverter = (time) => {
        return moment(time, 'HH:mm:ss').format('HH:mm');
      };
    
    const handleConfirmatioDialogOpen = () => {
      setConfirmDialog({
        open: true,
        message: (
          <>
            <span>Are you sure you want to cancel this slot?</span>
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>Date:</span>&nbsp;&nbsp;
            <span>{moment(bookingSlot?.date).format('MMMM DD YYYY')}</span> <br />
            <span style={{ fontWeight: 600 }}>Time:</span>&nbsp;&nbsp;
            <span>{timeConverter(bookingSlot?.startTime)}</span> - <span>{timeConverter(bookingSlot?.endTime)}</span>
          </>
        ),
        buttonLabel: 'Confirm',
      });
    };

    const handleConfirmDialogClose = () => {
      setConfirmDialog({
        open: false,
        message: '',
        buttonLabel: 'Confirm',
      });
    };

    const handleSlotCancelation = async () => {
    setConfirmDialog({
      open: false,
      message: '',
      buttonLabel: 'Confirm',
    });
    const res = await cancelVisitSlot({ slotId, userId });
    if (res.data) {
        dispatch(
          openMessage({
            messageSeverity: 'success',
            message: 'Booking is cancelled.',
          })
        );
        navigate('/');
    } else {
      console.log(res.error.data.message);
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: res.error.data.message,
        })
      );
    }
    }
  return (
    <Box sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
          <IconButton sx={{ fontSize: 32 }} onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Appointment Summary
          </Typography>
        </Box>
        <Divider sx={{ paddingTop: 2 }} />
        <Box sx={{ padding: 1, paddingY: 2, backgroundColor: '#FFF', borderRadius: 1 }}>
          <Typography sx={{ color: '#7a7494' }}>Consult For</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, paddingY: 2 }}>
            <Avatar
              src={visitImg}
              sx={{ width: 60, height: 60 }} // Customize the size as needed
            />
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>{bookingSlot?.visit?.stepTitle}</Typography>
          </Box>
          <Typography sx={{ paddingY: 1, color: '#7a7494' }}>Appointment Date</Typography>
          <Box>
            <Grid container sx={{ border: '1px solid #b7b4c5', borderRadius: 1 }}>
              <Grid
                item
                xs={3}
                sx={{
                  borderRight: '1px solid #b7b4c5',
                  backgroundColor: '#67627f',
                  color: '#FFF',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }}>{moment(bookingSlot?.date).format('MMMM DD')}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ borderRight: '1px solid #b7b4c5', textAlign: 'center' }}>
                <Typography sx={{ fontWeight: '600' }}>{moment(bookingSlot?.date).format('ddd')}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ display: 'flex', columnGap: 1, justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                    {timeConverter(bookingSlot?.startTime)}
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: '600' }}>-</Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                    {timeConverter(bookingSlot?.endTime)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: '#FFF', padding: 1, paddingY: 2 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ color: '#7a7494', paddingBottom: 1 }}>
              Address
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {bookingSlot?.site?.address}
            </Typography>
          </Box>
          <Box sx={{ paddingY: 2 }}>
            <Typography sx={{ color: '#7a7494', paddingBottom: 1 }}>Contact Information</Typography>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {bookingSlot?.site?.fax}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <CustomButton variant="outlined" sx={{ width: '100%' }} onClick={handleConfirmatioDialogOpen}>
          Cancel Booking
        </CustomButton>
      </Box>
      <ConfirmationDialog
        open={confirmDialog.open}
        buttonLabel={confirmDialog.buttonLabel}
        message={confirmDialog.message}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleSlotCancelation}
      />
    </Box>
  );
}

export default BookedVisitSlotDetails;
