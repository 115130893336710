import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../components/@extended/CustomButton';
import { useAddSponsorAdminMutation } from '../../store/slices/apiSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import Phone, {phoneValidationSchema} from '../../components/common/Phone';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import GooglePlacesAutocompleteComponent from '../../components/common/GooglePlacessAutocomplete';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address')
    .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
  contactNumber: phoneValidationSchema.concat(Yup.string().required('Contact number is required')),
});

const AdminDialog = ({ open, onClose, sponsorId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addAdmin] = useAddSponsorAdminMutation();
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState('');
  const [addressDetails, setAddressDetails] = useState();

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!address) {
      setAddressError('Address is required');
      setSubmitting(false);
      return;
    }
    try {
      const result = await addAdmin({ payload: { ...values, userName: values.email, ...addressDetails }, sponsorId });
  
      if (result.error) {
        let errorMessage = 'An error occurred';
        if (result.error.status === 401) {
          errorMessage = 'Unauthorized access';
        } else if (result.error.data.message.includes('duplicate')) {
          errorMessage = 'Email already exists';
        } else if (result.error.data.message) {
          errorMessage = result.error.data.message;
        }
  
        openMessageNotification({
          message: errorMessage,
          type: 'error',
        });
      } else {
        navigate(`/sponsorDisplay/${sponsorId}`);
        openMessageNotification({
          message: 'Sponsor Admin Created Successfully',
          type: 'success',
        });
        setAddress(null)
        resetForm();
        onClose();
      }
    } catch (error) {
      openMessageNotification({
        message: 'An unexpected error occurred',
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };
  const handleClose = ({ resetForm }) => {
    onClose();
    setAddress(null);
    setAddressDetails(null);
    setAddressError('');
    resetForm();
  };

  useEffect(() => {
    if (address) {
      setAddressError('');
      geocodeByAddress(address?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => setAddressDetails({ latitude: lat, longitude: lng, address: address.label }));
    } else {
      setAddressDetails(null);
    }
  }, [address]);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, touched, errors, values, handleChange, resetForm }) => (
        <Dialog open={open} onClose={() => handleClose({ resetForm })}>
          <DialogTitle>Add Sponsor Admin</DialogTitle>
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => handleClose({ resetForm })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    fullWidth
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    size="small"
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    size="small"
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    size="small"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <Field name="contactNumber" component={Phone} label="Contact Number" />
                </Grid>
                <Grid item xs={12} md={12} pb={2}>
                  <Grid item xs={12} md={12}>
                    <GooglePlacesAutocompleteComponent address={address} setAddress={setAddress}/>
                    {addressError && (
                    <Typography sx={{ fontSize: '12px', color: '#C62828', mt: 1 }}>
                      {addressError}
                    </Typography>
                  )}
                  </Grid>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={2}>
                  <Box paddingRight={1.5}>
                    <CustomButton variant="outlined" onClick={() => handleClose({ resetForm })}>
                      Cancel
                    </CustomButton>
                  </Box>
                  <Box>
                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                      Submit
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

const CreateAdmin = ({ sponsorId }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
          <CustomButton variant="contained" onClick={handleOpen}>
            + Sponsor Admin
          </CustomButton>
      <AdminDialog open={open} onClose={handleClose} sponsorId={sponsorId} />
    </Grid>
  );
};

export default CreateAdmin;
