import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomTableFoot,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableRow,
} from "../../components/@extended/CustomTable";
import RightArrow from "../../components/icons/RightArrow";
import { useNavigate } from "react-router-dom";
import { useGetTrialsMutation } from "../../store/slices/apiSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../util";
import { selectCurrentUser } from "../../store/slices/authSlice";
import CustomTablePagination from "../../components/common/TablePagination";
import { updatePagination } from "../../store/slices/applicationSlice";
import { Columns } from "../dashboard/TrialDetailColumns";

const CreatedTrialsTable = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [getTrials, { data, error }] = useGetTrialsMutation();
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.DRAFT_TRIALS_TABLE);
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const userRole = getUserRole(user);
  const columns = [
    {
      id: "trialTitle",
      label: `Trial Title`,
      style: {
        minWidth: 200,
        maxWidth: 300,
        textAlign:'justify'
      },
      align: "left",
    },
    {
      id: "protocolNumber",
      label: "Protocol Number",
      style: {
        minWidth: 170,
      },
      align: "center",
    },
    {
      id: "siteCount",
      label: "Number of Sites",
      style: {
        minWidth: 150,
      },
      align: "center",
    },
    {
        id: "status",
        label: "Status",
        style: {
          minWidth: 150,
        },
        align: "center",
    },
    { id: "action", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getTrials({
      filter: "Drafted,Created,Ongoing",
      sponsorId: sponsorId,
    });
    if (data || error) {
    }
  }, []);

  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'DRAFT_TRIALS_TABLE',page,rowsPerPage}));
  }
  return (
    <>
      <Box sx={{ py: 2 ,}}>
        <Typography variant="h6" color="initial">
          Trials
        </Typography>
        {(userRole === "PI" || userRole === "site_coordinator")
           && 
            <Typography variant="body1" color="initial">
              (Pending Site Initiation)
            </Typography>
          }
      </Box>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: 3,
            backgroundColor: theme.palette.primary.light,
          }}
        />
        <TableContainer sx={userRole==='sponsor' ? { flexGrow: 1, overflow: "auto" } : {maxHeight: 440}}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => (
                  <CustomTableHeadCell key={column.id} align={column.align} sx={{...column?.style}}>
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data && data.length > 0 ? (
                (rowsPerPage > 0
                  ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : data
                ).map((row) => {
                  return (
                    <CustomTableRow 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      key={row.id}
                      onClick={()=>{
                      navigate(`/eTMFs-eISF/doc/${row.id}`)
                      }} 
                      sx={{cursor:'pointer'}}                    
                    >
                      {columns.map((column) => {
                        let value = null;
                        const style = {...column?.style};
                        switch (column.id) {
                          case "createdAt": {
                            value = moment(row[column.id]).format(
                              "DD/MM/YYYY, hh:mm"
                            );
                            break;
                          }
                          case "currentExpenditure": {
                            value = "--"  
                            break;
                          }                      
                          case "status": {
                            style.fontWeight = 600;
                            if (row.status == "Created") {
                              style.color = "#FF6D00";
                            }else if (row.status == "Ongoing") {
                              style.color = "green";
                            } else {
                              style.color = "#FFCB00";
                            }
                            value = row.status;
                            break;
                          }
                          default:
                            value = row[column.id];
                            break;
                        }

                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Typography
                              variant="subtitle2"
                              color="initial"
                              style={style}
                            >
                              {value}
                            </Typography>
                            {column.id === "action" && (
                              <RightArrow
                                onClick={() =>
                                {
                                  navigate(`/eTMFs-eISF/doc/${row.id}`)
                                }
                                }
                              />
                            )}
                          </TableCell>
                        );
                      })}
                    </CustomTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {userRole === "PI" || userRole === "site_coordinator" ? (
                      <Typography variant="subtitle1" color="initial">
                      No Created Trials Found
                    </Typography>
                    ) : (
                      <Typography variant="subtitle1" color="initial">
                        No Trials Found
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {userRole === 'sponsor' ?
              <CustomTableFoot>
                <CustomTableRow sx={{ right: 0 }}>
                  <CustomTableHeadCell colSpan={columns.length}>
                    <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={data?.length} onNewPage={onNewPage} />
                  </CustomTableHeadCell>
                </CustomTableRow>
              </CustomTableFoot>
              : ''}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};


export default function ETMFs() {
  return (
    <CreatedTrialsTable/>
  )
}