import React, { useEffect, useState } from 'react';
import DynamicFormEngine from '../../dynamicFormEngine';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import originalConfig from './ProductCrfForms.json';
import { Box, Button, Typography } from '@mui/material';
import RightArrow from '../../../components/icons/RightArrow';
import { useDispatch, useSelector } from 'react-redux';
import {
  completeProductForVendor,
  createProductForVendor,
  getAssignmentVendorProduct,
} from '../../../store/slices/pfasSlice';

const ProductCrf = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sponsorId, assignmentId, vendorId, productStepKey, productId } = useParams();
  //   const [formConfig, setFormConfig] = useState(null);
  const [formConfig, setFormConfig] = useState(originalConfig.steps.find((_obj) => _obj.key == productStepKey));
  const [formLabel, setFormLabel] = useState('');

  const product = useSelector((state) =>
    getAssignmentVendorProduct(state, sponsorId, assignmentId, vendorId, productId)
  );

  useEffect(() => {
    if (product) {
      setFormLabel(product?.productDetails?.productName);
    }
  }, [product, productId]);

  const handleSave = (payload) => {
    if (payload.answers.productDetails) {
      dispatch(createProductForVendor({ orgId: sponsorId, assignmentId, vendorId, data: payload.answers }));
    } else {
      dispatch(
        completeProductForVendor({ orgId: sponsorId, assignmentId, vendorId, productId, data: payload.answers })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          flex: 'grow',
          display: 'flex',
          height: 70,
        }}
      >
        <Button
          type="text"
          style={{ position: 'absolute', top: 12 }}
          onClick={() => navigate(-1)}
          startIcon={<RightArrow leftArrow />}
        >
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
            Back
          </Typography>
        </Button>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {productId && (
            <Typography variant="h6" color="initial" ml={'auto'} mr={'auto'}>
              Product Name: {formLabel}
            </Typography>
          )}
        </Box>
      </Box>
      {formConfig && (
        <DynamicFormEngine
          formConfig={formConfig}
          userSiteTrialRole={'system_admin'}
          handleSave={(payload) => {
            handleSave(payload);
          }}
          formAnswers={{}}
        />
      )}
    </>
  );
};

export default ProductCrf;
