import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

function CustomTablePagination({page, rowsPerPage, count, onNewPage}) {

  const handleChangePage = (event, newPage) => {
    onNewPage(newPage, rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    onNewPage(0, parseInt(event.target.value, 10))
  };

  const getRowsPerPageOptions = (count) => {
    const defaultOptions = [5, 10, 15];
    const filteredOptions = defaultOptions.filter(option => option <= count);
    return filteredOptions;
  };
  
  return (
    <TablePagination
      component="div"
      count={count || 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={getRowsPerPageOptions(count)}
      sx={{
        '.MuiTablePagination-selectLabel':{
            m:'0px',
            fontSize:'17px',
        },
        '.MuiInputBase-root':{
            fontSize:'17px',
        },
        '.MuiTablePagination-displayedRows':{
            m:'0px',
            fontSize:'17px',
        },
      }}
    />
  );
}

export default CustomTablePagination;