export const Columns2 = () => {
  const columns = [
    {
      id: 'adminName',
      label: `Name`,
      style: {
        minWidth: 100,
        maxWidth: 300,
        textAlign: 'justify',
      },
      align: 'left',
    },
    {
      id: 'email',
      label: 'Email',
      style: {
        minWidth: 120,
      },
      align: 'left',
    },
    {
      id: 'contactNo',
      label: 'Contact Number',
      style: {
        minWidth: 170,
      },
      align: 'left',
    },
    {
      id: 'createdAt',
      label: 'Created Date',
      style: {
        minWidth: 170,
      },
      align: 'left',
    },
  ];

  return columns;
};
