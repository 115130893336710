import { TextField } from "@mui/material";
import { useField } from "formik";

// Custom TextField component for Formik
const FormikField = ({form, ...other}) => {
    const [field, meta, helpers] = useField(other.field.name);
    const touched = meta.touched;
    const currentError = meta.error;    

    return (
      <TextField
        sx={{
          height: other.height,
          minHeight: other.minheight,
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}
        multiline={other.multilined}
        maxLength={other.maxLineLength}s
        inputProps={{
          maxLength: other.maxlength,
        }}
        maxRows={4}
        {...field}
        {...other}
        error={touched && currentError && true}
        helperText={touched && currentError && currentError}
      />
    );
};

export default FormikField;