import { useTheme } from "@emotion/react";
import { useFormik } from "formik";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import * as Yup from "yup";
import { Box, Button, Card, CardContent, Grid, Stack, TextField, Typography } from "@mui/material";
import CustomButton from "../../components/@extended/CustomButton";
import * as React from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';
import { useResendOTPMutation, useVerifyOTPMutation } from "../../store/slices/apiSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from "react-redux";
import { openMessage } from "../../store/slices/showMessageSlice";

import OTPInputPannal from "./OTPInputPannal";

export default function EmailVarification({ userData, handleComplete,handleNext, handleBack, activeStep ,completedSteps}) {
  const { isSmallScreen } = useResponsive();
  const dispatch = useDispatch();

  const [verifyOTP, { error1 }] = useVerifyOTPMutation();  
  const [resendOTP, { error2 }] = useResendOTPMutation();  

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [genarateOtp, setGenarateOtp] = React.useState(false);

  const [otp, setOtp] = React.useState('');

  const onResendOTP = async () => {
    const result = await resendOTP({
      payload: {
        type: "EMAIL_VERIFICATION"
      },
    });
    if (!result?.error) {
      openMessageNotification({
        message: "OTP Send Successfully",
        type: "success",
      });
    } else {
      openMessageNotification({
        message: result?.error?.data?.message,
        type: "error",
      });
    }
    if(!result?.error){
      setMinutes(1);
      setSeconds(59);
    }    
  };

  const onVerifyOTP = async () => {
    const result = await verifyOTP({
      payload: {
        otp: otp,
        type: "EMAIL_VERIFICATION"
      },
    });
    if(otp.length===4){
      if (!result?.error) {
        handleComplete();
        openMessageNotification({
          message: "Email Verified Successfully",
          type: "success",
        });
      } else {
        openMessageNotification({
          message: result?.error?.data?.message,
          type: "error",
        });
      }
    }else{
      openMessageNotification({
        message: "Please enter the OTP to proceed",
        type: "error",
      });
    }
    return result;
  };

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  };


  return (
    <Grid>
      <Grid item>
      <Box sx={{ paddingTop: isSmallScreen ? 8 : 4 }}>
      </Box>
          <Grid sx={{display:'flex',justifyContent:'center'}} container rowSpacing={3}>
            <Grid item xs={12} px={0}>
              <Typography  variant="body1" color="#5d5d5d" pb={1}>
                <span style={{fontWeight:600}}>Email :</span> {userData?.email}
              </Typography>
            </Grid>
            {!userData?.isEmailVerified ?
              <Stack alignItems='flex-start'>
                <OTPInputPannal genarateOtp={genarateOtp} minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds} otp={otp} setOtp={setOtp} onResendOTP={onResendOTP}/>
              </Stack> :
              ""
            }
          </Grid>
          {genarateOtp?
          <CustomButton 
            onClick={onVerifyOTP} 
            variant="contained" 
            sx={{ color: 'white',mt:2}}
          >Verify OTP
          </CustomButton>:
          !userData?.isEmailVerified ?
          <CustomButton
            onClick={() => {setGenarateOtp(true);onResendOTP()}} 
            disabled={userData?.isEmailVerified ? true : false}
            variant="contained" 
            sx={{ color: 'white',mt:2 }}
          >Send OTP
          </CustomButton>:
          <Box sx={{marginTop:4}}>
            <CheckCircleIcon color="success" fontSize="large"/>
            <Typography  variant="h6" color="#2e7d32" pb={1}>
              Email Verified Successfully!
            </Typography>
          </Box>
        }
      </Grid>
      <Grid item xs={12} sx={{ px: 0}}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <CustomButton
            // color="inherit"
            variant="outlined"

            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </CustomButton>
          <Box sx={{ flex: '1 1 auto' }} />
          <CustomButton disabled={!userData?.isEmailVerified ? true : false} variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
            Next
          </CustomButton>
          <Box />
        </Box>
      </Grid>
    </Grid>
  )
}
