import React from 'react';
import { Box, Grid, InputLabel, Slider, Stack, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';

const ColourSliderField = (props) => {
  const theme = useTheme();
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  return (
    <Box
      sx={{
        height: props.justifycontent ? 'auto' : '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: props.justifycontent ? 'start' : 'space-between',
      }}
    >
      <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
        {props.label}
      </Typography>
      <Grid container sx={{ marginTop: props.valueLabelDisplay == 'off' ? 0 : 2 }}>
        {props?.startLabel && (
          <Grid item md={1} sm={0}>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              {props.startLabel}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          md={props?.startLabel && props?.endLabel ? 10 : 12}
          sm={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingX: props?.startLabel && props?.endLabel ? 2 : 0,
            paddingBottom: 1,
          }}
        >
          <Slider
            track={false}
            sx={{
              '& .MuiSlider-valueLabel': {
                top: -6,
                backgroundColor: 'unset',
                color: theme.palette.text.primary,
              },
              '& .MuiSlider-thumb': {
                height: 18,
                width: 18,
                borderRadius: 0,
                // clipPath: 'polygon(0 0, 100% 0%, 100% 60%, 50% 100%, 0 60%)',
                '&:before': {
                  borderBottom: '10px solid #FF6D01',
                  borderLeft: '10px solid rgba(0, 0, 0, 0)',
                  borderRight: '10px solid rgba(0, 0, 0, 0)',
                  boxShadow: 'none',
                  content: "''",
                  display: 'inline-block',
                  marginTop:3,
                  height: 0,
                  verticalAlign: 'top',
                  width: 0,
                  transform: 'rotate(180deg)'
                }
              },
              '& .MuiSlider-rail': {
                opacity: 0.5,
                boxShadow: 'inset 0px 0px 4px -2px #000',
                backgroundColor: '#d0d0d0',
              },
            }}
            {...field}
            {...rest}
          />
          <span
            style={{
              width: '100%',
              height: 6,
              background: 'linear-gradient(to right, green , yellow , red, purple)',
              marginTop: 3,
              borderRadius: '5%',
            }}
          />
        </Grid>
        {props?.endLabel && (
          <Grid item md={1} sm={0}>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              {props?.endLabel}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ColourSliderField;
