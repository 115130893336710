import { useNavigate, useParams } from 'react-router-dom';
import RightArrow from '../../components/icons/RightArrow';
import CustomButton from '../../components/@extended/CustomButton';
import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Button,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextFieldContainer from '../../components/inputContainers/TextFieldContainer';
import { getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { useAddTrailSysMutation, useGetAllConfigurationsQuery, useGetAllSponserConfigurationsQuery } from '../../store/slices/apiSlice';
import moment from 'moment';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch } from 'react-redux';

const CreateTrials = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { sponsorId } = useParams();
  const dispatch = useDispatch();
  const { data: sponserConfigurations, isLoading } = useGetAllSponserConfigurationsQuery(sponsorId);
  // const { data: configurations, isLoading } = useGetAllConfigurationsQuery();
  const [addTrail] = useAddTrailSysMutation();
  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };
  const [configurations, setconfigurations] = React.useState([]);

  useEffect(()=>{
    if(sponserConfigurations){
      const configs = sponserConfigurations.map(config => config.configuration);
      setconfigurations(configs);
    }
  },[sponserConfigurations])

  const formik = useFormik({
    initialValues: {
      protocolNumber: '',
      protocolVersion: '',
      protocolVersionDate: dayjs().format('MM/DD/YYYY'),
      trialTitle: '',
      totalSubjectNumber: '',
      configurationMappings: configurations ? configurations.map((config) => ({ id: config.id, value: '' })) : [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      protocolNumber: Yup.string().required('Protocol Number Required'),
      protocolVersion: Yup.string().required('Protocol Version Required'),
      protocolVersionDate: Yup.string().required('Protocol Version Date Required'),
      trialTitle: Yup.string().required('Trial Title Required'),
      totalSubjectNumber: Yup.number().typeError('Must be a number'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const configurationMappings = values.configurationMappings
          .filter((mapping) => mapping.value)
          .map((mapping) => ({
            configurationTypeId: mapping.id,
            configurationOptionId: mapping.value,
          }));

        const formattedValues = {
          protocolNumber: values.protocolNumber.trim(),
          protocolVersion: values.protocolVersion,
          protocolVersionDate: values.protocolVersionDate,
          trialTitle: values.trialTitle,
          totalSubjectNumber: values.totalSubjectNumber,
          configurationMappings,
        };

        const result = await addTrail({
          payload: formattedValues,
          sponsorId: sponsorId,
        });

        if (result.error) {
          let errorMessage = 'An error occurred';
          if (result.error.status === 401) {
            errorMessage = 'Unauthorized access';
          } else if (result.error.data.message.includes('duplicate')) {
            errorMessage = 'Protocol Number Already Exists';
          } else if (result.error.data.message) {
            errorMessage = result.error.data.message;
          }
          openMessageNotification({
            message: errorMessage,
            type: 'error',
          });
        } else {
          navigate(`/sponsorDisplay/${sponsorId}`);
          openMessageNotification({
            message: 'Trial Created Successfully',
            type: 'success',
          });
          resetForm();
        }
      } catch (error) {
        openMessageNotification({
          message: 'An unexpected error occurred',
          type: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleDateChange = (date) => {
    formik.setFieldValue('protocolVersionDate', date ? date.format('MM/DD/YYYY') : '');
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<RightArrow leftArrow />}
            sx={{ paddingBottom: 2 }}
            onClick={() => navigate(`/sponsorDisplay/${sponsorId}`)}
          >
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Sponsor Details
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={'Create Trial'} titleTypographyProps={{ variant: 'h6' }} />
            <CardContent>
              <Grid container>
                <Grid item sm={12} md={5} sx={{ pb: 1 }}>
                  <TextFieldContainer
                    name={'protocolNumber'}
                    placeholder={'Enter Protocol Number'}
                    label={'Protocol Number'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ pb: 1 }}>
                  <TextFieldContainer
                    name={'protocolVersion'}
                    placeholder={'Enter Protocol Version'}
                    label={'Protocol Version'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                    Protocol Version Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(
                        new Date(
                          moment(
                            getIn(formik.values, 'protocolVersionDate') && getIn(formik.values, 'protocolVersionDate'),
                            'MM/DD/YYYY'
                          )
                        )
                      )}
                      slotProps={{
                        textField: {
                          name: 'protocolVersionDate',
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ py: 2 }}>
                  <TextFieldContainer
                    name={'trialTitle'}
                    placeholder={'Enter Trial Title'}
                    label={'Trial Title'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <TextFieldContainer
                    name={'totalSubjectNumber'}
                    placeholder={'Enter Number of Subjects'}
                    label={'Number of Subjects'}
                    formik={formik}
                    type={'number'}
                  />
                </Grid>
                {configurations.length > 0 && 
                  <Grid item sm={12} sx={{ pb: 2, py: 2 }}>
                    <Divider />
                  </Grid>
                }
                {
                configurations.length > 0 &&
                configurations.map((config, index) => (
                  <Grid item sm={12} md={5} key={index} sx={{ pb: 2 }} mr={index%2===0?13:0}>
                    <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                      {config.label}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <Select
                        displayEmpty
                        name={`configurationMappings[${index}].value`}
                        value={formik.values.configurationMappings[index]?.value || ''}
                        onChange={(e) => formik.setFieldValue(`configurationMappings[${index}].value`, e.target.value)}
                        error={
                          formik.touched.configurationMappings?.[index]?.value &&
                          Boolean(formik.errors.configurationMappings?.[index]?.value)
                        }
                        input={<OutlinedInput size="small" />}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: '#aaa', fontStyle: 'normal' }}>Select {config.label}</em>
                        </MenuItem>
                        {config.options.map((option, idx) => (
                          <MenuItem value={option.id} key={idx}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton variant="outlined" onClick={() => navigate(`/sponsorDisplay/${sponsorId}`)}>
            Cancel
          </CustomButton>
          <Box>
            <CustomButton variant="outlined" onClick={() => formik.resetForm()}>
              Reset
            </CustomButton>
            <CustomButton variant="contained" onClick={formik.handleSubmit} sx={{ ml: 2 }}>
              Submit
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateTrials;
