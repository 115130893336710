import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import trialImg from '../../../components/Logo/trialImage.svg';

const CountCard = (props) => {
  const { title, maintitle, count } = props;
    return (
      <Box sx={{ paddingY: 0.5 }}>
        <Box
          sx={{
            display: 'flex',
            paddingY: 1,
            columnGap: 2,
            alignItems: 'center',
            width: '100%',
            border: '1px solid #DEE2EA',
            borderRadius: 3,
            backgroundColor: '#E9F1FC',
          }}
        >
          <Box sx={{ textAlign: 'center', width: '50%' }}>
            <Typography variant="body1" sx={{ color: 'grey', fontWeight: 500 }}>
              {title}
            </Typography>
            <Typography variant='subtitle1' sx={{ color: '#000', fontWeight: 600, color: '#4F6079' }}>{maintitle}</Typography>
          </Box>
          <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
            <Typography variant="h4" sx={{ color: maintitle == 'AE/SAE' ? '#EF0953' : '#2196f3' }}>
              {count}
            </Typography>
          </Box>
        </Box>
       </Box>
    );
};

export default CountCard;