import React, { useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import CustomButton from '../../../components/@extended/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { assignVendorToAssignment, removeVendorFromAssignment, selectAssignmentDetails } from '../../../store/slices/pfasSlice';
import { useParams } from 'react-router-dom';
import AddVendor from './AddVendor';

const AssignmentVendorSetup = ({ handleTabChange }) => {
  const { sponsorId, assignmentId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [vendor, setVendor] = useState('');
  const [createVendor, setCreateVendor] = useState(false);
  const organization = useSelector((state) => state.pfas.organizations.find((_sp) => String(_sp.id) === sponsorId));

  const assignmentDetails = useSelector((state) => selectAssignmentDetails(state, sponsorId, assignmentId));

  const handleAssignVendor = () => {
    dispatch(assignVendorToAssignment({ orgId: sponsorId, assignmentId, vendorId: vendor }));
    setVendor('');
  };

    const removeVendor = (vendorId) => {
        dispatch(removeVendorFromAssignment({ orgId: sponsorId, assignmentId, vendorId }));
    }
  return (
    <>
      <Card sx={{ pt: 1, pr: 1 }}>
        <CardHeader
          title="Assign Vendors"
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <>
              <CustomButton
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mr: 2 }}
                onClick={() => {
                  setCreateVendor(true);
                }}
              >
                <Typography variant="subtitle1" color={theme.palette.common.white}>
                  Create Vendor
                </Typography>
              </CustomButton>
              <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
                <Typography variant="subtitle1" color={theme.palette.common.white}>
                  Upload Details
                </Typography>
              </CustomButton>
              <Typography variant="subtitle1" fontSize={'12px'} color={'black'} textAlign={'right'} mt={1}>
                Note: Upload file with CSV/Excel format.
              </Typography>
            </>
          }
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={3}></Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl fullWidth>
                <InputLabel>Vendors</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={vendor}
                  label="Vendors"
                  onChange={(e) => {
                    setVendor(e.target.value);
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ color: '#aaa', fontStyle: 'normal' }}>
                      {organization?.vendors?.filter(
                        (_ven) => !assignmentDetails?.vendors?.some((vendor) => vendor.id === _ven.id)
                      ).length !== 0
                        ? 'Select Vendor'
                        : 'Add new Vendor'}
                    </em>
                  </MenuItem>
                  {organization?.vendors
                    ?.filter((_ven) => !assignmentDetails?.vendors?.some((vendor) => vendor.id === _ven.id))
                    .map((vendor) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        {vendor.vendorName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomButton onClick={handleAssignVendor}>Add</CustomButton>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {assignmentDetails?.vendors?.length >= 1 ? (
                assignmentDetails?.vendors?.map((vendor, index) => {
                  return (
                    <Box
                      sx={{
                        marginTop: index === 0 ? 0 : 3,
                        border: '1px solid #e7e7e7',
                        padding: 3,
                        borderRadius: 1,
                        backgroundColor: '#fbfbfb',
                      }}
                    >
                      <Box sx={{ display: 'flex', marginBottom: 2 }}>
                        <Typography sx={{ flexGrow: 1, fontWeight: 'bold', color: 'grey' }}>
                          {vendor?.vendorName}
                        </Typography>
                        <Button
                          variant="outlined"
                          sx={{ marginX: 2 }}
                          onClick={() => {
                              removeVendor(vendor.id);
                          }}
                        >
                          Remove Vendor
                        </Button>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Vendor Address:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.addressLine1}</Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Phone number:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.contactNumber}</Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Fax number:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.fax}</Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Contact Email:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.contactEmail}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: 200,
                    }}
                  >
                    <Typography variant="subtitle1" color="initial">
                      No Vendor selected.
                    </Typography>
                    <Typography variant="subtitle2" color="initial" sx={{ fontStyle: 'italic' }}>
                      Note: You can add new vendor or upload vendor's information.
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            {assignmentDetails?.vendors?.length >= 1 && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomButton variant="outlined" onClick={() => handleTabChange('_', 0)}>
                  Back
                </CustomButton>
                <CustomButton variant="contained" onClick={() => handleTabChange('_', 2)}>
                  Save & Continue
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <AddVendor open={createVendor} onClose={() => setCreateVendor(false)} />
    </>
  );
};

export default AssignmentVendorSetup;
