import { Checkbox, Table, Tooltip, TableBody, TableCell, TableContainer, DialogTitle, DialogContent, TableRow, Box, Divider, Card, Dialog, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { bloqcibeApi, useGetOngoingTrialsQuery, useGetSubjectListQuery, usePublishBookingSlotMutation } from "../../store/slices/apiSlice";
import CustomButton from "../../components/@extended/CustomButton";
import { CustomTableHead, CustomTableHeadCell, CustomTableRow,} from "../../components/@extended/CustomTable";
import _ from "lodash";
import { useTheme } from "@emotion/react";
import { openMessage } from "../../store/slices/showMessageSlice";
import moment from "moment";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  
  function union(a, b) {
    return [...a, ...not(b, a)];
  }

const PublishSlot = ({onClose, open, availableSlots}) => {
    const [checked, setChecked] = useState([]);
    const theme = useTheme();
    const dispatch = useDispatch()
    const sponsorId = useSelector((state) => state.auth?.sponsorId);
    const {
        data: ongoingTrials,
    } = useGetOngoingTrialsQuery(sponsorId);
    const [getTrialVisitDetails] =
      bloqcibeApi.endpoints.getTrialVisitDetails.useLazyQuery();
    const [publishBookingSlot, { publishError }] = usePublishBookingSlotMutation();
    const [visitList, setVisitList] = useState([]);
    const [trial, setTrial] = useState();
    const [site, setSite] = useState();
    const [allSubjects, setAllSubjects] = useState([]);
    const [rows, setRows] = useState([]);
    const [showSubject, setShowSubject] = useState(false)
    const [error, setError] = useState("")
    const [formValues, setFormValues] = useState()
    const [slotList, setSlotList] = useState([])

    const openMessageNotification = (message) => {
      dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };

    const userDetails = useSelector((state) => state.auth.user.details[0])

    const { data: subjectList } = useGetSubjectListQuery({
      sponsorId: sponsorId,
      trialId: trial,
      siteId: site,
    });

    const docSchema = Yup.object().shape({
      trialId: Yup.string().required("Trial is required"),
      visitId: Yup.string().required("Visit is required"),
    });

    const columns = useMemo(() => {
      if (subjectList) {
        setAllSubjects(subjectList.filter((subject) => subject?.userId !== null && subject?.stepStatus[subject.stepStatus.length-1]?.status !== "Pending"));
        const data = [
        {
          id: "checkbox",
          label: ``,
          align: "left",
          width: "10%"
        },
        {
          id: "subjectName",
          label: "Subject",
          align: "left",
          width: "90%"
        }];
        return data;
      }
    }, [subjectList])

  useEffect(() => {
      if (subjectList && showSubject) {
        const data = subjectList.filter((subject) => subject?.userId !== null && subject?.stepStatus[subject.stepStatus.length-1]?.status !== "Pending");
        setAllSubjects(data);
        //setRows(data);
      }
      if(showSubject){
        const data = availableSlots.filter(slot => {
          const currentDate = new Date();
          const slotStartTime = new Date(`${slot.date.split('T')[0]}T${slot.startTime}`);

          if (slotStartTime > currentDate && slot.trial.id === formValues.trialId && slot.visit.id === formValues.visitId) {
              return true;
          }
          return false;
        });
        setSlotList(data)
        setRows(allSubjects)
      }
    }, [showSubject]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
      };
    
    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
          setChecked(not(checked, items));
        } else {
          setChecked(union(checked, items));
        }
    };

    const handleFormChange = (event) => {
      setShowSubject(false)
      const { name, value } = event.target;
      if (name === "trialId") {
        // setRows([]);
        setTrial(value);
        const user = userDetails.find((user)=>user.trialId==value)
        setSite(user.siteId)
      }
      else if (name === "visitId") {
        const visitStep = visitList.find((_obj) => _obj.id == value);
        const selectedSubjects = allSubjects.filter((_obj) => {
            const visitStatus = _obj?.crfStatus?.stepStatus.find((_status) => _status.stepKey == visitStep.visitKey);
            const index = _obj?.crfStatus?.stepStatus.findIndex((_status) => _status.stepKey == visitStep.visitKey)
            if(index > 0){
              if(visitStatus && _obj?.crfStatus?.stepStatus[index-1]?.status!=='Pending' && visitStatus.status == 'Pending') {
                return true;
              }
              return false;
            }
            else{
              if(visitStatus && visitStatus.status == 'Pending') {
                return true;
              }
              return false;
            }         
        })
        setAllSubjects(selectedSubjects);
      }
    };

  useEffect(() => {
      (async () => {
        if (trial) {
          const _visitList = await getTrialVisitDetails({
            trialId: trial,
          });
          if (_visitList?.data) {
            const _list = _visitList.data.map((_visit) => {
              return { visitName: _visit.stepTitle, id: _visit.id, visitKey: _visit.stepKey };
            });
            setVisitList(_list);
          }
        }
      })();
  }, [trial]);


  async function handleFormSubmit(){
      if(checked.length>0){
        const result = await publishBookingSlot({
          trialId: formValues.trialId,
          visitId: formValues.visitId,
          siteId:site,
          payload: {
              "userIds": checked.map((subject)=>{return(subject.userId)})
          }
        });
        if (!result?.error) {
          openMessageNotification({
              message: "slot published successfully",
              type: "success",
          });
        } else {
                openMessageNotification({
                  message: result?.error?.data?.error_description,
                  type: "error",
                });
        }
        setError("");
        setChecked([]);
        setRows([]);
        setSlotList([]);
        onClose();
      }
      else(
        setError("Please select minimum 1 subject.")
      )
  }

  const availableSlotsTable = () => (
    <Box sx={{height:250}}>
      <Typography variant="h6" color="initial">
        Available Slots
      </Typography>
      <Box
          sx={{
            width: "100%",
              height: 3,
              backgroundColor: theme.palette.primary.light,
              borderRadiusTop: 5
          }}
      />
      <TableContainer
        sx={{
          maxHeight: 220,
          border: `1px ${theme.palette.grey[100]} solid `,
        }}
      >
          <Table stickyHeader sx={{width: "100%", overflow:"scroll"}} aria-label="simple table">
              <CustomTableHead>
                  <TableRow>
                      <CustomTableHeadCell sx={{textalign:"center"}}>Date</CustomTableHeadCell>
                      <CustomTableHeadCell sx={{textalign:"center"}}>Start Time</CustomTableHeadCell>
                      <CustomTableHeadCell sx={{textalign:"center"}}>End Time</CustomTableHeadCell>
                  </TableRow>
              </CustomTableHead>
              <TableBody>
                
                {slotList.length===0 ?
                  (<TableRow>
                    <TableCell align={"center"} colSpan={8}>
                      <Typography variant="subtitle1" color="initial"> No Available Data.</Typography>
                    </TableCell>
                  </TableRow>)
                : 
                (slotList?.map((row) => (
                    <CustomTableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                    >
                        <TableCell sx={{textalign:'center'}}>{moment.utc(row.date).format("MM/DD/YYYY")}</TableCell>
                        <TableCell sx={{textalign:'center'}}>{moment(row.startTime,"HH:mm:ss").format("HH:mm")}</TableCell>
                        <TableCell sx={{textalign:'center'}}>{moment(row.endTime,"HH:mm:ss").format("HH:mm")}</TableCell>
                                
                    </CustomTableRow>
                )))}
              </TableBody>
          </Table>
      </TableContainer>
    </Box>
  )

  const subjectTable = (items) => (
    <Box sx={{height:250}}>
      <Typography variant="h6" color="initial">
        Subject List
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: 3,
          backgroundColor: theme.palette.primary.light,
          borderRadiusTop: 5
        }}
      />
      <TableContainer
        sx={{
          maxHeight: 220,
          border: `1px ${theme.palette.grey[100]} solid `,
        }}
      >
        <Table stickyHeader sx={{width: "100%", overflow:"scroll"}} aria-label="simple table">
          <CustomTableHead>
          <TableRow>
              {columns && columns.map((column,index) => (
                  <CustomTableHeadCell
                    key={column.id}
                    align={column.align}
                    width={column.width}
                  >
                    {index === 0 ?
                      <Tooltip title={numberOfChecked(items) === items.length ? "" : "Select-All"}>
                      <Checkbox
                        sx={{p:0}}
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                          numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                          'aria-label': 'all items selected',
                        }}
                      />
                      </Tooltip>
                      :
                      column.label
                    }
                  </CustomTableHeadCell>
               
              )
              )}
               </TableRow>
            </CustomTableHead>
            <TableBody>
              {rows.length === 0 ? (
                  <TableRow>
                    <TableCell align={"center"} colSpan={8}>
                      <Typography variant="subtitle1" color="initial"> No Available Data.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                columns && rows.map((subject, index) => {
                    return (
                      <CustomTableRow tabIndex={-1} key={index} hover sx={{cursor:'pointer'}}>
                        {columns.map((column, index) => {
                          const value = subject[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {index===0 ? 
                                <Checkbox
                                  sx={{p:0}}
                                  key={index}
                                  role="listitem"
                                  onClick={handleToggle(subject)}
                                  checked={checked.indexOf(subject) !== -1}
                                  tabIndex={-1}
                                  textalign={"center"}
                                  disableRipple
                                />  
                                : value
                              }
                            </TableCell>
                          );
                        })}
                      </CustomTableRow>
                    );
                  })
                )}
            </TableBody>
          </Table>
      </TableContainer>
    </Box>
  );

  

  return (
      <>
      <Dialog scroll={"paper"} open={open} onClose={()=>{onClose(); setChecked([]); setError(""); setRows([]); setSlotList([])}} maxWidth={"md"} fullWidth>
            <DialogTitle>Publish Slots</DialogTitle>
            <Box></Box>
            <DialogContent>
        <Formik
          initialValues={{
            trialId: "",
            visitId: "",
          }}
          validationSchema={docSchema}
          onSubmit={(values) => {
            setShowSubject(true);
            setFormValues(values);
          }}
          
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            values,
            touched,
            errors,
          }) => (
            
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4.9} sx={{height:80}}>
                      <FormControl
                        fullWidth
                        error={touched.trialId && Boolean(errors.trialId)}
                      >
                        <InputLabel>Trial</InputLabel>
                        <Field
                          component={Select}
                          onChange={(e) => {
                            handleFormChange(e);
                            handleChange(e);
                          }}
                          value = {values.trialId}
                          onBlur={handleBlur}
                          label="Trial"
                          inputProps={{
                            id: "trialId",
                            name: "trialId",
                          }}
                        >
                          {ongoingTrials &&
                            ongoingTrials.map((trial, index) => (
                              <MenuItem key={index} value={trial.id}>
                                {trial.protocolNumber}
                              </MenuItem>
                            ))}
                        </Field>
                        {touched.trialId && errors.trialId ? (
                          <FormHelperText>{errors.trialId}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} md={4.8} sx={{height:80}}>
                    <FormControl
                        fullWidth
                        error={touched.visitId && Boolean(errors.visitId)}
                      >
                        <InputLabel>Visit</InputLabel>
                        <Field
                          component={Select}
                          onChange={(e) => {
                            handleFormChange(e);
                            handleChange(e);
                          }}
                          value = {values.visitId}
                          onBlur={handleBlur}
                          label="Visit"
                          inputProps={{
                            id: "visitId",
                            name: "visitId",
                          }}
                        >
                          {visitList &&
                            visitList?.map((visit, index) => (
                              <MenuItem key={index} value={visit.id}>{visit.visitName}</MenuItem>
                            ))}
                        </Field>
                        {touched.visitId && errors.visitId ? (
                          <FormHelperText>{errors.visitId}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2.2} alignContent={"center"}>
                      <CustomButton type="submit" >Show Slots</CustomButton>
                    </Grid>

                    <Grid item xs={6} md={5.7}>
                      {availableSlotsTable()}
                    </Grid>
                    <Divider orientation="vertical" sx={{p:2, mt:6}} flexItem />
                    <Grid item xs={6} md={5.7}>
                      {subjectTable(rows)}
                      {error? 
                      <FormHelperText sx={{color:"#c62828"}}>{error}</FormHelperText> : ""
                      }
                    </Grid>            
                    <Box sx={{ p: 2, width: '100%', mt: 'auto', display: 'flex', justifyContent: 'end', columnGap: 2, bottom: 0}}>
                      <CustomButton variant="outlined" onClick={()=>{onClose(); setChecked([]); setError(""); setRows([]); setSlotList([])}}>Cancel</CustomButton>
                      <CustomButton variant="contained" disabled={slotList.length===0 || rows.length === 0} onClick={()=>handleFormSubmit()}>Publish</CustomButton>
                    </Box>
                    
                  </Grid>
                </Form>
          )}
      </Formik> 
      
      </DialogContent>
          </Dialog>      
      </>
  );
}

export default PublishSlot;