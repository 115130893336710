const DynamicFormAction = Object.freeze({
  FORM_SUBMITTED: 'FORM_SUBMITTED', // When the form is successfully submitted
  FORM_SUBMISSION_FAILED: 'FORM_SUBMISSION_FAILED', // When form submission fails
  FORM_VALIDATION_ERROR: 'FORM_VALIDATION_ERROR', // When there are validation errors
  FORM_LOADING: 'FORM_LOADING', // When the form is loading data
  FORM_LOADED: 'FORM_LOADED', // When the form data is loaded
  FORM_RESET: 'FORM_RESET', // When the form is reset to its initial state
  FORM_FIELD_CHANGED: 'FORM_FIELD_CHANGED', // When a form field value changes
  FORM_INIT: 'FORM_INIT', // When the form is initialized
  FORM_ERROR: 'FORM_ERROR', // When there is a generic error in the form
  FORM_SAVE_PROGRESS: 'FORM_SAVE_PROGRESS', // When the form save process is in progress
  FORM_SAVE_SUCCESS: 'FORM_SAVE_SUCCESS', // When the form save process is successful
FORM_SAVE_FAILURE: 'FORM_SAVE_FAILURE',
  NA : 'NA'
});

export default DynamicFormAction;
