import React, { useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormikField from '../../visitScheudle/FormikTextField';
import CustomButton from '../../../components/@extended/CustomButton';
import { addAssignmentForOrg, selectAssignmentDetails } from '../../../store/slices/pfasSlice';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateComplianceOfficer from '../CreateComplianceOfficer';
import FormikDatePickerField from '../../../components/common/FormikDatePickerField';
import moment from 'moment';

const AssignmentBasicDetailsSection = ({ handleTabChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sponsorId, assignmentId } = useParams();
  const [showCreateComplianceOfficer, setShowCreateComplianceOfficer] = useState(false);

  const organization = useSelector((state) => state.pfas.organizations.find((_sp) => String(_sp.id) === sponsorId));

  const studyDetails = useSelector((state) => selectAssignmentDetails(state, sponsorId, assignmentId));
  const validationSchema = Yup.object().shape({
    assignmentNumber: Yup.string()
      .required('Protocol Number is required')
      .matches(/^[A-Za-z0-9-]+$/, 'Protocol Number can only contain alphanumeric characters and dashes'),
    assignmentVersion: Yup.string()
      .required('Protocol Version is required')
      .matches(/^[A-Za-z0-9.]+$/, 'Protocol Version can only contain alphanumeric characters and periods'),
    assignmentDate: Yup.string().required('Assignmement Document Date is required'),
    assignmentTitle: Yup.string().required('Study Title is required'),
    complianceOfficer: Yup.string().required('Compliance Officer is required'),
  });

  const initialValues = {
    assignmentNumber: studyDetails ? studyDetails.assignmentNumber : '',
    assignmentVersion: studyDetails ? studyDetails.assignmentVersion : '',
    assignmentDate: studyDetails
      ? moment(studyDetails.assignmentDate).format('MM-DD-YYYY')
      : moment().format('MM-DD-YYYY'),
    assignmentTitle: studyDetails ? studyDetails.assignmentTitle : '',
    complianceOfficer: studyDetails ? studyDetails.complianceOfficer : '',
  };

  const handleFormSubmit = (values) => {
    dispatch(addAssignmentForOrg({ orgId: sponsorId, data: values }));
    navigate(window.location.pathname + '/' + String(organization.assignments.length + 1, { replace: true }));
  };
  return (
    <>
      <Grid container rowGap={2} sx={{ mt: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { }, errors) => {
            console.log(errors)
            handleFormSubmit(values);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, resetForm, touched, errors }) => (
            <Grid item xs={12}>
              <Form onSubmit={handleSubmit}>
                <Card sx={{ mb: 3, px: 1 }}>
                  <CardHeader
                    title="Assignment Details"
                    titleTypographyProps={{ variant: 'h6' }}
                    action={
                      <Box>
                        <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
                          <Typography variant="subtitle1" color={theme.palette.common.white}>
                            Upload Details
                          </Typography>
                        </CustomButton>
                        <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
                          Note: Upload file with CSV/Excel format.
                        </Typography>
                      </Box>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item sm={12} md={6}>
                        <FormControl fullWidth error={touched.assignmentNumber && Boolean(errors.assignmentNumber)}>
                          <Field
                            component={FormikField}
                            name="assignmentNumber"
                            label="Assignment Number"
                            type="text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <FormControl fullWidth error={touched.assignmentTitle && Boolean(errors.assignmentTitle)}>
                          <Field component={FormikField} name="assignmentTitle" label="Assignment Title" type="text" />
                        </FormControl>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <FormControl fullWidth error={touched.assignmentVersion && Boolean(errors.assignmentVersion)}>
                          <Field
                            component={FormikField}
                            name="assignmentVersion"
                            label="Assignment Version"
                            type="text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <FormControl fullWidth error={touched.assignmentDate && Boolean(errors.assignmentDate)}>
                          <Field
                            component={FormikDatePickerField}
                            name="assignmentDate"
                            label="Assignment Date"
                            type="text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={1.5} md={5.5}>
                        <Box>
                          <FormControl fullWidth error={touched.complianceOfficer && Boolean(errors.complianceOfficer)}>
                            <InputLabel>Compliance Officer</InputLabel>
                            <Field
                              component={Select}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              label="Compliance Officer"
                              inputProps={{
                                id: 'complianceOfficer',
                                name: 'complianceOfficer',
                              }}
                            >
                              <MenuItem disabled value={''}>
                                Select Compliance Officer
                              </MenuItem>
                              {organization.complianceOfficers &&
                                organization.complianceOfficers.map((cat) => (
                                  <MenuItem key={cat.value} value={cat.id}>
                                    {cat.firstName + ' ' + cat.lastName}
                                  </MenuItem>
                                ))}
                            </Field>
                            {touched.complianceOfficer && errors.complianceOfficer && (
                              <FormHelperText>{errors.complianceOfficer}</FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={0.5}>
                        <Box
                          sx={{
                            pt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton aria-label="Add" onClick={() => setShowCreateComplianceOfficer(true)}>
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CustomButton
                          variant="contined"
                          // onClick={handleSave}
                          type="save"
                          color="primary"
                          sx={{ padding: '8px 30px', textTransform: 'none' }}
                        >
                          Create Assignment
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Form>
            </Grid>
          )}
        </Formik>
      </Grid>
      <CreateComplianceOfficer
        open={showCreateComplianceOfficer}
        handleClose={() => setShowCreateComplianceOfficer(false)}
      />
    </>
  );
};

export default AssignmentBasicDetailsSection;
