import { Box, Link, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import SignatureBox from '../../components/common/SignatureBox';
import moment from 'moment';
import { bloqcibeApi, useSaveDOASignMutation } from '../../store/slices/apiSlice';
import { base64ImageToBlob } from '../util';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';

const SignField = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { s3Record, disabled = false, isError = false, access, documentType, assignedTaskId, reloadData } = props;
  const [sign, setSign] = useState(null);
  const [signatureDialog, setSignatureDialog] = useState(false);
  const [downloadDOASignature] = bloqcibeApi.endpoints.downloadDOASignature.useLazyQuery();
  const [saveDOASign] = useSaveDOASignMutation();
  const openDialog = () => {
    if (!disabled && access) {
      setSignatureDialog(true);
    }
  };
  useEffect(() => {
    (async () => {
      if (s3Record) {
        const result = await downloadDOASignature({ s3Key: s3Record.documentS3Key });
        if (result.data) {
          const byteArray = base64ToArrayBuffer(result.data);
          const rr = new Blob([byteArray], { type: 'application/octet-stream' });
          const reader = new FileReader();
          reader.readAsDataURL(rr);
          reader.onloadend = function () {
            const base64data = reader.result;
            setSign(base64data);
          };
        } else {
          console.log('Failed to load signature');
        }
      }
    })();
  }, [s3Record]);
  const handleClose = () => {
    setSignatureDialog(false);
  };
  const saveSignature = async (data) => {
    if(data==null){
      dispatch(
          openMessage({
              messageSeverity: 'error',
              message: 'Signature Required',
          })
      );
  }else{
    const formData = new FormData();
    formData.append('file', base64ImageToBlob(data));
    formData.append('documentType', documentType);
    await saveDOASign({ payload: formData, assignedTaskId: assignedTaskId });
    setSign(data);
    setSignatureDialog(false);
    reloadData();
  } 
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: isError
            ? `0px 0px 8px 0px ${theme.palette.error.main}`
            : '1px 1px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.10)',
          height: 60,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {sign ? (
          <img
            src={sign}
            //width={180}
            height={50}
            disabled={disabled}
            //alt={"Click here to sign"}
            onClick={openDialog}
          />
        ) : (
          <Typography onClick={openDialog} color={'grey'} sx={{ fontSize: 12 }}>
            {disabled ? 'Selected' : access ? 'Click here to sign' : 'Signature'}
          </Typography>
        )}
      </Box>
      <SignatureBox open={signatureDialog} handleClose={handleClose} saveSign={saveSignature} />
    </>
  );
};

const SignatureSection = (props) => {
  const { row, documentType, reloadData, disabled, access = false } = props;
  const userDetails = useSelector((state) => state.userDetails.loggedInUser);
  const [showComment, setShowComment] = useState(false);
  const [s3Record, setS3Record] = useState();
  useEffect(() => {
    if (row) {
      const signRec = row.documents && row.documents.find((_obj) => _obj.type == documentType);
      if (signRec) {
        setS3Record(signRec);
      }
    }
  }, [row]);
  const showCommentText = () => {
    setShowComment(true);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
      <SignField
        documentType={documentType}
        assignedTaskId={row.assignedTaskId}
        s3Record={s3Record}
        reloadData={reloadData}
        disabled={disabled}
        access={access}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 14 }}>
          {s3Record && s3Record.user.firstName + ' ' + s3Record.user.lastName}
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
          {s3Record && moment(s3Record.updatedAt).format('DD/MM/YYYY')}
        </Typography>
      </Box>

      {/* {!showComment && <Link ariant="body2" component="button" onClick={showCommentText}>
                <Typography sx={{ fontSize: 12 }}>View Comment</Typography>
            </Link>}
            {
                showComment &&
                <Box sx={{ padding: 1, border: '1px solid #e6e6e6' }}>
                    <Typography sx={{ fontSize: 12 }}>I approve of this change
                        to DOA</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link ariant="body2" component="button" onClick={() => setShowComment(false)} >
                            <Typography sx={{ fontSize: 12 }}>Less</Typography>
                        </Link>
                    </Box>
                </Box>
            } */}
    </Box>
  );
};

export default SignatureSection;
