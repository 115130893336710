import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@mui/material';
import React, { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RemovedUserDialog = ({isRemoved}) => {
  const theme = useTheme()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleClose = () => {
    navigate('/login');
    window.location.reload(); // Reload the page after navigation
  };
  return (
    <Dialog open={isRemoved} onClose={handleClose}>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 2 }}>
            <InfoOutlinedIcon sx={{ fontSize: 60, color: theme.palette.grey.A700 }}></InfoOutlinedIcon>
          </Box>
          <DialogContentText>You have no assigned trials. Please contact the sponsor admin.</DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemovedUserDialog;