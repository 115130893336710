import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../components/@extended/CustomButton';
import { addSiteCroToStudy, selectStudyDetails } from '../../../store/slices/financeSlice';
import AddSiteCro from './AddSiteCro';
import AddIcon from '@mui/icons-material/Add';
import SiteBudgetForm from './SiteBudgetForm';
import ManageInvoice from './manageInvoice/ManageInvoice';


const StudySiteSelection = ({ handleTabChange }) => {
  const { sponsorId, studyId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedSite, setSelectedSite] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(null);

  const sites = useSelector((state) => state.finance.sites);
  const studyDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));
  const [site, setSite] = useState(null);
  const [openAddSite, setOpenAddSite] = useState(false);
  const handleAssignSite = () => {
    dispatch(addSiteCroToStudy({ sponsorId, studyId, siteId: site }));
    setSite(null);
  };

  const handleOpenInvoice = (site) => {
    setSelectedSite(site);
    setOpenInvoice(true);
  };

  const handleCloseInvoice = () => {
    setSelectedSite(null);
    setOpenInvoice(null);
  };

  return (
    <>
      {!selectedSite && !openInvoice ? (
        <Card sx={{ pt: 1, pr: 1 }}>
          <CardHeader
            title="Sites/CROs"
            titleTypographyProps={{ variant: 'h6' }}
            action={
              <>
                <CustomButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpenAddSite(true);
                  }}
                >
                  <Typography variant="subtitle1" color={theme.palette.common.white}>
                    Create Site/CRO
                  </Typography>
                </CustomButton>
                <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
                  <Typography variant="subtitle1" color={theme.palette.common.white}>
                    Upload Details
                  </Typography>
                </CustomButton>
                <Typography variant="subtitle1" fontSize={'12px'} color={'black'} textAlign={'right'} mt={1}>
                    Note: Upload file with CSV/Excel format.
                </Typography>
              </>
            }
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={3}></Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormControl fullWidth>
                  <InputLabel>Sites/CROs</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={site}
                    label="Sites/CROs"
                    onChange={(e) => {
                      setSite(e.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em style={{ color: '#aaa', fontStyle: 'normal' }}>
                        {sites.filter((_site) => !studyDetails?.sites?.some((_studySite) => _studySite.id === _site.id))
                          .length !== 0
                          ? 'Select Site/CRO'
                          : 'Add new Site/CRO'}
                      </em>
                    </MenuItem>
                    {sites
                      .filter((_site) => !studyDetails?.sites?.some((_studySite) => _studySite.id === _site.id))
                      .map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.orgName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton onClick={handleAssignSite}>Add</CustomButton>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {studyDetails?.sites?.length >= 1 ? (
                  studyDetails?.sites?.map((site, index) => {
                    return (
                      <Box
                        key={site.id}
                        sx={{
                          marginTop: index === 0 ? 0 : 3,
                          border: '1px solid #e7e7e7',
                          padding: 3,
                          borderRadius: 1,
                          backgroundColor: '#fbfbfb',
                        }}
                      >
                        <Box sx={{ display: 'flex', marginBottom: 2 }}>
                          <Typography sx={{ flexGrow: 1, fontWeight: 'bold', color: 'grey' }}>
                            {site?.orgName}
                          </Typography>
                          <Button
                            variant="outlined"
                            sx={{ marginX: 2 }}
                            onClick={() => {
                              setSelectedSite(site);
                            }}
                          >
                            Manage Budget
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              handleOpenInvoice(site);
                            }}
                          >

                            Manage Invoice
                          </Button>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item sm={12} md={2}>
                            <Typography sx={{ color: 'grey' }}>{'Site Address:'}</Typography>
                          </Grid>
                          <Grid item sm={12} md={10}>
                            <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{site?.addressLine1}</Typography>
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <Typography sx={{ color: 'grey' }}>{'Contact Information:'}</Typography>
                          </Grid>
                          <Grid item sm={12} md={10}>
                            <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{site?.contactInfo}</Typography>
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <Typography sx={{ color: 'grey' }}>{'Contact Email:'}</Typography>
                          </Grid>
                          <Grid item sm={12} md={10}>
                            <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{site?.contactEmail}</Typography>
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <Typography sx={{ color: 'grey' }}>{'Account Status:'}</Typography>
                          </Grid>
                          <Grid item sm={12} md={10}>
                            <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{site?.accountStatus}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 200,
                      }}
                    >
                      <Typography variant="subtitle1" color="initial">
                        No Sites/CROs selected.
                      </Typography>
                      <Typography variant="subtitle2" color="initial" sx={{ fontStyle: 'italic' }}>
                        Note: You can add new site or upload sites information.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              {studyDetails?.sites?.length >= 1 && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CustomButton variant="outlined" onClick={() => handleTabChange('_', 0)}>
                    Back
                  </CustomButton>
                  <CustomButton variant="contained" onClick={() => handleTabChange('_', 2)}>
                    Save & Continue
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      ) : selectedSite && !openInvoice ? (
        <SiteBudgetForm selectedSite={selectedSite} setSelectedSite={setSelectedSite} openInvoice={handleOpenInvoice} />
      ) : (
        <ManageInvoice
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
          closeInvoice={handleCloseInvoice}
        />
      )}
      <AddSiteCro
        open={openAddSite}
        onClose={() => {
          setOpenAddSite(false);
        }}
      />
    </>
  );
};

export default StudySiteSelection;