import React, { useEffect, useMemo, useState } from "react";
import CustomCard from "../../components/@extended/CustomCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  CustomTableHead,
  CustomTableHeadCell,
} from "../../components/@extended/CustomTable";
import CustomButton from "../../components/@extended/CustomButton";
import { useGetLibraryFormQuery, useGetSubjectListQuery } from "../../store/slices/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useNavigate } from "react-router-dom";
import { setSteps } from "../../store/slices/subjectSlice";
import { updatePagination } from "../../store/slices/applicationSlice";
import _ from "lodash";
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import DownloadAEReport from "../../components/common/DownloadAEReport";
import CustomTablePagination from "../../components/common/TablePagination";
import AnimatedBloqcubeLogo from "../../components/Logo/AnimatedBloqcubeLogo";

const SubjectListCard = ({ trialId, siteId, trialSiteId, setOpenAeDownload }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // const [openAeDownload, setOpenAeDownload] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.SUBJECT_TABLE);
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const { data: subjectList, isLoading } = useGetSubjectListQuery({
    sponsorId: sponsorId,
    trialId: trialId,
    siteId: siteId,
  });
  const enrolmentDocumentKey = useSelector(
    (state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.eConsentForm
  );
  const { data: formData } = useGetLibraryFormQuery(
    `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`
  );
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [searchedSubjectList, setSearchedSubjectList] = React.useState([]);

  const handleSearch = () => {
    if (searchValue) {
      setRows(searchedSubjectList);
    }
  };

  const columns = useMemo(() => {
    if (subjectList && formData) {
      const data = [
        {
          id: 'srNo',
          label: `Sr No`,
          minWidth: 60,
          align: 'left',
        },
        {
          id: 'subject',
          label: 'Subject',
          minWidth: 200,
          align: 'left',
        },
      ];
      formData.steps.forEach((col) => {
        if (!col.ignoreStatusCheck)
          data.push({
            id: col.key,
            label: col.label,
            minWidth: 70,
            align: 'center',
          });
      });
      data.push({
        id: 'visitCount',
        label: 'Visit',
        minWidth: 70,
        align: 'center',
      });
      return data;
    }
  }, [subjectList, formData]);
  useEffect(() => {
    if (subjectList) {
      const data = subjectList.map((subject, index) => {
        const result = _.reduce(
          subject.stepStatus,
          (acc, step) => {
            acc[step.stepKey] = step.status;
            return acc;
          },
          {}
        );
        return {
          srNo: index + 1,
          subject: subject?.subjectName,
          subjectId: subject?.id,
          visitCount: subject?.visitCount,
          subjectMasterId: subject?.stepStatus[0]?.subjectMasterId,
          subjectNumber: subject?.subjectNumber,
          ...result,
        };
      });
      if (!searchValue) {
        setRows(data);
      } else {
        dispatch(updatePagination({tableType:'SUBJECT_TABLE',page:0,rowsPerPage}));


        const searchedSubjects = data.filter(function (row) {
          if ( row['subject'] &&
            row['subject'].toLowerCase().includes(searchValue.toLowerCase()) 
          ) {
            return row;
          }
        });
        setRows(searchedSubjects);
        setSearchedSubjectList(searchedSubjects);
      }
    }
  }, [subjectList, searchValue]);

  const handleNavigate = (subjectId, subjectMasterId) => {
    const step = _.filter(subjectList, (su) => {
      return su.stepStatus[0]?.subjectMasterId === subjectMasterId;
    });
    dispatch(setSteps(step[0]?.stepStatus));
    navigate(`/eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectMasterId}/details`, {});
  };

  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'SUBJECT_TABLE',page,rowsPerPage}));
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          height: 60,
          backgroundColor: '#fafafa',
          mb: 2,
        }}
      >
        <FormControl sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Search Subject</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={'text'}
            onChange={(e) => setSearchValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleSearch} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Search Subject"
          />
        </FormControl>
        <Box>
          <CustomButton
            // sx={{ mr: 2 }}
            variant="contained"
            onClick={() => {
              setOpenAeDownload(true);
            }}
          >
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              AE/SAE Report
            </Typography>
          </CustomButton>
          {/* <CustomButton variant="contained" onClick={() => {}}>
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              Export
            </Typography>
          </CustomButton> */}
        </Box>
      </Box>
      <CustomCard
        title={'Subjects'}
        subHeader={`Total: ${rows?.length}`}
        sx={{
          p: 1,
          pt: 2,
          paddingBottom: 0,
          "&:last-child": {
            paddingBottom: 0,
          }
        }}
        action={
          <div style={{ display: 'flex', alignItems: 'center', paddingRight: 15 }}>
            <CheckCircleRoundedIcon
              sx={{
                color: theme.palette.success.light,
                fontSize: 20,
              }}
            />
            <Typography>Completed</Typography>
            <Divider orientation="vertical" width={5} flexItem />
            <WatchLaterIcon
              sx={{
                color: theme.palette.primary.light,
                fontSize: 20,
              }}
            />
            <Typography>Verification Pending</Typography>
            <Divider orientation="vertical" width={5} flexItem />
            <PendingActionsRoundedIcon
              sx={{
                color: theme.palette.primary.light,
                fontSize: 20,
              }}
            />
            <Typography>External Verification Pending</Typography>
            <Divider orientation="vertical" width={5} flexItem />
            <WatchLaterIcon
              sx={{
                color: 'lightgrey',
                fontSize: 20,
              }}
            />
            <Typography>Pending to Submit</Typography>
          </div>
        }
      >
        <TableContainer
          sx={{
            border: `1px ${theme.palette.grey[100]} solid `,
          }}
        >
          <Table stickyHeader>
            <CustomTableHead>
              {columns &&
                columns.map((column) => {
                  return (
                    <CustomTableHeadCell key={column.id} align={column.align} width={column.minWidth}>
                      {column.label}
                    </CustomTableHeadCell>
                  );
                })}
            </CustomTableHead>
            <TableBody>
              {isLoading ? (
                <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ height: 250, width: 250 }}>
                    <AnimatedBloqcubeLogo />
                  </Box>
                </Box>
              ) : rows.length === 0 && !isLoading ? (
                <TableRow>
                  <TableCell align={'center'} colSpan={8}>
                    <Typography variant="subtitle1" color="initial">
                      No Available Data.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                columns && !isLoading &&
                (rowsPerPage > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows
                ).map((subject, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index} hover sx={{ cursor: 'pointer' }}>
                      {columns.map((column) => {
                        const value = subject[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => {
                              handleNavigate(subject.subjectId, subject.subjectMasterId);
                            }}
                          >
                            {value === 'Pending' ? (
                              <WatchLaterIcon
                                sx={{
                                  color: 'lightgrey',
                                  fontSize: 20,
                                }}
                              />
                            ) : value === 'Verification_Pending' ? (
                              <WatchLaterIcon
                                sx={{
                                  color: theme.palette.primary.light,
                                  fontSize: 20,
                                }}
                              />
                            ) : value === 'Completed' ? (
                              <CheckCircleRoundedIcon
                                sx={{
                                  color: theme.palette.success.light,
                                  fontSize: 20,
                                }}
                              />
                            ) : value === 'External_Verification_Pending' ? (
                              <PendingActionsRoundedIcon
                                sx={{
                                  color: theme.palette.primary.light,
                                  fontSize: 20,
                                }}
                              />
                            ) : value === 'Completed' ? (
                              <CheckCircleRoundedIcon
                                sx={{
                                  color: theme.palette.success.light,
                                  fontSize: 20,
                                }}
                              />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={rows.length} onNewPage={onNewPage}/>
      </CustomCard>
    </>
  );
};

export default SubjectListCard;
