import { Box, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setQuizDrawer } from '../../../store/slices/applicationSlice';

const ResourceLinkField = (props) => {
  const { label, instructionText, link, _key } = props;
  const dispatch = useDispatch();

  const handleClick = (e) => {
  if (_key === 'icQuiz') {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setQuizDrawer(true));
  } 
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: label ? 2 : 0 }}>
      <Typography variant="h6">{label}</Typography>
      <Link component={_key === 'icQuiz' ? 'button' : ''} onClick={(e) => handleClick(e)} href={link}>
        {instructionText}
      </Link>
    </Box>
  );
};

export default ResourceLinkField;
