import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../components/@extended/CustomCard";
import {
  CustomTableHead,
  CustomTableHeadCell,
} from "../../components/@extended/CustomTable";
import { useGetWithdrawSubjectListQuery } from "../../store/slices/apiSlice";
import { selectCurrentUser } from "../../store/slices/authSlice";
import { getUserRole } from "../util";
import CustomTablePagination from "../../components/common/TablePagination";
import { updatePagination } from "../../store/slices/applicationSlice";

const SubjectWithdrawListCard = ({ trialId, sponsorId, siteId,trialSiteId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.WITHDRAW_SUBJECT_TABLE);
  const { data: subjectList } = useGetWithdrawSubjectListQuery({
    trialId,
    sponsorId,
    siteId,
  });
  const user = useSelector(selectCurrentUser);
  const userRole = getUserRole(user);
  const columns = useMemo(() => {
    if (subjectList) {
      switch (userRole) {
        case 'PI':
        case 'site_coordinator':
          return [
            {
              id: 'srNo',
              label: `Sr No.`,
              minWidth: 60,
              align: 'left',
            },
            {
              id: 'subjectName',
              label: 'Subject Name',
              minWidth: 200,
              align: 'center',
            },
            {
              id: 'subjectNumber',
              label: 'Subject Number',
              minWidth: 200,
              align: 'center',
            },
            {
              id: 'status',
              label: 'Status',
              minWidth: 200,
              align: 'center',
            },
            {
              id: 'createdAt',
              label: 'Discontinued Date',
              minWidth: 200,
              align: 'center',
            },
            // {
            //   id: 'visitCount',
            //   label: 'Visit',
            //   minWidth: 70,
            //   align: 'center',
            // },
          ];
        case 'site_monitor':
          return [
            {
              id: 'srNo',
              label: `Sr No.`,
              minWidth: 60,
              align: 'left',
            },
            {
              id: 'subjectNumber',
              label: 'Subject Number',
              minWidth: 200,
              align: 'center',
            },
            {
              id: 'status',
              label: 'Status',
              minWidth: 200,
              align: 'center',
            },
            {
              id: 'createdAt',
              label: 'Discontinued Date',
              minWidth: 200,
              align: 'center',
            },
            // {
            //   id: 'visitCount',
            //   label: 'Visit',
            //   minWidth: 70,
            //   align: 'center',
            // },
          ];
      }

    }
  }, [subjectList]);

  const handleNavigate = (subjectMasterId) => {
    navigate(`/eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectMasterId}/details`, {})
  }
  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'WITHDRAW_SUBJECT_TABLE',page,rowsPerPage}));
  }
    
  return (
    <>
      {
       subjectList && <CustomCard
          title={"Discontinued Subjects"}
          subHeader={`Total: ${subjectList?.length}`}
          sx={{
            p: 1,
            pt: 2,
            paddingBottom: 0,
            "&:last-child": {
              paddingBottom: 0,
            }
          }}
        >
          <TableContainer
            sx={{
              border: `1px ${theme.palette.grey[100]} solid `,
            }}
          >
            <Table stickyHeader>
              <CustomTableHead>
                {columns &&
                  columns.map((column) => {
                    return (
                      <CustomTableHeadCell
                        key={column.id}
                        align={column.align}
                        width={column.minWidth}
                      >
                        {column.label}
                      </CustomTableHeadCell>
                    );
                  })}
              </CustomTableHead>
              <TableBody>
                {subjectList?.length === 0 ? (
                  <TableRow>
                    <TableCell align={"center"} colSpan={8}>
                      <Typography variant="subtitle1" color="initial">
                        No Available Data.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  columns &&
                  (rowsPerPage > 0
                    ? subjectList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : subjectList
                  ).map((subject, index1) => {
                    return (
                      <TableRow tabIndex={-1} key={index1} hover sx={{cursor:'pointer'}}>
                        {columns.map((column) => {
                          let value;
                          switch (column.id) {
                            case "srNo":
                              value = index1 + 1 +(page * rowsPerPage);
                              break;
                            case "createdAt":
                              value = moment(subject[column.id]).format("DD/MM/YYYY");
                              break;
                            case "status":
                              if (subject[column.id] === "Enrollment_Failure") {
                                value = "Screening Failed";
                              } else {
                                value = subject[column.id];
                              }
                              break;
                            default:
                              value = subject[column.id];
                              break;
                          }
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                handleNavigate(subject.id);
                              }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={subjectList.length} onNewPage={onNewPage}/>
        </CustomCard>
      }
    </>
  );
};

export default SubjectWithdrawListCard;
