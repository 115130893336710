import { Box, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../@extended/CustomButton';
import { bloqcibeApi } from '../../store/slices/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openBackdropLoading } from '../../store/slices/applicationSlice';
import { openMessage } from '../../store/slices/showMessageSlice';

const DownloadAEReport = ({ trialId, open, handleClose, siteId, documentKey }) => {
  const dispatch = useDispatch();
  const [value,setValue]= useState("")    
  const [error,setError]= useState("")
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const [downloadAESAEReport] = bloqcibeApi.endpoints.downloadAESAEReport.useLazyQuery();

  const onCloseModal = () => {
        setError("");
        setValue("");
        handleClose();
  };

  function handleChange(value){
    setValue(value);
    setError("")
  }

  const handleDownload = async () =>{
    if(!value){
      setError("Document type is required")
    }
    else{
      dispatch(openBackdropLoading({ backdropLoading: true }));
      onCloseModal();
      try {
        const response = await downloadAESAEReport({
          sponsorId:sponsorId, 
          trialId:trialId, 
          siteId:siteId, 
          documentKey:documentKey,
          type:value,
        }).unwrap();
        
        const url = response;
        const blob = new Blob([url], { type: 'application/pdf' });
        const a = document.createElement('a');
        a.href = url;
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-');
        const time = now.toLocaleTimeString().replace(/:/g, '-');
        const dateTime = `${date}_${time}`;
        const fileTypeExtension = blob.type.split('/')[1];
        a.download = `_AE/SAE_${dateTime}.${fileTypeExtension}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        dispatch(openBackdropLoading({ backdropLoading: false }));
        dispatch(
          openMessage({
            messageSeverity: "success",
            message: "Document downloaded successfully!",
          })
        );
      } catch (error) {
        dispatch(openBackdropLoading({ backdropLoading: false }));
        dispatch(
          openMessage({
            messageSeverity: "error",
            message: (value==="ADVERSE_EVENT")? "Subject data with Adverse Event not found" : "Subject data with Serious Adverse Event not found",
          })
        );
      }
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      //sx={{minHeight: '50%'}}
      PaperProps={{ sx: { minHeight: 'fit-content' } }}
      scroll={'paper'}
      open={open}
      onClose={onCloseModal}
    >
      <DialogTitle>Download AE/SAE Report</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    <DialogContent>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
        <FormControl sx={{minWidth: '100%', height:'70px'}}>
          <InputLabel>Document Type</InputLabel>
          <Select
            label="Document type"
            value={value}
            onChange={(e)=>handleChange(e.target.value)}
            placeholder='Document type'
          >
            <MenuItem value={'ADVERSE_EVENT'}>Adverse Event Report</MenuItem>
            <MenuItem value={'SERIOUS_ADVERSE_EVENT'}>Serious Adverse Event Report</MenuItem>
          </Select>
          {error && (
          <Typography variant="body2" color="error" sx={{ textAlign: 'left', pt: 0.5, mb: 1 }}>
            {error}
          </Typography>
        )}
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}>
          <CustomButton variant={'contained'} size="small" onClick={handleDownload}>
            Download
          </CustomButton>
      </Box>
    </DialogContent>
    </Dialog>
  );
};

export default DownloadAEReport;
