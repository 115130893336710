import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import CustomButton from '../../../components/@extended/CustomButton';
import FormikField from '../../visitScheudle/FormikTextField';
import AddSiteCro from './AddSiteCro';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addSiteCroToStudy, saveStudyBasicDetails, selectStudyDetails, updateStudyBasicDetails } from '../../../store/slices/financeSlice';
import FocusError from '../../../components/common/FocusError';
import StudyBasicDetailsSection from './StudyBasicDetailsSection';
import { openMessage } from '../../../store/slices/showMessageSlice';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const StudyDetailsForm = ({ handleTabChange }) => {
  const { sponsorId, studyId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sameAsSponsorAccount, setSameAsSponsorAccount] = useState(false);
  const sponsor = useSelector((state) =>
    state.finance.sponsors.find((sponsor) => String(sponsor.id) === String(sponsorId))
  );
  const [site, setSite] = useState(null);
  const [openAddSite, setOpenAddSite] = useState(false);
  const sites = useSelector((state) => state.finance.sites);
  const [accountVerified, setAccountVerified] = useState(false);
  const accountDetailsValidationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required('Account Holder Name is required'),
    account_holder_email: Yup.string()
      .required('Account Holder Email is required')
      .email('Account Holder Email must be a valid email address')
      .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
    account_holder_number: Yup.string()
      .required('Account Number is required')
      .matches(/^[0-9]+$/, 'Account Number must be numeric'),
    confirm_account_holder_number: Yup.string()
      .required('Confirm Account Number is required')
      .oneOf([Yup.ref('account_holder_number'), null], 'Account Numbers must match'),
    routingNumber: Yup.string()
      .required('Routing Number is required')
      .matches(/^[0-9]+$/, 'Routing Number must be numeric'),
    confirm_routing_Number: Yup.string()
      .required('Confirm Routing Number is required')
      .oneOf([Yup.ref('routingNumber'), null], 'Routing Numbers must match'),
    abaNumber: Yup.string()
      .required('ABA number is required')
      .matches(/^[0-9]+$/, 'ABA number must be numeric'),
    swiftNumber: Yup.string()
      .required('Swift Number is required')
      .matches(/^[0-9]+$/, 'Swift Number must be numeric'),
    country: Yup.string().required('Country is required'),
    currency: Yup.string().required('Currency is required'),
  });
  const validationSchema = Yup.object().shape({
    protocolNumber: Yup.string()
      .required('Protocol Number is required'),
    // .matches(/^[A-Za-z0-9-]+$/, 'Protocol Number can only contain alphanumeric characters and dashes'),
    protocolVersion: Yup.string()
      .required('Protocol Version is required'),
    // .matches(/^[A-Za-z0-9.-]+$/, 'Protocol Version can only contain alphanumeric characters and periods'),
    totalSubjectNumber: Yup.number()
      .required('Total Subject Number is required')
      .positive('Total Subject Number must be a positive number')
      .integer('Total Subject Number must be an integer'),
    studyTitle: Yup.string().required('Study Title is required'),

    accountDetails: accountDetailsValidationSchema,
  });

  const studyDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));

  const [initialValues, setInitialValues] = useState({
    protocolNumber: '',
    protocolVersion: '',
    totalSubjectNumber: '',
    studyTitle: '',
    accountDetails: {
      account_holder_name: '',
      account_holder_email: '',
      account_holder_number: '',
      confirm_account_holder_number: '',
      routingNumber: '',
      confirm_routing_Number: '',
      abaNumber: '',
      swiftNumber: '',
      country: '',
      currency: '',
    },
  });

  useEffect(() => {
    if (studyDetails) {
      setInitialValues({
        protocolNumber: studyDetails.protocolNumber || '',
        protocolVersion: studyDetails.protocolVersion || '',
        totalSubjectNumber: studyDetails.totalSubjectNumber || '',
        studyTitle: studyDetails.studyTitle || '',
        accountDetails: {
          account_holder_name: studyDetails.accountDetails?.account_holder_name || '',
          account_holder_email: studyDetails.accountDetails?.account_holder_email || '',
          account_holder_number: studyDetails.accountDetails?.account_holder_number || '',
          confirm_account_holder_number: studyDetails.accountDetails?.confirm_account_holder_number || '',
          routingNumber: studyDetails.accountDetails?.routingNumber || '',
          confirm_routing_Number: studyDetails.accountDetails?.confirm_routing_Number || '',
          abaNumber: studyDetails.accountDetails?.abaNumber || '',
          swiftNumber: studyDetails.accountDetails?.swiftNumber || '',
          country: studyDetails.accountDetails?.country || '',
          currency: studyDetails.accountDetails?.currency || '',
        },
      });
      setSameAsSponsorAccount(studyDetails.sameAsSponsorAccount)
      if(studyDetails.accountStatus === 'Verified'){
        setAccountVerified(true);
      }
    }
  }, [studyDetails]);

  const setSponsorAccount = (e, values) => {
    const checkedVal = e.target.checked;
    setSameAsSponsorAccount(checkedVal);
    const _sameasSponsor = checkedVal ? sponsor.accountDetails : undefined;
    setInitialValues({
      ...values,
      accountDetails: {
        account_holder_name: _sameasSponsor?.account_holder_name || '',
        account_holder_email: _sameasSponsor?.account_holder_email || '',
        account_holder_number: _sameasSponsor?.account_holder_number || '',
        confirm_account_holder_number: _sameasSponsor?.confirm_account_holder_number || '',
        routingNumber: _sameasSponsor?.routingNumber || '',
        confirm_routing_Number: _sameasSponsor?.confirm_routing_Number || '',
        abaNumber: _sameasSponsor?.abaNumber || '',
        swiftNumber: _sameasSponsor?.swiftNumber || '',
        country: _sameasSponsor?.country || '',
        currency: _sameasSponsor?.currency || '',
      },
    })
  }

  const handleFormSubmit = (values) => {
    if(accountVerified){
      dispatch(updateStudyBasicDetails({ sponsorId, studyId, studyDetails: { ... values, sameAsSponsorAccount: sameAsSponsorAccount, accountStatus:'Verified'} }));
    }else{
      dispatch(updateStudyBasicDetails({ sponsorId, studyId, studyDetails: { ... values, sameAsSponsorAccount: sameAsSponsorAccount, accountStatus:'Unverified'} }));
    }
    handleTabChange('_', 1);
    setAccountVerified(false);
  };

  const handleAssignSite = () => {
    dispatch(addSiteCroToStudy({ sponsorId, studyId, siteId: site }));
    setSite(null);
  };

  const validateAccountDetails = async (values, setFieldTouched, setFieldError) => {
    try {
      await accountDetailsValidationSchema.validate(values.accountDetails, { abortEarly: false });
      setAccountVerified(true);
      dispatch(openMessage({ messageSeverity: 'success', message: 'Bank Account Details Validated.' }));
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          setFieldTouched(`accountDetails.${err.path}`, true);
          setFieldError(`accountDetails.${err.path}`, err.message);
        });
      }
      setAccountVerified(false);
    }
  };
  return (
    <Grid container rowGap={2} sx={{ mt: 2 }}>
      {studyId ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, handleBlur, resetForm, touched, errors, values, setFieldTouched, setFieldError }) => (
            <>
              <Grid item xs={12}>
                <Form onSubmit={handleSubmit}>
                  <Card sx={{ mb: 3, px: 1 }}>
                    <CardHeader
                      title="Study Details"
                      titleTypographyProps={{ variant: 'h6' }}
                      action={
                        <Box>
                          <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => { }}>
                            <Typography variant="subtitle1" color={theme.palette.common.white}>
                              Upload Details
                            </Typography>
                          </CustomButton>
                          <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
                            Note: Upload file with CSV/Excel format.
                          </Typography>
                        </Box>
                      }
                    />
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item sm={12} md={6}>
                          <FormControl fullWidth error={touched.protocolNumber && Boolean(errors.protocolNumber)}>
                            <Field component={FormikField} name="protocolNumber" label="Protocol Number" type="text" />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl fullWidth error={touched.protocolVersion && Boolean(errors.protocolVersion)}>
                            <Field
                              component={FormikField}
                              name="protocolVersion"
                              label="Protocol Version"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={touched.totalSubjectNumber && Boolean(errors.totalSubjectNumber)}
                          >
                            <Field
                              component={FormikField}
                              name="totalSubjectNumber"
                              label="Total Subject Number"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl fullWidth error={touched.studyTitle && Boolean(errors.studyTitle)}>
                            <Field component={FormikField} name="studyTitle" label="Study Title" type="text" />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                        <Grid item sm={12}>
                          <Box sx={{ display:'flex', justifyContent:'space-between' }}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="h6" mr={2}>Bank Account Information</Typography>
                              {accountVerified ?
                                <CheckCircleRoundedIcon
                                  sx={{
                                    color: theme.palette.success.light,
                                    fontSize: 20,
                                  }}
                                /> :
                                <WatchLaterIcon
                                  sx={{
                                    color: '#FFCB00',
                                    fontSize: 20,
                                  }}
                                />
                              }
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: accountVerified ? theme.palette.success.light : ' #FFCB00',
                                  width: 'fit-content',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  marginLeft: theme.spacing(0.5),
                                }}
                              >
                                {accountVerified ? "Verified" : "Unverified"}
                              </Typography>
                            </Box>
                            <FormControlLabel control={<Checkbox disabled={accountVerified ? true : false} checked={sameAsSponsorAccount}
                              onChange={(e) => setSponsorAccount(e, values)} />} label="Same as sponsor's account details" />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.account_holder_name &&
                              Boolean(errors.accountDetails?.account_holder_name)
                            }
                          >
                            <Field
                              component={FormikField}
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              name="accountDetails.account_holder_name"
                              label="Account Holder Name"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.account_holder_email &&
                              Boolean(errors.accountDetails?.account_holder_email)
                            }
                          >
                            <Field
                              component={FormikField}
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              name="accountDetails.account_holder_email"
                              label="Account Holder Email"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.account_holder_number &&
                              Boolean(errors.accountDetails?.account_holder_number)
                            }
                          >
                            <Field
                              component={FormikField}
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              name="accountDetails.account_holder_number"
                              label="Account Number"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.confirm_account_holder_number &&
                              Boolean(errors.accountDetails?.confirm_account_holder_number)
                            }
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.confirm_account_holder_number"
                              label="Confirm Account Number"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.routingNumber && Boolean(errors.accountDetails?.routingNumber)
                            }
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.routingNumber"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              label="Routing Number"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={
                              touched.accountDetails?.confirm_routing_Number &&
                              Boolean(errors.accountDetails?.confirm_routing_Number)
                            }
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.confirm_routing_Number"
                              label="Confirm Routing Number"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={touched.accountDetails?.abaNumber && Boolean(errors.accountDetails?.abaNumber)}
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.abaNumber"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              label="ABA number"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={touched.accountDetails?.swiftNumber && Boolean(errors.accountDetails?.swiftNumber)}
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.swiftNumber"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              label="Swift Number"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={touched.accountDetails?.country && Boolean(errors.accountDetails?.country)}
                          >
                            <Field component={FormikField}  disabled={accountVerified || sameAsSponsorAccount ? true : false} name="accountDetails.country" label="Country" type="text" />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <FormControl
                            fullWidth
                            error={touched.accountDetails?.currency && Boolean(errors.accountDetails?.currency)}
                          >
                            <Field
                              component={FormikField}
                              name="accountDetails.currency"
                              disabled={accountVerified || sameAsSponsorAccount ? true : false}
                              label="Currency"
                              type="text"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <CustomButton
                            variant="outlined"
                            onClick={() => {
                              accountVerified ? 
                                setAccountVerified(false) :
                                validateAccountDetails(values, setFieldTouched, setFieldError);
                            }}
                            color="primary"
                            sx={{ padding: '8px 30px', textTransform: 'none' }}
                          >
                            {!accountVerified ? "Validate" : "Edit"}
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Form>
                <Grid item xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Box sx={{ pt: 3 }}>
                    <CustomButton
                      variant="outlined"
                      onClick={()=>{resetForm(); setAccountVerified(false)}}
                      color="primary"
                      sx={{ padding: '8px 30px', textTransform: 'none' }}
                    >
                      Reset
                    </CustomButton>
                    <CustomButton onClick={handleSubmit} style={{ marginLeft: 15 }}>
                      Save & Continue
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
              <FocusError />
            </>
          )}
        </Formik>
      ) : (
        <StudyBasicDetailsSection />
      )}
      <AddSiteCro
        open={openAddSite}
        onClose={() => {
          setOpenAddSite(false);
        }}
      />
    </Grid>
  );
};

export default StudyDetailsForm;
