import { useFormik } from "formik";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import * as Yup from "yup";
import {  Box,  Button,  Grid,   IconButton,   TextField, Typography } from "@mui/material";
import CustomButton from "../../components/@extended/CustomButton";
import { useSetProfileMutation } from "../../store/slices/apiSlice";
import { openMessage } from "../../store/slices/showMessageSlice";
import { useDispatch } from "react-redux";
// import { Input } from "@mui/base";
import ProfilePic from '../../components/common/profilePic'
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";

export default function UpdateProfile({userData,handleNext , steps  ,completedSteps,handleComplete,handlePasswordDialogClose}){
  const [setProfile, { error }] = useSetProfileMutation();  
  const { isSmallScreen } = useResponsive();
  const dispatch = useDispatch();

  let [editButtonFlag,setEditButtonFlag]=useState(true);

    const docSchema = Yup.object().shape({
        firstName: Yup.string().required("first name is required"),
        lastName: Yup.string().required("last name is required"),
        address: Yup.string().required("address is required"),
        contactNumber:Yup.number().required("Contact Number is required"),
      });

    const formik = useFormik({
        initialValues: {
          firstName: userData?.firstName,
          lastName:userData?.lastName,
          address: userData?.address,
          contactNumber:userData?.contactNumber
        },
        validationSchema: docSchema,
        onSubmit: async (values) => {
          const result = await setProfile({
            sponsorId: userData?.sponsorId,
            userId:userData?.id,
            payload: {
              firstName: values?.firstName,
              lastName: values?.lastName,
              address: values?.address,
              contactNumber:values?.contactNumber
            },
          });
          if (!result?.error) {
            handleComplete()
            openMessageNotification({
              message: "Profile updated Successfully",
              type: "success",
            });
          } else {
            openMessageNotification({
              message: result?.error?.data?.message,
              type: "error",
            });
          }
        },
      });
      
      const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
      };

      const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        formik.handleSubmit();
        if(completedSteps() === steps.length - 1)
          handlePasswordDialogClose();
      };

      const handleEditButtonClick = () => {
        formik.setValues({
          firstName: userData?.firstName,
          lastName:userData?.lastName,
          address: userData?.address,
          contactNumber:userData?.contactNumber,
        });
        setEditButtonFlag(!editButtonFlag);
      };
    
    return (
      <>
        <Box sx={{ paddingBottom: 3 }}>
          <Box
            sx={{
              paddingTop: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <ProfilePic />
          </Box>
          <Box sx={{ width: '100%', ml: 'auto', paddingBottom: 2 }}>
            <IconButton
              onClick={handleEditButtonClick}
              sx={{ position: 'absolute', right: '40px', padding: '5px', minWidth: 'fit-content' }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
              <form onSubmit={handleFormSubmit}>
                
                <Grid container rowSpacing={3} spacing={10}>
                  <Grid item xs={12} sm={6} px={0}>
                    <Typography textAlign='left' variant="body1" color="#5d5d5d" pb={1}>
                      First Name
                    </Typography>
                    <TextField
                      //sx={{height:65}}
                      disabled={editButtonFlag}
                      size='small'
                      name="firstName"
                      fullWidth
                      placeholder="Enter your name"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} px={0}>
                    <Typography textAlign='left' variant="body1" color="#5d5d5d" pb={1}>
                      Last Name
                    </Typography>
                    <TextField
                      //sx={{height:65}}
                      disabled={editButtonFlag}
                      size='small'
                      name="lastName"
                      fullWidth
                      placeholder="Enter your name"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} px={0}>
                    <Typography textAlign='left' variant="body1" color="#5d5d5d" pb={1}>
                      Email
                    </Typography>
                    <TextField
                      //sx={{height:65}}
                      disabled
                      size='small'
                      name="email"
                      fullWidth
                      value={userData?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} px={0}>
                    <Typography textAlign='left' variant="body1" color="#5d5d5d" pb={1}>
                      Mobile Number
                    </Typography>
                    <TextField
                      disabled={editButtonFlag}
                      //sx={{height:65}}
                      size='small'
                      name="contactNumber"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.contactNumber}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                      error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                    />
                  </Grid>
                  <Grid item xs={12} px={0}>
                    <Typography textAlign='left' variant="body1" color="#5d5d5d" pb={1}>
                      Address
                    </Typography>
                    <TextField
                      //sx={{height:65}}
                      disabled={editButtonFlag}
                      size='small'
                      name="address"
                      fullWidth
                      placeholder="Enter your address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.address && formik.errors.address}
                      error={formik.touched.address && Boolean(formik.errors.address)}
                    />
                  </Grid>  
                </Grid>
                
                <Grid item xs={12}>
                    <Box sx={{ justifyContent:'flex-end',columnGap:2, display: 'flex', flexDirection: 'row', pt: 3 }}>
                    {editButtonFlag ?
                      <CustomButton
                        onClick={handleNext}
                        variant="outlined"
                        color="primary"
                        sx={{ padding: "8px 30px", textTransform: "none", mr: 1  }}
                      >
                        Next
                      </CustomButton>
                      :
                      <CustomButton type="submit" variant="outlined">
                            {completedSteps() === steps.length - 1
                              ? 'Finish'
                              : 'Save & Next'}
                      </CustomButton>}
                    </Box>
                  </Grid>
              </form>
              
        </>
    )
}