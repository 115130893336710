import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import GaugeComponent from 'react-gauge-component';
import CustomCard from '../../../components/@extended/CustomCard';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDashboradDataQuery } from '../../../store/slices/apiSlice';
import { SvgSpinnersBlocksShuffle2 } from '../../../components/icons/ReportingDashboardLoadingIcon';
import AnimatedBloqcubeLogo from '../../../components/Logo/AnimatedBloqcubeLogo';
// Chart.js gauge

function SubjectDropoutRate(props) {
  const gaugeRef = useRef(null);
  const drawerOpen = useSelector((state) => state.application.drawerOpen);
  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);
  const { data: dashboardData, isLoading } = useDashboradDataQuery({
    data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
  });

  useEffect(() => {
    gaugeRef.current && gaugeRef.current.refresh();
  }, [drawerOpen]);

  return (
    <>
      {dashboardData && (
        <Box onMouseDown={(e)=>e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: 350,
              height: 250,
              '& .grafana-gauge': { width: '100%', height: '100%' },
              '& svg': { width: '100%', height: '100%' },
            }}
          >
            <GaugeComponent
              value={dashboardData?.[props?.preference] ? dashboardData?.[props?.preference]?.subjectDropoutRate ? Math.floor(dashboardData[props.preference].subjectDropoutRate) : 0 : 0}
              type="radial"
              labels={{
              valueLabel:{style:{fontSize:"40px",fill: "#696969",textShadow: "none"}},
              tickLabels:{
                defaultTickValueConfig: {
                  style: {
                    fontSize: "14px",
                    fill: "#696969", 
                    textShadow: "none",
                  },
                },
              }
              }}
              arc={{
              // gradient: true,
              cornerRadius: 1,
              colorArray: ['#A5E32B','#5BE12C','#E6DA2A','#E88F29','#EA4228'],
              subArcs: [{limit: 20}, {limit: 40}, {},{},{}],
              padding:0.01,
              width: 0.3
              }}
              pointer={{
              type:"needle",
              width:15,
              elastic: true,
              
            }}
            />
          </Box>
        </Box>
      )}
      {isLoading && (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <CircularProgress /> */}
          <Box sx={{ height: 250, width: 250 }}>
            <AnimatedBloqcubeLogo />
          </Box>
        </Box>
      )}
    </>
  );
}

export default SubjectDropoutRate;
