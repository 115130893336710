import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { useField } from "formik";
import { at } from "lodash";
const DateTimePickerField = (props) => {
  const theme = useTheme()
  const { label, setFieldValue, name, disabled } = props;
  const [field, meta] = useField(name);
  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    } else {
      return " ";
    }
  }
  return (
    <>
      <Box
        sx={{
          height: props.justifycontent ? 'auto' : '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: props.justifycontent ? 'start' : 'space-between',
        }}
      >
        <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
          {label}
          {label && props.required && <span style={{ color: 'red', fontSize: 18 }}>*</span>}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            onChange={(value) => {
              setFieldValue(name, dayjs(value).format('MM/DD/YYYY hh:mm'), true);
            }}
            value={dayjs(new Date(moment(field.value && field.value, 'MM/DD/YYYY hh:mm')))}
            sx={{ svg: { color: disabled ? '#bdbdbd' : theme.palette.primary.light } }}
            disabled={disabled}
            referenceDate={dayjs('2022-04-17T15:30')}
            slotProps={{
              textField: {
                error: meta.touched && meta.error && true,
                helperText: _renderHelperText(),
                name: name,
                variant: 'outlined',
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default React.memo(DateTimePickerField);
