import { useTheme } from '@emotion/react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const GooglePlacesAutocompleteComponent = (props) => {
    const { address, setAddress, Width, BorderColor, gridChangeRequired, placeholder } = props;
    const theme = useTheme();
    const gridRef = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (gridRef.current) {
            setWidth(gridRef.current.offsetWidth);
        }
        const handleResize = () => {
            if (gridRef.current) {
                setWidth(gridRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log(address)
    return (
        <Grid container ref={gridRef} sx={{
            border: BorderColor ? BorderColor : '1px solid black',
            borderRadius: 1,
            borderColor: BorderColor ? BorderColor : 'hsl(0, 0%, 80%)',
            ':hover': {
                borderColor: BorderColor ? BorderColor : 'black'
            },
            ':focus-within': {
                border: BorderColor ? BorderColor : `1px solid ${theme.palette.primary.main}`,
                borderColor: BorderColor ? BorderColor : theme.palette.primary.main,
            },
        }}>
            <Grid item xs={gridChangeRequired ? 10 : 11}>
            <GooglePlacesAutocomplete
                apiKey= {process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                textInputProps={{
                    clearButtonMode: 'never'
                }}
                selectProps={{
                    value: address,
                    onChange: setAddress,
                    placeholder: placeholder ? placeholder : 'Address',
                    menuPortalTarget: document.body,
                    styles: {
                        container: (provided) => ({
                            ...provided,
                        }),
                        control: (provided) => ({
                            ...provided,
                            ':hover': {
                                borderColor: 'none'
                            },
                            ':focus-within': {
                                border: 'none',
                            },
                            boxShadow: 0,
                            border:'none'
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            border: 'none',
                            ':focus': {
                                border: 'none'
                            }
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            display: 'none'
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none'
                        }),
                        menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 1500,
                            position: 'absolute',
                            width: Width ? Width : width,
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            maxHeight: '140px',
                            '&::-webkit-scrollbar': {
                                width: '0.3em',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#888',
                            },
                        }),
                        option: (provided) => ({
                            ...provided,
                            ":hover": { backgroundColor: theme.palette.primary.lighter },
                            backgroundColor: "white",
                            color: 'black'
                        }),
                    },
                }}
                renderRightButton={() => <CloseIcon />}
            />
            </Grid>
            <Grid item xs={gridChangeRequired ? 2 : 1} sx={{textAlign:'center',alignContent:'center', height:'100%'}}>
                {address ? 
                    <IconButton sx={{ml:2}} onClick={(e)=>{e.target.value=null; setAddress(null)}}><CloseIcon fontSize='small' sx={{ color:'hsl(0, 0%, 50%)', ':hover': { color:'hsl(0, 0%, 40%)'}}}/></IconButton>
                :
                ""}
            </Grid>
        </Grid>
    );
};

export default GooglePlacesAutocompleteComponent;
