// material-ui
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "../../store/slices/apiSlice";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import logo from "../../assets/images/logo/bitmap@2x.jpg";
import vector from "../../assets/images/login/undrawMedicineB1Ol@3x.png";
import CustomButton from "../../components/@extended/CustomButton";
import * as Yup from "yup";
  
import { useFormik } from "formik";
import ShowMessage from "../../components/showMessage/ShowMessage";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import { useDispatch } from "react-redux";
import { openMessage } from "../../store/slices/showMessageSlice";



const Reset=()=>{
    const [forgot, { error }] = useForgetPasswordMutation();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isSmallScreen } = useResponsive();

    const docSchema = Yup.object().shape({
      email: Yup.string().required("Username/Email Id is required")
      .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
    });

    const formik = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: docSchema,
        onSubmit: async (values) => {
          const result = await forgot({
            email: values.email,
          });
          if (!result?.error) {
            openMessageNotification({
              message: "Email sent successfully",
              type: "success",
            });
            navigate("/login");
          } else {
            openMessageNotification({
              message: result?.error?.data?.message,
              type: "error",
            });
          }
        },
      });

      const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        formik.handleSubmit();
      };
    
      const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
      };
    
    return(
        <>
          <Box sx={{ display: "flex", justifyContent: "center", py: 4, paddingTop: isSmallScreen ? 8 : 4 }}>
            <Typography variant="h5" color="initial">
              Forgot Password
            </Typography>
          </Box>
              <form onSubmit={handleFormSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} px={3}>
                    <Typography variant="body1" color="#5d5d5d" pb={1}>
                      Username/Email Id
                    </Typography>
                    <TextField
                      name="email"
                      fullWidth
                      placeholder="Username/Email Id"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.email && formik.errors.email}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between', px: 3 }}>
                    <Button
                      variant="text"
                      sx={{ width: 'fit-content', color: theme.palette.primary.light, p:0, ":hover":'none' }}
                      size="large"
                      onClick={()=>{navigate("/login")}}
                    >
                      Back to Login
                    </Button>
                      
                    <CustomButton
                      size="large"
                      type="submit"
                      sx={{
                        width: 150,
                        backgroundColor: theme.palette.primary.light,
                      }}
                    >
                      Submit
                    </CustomButton>

                  </Grid>

                </Grid>
              </form>
        </>
    )
}

const ForgotPassword = () => {
  
  const theme = useTheme();
  const { isSmallScreen } = useResponsive();
  
  // const openMessageNotification = (message) => {
  //   dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  // };

  return (
    <>
      <Grid container sx={{ height: "100vh", width: '100%' }}>
        {!isSmallScreen && <Grid item xs={12} maxHeight={100}>
          <Box sx={{ p: 4, position:'absolute', right:0, display: "flex", justifyContent: isSmallScreen ? "center" : 'flex-end' }}>
            <Button
              size="large"
              variant="outlined"
              sx={{ textTransform: "none", color: theme.palette.primary.light }}
              startIcon={
                <HelpOutlineOutlinedIcon
                  sx={{ color: theme.palette.primary.light }}
                />
              }
            >
              Help & Support
            </Button>
          </Box>
        </Grid>}
        <Grid
          item
          md={6}
          //sm={12}
          style={{
            display: "flex", justifyContent: isSmallScreen ? 'center' : "flex-end", ...(isSmallScreen && {
              alignItems: 'center',
            })
          }}
        >
          <Paper sx={{
            height: isSmallScreen ? 'auto' : 550, ...(!isSmallScreen && {
              width: 480,
            })
            }} elevation={isSmallScreen?0:5}>

            <Box sx={{ display: "flex", justifyContent: "center", pt: 5 }}>
              <img src={logo} alt="Bloqcube" width="200" />
            </Box>

            <Reset/>

          </Paper>
        </Grid>
        {!isSmallScreen && <Grid
          item
          md={6}
          style={{ display: "flex", justifyContent: "center " }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", pt: 8 }}>
            <img src={vector} alt="Bloqcube" width="100%" height={"65%"} />
          </Box>
        </Grid>}
      </Grid>
      <ShowMessage />
    </>
  );
};
  
export default ForgotPassword;
  