export const ConfigurationTableColumns = () => {
    const columns = [
      {
        id: 'configurationLabel',
        label: 'Configuration Label',
        style: {
          maxWidth: 200,
          textAlign: 'center',
        },
      },
      {
        id: 'key',
        label: 'Key',
        style: {
            maxWidth: 200,
          textAlign: 'center',
        },
      },
    //   {
    //     id: 'version',
    //     label: 'Version',
    //     style: {
    //         maxWidth: 200,
    //       textAlign: 'center',
    //     },
    //   },
      {
        id: 'createdAt',
        label: 'Created At',
        style: {
            maxWidth: 200,
          textAlign: 'center',
        },
      },
      {
        id: 'action',
        label: 'Action',
        style: {
          maxWidth: 200,
          textAlign: 'center',
        },
      },
    ];
    return columns;
  };
  