import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../components/@extended/CustomButton';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import { selectStudyDetails } from '../../../store/slices/financeSlice';

const vendorColumns = [
  {
    id: 'vendor',
    label: `Name`,
    minWidth: 200,
    maxWidth: 300,
    align: 'left',
  },
  {
    id: 'enrollees',
    label: 'Subject Screening',
    minWidth: 200,
    align: 'center',
  },
  {
    id: 'screenFails',
    label: 'Screening Failure',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'total',
    label: 'Total Amount',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'revenueAmount',
    label: 'Revenue Amount',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

const procedureCosts = [
  {
    key: 'col1',
    label: 'Procedure',
    minWidth: 150,
    align: 'left',
  },
  {
    key: 'cost',
    label: 'Unit Cost',
    minWidth: 100,
    align: 'center',
  },
  {
    key: 'View_Visit_Details',
    label: 'Visit-1 (Screening)',
    minWidth: 100,
    align: 'center',
  },
  {
    key: 'Create_Visit_2',
    label: 'Visit-2',
    minWidth: 100,
    align: 'center',
  },
  {
    key: 'Create_Visit_3',
    label: 'Visit-3',
    minWidth: 100,
    align: 'center',
  },
];

const ExpandableTableRow = ({ children, expandComponent, row, columns, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const theme = useTheme();
  const visitColumns = [
    {
      id: 'srNo',
      label: `Sr. No.`,
      minWidth: 50,
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'View_Visit_Details',
      label: 'Visit-1 (Screening)',
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'Create_Visit_2',
      label: 'Visit-2',
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'Create_Visit_3',
      label: 'Visit-3',
      minWidth: 170,
      align: 'center',
    },
  ];
  return (
    <React.Fragment>
      <TableRow {...otherProps} sx={{ '& > *': { borderBottom: 'unset' } }}>
        {columns.map((column) => {
          let value = row[column.id];
          switch (column.id) {
            case 'enrollees':
            case 'screenFails':
            case 'total':
            case 'revenueAmount':
              value = '$' + value;
              break;
          }
          return (
            <TableCell key={column.id} align={column.align}>
              {column.id === 'action' ? (
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                </IconButton>
              ) : (
                <Typography variant="subtitle1" color="initial">
                  {value}
                </Typography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow sx={{ py: 1 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
          <Collapse in={isExpanded}>
            <Box sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                sx={{ color: theme.palette.grey[800], fontWeight: 600, mb: 0.5 }}
              >
                Visit costs
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }}>
                  <TableHead>
                    {visitColumns.map((visit, index) => {
                      return (
                        <CustomTableHeadCell
                          key={visit.key}
                          align="center"
                          sx={{ minWidth: visit?.minWidth, maxWidth: visit?.maxWidth }}
                        >
                          {visit.label}
                        </CustomTableHeadCell>
                      );
                    })}
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {visitColumns.map((visit, index) => {
                        let value = row[visit.id];
                        switch (visit.id) {
                          case 'srNo':
                            value = index + 1;
                            break;
                        }
                        return (
                          <TableCell key={visit.id} align={'center'}>
                            <Typography variant="subtitle1" color="initial">
                              {value ? value : '- -'}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const SiteDetails = (props) => {
  const { site } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      key={site.id}
      sx={{
        ml: 0,
        mt: 2,
        border: '1px solid #e7e7e7',
        padding: 3,
        borderRadius: 1,
        backgroundColor: '#fbfbfb',
      }}
    >
      <Grid item sm={12} md={6}>
        <LabelContainer label={'Site Name'} value={site?.orgName} />
      </Grid>
      <Grid item sm={12} md={6}>
        <LabelContainer
          label={'Site Address'}
          value={
            site?.addressLine1 +
            ', ' +
            site?.city +
            ', ' +
            site?.state +
            ' - ' +
            site?.postalcode +
            ', ' +
            site?.country
          }
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <LabelContainer label={'Contact Email'} value={site?.contactEmail} />
      </Grid>
      <Grid item sm={12} md={6}>
        <LabelContainer label={'Contact Number'} value={site?.contactInfo} />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.grey[800], fontWeight: 600, mb: 1 }}>
            Vendors:
          </Typography>
          <TableContainer
            sx={{
              maxHeight: 300,
              border: `1px ${theme.palette.grey[100]} solid `,
            }}
          >
            <Table stickyHeader>
              <CustomTableHead>
                {vendorColumns.map((column) => (
                  <CustomTableHeadCell
                    key={column.id}
                    align={column.align}
                    sx={{ minWidth: column?.minWidth, maxWidth: column?.maxWidth }}
                  >
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </CustomTableHead>
              <TableBody>
                {site.vendors.length === 0 ? (
                  <TableRow>
                    <TableCell align={'center'} colSpan={vendorColumns.length}>
                      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[900] }}>
                        No Vendors Assigned.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  site.vendors.map((row, index) => {
                    return (
                      <>
                        <ExpandableTableRow
                          tabIndex={-1}
                          key={index}
                          row={row}
                          columns={vendorColumns}
                        ></ExpandableTableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.grey[800], fontWeight: 600, mb: 1 }}>
            Procedure Costs:
          </Typography>
          <TableContainer
            sx={{
              maxHeight: 300,
              border: `1px ${theme.palette.grey[100]} solid `,
            }}
          >
            <Table stickyHeader>
              <CustomTableHead>
                {procedureCosts.map((column) => (
                  <CustomTableHeadCell
                    key={column.id}
                    align={column.align}
                    sx={{ minWidth: column?.minWidth, maxWidth: column?.maxWidth }}
                  >
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </CustomTableHead>
              <TableBody>
                {site.procedureCosts.length === 0 ? (
                  <TableRow>
                    <TableCell align={'center'} colSpan={procedureCosts.length}>
                      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[900] }}>
                        No Vendors Assigned.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  site.procedureCosts.map((row, index) => {
                    return (
                      <>
                        <TableRow>
                          {procedureCosts.map((column) => {
                            let value = row[column.key];
                            if (column.key !== 'col1' && value) {
                              value = '$' + value;
                            }
                            return (
                              <TableCell key={column.key} align={column.align}>
                                <Typography variant="subtitle1" color="initial">
                                  {value ? value : '$0'}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export const LabelContainer = ({ label, value }) => {
  const theme = useTheme();
  return (
    <Grid container pb={2}>
      <Grid item xs={4}>
        <Typography variant="subtitle1" sx={{ color: theme.palette.grey[800], fontWeight: 600 }}>
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle1" sx={{ color: theme.palette.grey[900] }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StudySummary = () => {
  const { sponsorId, studyId } = useParams();
  const trialDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(0);

  const procedureCosts = [
    {
      key: 'col1',
      label: 'Procedure',
      minWidth: 150,
      align: 'left',
    },
    {
      key: 'cost',
      label: 'Unit Cost',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'View_Visit_Details',
      label: 'Visit-1 (Screening)',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_2',
      label: 'Visit-2',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_3',
      label: 'Visit-3',
      minWidth: 100,
      align: 'center',
    },
  ];

  const handleChange = (event, newValue) => {
    console.log('newValue', newValue);
    setTabValue(newValue);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ fontWeight: 510 }} color={theme.palette.grey[800]}>
                Study Summary
              </Typography>
              <CustomButton>Export</CustomButton>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Trial Title'} value={trialDetails?.studyTitle} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Protocol Number'} value={trialDetails?.protocolNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Protocol Version'} value={trialDetails?.protocolVersion} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Number of Subjects'} value={trialDetails?.totalSubjectNumber} />
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h6" color="initial">
              Study Account Information
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Account Holder Name'} value={trialDetails?.accountDetails?.account_holder_name} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Account Holder Email'} value={trialDetails?.accountDetails?.account_holder_email} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Account Number'} value={trialDetails?.accountDetails?.account_holder_number} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Routing Number'} value={trialDetails?.accountDetails?.routingNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'ABA Number'} value={trialDetails?.accountDetails?.abaNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Swift Number'} value={trialDetails?.accountDetails?.swiftNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Country'} value={trialDetails?.accountDetails?.country} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Currency'} value={trialDetails?.accountDetails?.currency} />
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h6" color="initial">
              Site Budget Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant={'scrollable'}
              scrollButtons="auto"
            >
              {trialDetails?.sites.map((site, index) => {
                return <Tab key={site.orgName} label={site.orgName} {...a11yProps(0)} value={index} />;
              })}
            </Tabs>

            {trialDetails?.sites.map((site, index) => {
              return (
                <TabPanel value={tabValue} index={index}>
                  <SiteDetails site={site} />
                </TabPanel>
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
            <CustomButton onClick={() => {}}>Finish</CustomButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudySummary;
