import {
  Grid,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/@extended/CustomButton";
import { useNavigate } from "react-router-dom";
import { useGetTrialsMutation, useGetMeUserQuery } from "../../store/slices/apiSlice";
import DraftTrialsTable from "./DraftTrialsTable";
import OnGoingTrialsTable from "./OnGoingTrials";

const SponsorDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [getTrials, { data }] = useGetTrialsMutation();
    
  return (
    <Grid container rowSpacing={1.5}>
      <Grid item sm={12} sx={{ paddingTop: 8 }}>
        <OnGoingTrialsTable />
      </Grid>
      <Grid item sm={12} sx={{ paddingTop: 8 }}>
         <Divider sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`, borderRadius:5 }} />
        <DraftTrialsTable />
      </Grid>
    </Grid>
  );
};


export default SponsorDashboard;
