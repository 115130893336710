import { useSelector } from "react-redux";
import { useMemo } from "react";
import { selectCurrentUser } from "../../store/slices/authSlice";
import { getUserRole } from "../util";

export const SubjectDetailColumns = () => {
  const user = useSelector((state) => state.auth.user);
  const userRole = getUserRole(user);
  const columns = useMemo(() => {
    return [
        {
          id: "subjectName",
          label: userRole==='site_monitor'?`Subject ID`:`Subject Name`,
          style: {
            minWidth: 200,
            maxWidth: 300,
            textAlign:'justify'
          },
          align: "left",
        },
        {
          id: "protocolNumber",
          label: "Protocol Number",
          style: {
            minWidth: 170,
          },
          align: "center",
        },
        {
          id: "visitTitle",
          label: "Visit Title",
          style: {
            minWidth: 170,
          },
          align: "center",
        },
        {
          id: "date",
          label: "Date",
          style: {
            minWidth: 170,
          },
          align: "center",
        },
        {
            id: "startTime",
            label: "Start Time",
            style: {
              minWidth: 170,
            },
            align: "center",
        },
        {
            id: "endTime",
            label: "End Time",
            style: {
              minWidth: 170,
            },
            align: "center",
        },
        {
            id: "action",
            label: "Actions",
            style: {
              minWidth: 170,
            },
            align: "center",
        },
      ];
    
  }, []);

  return columns;
};