import React, { useEffect, useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CustomCard from '../../../components/@extended/CustomCard';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDashboradDataQuery, useGetOngoingTrialsQuery } from '../../../store/slices/apiSlice';
import { SvgSpinnersBlocksShuffle2 } from '../../../components/icons/ReportingDashboardLoadingIcon';
import AnimatedBloqcubeLogo from '../../../components/Logo/AnimatedBloqcubeLogo';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AEIncidentsAcrossSites = (props) => {
  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);

  const { data: dashboardData, isLoading } = useDashboradDataQuery({
    data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
  });
  
  const labels = useMemo(() => {
    if (dashboardData) {
      return dashboardData[props?.preference]?.map((_site) => _site?.site?.orgname);
    } else {
      return [];
    }
  }, [dashboardData]);

  const siteAECount = useMemo(() => {
    if (dashboardData) {
      return dashboardData[props?.preference]?.map((_site) => _site?.aeSubjectCount);
    } else {
      return [];
    }
  }, [dashboardData]);

  const siteSAECount = useMemo(() => {
    if (dashboardData) {
      return dashboardData[props?.preference]?.map((_site) => _site?.saeSubjectCount);
    } else {
      return [];
    }
  }, [dashboardData]);

  // const labels = ;
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'SAE',
        data: siteSAECount,
        borderColor: '#FF6D00',
        backgroundColor: '#FF6D00',
      },
      {
        label: 'AE',
        data: siteAECount,
        borderColor: '#FFCB00',
        backgroundColor: '#FFCB00',
      },
    ],
  };

  return (
    <Box onMouseDown={(e)=>e.stopPropagation()} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
      {isLoading && (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ height: 250, width: 250 }}>
            <AnimatedBloqcubeLogo />
          </Box>
        </Box>
      )}
      {dashboardData && <Bar options={options} data={data} />}
    </Box>
  );
};

export default AEIncidentsAcrossSites;
