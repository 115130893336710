import { Box, Button, Chip, Divider, Drawer, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import DateTimeField from "../visitScheudle/FormikDateField";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import CustomButton from "../../components/@extended/CustomButton";
import FormikDatePickerField from "../../components/common/FormikDatePickerField";
import FormikMultiSelectCheckbox from "../../components/common/FormikMultiSelectCheckbox";
import MultiSelectField from "../../components/common/FormikMultiSelectCheckbox";
import { useEffect, useState } from "react";
import { useAssignTaskMutation, useUpdateAssignedTaskMutation } from "../../store/slices/apiSlice";
import dayjs from "dayjs";

const DelegationForm = (props) => {
    const { open, onClose, delegationDetails, allDOATasks, users } = props;
    const [allTasks, setAllTasks] = useState([]);
    const [assignTask] = useAssignTaskMutation();
    const [updateAssignedTask] = useUpdateAssignedTaskMutation();

    useEffect(() => {
        if (allDOATasks) {
            setAllTasks(allDOATasks.map((taskObj) => {
                return {
                  id: taskObj.id,
                  value: taskObj.taskNumber,
                  label: taskObj.title,
                };
            }));
        }
    }, [allDOATasks])

    const validationSchema = Yup.object().shape({
        member: Yup.string().required('Member is required'),
        tasks: Yup.array()
            .min(1, 'At least one task is required'),
        startDate: Yup.string()
            .required("Start date is required")
            .test("isAfterCurrentDate", "Date must be after the current date", function(value) {
              const date = moment(value).format('MM-DD-YYYY')
              const currDate = moment().format('MM-DD-YYYY')
              if (moment(date).isBefore(currDate)) {
                  return false;
              }
              return true;
            }),
        endDate: Yup.string()
            .required("End date is required")
            .test(
                "start-end-date",
                "End date should be after the start date",
                function (value) {
                    const { startDate } = this.parent;
                    return moment(value, 'MM/DD/YYYY').isAfter(moment(startDate, 'MM/DD/YYYY')) || moment(value, 'MM/DD/YYYY').isSame(moment(startDate, 'MM/DD/YYYY'));
                }
            ),
    });
    const initialValues = {
      member: delegationDetails ? delegationDetails.userId : '',
      tasks: delegationDetails ? [delegationDetails.taskMasterId] : [],
      startDate: delegationDetails
        ? moment(delegationDetails.startDate).format('MM/DD/YYYY')
        : moment(new Date).format('MM/DD/YYYY'),
      endDate: delegationDetails ? moment(delegationDetails.endDate).format('MM/DD/YYYY') : moment(new Date).format('MM/DD/YYYY'),
    };
    const handleSubmit = async (values) => {
        if(delegationDetails) {
            await updateAssignedTask({ assignedTaskId: delegationDetails.id, payload: {
                startDate: moment(values.startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'),
                endDate: moment(values.endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'),
            } })
        } else {
            const taskArr = values.tasks.map((taskObj) => {
                return {
                    taskId: taskObj,
                    startDate: moment(values.startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'),
                    endDate: moment(values.endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'),
                }
            });
            await assignTask({ userId: values.member, payload: taskArr })
        }
        onClose(true);
    }

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 400, // Set the width of the Drawer paper
            height: '100%',
            paddingTop: 8,
            backgroundColor: '#F5F5F5',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box>
          <Box sx={{ padding: 3 }}>
            <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>
              {delegationDetails ? 'Update Delegation' : 'Add Delegation'}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: 3 }}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <FormControl
                        fullWidth
                        error={errors.member && touched.member}
                        disabled={delegationDetails ? true : false}
                      >
                        <InputLabel>Member</InputLabel>
                        <Field
                          name="member"
                          as={Select}
                          label={'Member'}
                          // style={{ height: 50 }}
                        >
                          {users?.map((userObj) => {
                            return (
                              <MenuItem key={userObj.userId} value={userObj.userId}>
                                {userObj.user.firstName} {userObj.user.lastName}
                              </MenuItem>
                            );
                          })}
                        </Field>
                        {errors.member && touched.member && <FormHelperText>{errors.member}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl
                        fullWidth
                        error={errors.tasks && touched.tasks}
                        disabled={delegationDetails ? true : false}
                      >
                        <InputLabel>Tasks</InputLabel>
                        <Select
                          multiple
                          value={values.tasks || []}
                          onChange={(event) => {
                            const { value } = event.target;
                            setFieldValue('tasks', value);
                          }}
                          disabled={delegationDetails ? true : false}
                          label={'Tasks'}
                          renderValue={(selected) => (
                            <div>
                              {allTasks
                                .filter((_obj) => selected.includes(_obj.id))
                                .map((_obj) => (
                                  <Chip key={_obj?.id} label={_obj?.value} sx={{ ml: 1 }} />
                                ))}
                            </div>
                          )}
                        >
                          {allTasks.length === 0 && (
                            <MenuItem value={""} disabled>
                              No DOA Tasks Available.
                            </MenuItem>
                          )}
                          {allTasks.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.value} - {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tasks && touched.tasks && <FormHelperText>{errors.tasks}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl fullWidth error={touched.startDate && Boolean(errors.startDate)}>
                        <Field
                          label="Start Date"
                          name="startDate"
                          style={{ height: 50 }}
                          component={FormikDatePickerField}
                          inputProps={{
                            name: 'startDate',
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl fullWidth error={touched.endDate && Boolean(errors.endDate)}>
                        <Field
                          label="End Date"
                          name="endDate"
                          style={{ height: 50 }}
                          component={FormikDatePickerField}
                          inputProps={{
                            name: 'endDate',
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2, columnGap: 2 }}>
                    <Button color="primary" onClick={onClose}>
                      Cancel
                    </Button>
                    <CustomButton type="submit" size="small" variant="contained" color="primary">
                      Submit
                    </CustomButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Drawer>
    );
}

export default DelegationForm;