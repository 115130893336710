import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetOngoingTrialsQuery } from '../../store/slices/apiSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const WidgetSetting = ({ trial, selectedSites, setTrial, setSelectedSites, siteList, ...props }) => {
  const { onClose, open, widgets, onWidgetSelection, onWidgetSelectAll } = props;
  const [allSelected, setAllSelected] = useState(false);
  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);
  const { data: ongoingTrials } = useGetOngoingTrialsQuery(sponsorId);
  useEffect(() => {
    if (widgets) {
      const filteredVal = Object.values(widgets).filter((obj1) => obj1.display);
      setAllSelected(filteredVal.length == Object.values(widgets).length);
    }
  }, [widgets]);
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 600, // Set the width of the Drawer paper
            height: '100%',
            paddingTop: 8,
            backgroundColor: '#F5F5F5',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box>
          <Box sx={{ padding: 3 }}>
            <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>Dashboard Setting</Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: 3 }}>
            <Box sx={{ pb: 2 }}>
              <InputLabel>Trial</InputLabel>
              <Select
                value={trial}
                onChange={(e) => {
                  setTrial(e.target.value);
                }}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  <em style={{ color: '#aaa', fontStyle: 'normal' }}>Select Trial</em>
                </MenuItem>
                {ongoingTrials &&
                  ongoingTrials.map((trial, index) => (
                    <MenuItem key={index} value={trial.id}>
                      {trial.protocolNumber}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box sx={{ paddingY: 2 }}>
              <InputLabel>Site</InputLabel>
              <Select
                value={selectedSites}
                onChange={(e) => {
                  if (selectedSites.length > 1 && selectedSites.includes(e.target.value)) {
                    const filtered = selectedSites.filter((siteId) => siteId !== e.target.value);
                    setSelectedSites(filtered);
                  } else if (!selectedSites.includes(e.target.value)) {
                    setSelectedSites([e.target.value, ...selectedSites]);
                  }
                }}
                fullWidth
                renderValue={() => {
                  const _vals = siteList.filter((site) => selectedSites?.includes(site.id));
                  return _vals.map((site) => site.siteName).join(', ');
                }}
              >
                <MenuItem value="" disabled>
                  <em style={{ color: '#aaa', fontStyle: 'normal' }}>Select Site</em>
                </MenuItem>
                {siteList &&
                  siteList?.map((site, index) => (
                    <MenuItem key={index} value={site.id}>
                      <Checkbox checked={selectedSites?.includes(site.id)} />
                      {site.siteName}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Divider />
            <Box sx={{ paddingY: 2 }}>
              <InputLabel>Widget Preferences</InputLabel>
              <FormGroup>
                <FormControlLabel
                  sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold' } }}
                  control={<Checkbox checked={allSelected} onChange={(e) => onWidgetSelectAll(e)} />}
                  label={'Select All'}
                />
                {Object.keys(widgets).map(
                  (key) =>
                    widgets[key].label && (
                      <FormControlLabel
                        key={`${key}wid`}
                        control={
                          <Checkbox checked={widgets[key].display} onChange={(e) => onWidgetSelection(e, key)} />
                        }
                        label={widgets[key].label}
                      />
                    )
                )}
              </FormGroup>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default WidgetSetting;
