import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { bloqcibeApi, useCreateScheduleMutation } from '../../store/slices/apiSlice';
import CustomButton from '../../components/@extended/CustomButton';
import FormikField from '../visitScheudle/FormikTextField';
import FormikMultiSelect from '../visitScheudle/FormikMultiselect';
import { combineDateTime } from '../../util/util';

const CreateSchedule = ({ handleClose, open, bookingSlot }) => {
  const theme = useTheme();
  const [createSchedule] = useCreateScheduleMutation();
  const [getTrialSiteMembers] = bloqcibeApi.endpoints.getTrialSiteMembers.useLazyQuery();
  const [comment, setComment] = useState('');
  const [userList, setUserList] = useState([]);
  const loggedInUser = useSelector((state) => state?.userDetails?.loggedInUser);
  const sponsorId = useSelector((state) => state.auth.sponsorId);

  const onCloseModal = () => {
    // formik.resetForm();
    handleClose();
  };

  const docSchema = Yup.object().shape({
    scheduleTitle: Yup.string().required('Schedule title is required'),
    users: Yup.array().min(1, 'At least one option is required').required('Required'),
  });

  useEffect(() => {
    if (bookingSlot) {
      (async () => {
        const _userList = await getTrialSiteMembers({
          sponsorId,
          trialId: bookingSlot.slot?.trialId,
          siteId: bookingSlot.slot?.siteId,
        });
        if (_userList?.data) {
          const _list = _userList.data.map((_user) => ({
            label: `${_user.user?.firstName} ${_user.user?.lastName}`,
            value: _user.userId,
            role: _user.role,
          }));
          setUserList(_list);
        }
      })();
    }
  }, [bookingSlot]);

  const handleFormSubmit = async (values) => {
    const startDate = combineDateTime(bookingSlot.slot.date, bookingSlot.slot.startTime);
    const endDate = combineDateTime(bookingSlot.slot.date, bookingSlot.slot.endTime);
    const participants = values.users.map((userId) => {
      const _user = _.find(userList, (user) => user.value === userId);
      return {
        id: userId,
        role: _user?.role,
      };
    });
     participants.push({ id: bookingSlot.userId,role:"subject" }); 
    const payload = {
      trialId: bookingSlot.slot?.trialId,
      siteId: bookingSlot.slot?.siteId,
      payload: {
        title: values.scheduleTitle,
        startDate: startDate,
        endDate: endDate,
        participants: participants,
      },
    };
    const schedule = await createSchedule(payload);
    if (schedule.data) handleClose();
  };
  return (
    <>
      <Formik
        initialValues={{
          users: [],
          startTime: dayjs(moment(bookingSlot?.slot?.startTime, 'HH:mm:ss').format('YYYY-MM-DDTHH:mm')),
          endTime: dayjs(moment(bookingSlot?.slot?.endTime, 'HH:mm:ss').format('YYYY-MM-DDTHH:mm')),
        }}
        validationSchema={docSchema}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          handleFormSubmit(values);
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Dialog scroll={'paper'} open={open} onClose={onCloseModal}>
            <DialogTitle>Create Schedule</DialogTitle>
            <DialogContent>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: 2,
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2" color="initial" sx={{ fontWeight: 600, pr: 2 }}>
                        Trial:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: theme.palette.grey[900] }}>
                        {bookingSlot?.slot?.trial?.protocolNumber}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: 2,
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2" color="initial" sx={{ fontWeight: 600, pr: 2 }}>
                        Site:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: theme.palette.grey[900] }}>
                        {bookingSlot?.slot?.site?.orgname}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: 2,
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2" color="initial" sx={{ fontWeight: 600, pr: 2 }}>
                        Visit:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: theme.palette.grey[900] }}>
                        {bookingSlot?.visit?.stepTitle}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: 2,
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2" color="initial" sx={{ fontWeight: 600, pr: 2 }}>
                        Subject:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: theme.palette.grey[900] }}>
                        {`${bookingSlot?.user.firstName} ${bookingSlot?.user.lastName}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle2" color="initial" sx={{ fontWeight: 600, pb: 1 }}>
                      Schedule Details:
                    </Typography>
                    <Grid container sx={{ border: '1px solid #b7b4c5', borderRadius: 1 }}>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          borderRight: '1px solid #b7b4c5',
                          backgroundColor: '#67627f',
                          color: '#FFF',
                          textAlign: 'center',
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          {moment(bookingSlot?.date).format('MMMM DD')}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ borderRight: '1px solid #b7b4c5', textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: '600' }}>{moment(bookingSlot?.date).format('ddd')}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={{ display: 'flex', columnGap: 1, justifyContent: 'center' }}>
                          <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                            {moment(bookingSlot?.slot?.startTime, 'HH:mm:ss').format('HH:mm')}
                          </Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: '600' }}>-</Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                            {moment(bookingSlot?.slot?.endTime, 'HH:mm:ss').format('HH:mm')}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={touched.scheduleTitle && Boolean(errors.scheduleTitle)}>
                      <Field
                        component={FormikField}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="scheduleTitle"
                        type="text"
                        onBlur={handleBlur}
                        label="Schedule Title"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikMultiSelect name="users" label="Select Users" options={userList} />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', pt: 4 }}>
                  <CustomButton sx={{ width: 200 }} onClick={() => handleSubmit()}>
                    Save
                  </CustomButton>
                  <CustomButton sx={{ width: 200 }} variant="outlined" onClick={onCloseModal}>
                    Cancel
                  </CustomButton>
                </Box>
              </Form>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default CreateSchedule;
