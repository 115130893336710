import { Pin } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import GooglePlacesAutocompleteComponent from './GooglePlacessAutocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const GoogleMapComponent = (props) => {
    const { setCurrentAddress, openGoogleMap} = props;
    const dispatch = useDispatch()
    const [address, setAddress] = useState();
    const [center, setCenter] = useState()
    const [showSearch, setShowSearch] = useState(false);

    const APIKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: APIKEY,
    });

    //function to set map initially at current location of user
    useEffect(() => {
        getGeoLocation();
    }, [openGoogleMap])

    const getGeoLocation = async () => {
        if ('geolocation' in navigator) {
            await navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setCenter({'lat':latitude, 'lng':longitude})
                    const latlng = new window.google.maps.LatLng(latitude, longitude);
                    setCurrentAddress({label:`${latlng.lat()}, ${latlng.lat()}`,"latLng": {lat: latlng.lat(), lng: latlng.lng()}})
                },
                (error) => {
                    dispatch(openMessage({ message: "Please enable location access to submit your response.", messageSeverity: "error" }))
                    console.error(`Error getting geolocation: ${error.message}`);
                }
            );
        } else {
            // Geolocation is not supported
            dispatch(openMessage({ message: "Geolocation is not supported!", messageSeverity: "error" }))
            console.log('Geolocation is not supported');
        }
    }
    useEffect(()=>{
        if(address?.value){
            geocodeByAddress(address?.label)
            .then(results => {
                if (results.length !== 0) {
                return getLatLng(results[0]);
                } else {
                throw new Error("ZERO_RESULTS");
                }
            })
            .then(({ lat, lng }) =>{
                setCenter({ 'lat': lat, 'lng': lng })
                setCurrentAddress({label:address.label, latLng:{lat:lat, lng:lng}, placeId:address.value.placeId})
            })
            .catch(error => {
                if (error === 'ZERO_RESULTS') {;
                }
            });
        }
    },[address])

    const handleActiveMarker = (ev) => {
        setAddress({label:`${ev.latLng.lat()}, ${ev.latLng.lat()}`})
        setCurrentAddress({label:`${ev.latLng.lat()}, ${ev.latLng.lat()}`, latLng:{lat:ev.latLng.lat(), lng:ev.latLng.lng()}, placeId:ev.placeId});
        setCenter({ 'lat': ev.latLng.lat(), 'lng': ev.latLng.lng() })
    };


    return (
        <Box width='100%'>
            {isLoaded ?
                <GoogleMap
                    bootstrapURLKeys={{ id: 'google-map-script', key: APIKEY }}
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={center}
                    zoom={8}
                    options={{
                        fullscreenControl: false,
                    }}
                    onClick={ev => handleActiveMarker(ev)}
                >
                    {!showSearch ?
                    <IconButton onClick={()=>setShowSearch(true)} sx={{position:'absolute', right:0, top:0, margin:"10px"}}>
                        <SearchIcon fontSize='large' sx={{color:'#444'}}/>
                    </IconButton>
                    : 
                    <Box sx={{ display:'flex', width:'60%', columnGap:0.5, flexDirection:'row', position:'absolute', alignItems:'center', right:0, top:0, margin:"10px"}}>   
                        <Box sx={{width:'400px', backgroundColor: 'white', borderRadius:'5px'}}>
                            <GooglePlacesAutocompleteComponent placeholder={"Search Location"} gridChangeRequired={true} Width={'370px'} BorderColor={'none'} address={address} setAddress={setAddress}/>
                        </Box>
                        <IconButton onClick={()=>setShowSearch(false)}>
                            <ClearIcon fontSize='medium' sx={{color:'#444'}}/>
                        </IconButton>
                    </Box>
                    }
                    <Marker position={center} />
                    <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                </GoogleMap>
                :
                <></>
            }
        </Box>
    );
};

export default GoogleMapComponent;
