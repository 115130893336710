import React from 'react';
import {
  Grid,
  Typography,
  Button,
  CardContent,
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import CustomCard from '../../components/@extended/CustomCard';
import OrganizationLogo from '../Sponsors/OrganizationLogo';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RightArrow from '../../components/icons/RightArrow';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../components/@extended/CustomButton';
import { CustomTableHead, CustomTableHeadCell } from '../../components/@extended/CustomTable';
const LabelContainer = ({ label, value }) => {
  return (
    <Grid container py={1}>
      <Grid item xs={4} md={5}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={7}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const studyTableColumns = [
  {
    id: 'protocolNumber',
    label: 'Protocol Number',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'protocolVersion',
    label: 'Protocol Version',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'totalSubjectNumber',
    label: 'Number of Subjects',
    style: {
      minWidth: 200,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'accountStatus',
    label: 'Account Status',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'right',
  },
];

const SponsorDetails = () => {
  const navigate = useNavigate();
  const { sponsorId } = useParams();
  const theme = useTheme()
  // Access sponsor details from Redux store
  const sponsor = useSelector((state) =>
    state.finance.sponsors.find((sponsor) => String(sponsor.id) === String(sponsorId))
  );

  const handleRowClick = () => {
    // Navigate to the sponsor details page using the sponsor ID
    navigate(`/finance/${sponsorId}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button type="text" onClick={() => navigate('/finance')} startIcon={<RightArrow leftArrow />} sx={{}}>
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
            Sponsor
          </Typography>
        </Button>
        <Box>
          <CustomButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate(`/finance/${sponsorId}/setup`);
            }}
          >
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Setup Study
            </Typography>
          </CustomButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomCard title="Sponsor Organization Details">
          <CardContent onClick={handleRowClick}>
            {/* Add onClick handler to trigger navigation */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight={160}>
                  <OrganizationLogo />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <LabelContainer label={'Organization Name'} value={sponsor?.orgName || 'N/A'} />
                <LabelContainer label={'Organization Category'} value={sponsor?.orgCategory || 'N/A'} />
                <LabelContainer label={'Contact Info.'} value={sponsor?.contactInfo || 'N/A'} />
                <LabelContainer label={'Contact Number'} value={sponsor?.contactEmail || 'N/A'} />
                <LabelContainer label={'Address'} value={sponsor?.addressLine1+", "+sponsor?.city+", "+sponsor?.state+", "+sponsor?.country+" - "+sponsor?.postalcode || 'N/A'} />
              </Grid>
            </Grid>
          </CardContent>
        </CustomCard>
      </Grid>
      <Grid item sm={12} md={12}>
        <Typography variant="h6" color="initial" sx={{ pt: 2, pb: 1 }}>
          Sponsor Studies
        </Typography>
        <Paper sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: 3,
              backgroundColor: theme.palette.primary.light,
              borderRadiusTop: 5,
            }}
          />
          <TableContainer sx={{ maxHeight: 550 }}>
            <Table stickyHeader aria-label="sticky table">
              <CustomTableHead>
                <TableRow>
                  {studyTableColumns.map((column) => (
                    <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                      {column.label}
                    </CustomTableHeadCell>
                  ))}
                </TableRow>
              </CustomTableHead>
              {sponsor.studies.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={studyTableColumns.length}>
                    <Typography variant="subtitle1" color="initial">
                      No studies Found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                sponsor.studies.map((study) => (
                  <TableRow key={study.id}>
                    {/* <TableCell align="center">{study.id}</TableCell> */}
                    <TableCell align="center">{study.studyTitle}</TableCell>
                    <TableCell align="center">{study.protocolNumber}</TableCell>
                    <TableCell align="center">{study.totalSubjectNumber}</TableCell>
                    <TableCell align="center">{study.createdAt}</TableCell>
                    <TableCell align="center">{study.accountStatus}</TableCell>
                    <TableCell align="center">
                      <RightArrow onClick={() => navigate(`/finance/${sponsorId}/setup/${study.id}`)} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SponsorDetails;
