import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Tabs, Tab, Typography, Toolbar, Divider } from '@mui/material';
import ManageComments from './ManageComments';
import ManageDocuments from './ManageDocuments';

function ManageTaskResources(props) {
    const { onClose, selectedTask, open, saveCommentData, uploadDocument, deleteDocument, trialId, siteTrialId } = props;
    const [tabValue, setTabValue] = useState(0);
    
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500, // Set the width of the Drawer paper
            height: '100vh',
            backgroundColor: '#F5F5F5',
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Divider/>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Comments" />
          <Tab label="Documents" />
        </Tabs>
        {tabValue === 0 && (
          <Typography variant="h6" align="center" sx={{ my: 2 }}>
            <ManageComments commentHist={selectedTask.comment} saveCommentData={saveCommentData} trialId={trialId} siteTrialId={siteTrialId}/>
          </Typography>
        )}
        {tabValue === 1 && (
          <Typography variant="h6" align="center" sx={{ my: 2 }}>
            <ManageDocuments uploadDocument={uploadDocument} deleteDocument={deleteDocument} uploadedDocuments={selectedTask?.documents} />
          </Typography>
        )}
      </Drawer>
    );
}

export default ManageTaskResources;
