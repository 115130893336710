import { useTheme } from '@emotion/react';
import {
  Divider,
  Grid,
} from '@mui/material';
import React from 'react';
import SponsorOrganizationTable from './SponsorOrganizationTable';

const Sponsor = () => {
  const theme=useTheme();

  return (
    <Grid container>
      <Grid item sm={12} >
        <SponsorOrganizationTable />
      </Grid>
      <Divider sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`, borderRadius: 5 }} />
    </Grid>
  );
};

export default Sponsor;
