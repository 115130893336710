import { Box, Button, CardContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "../../components/icons/RightArrow";
import TrialSiteDetailsCard from "./TrialSiteDetailsCard";
import SiteClinicalTeamCard from "./SiteClinicalTeamCard";
import TrialSiteBudgetCard from "./TrialSiteBudgetCard";
import TrialSiteCurrentExpenditure from "./TrialSiteCurrentExpenditure";
import CustomCard from "../../components/@extended/CustomCard";
import SiteStatusStepper from "./SiteStatusStepper";
import { bloqcibeApi, useGetLibraryFormQuery, useGetTrialSiteInfoQuery } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import _ from 'lodash'
import CustomButton from "../../components/@extended/CustomButton";
import SubjectListCard from "./SubjectListCard"
import DocumentsList from "../../components/common/DocumentsList";
import SubjectWithdrawListCard from "./SubjectWithdrawListCard";
import DocumentUploadWithCategory from "../../components/common/DocumentUploadWithCategory";
import ReadonlyFormDetails from "./ReadonlyFormDetails";
import SubjectAEListCard from "./SubjectAEListCard";
import AuditExportButton from "../../components/common/AuditExportButton";
import DownloadAEReport from "../../components/common/DownloadAEReport";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from "@mui/icons-material/Search";

const TrialSiteDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { trialId, trialSiteId } = useParams();
  const [openICForm, setOpenICForm] = useState(false);
  const [readOnlyFormDetails, setReadOnlyFormDetails] = useState({
    title: '',
    step: ''
  })
  const [searchValue, setSearchValue] = useState('');
  const [openAeDownload, setOpenAeDownload] = useState(false);
  const crfDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.crfForm);
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const loggedInUser = useSelector((state) => state.auth.user);
  const [uploadDocModal, setUploadDocModal] = useState(false)
  const [getLibraryForm] = bloqcibeApi.endpoints.getLibraryForm.useLazyQuery();
  const [getTrialSiteInfo] = bloqcibeApi.endpoints.getTrialSiteInfo.useLazyQuery();
  const [formData, setFormData] = useState(null)
  const enrolmentDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.eConsentForm);
  const [trialSiteData, setTrialSiteData] = useState(null);

  useEffect(() => {
    (async () => {
    const res = await getTrialSiteInfo({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: trialSiteId,
    });
      setTrialSiteData(res?.data);
    })();
  }, []);

  useEffect(()=>{
    (async()=>{
      if(enrolmentDocumentKey){
        const data = await getLibraryForm(
          `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`
        );
        if(data?.data){
          setFormData(data?.data)
        }
        else if(data?.error){
          console.error('UNABLE TO GET FORM DETAILS');
        }
      }
    })();
  }, [enrolmentDocumentKey])

  const siteInitianDocumentKey = useSelector(
    (state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.siteInitiationForm
  );
  const siteId = trialSiteData?.siteTrialData?.siteId;
  const siteName = trialSiteData?.siteTrialData?.site?.orgname;
  const currentUserRoleOfTrailSite = useMemo(() => {
    if (trialSiteData) {
      const users = trialSiteData.users;
      const logginUserEmail = loggedInUser.email;
      const userObject = _.find(users, (user) => {
        return user.user.email?.toLowerCase() == logginUserEmail?.toLowerCase();
      })

      if (userObject) {
        return userObject.role;
      }
      return "sponsor";
    }

  }, [trialSiteData]);
  const viewReadOnlyForm = (title, step) => {
    setReadOnlyFormDetails({
      title, step
    })
    setOpenICForm(true)
  }

  const pageSize = useMemo(() => {
    if (currentUserRoleOfTrailSite == 'site_admin' ||
      currentUserRoleOfTrailSite == 'sponsor' || currentUserRoleOfTrailSite == 'site_monitor') {
      return 7;
    }
    return 12;
  }, [currentUserRoleOfTrailSite]);

  const handleOpenDocumentModal = () => {
    setUploadDocModal(true)
  }

  return (
    <>
      <Grid container p={3} rowGap={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="text" onClick={() => navigate(-1)} startIcon={<RightArrow leftArrow />}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                Back to Study Details
              </Typography>
            </Button>
            {currentUserRoleOfTrailSite === 'site_monitor' || currentUserRoleOfTrailSite === 'sponsor' ? (
              <AuditExportButton
                protocolNumber={trialSiteData?.siteTrialData?.trial?.protocolNumber}
                siteName={trialSiteData?.siteTrialData?.site?.orgname}
              />
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid container direction={'row'} columnSpacing={3}>
          <Grid item sm={12} md={pageSize}>
            <Box sx={{ pb: 3 }}>
              <TrialSiteDetailsCard siteDetails={trialSiteData?.siteTrialData} />
            </Box>
            <Box sx={{ pb: 3 }}>
              <SiteClinicalTeamCard
                teamMembers={trialSiteData?.users}
                currentUserRoleOfTrailSite={currentUserRoleOfTrailSite}
                trialId={trialId}
                siteId={siteId}
                siteName={siteName}
              />
            </Box>
            {(currentUserRoleOfTrailSite == 'site_admin' ||
              currentUserRoleOfTrailSite == 'sponsor' ||
              currentUserRoleOfTrailSite == 'site_monitor') && (
              <Box sx={{ pb: 3 }}>
                <CustomCard
                  title="eISF Documents"
                  action={
                    currentUserRoleOfTrailSite == 'site_admin' && (
                      <CustomButton size="small" onClick={handleOpenDocumentModal} startIcon={<CloudUploadIcon />}>
                        Upload Documents
                      </CustomButton>
                    )
                  }
                >
                    <DocumentsList trialId={trialId} sponsorId={sponsorId} siteId={siteId} />
                </CustomCard>
              </Box>
            )}
            {siteInitianDocumentKey !== 'NA' && (
              <Box sx={{ pb: 3 }}>
                <SiteStatusStepper />
              </Box>
            )}
          </Grid>
          {(currentUserRoleOfTrailSite == 'site_admin' ||
            currentUserRoleOfTrailSite == 'sponsor' ||
            currentUserRoleOfTrailSite == 'site_monitor') && (
            <Grid item md={5} sm={12}>
              <Box sx={{ pb: 3 }}>
                <TrialSiteBudgetCard siteDetails={trialSiteData?.siteTrialData} />
              </Box>
              <Box sx={{ pb: 3 }}>
                <TrialSiteCurrentExpenditure siteDetails={trialSiteData?.siteTrialData} />
              </Box>
            </Grid>
          )}
          {(currentUserRoleOfTrailSite == 'PI' ||
            currentUserRoleOfTrailSite == 'site_coordinator' ||
            currentUserRoleOfTrailSite == 'site_monitor') &&
            trialSiteData?.siteTrialData?.status == 'Completed' && (
              <Grid container rowSpacing={4.5} justifyContent={'space-between'}>
                <Grid item
                >
                  {currentUserRoleOfTrailSite === 'site_monitor' &&
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search Subject</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={'text'}
                      onChange={(e) => setSearchValue(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton 
                          // onClick={handleSearch} 
                          edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Search Subject"
                    />
                  </FormControl>
                  }
                </Grid>
                <Grid
                  item
                  sm={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    columnGap: 2,
                  }}
                >
                  {(currentUserRoleOfTrailSite == 'PI' ||
                    currentUserRoleOfTrailSite == 'site_monitor' ||
                    currentUserRoleOfTrailSite == 'site_coordinator') &&
                    formData &&
                    formData?.steps?.find((_obj) => _obj.key == 'InstructionsAndGuidance') && (
                      <CustomButton
                        variant="contained"
                        onClick={() => viewReadOnlyForm('Additional Info', 'InstructionsAndGuidance')}
                      >
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                          Additional Info
                        </Typography>
                      </CustomButton>
                    )}
                  {(currentUserRoleOfTrailSite == 'PI' ||
                    currentUserRoleOfTrailSite == 'site_monitor' ||
                    currentUserRoleOfTrailSite == 'site_coordinator') && (
                    <CustomButton variant="contained" onClick={() => viewReadOnlyForm('IC Form Details', 'IC')}>
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        View IC Form
                      </Typography>
                    </CustomButton>
                  )}
                  {(currentUserRoleOfTrailSite == 'PI' || currentUserRoleOfTrailSite == 'site_coordinator') && (
                    <CustomButton
                      variant="contained"
                      onClick={() => {
                        navigate(`/eConcent/${trialId}/trial-site/${trialSiteId}/SubjectEnrollmentFormParent`);
                      }}
                    >
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        + Screen Subject
                      </Typography>
                    </CustomButton>
                  )}
                  {currentUserRoleOfTrailSite === 'site_monitor' && (
                    <CustomButton
                      sx={{ mr: 2 }}
                      variant="contained"
                      onClick={() => {
                        setOpenAeDownload(true);
                      }}
                    >
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        AE/SAE Report
                      </Typography>
                    </CustomButton>
                  )}
                </Grid>
                <Grid item sm={12} sx={{ paddingTop: 8 }}>
                  {currentUserRoleOfTrailSite === 'site_monitor' && (
                    <SubjectAEListCard searchValue={searchValue} trialId={trialId} siteId={siteId} trialSiteId={trialSiteId} />
                  )}
                  {currentUserRoleOfTrailSite !== 'site_monitor' && (
                    <SubjectListCard
                      teamMembers={trialSiteData?.users}
                      trialId={trialId}
                      siteId={siteId}
                      trialSiteId={trialSiteId}
                      setOpenAeDownload={setOpenAeDownload}
                    />
                  )}
                </Grid>
                <Grid item sm={12} sx={{ paddingTop: 8 }}>
                  <SubjectWithdrawListCard
                    sponsorId={sponsorId}
                    trialId={trialId}
                    siteId={siteId}
                    trialSiteId={trialSiteId}
                  />
                </Grid>
              </Grid>
            )}
        </Grid>
        {
          <ReadonlyFormDetails
            title={readOnlyFormDetails.title}
            open={openICForm}
            trialId={trialId}
            sponsorId={sponsorId}
            trialSiteId={trialSiteId}
            step={readOnlyFormDetails.step}
            handleClose={() => setOpenICForm(false)}
          />
        }
      </Grid>
      <DocumentUploadWithCategory
        dialogTitle={'Upload eISF Documents'}
        open={uploadDocModal}
        handleClose={() => setUploadDocModal(false)}
        trialId={trialId}
        siteId={siteId}
      />
      <DownloadAEReport
        open={openAeDownload}
        handleClose={() => setOpenAeDownload(false)}
        trialId={trialId}
        siteId={siteId}
        documentKey={crfDocumentKey}
      />
    </>
  );
};

export default TrialSiteDetails;
