import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled, useTheme } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { bloqcibeApi } from '../../store/slices/apiSlice';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch } from 'react-redux';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const extractFilenameFromKey = (key) => {
  // Split the key by '/'
  const parts = key.split('/');
  // Get the last part (filename)
  const filename = parts[parts.length - 1];
  return filename;
};

const ManageDocuments = (props) => {
  const theme = useTheme();
  const { uploadDocument, deleteDocument, uploadedDocuments } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMeta, setAnchorMeta] = useState(null);
  const dispatch = useDispatch()

  const handleUpload = async (acceptedFiles) => {
    uploadDocument(acceptedFiles);
  };

  const handleDelete = async (s3Key) => {
    deleteDocument(s3Key);
  };

  const [downloadDOASignature] = bloqcibeApi.endpoints.downloadDOASignature.useLazyQuery();


  const downloadDoc = async (s3Key, fileName) => {
    const result = await downloadDOASignature({
      s3Key,
    });
    if (result.data) {
      let byteArray = base64ToArrayBuffer(result.data);
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    } else {
    dispatch(
      openMessage({
        messageSeverity: 'error',
        message: result?.error?.data?.message,
      })
    );
    }
  };

  const openAnchor = (event, document) => {
    setAnchorEl(event.currentTarget);
    setAnchorMeta(document);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <List sx={{ height: 'calc(100vh - 220px)', overflow: 'auto', padding: 2 }}>
        {uploadedDocuments &&
        uploadedDocuments.filter(
          (_doc) => _doc?.type !== 'COMPLETE_TASK_SIGNATURE' && _doc?.type !== 'APPROVE_TASK_SIGNATURE'
        ).length > 0 ? (
          uploadedDocuments.map((_document, index) => {
            const fileName = extractFilenameFromKey(_document?.documentS3Key);
            if (_document.type === 'DOA_TASK_DOCUMENT')
              return (
                <>
                  <ListItem
                    key={`doc_${_document.id}`}
                    secondaryAction={
                      <IconButton
                        aria-describedby={`docId_${index}`}
                        onClick={(e) =>
                          openAnchor(e, { id: `docId_${index}`, documentS3Key: _document?.documentS3Key, fileName })
                        }
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <InsertDriveFileOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={fileName}
                      secondary={`Uploaded by: ${_document.createdBy} on ${new Date(
                        _document.createdAt
                      ).toLocaleDateString()}`}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
          })
        ) : (
          <Box sx={{ textAlign: 'center', paddingY: 10 }}>
            <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
              No Documents available.
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary} sx={{ pb: 0.5, fontStyle: 'italic' }}>
              Note: You can add documents by clicking on the 'Add File' button
            </Typography>
          </Box>
        )}
      </List>
      <Divider />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2 }}>
        <Button
          sx={{ width: '40%' }}
          component="label"
          variant="outlined"
          size="large"
          //   onClick={(e) => checkforName(e)}
          startIcon={<CloudUploadIcon />}
          // disabled={!formik.isValid}
        >
          Upload file
          <VisuallyHiddenInput
            onChange={(e) => {
              handleUpload(e.target.files);
              e.target.value = null;
            }}
            type="file"
            multiple={false}
            accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
          />
        </Button>
      </Box>
      <Popover
        id={anchorMeta?.id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setAnchorMeta(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List sx={{ width: 250 }}>
          <ListItem>
            <ListItemButton
              onClick={() => {
                downloadDoc(anchorMeta?.documentS3Key, anchorMeta?.fileName);
                setAnchorEl(null);
                setAnchorMeta(null);
              }}
            >
              <ListItemIcon>
                <CloudDownloadIcon sx={{ color: theme.palette.grey[600] }} />
              </ListItemIcon>
              <ListItemText primary="Download" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleDelete(anchorMeta?.documentS3Key);
                setAnchorEl(null);
                setAnchorMeta(null);
              }}
            >
              <ListItemIcon>
                <DeleteIcon sx={{ color: theme.palette.grey[600] }} />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default ManageDocuments;
