import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Dialog, Grid, Button } from "@mui/material";
import CustomButton from "../../components/@extended/CustomButton";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import ResetPasswordForm from "./resetPasswordForm";


const ResetPasswordDialog = ({openDialog,handlePasswordDialogClose}) => {
  const theme = useTheme();
  const {isSmallScreen} = useResponsive();
  
  //open reset password form
  const [openResetForm, setOpenResetForm] = useState(false)
  

  const handleClose = (value) => {
    handlePasswordDialogClose();
    setOpenResetForm(false);
  };

  const handleCloseResetForm = (value) => {
    handlePasswordDialogClose();
    setOpenResetForm(false);
  };

  const handleOpenResetForm = () => {
    setOpenResetForm(true);
  };

  return (
    <Dialog onClose={handleClose} open={openDialog || openResetForm}>
      <Grid container sx={{ p: 3 }} rowGap={2}>
        {openResetForm ? (
          <ResetPasswordForm handleClose={handleClose} handleCloseResetForm={handleCloseResetForm} tempPasswordLabel={"Temporary Password"}/>
        ) : (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4, paddingTop: isSmallScreen ? 8 : 4 }}>
              <Typography variant="subtitle1" color="initial">
                Your temporary password will expire in 24 hours. Please reset your password.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{ width: 150, color: theme.palette.primary.light }}
                // size="large"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <CustomButton
                variant="text"
                onClick={() => {
                  handleOpenResetForm();
                }}
              >
                Reset password
              </CustomButton>
            </Box>
          </Box>
        )}
      </Grid>
    </Dialog>
  );
};

export default ResetPasswordDialog;
