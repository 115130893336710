import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../../components/@extended/CustomButton';
import Phone, { phoneValidationSchema } from '../../../components/common/Phone';
import FormikField from '../../visitScheudle/FormikTextField';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { addVendorForOrg } from '../../../store/slices/pfasSlice';
import { openMessage } from '../../../store/slices/showMessageSlice';

const AddVendor = ({ open, onClose }) => {
  const { sponsorId, assignmentId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [accountVerified, setAccountVerified] = useState(false);
  const accountDetailsValidationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required('Account Holder Name is required'),
    account_holder_email: Yup.string()
      .email('Invalid email')
      .required('Account Holder Email is required')
      .matches(
        /^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        'Email address can only contain lowercase letters, numbers, and periods.'
      ),
    account_holder_number: Yup.string().required('Account Number is required'),
    confirm_account_holder_number: Yup.string()
      .oneOf([Yup.ref('account_holder_number'), null], 'Confirm Account Number must match Account Number')
      .required('Confirm Account Number is required'),
    routingNumber: Yup.string().required('Routing Number is required'),
    confirm_routing_Number: Yup.string()
      .required('Confirm Routing Number is required')
      .oneOf([Yup.ref('routingNumber'), null], 'Confirm Routing Number must match Routing Number'),
    abaNumber: Yup.string()
      .required('ABA number is required')
      .matches(/^[0-9]+$/, 'ABA number must be numeric'),
    swiftNumber: Yup.string()
      .required('Swift Number is required')
      .matches(/^[0-9]+$/, 'Swift Number must be numeric'),
    country: Yup.string().required('Country is required'),
    currency: Yup.string().required('Currency is required'),
  });

  const validationSchema = Yup.object().shape({
    vendorName: Yup.string().required('Vendor Name is required'),
    vendorType: Yup.string().required('Vendor Category is required'),
    contactEmail: Yup.string()
      .email('Invalid email')
      .required('Contact Email is required')
      .matches(
        /^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        'Email address can only contain lowercase letters, numbers, and periods.'
      ),
    contactNumber: phoneValidationSchema.concat(Yup.string().required('Contact number is required')),
    fax: phoneValidationSchema.concat(Yup.string().required('Fax is required')),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    postalcode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
    accountDetails: accountDetailsValidationSchema,
  });

  const validateAccountDetails = async (values, setFieldTouched, setFieldError) => {
    try {
      await accountDetailsValidationSchema.validate(values.accountDetails, { abortEarly: false });
      setAccountVerified(true);
      dispatch(openMessage({ messageSeverity: 'success', message: 'Bank Account Details Validated.' }));
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          setFieldTouched(`accountDetails.${err.path}`, true);
          setFieldError(`accountDetails.${err.path}`, err.message);
        });
      }
      setAccountVerified(false);
    }
  };

  const handleFormSubmit = (values) => {
    dispatch(addVendorForOrg({data:values, orgId:sponsorId}));
  };
  return (
    <Dialog scroll={'paper'} open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          vendorName: '',
          vendorType: '',
          contactNumber: '',
          fax: '',
          contactEmail: '',
          addressLine1: '',
          state: '',
          city: '',
          postalcode: '',
          country: '',
          accountDetails: {
            account_holder_name: '',
            account_holder_email: '',
            account_holder_number: '',
            confirm_account_holder_number: '',
            routingNumber: '',
            confirm_routing_Number: '',
            abaNumber: '',
            swiftNumber: '',
            country: '',
            currency: '',
          },
        }}
        onSubmit={(values, { resetForm }) => {
          handleFormSubmit(values);
          onClose();
          setAccountVerified(false);
          resetForm();
        }}
      >
        {({
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldError,
        }) => (
          <>
            <DialogTitle>
              Create Vendor
              <IconButton
                aria-label="close"
                onClick={() => {
                  onClose();
                  setAccountVerified(false);
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', pb: 3 }}>
                <Grid container spacing={4} sx={{ pt: 2 }}>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.vendorName && Boolean(errors.vendorName)}>
                      <Field component={FormikField} name="vendorName" label="Vendor Name" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.vendorType && Boolean(errors.vendorType)}>
                      <InputLabel>Vendor Type</InputLabel>
                      <Field
                        component={Select}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        label="Vendor Type"
                        inputProps={{
                          id: 'vendorType',
                          name: 'vendorType',
                        }}
                      >
                        <MenuItem value={'Regular'}>Regular</MenuItem>
                        <MenuItem value={'Intermediate'}>Intermediate</MenuItem>
                      </Field>
                      {touched.vendorType && errors.vendorType && <FormHelperText>{errors.vendorType}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.contactInfo && Boolean(errors.contactInfo)}>
                      <Field name="contactNumber" size="medium" component={Phone} label="Contact Number" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.fax && Boolean(errors.fax)}>
                      <Field name="fax" size="medium" component={Phone} label="Fax" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.contactEmail && Boolean(errors.contactEmail)}>
                      <Field component={FormikField} name="contactEmail" label="Contact Email" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.addressLine1 && Boolean(errors.addressLine1)}>
                      <Field component={FormikField} name="addressLine1" label="Address Line 1" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.state && Boolean(errors.state)}>
                      <Field component={FormikField} name="state" label="State" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.city && Boolean(errors.city)}>
                      <Field component={FormikField} name="city" label="City" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.postalcode && Boolean(errors.postalcode)}>
                      <Field component={FormikField} name="postalcode" label="Postal Code" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl fullWidth error={touched.country && Boolean(errors.country)}>
                      <Field component={FormikField} name="country" label="Country" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={12}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" mr={2}>
                        Bank Account Information
                      </Typography>
                      {accountVerified ? (
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.light,
                            fontSize: 20,
                          }}
                        />
                      ) : (
                        <WatchLaterIcon
                          sx={{
                            color: '#FFCB00',
                            fontSize: 20,
                          }}
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: accountVerified ? theme.palette.success.light : ' #FFCB00',
                          width: 'fit-content',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginLeft: theme.spacing(0.5),
                        }}
                      >
                        {accountVerified ? 'Verified' : 'Unverified'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_name &&
                        Boolean(errors.accountDetails?.account_holder_name)
                      }
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_name"
                        label="Account Holder Name"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_email &&
                        Boolean(errors.accountDetails?.account_holder_email)
                      }
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_email"
                        label="Account Holder Email"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.account_holder_number &&
                        Boolean(errors.accountDetails?.account_holder_number)
                      }
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.account_holder_number"
                        label="Account Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.confirm_account_holder_number &&
                        Boolean(errors.accountDetails?.confirm_account_holder_number)
                      }
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.confirm_account_holder_number"
                        label="Confirm Account Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.routingNumber && Boolean(errors.accountDetails?.routingNumber)}
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.routingNumber"
                        label="Routing Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={
                        touched.accountDetails?.confirm_routing_Number &&
                        Boolean(errors.accountDetails?.confirm_routing_Number)
                      }
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.confirm_routing_Number"
                        label="Confirm Routing Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.abaNumber && Boolean(errors.accountDetails?.abaNumber)}
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.abaNumber"
                        label="ABA number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.swiftNumber && Boolean(errors.accountDetails?.swiftNumber)}
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.swiftNumber"
                        label="Swift Number"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.country && Boolean(errors.accountDetails?.country)}
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.country"
                        label="Country"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={touched.accountDetails?.currency && Boolean(errors.accountDetails?.currency)}
                    >
                      <Field
                        disabled={accountVerified ? true : false}
                        component={FormikField}
                        name="accountDetails.currency"
                        label="Currency"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => {
                        accountVerified
                          ? setAccountVerified(false)
                          : validateAccountDetails(values, setFieldTouched, setFieldError);
                      }}
                      color="primary"
                      sx={{ padding: '8px 30px', textTransform: 'none' }}
                    >
                      {!accountVerified ? 'Validate' : 'Edit'}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 3, py: 1 }}>
                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    onClose();
                    setAccountVerified(false);
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton variant="contained" type="save" onClick={handleSubmit}>
                  Save
                </CustomButton>
              </Box>
            </DialogContent>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddVendor;
