import {
    Grid,
    useTheme,
    Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTrialsMutation, useGetMeUserQuery } from "../../store/slices/apiSlice";
import DraftTrialsTable from "./DraftTrialsTable";
import OnGoingTrialsTable from "./OnGoingTrials"
import { useSelector } from "react-redux";
import { getUserRole } from "../util";
import BookedVisitSlots from "./BookedVisitSlots";
 
  
  const SiteAdminDashboard = () => {
    const theme = useTheme();
    const loggedInUser = useSelector((state) => state.auth.user);
    const [getTrials, { data }] = useGetTrialsMutation();
    const userRole = getUserRole(loggedInUser);

      
    return (
      <Grid container rowSpacing={4.5}>
        <Grid item sm={12} sx={{ paddingTop: 8 }}>
          <BookedVisitSlots />
        </Grid>
        <Grid item sm={12} sx={{ paddingTop: 8 }}>
          <Divider sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`, borderRadius: 5 }} />
          <OnGoingTrialsTable />
        </Grid>
        {(userRole == 'PI' || userRole == 'site_admin') && (
          <Grid item sm={12} sx={{ paddingTop: 8 }}>
            <Divider sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`, borderRadius: 5 }} />
            <DraftTrialsTable />
          </Grid>
        )}
      </Grid>
    );
  };
  
  export default SiteAdminDashboard;
  