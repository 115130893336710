import { Alert, Box, Button, Divider, Drawer, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import dayjs from 'dayjs';
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {bloqcibeApi, useCreateTrialBookingSlotMutation, useGetOngoingTrialsQuery, useUpdateTrialBankDetailMutation, useUpdateTrialBookingSlotMutation } from "../../store/slices/apiSlice";
import FormikDatePickerField from "../../components/common/FormikDatePickerField";
import FormikTimePickerField from "../../components/common/FormikTimePickerField";
import DeleteIcon from '@mui/icons-material/Delete';
import FormikField from "../visitScheudle/FormikTextField";
import CustomButton from "../../components/@extended/CustomButton";
import moment from "moment";
import { openMessage } from "../../store/slices/showMessageSlice";

const AddSlot = (props) => {
    const { open, onClose, editSlotDetails, copySlotDetails} = props;
    const dispatch = useDispatch()
    const [createTrialBookingSlot, { error }] = useCreateTrialBookingSlotMutation();
    const [updateTrialBookingSlot, { errorSlot }] = useUpdateTrialBookingSlotMutation();
    const [showError, setShowError] = useState(false);
    const [trial, setTrial] = useState();
    // const [site, setSite] = useState();
    // const [siteList, setSiteList] = useState([]);
    const [visitList, setVisitList] = useState([]);
    const sponsorId = useSelector((state) => state.auth?.sponsorId);
    const {
        data: ongoingTrials,
    } = useGetOngoingTrialsQuery(sponsorId);
    // const [getTrialSiteDetails] =
    //     bloqcibeApi.endpoints.getTrialSiteDetails.useLazyQuery();
    const [getTrialVisitDetails] =
        bloqcibeApi.endpoints.getTrialVisitDetails.useLazyQuery();

    const openMessageNotification = (message) => {
            dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };
    
    const noOverlap = (intervals) => {
        for (let i = 0; i < intervals.length; i++) {
            const newStart = moment(intervals[i].startTime).format("HH:mm");
            const newEnd = moment(intervals[i].endTime).format("HH:mm");
            for (let j = 0; j < intervals.length; j++) {
                const start = moment(intervals[j].startTime).format("HH:mm");
                const end = moment(intervals[j].endTime).format("HH:mm");

                if(i!=j){
                    if (moment(intervals[i].startTime).isBetween(intervals[j].startTime, intervals[j].endTime) || moment(intervals[i].endTime).isBetween(intervals[j].startTime, intervals[j].endTime)) {
                        return false; // Overlap detected
                    }
                    if(moment(intervals[i].startTime).isValid() && moment(intervals[i].endTime).isValid() && moment(intervals[j].startTime).isValid() && moment(intervals[j].endTime).isValid()){
                        if (newStart === start || newEnd === end ) {
                            return false; // Overlap detected
                        }
                    }
                }
            }
        }
        return true; // No overlap
    };

    const validationSchema = Yup.object().shape({
        trialId: Yup.string().required("Trial is required"),
        visitId: Yup.string().required("Visit is required"),
        // siteId: Yup.string().required("Site is required"),
        date: Yup.date("Please select or enter valid date")
            .typeError("Please select or enter valid date")
            .required("Date is required")
            .test("isAfterCurrentDate", "Date must be after the current date", function(value) {
                const date = moment(value).format('MM-DD-YYYY')
                const currDate = moment().format('MM-DD-YYYY')
                if (moment(date).isBefore(currDate)) {
                    return false;
                }
                return true;
            }),
        slots: Yup.array().of(
            Yup.object()
            .shape({
                startTime: Yup
                    .string()
                    .required('Start time is required')
                    .test("isAfterCurrentTime", "Start time must be after the current time", function(value, context) {
                        const { date } = context.options.context;
                        if (moment(date).isSame(moment(), 'day') && moment(value).utcOffset("+05:30").isBefore(moment())) {
                            return false;
                        }
                        return true;
                    }),
                endTime: Yup
                    .string()
                    .required("end time is required")
                    .test("is-greater", "End time must be after the start time", function(value) {
                        const { startTime } = this.parent;
                        return moment(startTime).isBefore(moment(value));
                    })
                    .test("isAfterCurrentTime", "End time must be after the current time", function(value,context) {
                        const { date } = context.options.context;
                        if (moment(date).isSame(moment(), 'day') && moment(value).utcOffset("+05:30").isBefore(moment())) {
                            return false;
                        }
                        return true;
                    }),
                maxCapacity: Yup.number().test("atleast-1", "Capacity should be atleast 1", function(value){
                    return value>0;
                }),
            })
            .test('no-overlap', 'Overlap detected', function (value) {
                const parent = this.parent;
                if (parent) {
                  return noOverlap(parent);
                }
                return true;
            })
        )
        
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
            if (name === "trialId") {
                setTrial(value);
            }
    };

    useEffect(() => {
        if(editSlotDetails){
            setTrial(editSlotDetails.trial.id)
        }
        else if(copySlotDetails){
            setTrial(copySlotDetails[0].trial.id)
        }
    }, [editSlotDetails, copySlotDetails]);

    // useEffect(() => {
    //     (async () => {
    //         if (trial) {
    //             const _siteList = await getTrialSiteDetails({
    //             sponsorId,
    //             trialId: trial,
    //             });
    //             if (_siteList?.data) {
    //             const _list = _siteList.data.map((_site) => {
    //                 return { siteName: _site.site.orgname, id: _site.siteId };
    //             });
    //             setSiteList(_list);
    //             }
    //         }
    //         })();
    //   }, [editSlotDetails,trial]);

    useEffect(() => {
        (async () => {
          if (trial) {
            const _visitList = await getTrialVisitDetails({
              trialId: trial,
            });
            if (_visitList?.data) {
              const _list = _visitList.data.map((_visit) => {
                return { visitName: _visit.stepTitle, id: _visit.id };
              });
              setVisitList(_list);
            }
          }
        })();
    }, [editSlotDetails, trial]);
   
    


    return (<>
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 600, // Set the width of the Drawer paper
                    height: '100%',
                    paddingTop: 8,
                    backgroundColor: '#F5F5F5',
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box>
                <Box sx={{ padding: 3 }}><Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>
                    {editSlotDetails? "Update Slot" : 'Add Slot'}
                </Typography>
                </Box>
                <Divider />
                <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column', rowGap: 4 }}>
                    <Formik
                        initialValues={{
                            trialId: editSlotDetails? editSlotDetails?.trial.id : copySlotDetails? copySlotDetails[0].trial.id : "",
                            visitId: editSlotDetails? editSlotDetails?.visitId : copySlotDetails? copySlotDetails[0].visitId : "",
                            // siteId: editSlotDetails? editSlotDetails?.site.id : copySlotDetails? copySlotDetails[0].site.id : "",
                            date: editSlotDetails? dayjs(editSlotDetails?.date).format('MM-DD-YYYY') : copySlotDetails? dayjs(copySlotDetails[0].date).format('MM-DD-YYYY') : dayjs().format('MM-DD-YYYY'),
                            slots: 
                                copySlotDetails? copySlotDetails.map((copySlotDetail)=>{
                                    return({
                                    startTime: copySlotDetail? dayjs(moment(copySlotDetail?.startTime, "HH:mm:ss").format("YYYY-MM-DDTHH:mm")) : null, 
                                    endTime: copySlotDetail? dayjs(moment(copySlotDetail?.endTime, "HH:mm:ss").format("YYYY-MM-DDTHH:mm")) : null, 
                                    maxCapacity: copySlotDetail? copySlotDetail?.maxCapacity : 1,
                                })}):
                                [{
                                    startTime: editSlotDetails? dayjs(moment(editSlotDetails?.startTime, "HH:mm:ss").format("YYYY-MM-DDTHH:mm")) : null, 
                                    endTime: editSlotDetails? dayjs(moment(editSlotDetails?.endTime, "HH:mm:ss").format("YYYY-MM-DDTHH:mm")) : null, 
                                    maxCapacity: editSlotDetails? editSlotDetails?.maxCapacity: 1,
                                }]
                        }}
                        validationSchema={validationSchema}
                        validate={values => {
                            validationSchema.validate(values, { context: values }).catch(err => {
                                console.log(err)
                            });
                        }}
                        onSubmit={async (values, {resetForm}) => {
                            if(!editSlotDetails){
                                const result = await createTrialBookingSlot({
                                    trialId: values.trialId,
                                    visitId: values.visitId,
                                    // siteId: values.siteId,
                                    payload: {
                                        date: values.date,
                                        slots: values.slots.map((slot)=>({
                                            startTime:moment(slot.startTime.$d, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm"),
                                            endTime:moment(slot.endTime.$d, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm"),
                                            maxCapacity:slot.maxCapacity
                                        }))
                                    }
                                });
                                if (!result?.error) {
                                    openMessageNotification({
                                        message: "slot added successfully",
                                        type: "success",
                                    });
                                } else {
                                    if(result?.error?.data?.statusCode == 400){
                                        openMessageNotification({
                                            message: result?.error?.data?.message,
                                            type: "error",
                                        });
                                    }
                                else{
                                        openMessageNotification({
                                        message: result?.error?.data?.error_description,
                                        type: "error",
                                        });
                                    }
                                }
                            }
                            else{
                                const result = await updateTrialBookingSlot({
                                    slotId: editSlotDetails?.id,
                                    payload: {
                                            startTime:moment(values.slots[0].startTime.$d, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm"),
                                            endTime:moment(values.slots[0].endTime.$d, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm"),    
                                            maxCapacity:values.slots[0].maxCapacity
                                    }
                                });
                                if (!result?.error) {
                                    openMessageNotification({
                                        message: "slot updated successfully",
                                        type: "success",
                                    });
                                } else {
                                    if(result?.error?.data?.statusCode == 400){
                                        openMessageNotification({
                                            message: result?.error?.data?.message,
                                            type: "error",
                                        });
                                    }
                                else{
                                        openMessageNotification({
                                        message: result?.error?.data?.error_description,
                                        type: "error",
                                        });
                                    }
                                }
                            }
                            onClose()
                        }}
                    >
                        {({
                            isSubmitting,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            resetForm,
                            values,
                            touched,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={11} md={5.5} sx={{height:100}}>
                                        <FormControl
                                            fullWidth
                                            error={touched.trialId && Boolean(errors.trialId)}
                                        >
                                            <InputLabel>Trial</InputLabel>
                                            <Field
                                                disabled = {editSlotDetails ? true : false} 
                                                component={Select}
                                                onBlur={handleBlur}
                                                label="Trial"
                                                onChange={(e) => {
                                                    handleFormChange(e);
                                                    handleChange(e);
                                                }}
                                                value={values.trialId}
                                                inputProps={{
                                                    id: "trialId",
                                                    name: "trialId",
                                                }}
                                            >
                                                {ongoingTrials &&
                                                ongoingTrials.map((trial, index) => (
                                                    <MenuItem key={index} value={trial.id}>
                                                        {trial.protocolNumber}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                            {touched.trialId && errors.trialId ? (
                                                <FormHelperText>{errors.trialId}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={11} md={5.5} sx={{height:100}}>
                                        <FormControl
                                            fullWidth
                                            error={touched.siteId && Boolean(errors.siteId)}
                                        >
                                            <InputLabel>Site</InputLabel>
                                            <Field
                                                disabled = {editSlotDetails ? true : false} 
                                                component={Select}
                                                onBlur={handleBlur}
                                                label="Site"
                                                onChange={handleChange}
                                                value={values.siteId}
                                                inputProps={{
                                                    id: "siteId",
                                                    name: "siteId",
                                                }}
                                            >
                                                {siteList && siteList?.map((site,index) => (
                                                    <MenuItem key={index} value={site.id}>{site.siteName}</MenuItem>
                                                ))}

                                            </Field>
                                            {touched.siteId && errors.siteId ? (
                                                <FormHelperText>{errors.siteId}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={11} md={5.5} sx={{height:100}}>
                                        <FormControl
                                            fullWidth
                                            error={touched.visitId && Boolean(errors.visitId)}
                                        >
                                            <InputLabel>Visit</InputLabel>
                                            <Field
                                                disabled = {editSlotDetails ? true : false} 
                                                component={Select}
                                                onBlur={handleBlur}
                                                label="Visit"
                                                onChange={handleChange}
                                                value={values.visitId}
                                                inputProps={{
                                                    id: "visitId",
                                                    name: "visitId",
                                                }}
                                                
                                            >
                                                {visitList && visitList?.map((visit,index) => (
                                                    <MenuItem key={index} value={visit.id}>{visit.visitName}</MenuItem>
                                                ))}

                                            </Field>
                                            {touched.visitId && errors.visitId ? (
                                                <FormHelperText>{errors.visitId}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={11} md={5.5} sx={{height:115}}>
                                        <FormControl
                                            fullWidth
                                            error={touched.date && Boolean(errors.date)}
                                        >
                                            <Field disabled = {editSlotDetails ? true : false} name="date" component={FormikDatePickerField} label="Select Date" inputProps={{
                                                name: "date",
                                            }}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{height:"40%"}}>
                                        <FieldArray name="slots">
                                            {({ push, remove }) => (
                                                <>
                                                    {values.slots.map((slot, index) => (
                                                        <Box key={index} sx={{height: 105}}>
                                                            <Box sx={{display:"flex", direction:"row"}}>
                                                                <Grid container spacing={4}>
                                                                    <Grid item xs={4}>
                                                                        <Field name={`slots.${index}.startTime`} size='small' disablePast={(values.date===moment().format('MM-DD-YYYY') || values.date===moment().format('MM/DD/YYYY'))? true : false} component={FormikTimePickerField} label="Start Time" sx={{height:70}}/>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Field name={`slots.${index}.endTime`} size='small' disablePast={(values.date===moment().format('MM-DD-YYYY') || values.date===moment().format('MM/DD/YYYY'))? true : false} component={FormikTimePickerField} label="End Time" sx={{height:70}}/>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Field
                                                                            component={FormikField}
                                                                            name={`slots.${index}.maxCapacity`}
                                                                            label="Capacity"
                                                                            size='large'
                                                                            type="number"
                                                                            
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1} justifyContent={"center"}>
                                                                    {values.slots.length > 1 ? <Box sx={{ width: '5%'}}><IconButton sx={{p:0, pt:1.5, "&:hover":{backgroundColor: "transparent"}}}  aria-label="remove" onClick={() => remove(index)}>
                                                                        <DeleteIcon/>
                                                                    </IconButton></Box> : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            {touched.slots && errors.slots && (
                                                                !errors.slots[index]?.startTime && !errors.slots[index]?.endTime && !errors.slots[index]?.maxCapacity ? (
                                                                    <FormHelperText sx={{mt:-2, color: "#c62828"}}>{errors.slots[index]}</FormHelperText>
                                                                ) : null
                                                            )}
                                                        </Box>
                                                    ))}
                                                    {!editSlotDetails ?
                                                    <Button
                                                        onClick={() => push({ startTime: '', endTime: '', maxCapacity: 1 })}
                                                        variant="text"
                                                    >
                                                        + New Time Slot
                                                    </Button>
                                                    : ""}
                                                    
                                                </>
                                            )}
                                        </FieldArray>
                                        
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Box sx={{ width: '100%', mt: 'auto', display: 'flex', justifyContent: 'space-between', bottom: 0}}>
                                            <CustomButton variant="outlined" onClick={onClose}>Cancel</CustomButton>
                                            <CustomButton type="submit" variant="contained" >{editSlotDetails? "Update" : "Add"}</CustomButton>
                                        </Box>
                                    </Grid>
                                    
                                </Grid>
                                
                            </Form>
                        )}
                    </Formik>

                </Box>
            </Box>
            
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={() => setShowError(false)} severity={'error'} sx={{ width: '100%' }}>
                        {'Please add the slots!'}
                    </Alert>
                </Snackbar>
            </Stack>
        </Drawer></>);
}

export default AddSlot;