import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Box } from '@mui/material';
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../@extended/CustomButton";

const ConfirmationDialog = (props) => {
  const { open, handleClose, handleConfirm, message, buttonLabel } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="initial">
          {message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', pt: 3 }}>
          <CustomButton variant={'outlined'} size="small" onClick={handleClose}sx={{mr:2}}>
            Cancel
          </CustomButton>
          <CustomButton variant={'contained'} size="small" onClick={handleConfirm}>
            {buttonLabel}
          </CustomButton>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleConfirm}>{buttonLabel}</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ConfirmationDialog;
