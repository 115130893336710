export const SponsorOrganizationColumns = () => {
  const columns = [
    {
      id: 'logo',
      label: '',
      style: {
        minWidth: 5,
        maxWidth: 7,
        textAlign: 'left',
      },
      align: 'left',
    },
    {
      id: 'name',
      label: 'Name',
      style: {
        minWidth: 150,
        textAlign: 'left',
      },
      align: 'left',
    },
    {
      id: 'createdAt',
      label: 'Created Date',
      style: {
        minWidth: 170,
        textAlign: 'left',
      },
      align: 'left',
    },
    {
      id: 'email',
      label: 'Email',
      style: {
        minWidth: 200,
        textAlign: 'left',
      },
      align: 'left',
    },
    {
      id: 'rightArrow',
      label: '',
      style: {
        minWidth: 50,
        textAlign: 'right',
      },
      align: 'right',
    },
  ];
  return columns;
};
