import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from "../../components/@extended/CustomTable";
import { useEffect, useMemo, useRef, useState } from "react";
import SignatureBox from "../../components/common/SignatureBox";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SignatureSection from "./SignatureSection";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { bloqcibeApi, useAddDOACommentMutation, useChangeDOATaskStatusMutation, useDeleteDOADocumentsMutation, useGetTrialSiteInfoQuery, useUploadDoaTaskDocumentMutation,useBulkVerifyDOATasksMutation, useSaveDOASignMutation } from "../../store/slices/apiSlice";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import { openMessage } from "../../store/slices/showMessageSlice";
import ManageTaskResources from "./ManageTaskReources";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CustomButton from "../../components/@extended/CustomButton";
import Checkbox from '@mui/material/Checkbox';
import RightArrow from "../../components/icons/RightArrow";
import SearchIcon from "@mui/icons-material/Search";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { updatePagination } from "../../store/slices/applicationSlice";
import CustomTablePagination from "../../components/common/TablePagination";

const ButtonWrapper=()=>{
    
}

const TaskList = ({ selectedUser, trialId, siteId, siteTrialId,setSelectedUser,setOpenTaskList,value }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const focusRowRef = useRef(null);
    const [rows, setRows] = useState([]);
    const [selectTask,setSelectTask]=useState(false);
    const [hilightedTask,setHilightedTask]=useState();
    const [checkedTask,setCheckedTask]=useState([]);
    const [openSignatureBox,setOpenSignatureBox]=useState();
    const [openManageTask,setOpenManageTask]=useState(false);
    const [changeStatusType, setChangeStatusType] = useState();
    const [selectedAssignedTaskId, setSelectedAssignedTaskId] = useState();
    const [selectedDocumentType , setselectedDocumentType] = useState()
    const [selectedResourceTask, setSelectedResourceTask] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [openResources, setOpenResources] = useState(false);
    // const [confirmDialog, setConfirmDialog] = useState({
    //     open: false,
    //     message: '',
    //     buttonLabel: 'Confirm',
    // });
    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const [addDOAComment] = useAddDOACommentMutation();
    const loggedinUserId = useSelector((state) => state.userDetails?.loggedInUser?.id);
    const [fetchUserAssigngedTasks, { data: userAssignedTasks }] =
        bloqcibeApi.endpoints.fetchUserAssigngedTasks.useLazyQuery();
    const [fetchAllAssigngedTasks, { data: allAssignedTasks }] =
        bloqcibeApi.endpoints.fetchAllAssigngedTasks.useLazyQuery();
    const [changeDOATaskStatus] = useChangeDOATaskStatusMutation();
    const [uploadDoaTaskDocument] = useUploadDoaTaskDocumentMutation();
    const [deleteDOADocuments] = useDeleteDOADocumentsMutation();
    const [bulkVerifyTask] = useBulkVerifyDOATasksMutation();
    const [saveDOASign] = useSaveDOASignMutation();
    const { data: trialSiteData } = useGetTrialSiteInfoQuery({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: siteTrialId,
    });

    const [page , setPage] = useState(0);
    const [rowsPerPage , setRowsPerPage] = useState(5);


    const DOA_MY_TASK = useSelector((state) => state.application.pagination.DOA_MY_TASK);
    const DOA_COMPLETED_TASK = useSelector((state) => state.application.pagination.DOA_COMPLETED_TASK);
    const DOA_SELECTED_USER_LOG = useSelector((state) => state.application.pagination.DOA_SELECTED_USER_LOG);

    useEffect(()=>{
        let data ;
        if(selectedUser){
            data = DOA_SELECTED_USER_LOG
        }else if (value === 1){
            data = DOA_MY_TASK
        }else if (value === 2){
            data = DOA_COMPLETED_TASK
        }
        setPage(data.page)
        setRowsPerPage(data.rowsPerPage)

    },[selectedUser ,value , DOA_MY_TASK , DOA_SELECTED_USER_LOG ,DOA_COMPLETED_TASK])

    let isPILoggedin = useMemo(() => {
      if (trialSiteData && trialSiteData.users) {
        const loggedInUserInfo = trialSiteData.users.find((_obj) => _obj.userId == loggedinUserId);
    
        return loggedInUserInfo.role === 'PI' ? true : false;
      }
      return false;
    }, [trialSiteData]);

    useEffect(()=>{
        reloadData();
        setSelectTask(false)
        setSearchValue('')
        setIsSearching(false);
        setCheckedTask([]);
    },[value, selectedUser,loggedinUserId])

    useEffect(()=>{
        if(location?.state?.taskId){
            setHilightedTask(location?.state?.taskId)
            window.history.replaceState(
                {...window.history.state , usr:null},
                document.title
            )
        }
    },[location])

    useEffect(() => {
        (async () => {
            if (trialId && siteId) {
                await reloadData();
            }
        })()
    }, [value, trialId, siteId])

    useEffect(()=>{
        if(focusRowRef.current){
            focusRowRef.current.scrollIntoView({behaviour:'smooth',block:'center'})
        }
    },[rows])
    // const calculatePeriodEnd = (startDate, endDate, status, documents) => {
    //     if(status.toUpperCase() == 'COMPLETED' || status.toUpperCase() == 'APPROVED') {
    //         const signRec = documents.find((_obj) => _obj.type == 'COMPLETE_TASK_SIGNATURE');
    //         return moment(signRec?.updatedAt).format('DD-MMM-YYYY');
    //     } else {
    //         if(moment(startDate).isAfter(moment())) {
    //             return moment(endDate).format('DD-MMM-YYYY');
    //         } else {
    //             return 'present';
    //         }
    //     }
    // }
    useEffect(() => {
        let sortedTasks = []
        if (value !== 2 && userAssignedTasks && userAssignedTasks.length > 0) {
            sortedTasks=[...userAssignedTasks]
            if(isPILoggedin){
                sortedTasks.sort((a, b) => {
                if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
                  return -1;
                }
                if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
                  return 1;
                }
                return 0;
              });
            }
            if(!searchValue){
                setIsSearching(false);
            }else if(searchValue){
                setIsSearching(true);
                const searchedTasks = sortedTasks.filter(function (row) {
                    const task = row.taskTitle
                    const no = row.taskNumber
                    if (task.toLowerCase().includes(searchValue.toLowerCase())  || no.includes(searchValue)) {
                      return row;
                    }
                });
                sortedTasks=[...searchedTasks]
            }
        }
        else if(value === 2 && allAssignedTasks){
            if(!searchValue){
                setIsSearching(false);
                sortedTasks=[...allAssignedTasks]
                sortedTasks = sortedTasks.filter((task)=>task.status==='COMPLETED')
            }else if(searchValue){
                setIsSearching(true);
                sortedTasks=[...allAssignedTasks]
                sortedTasks = sortedTasks.filter((task)=>task.status==='COMPLETED')
                const searchedTasks = sortedTasks.filter(function (row) {
                    const name = row.firstName + ' ' + row.lastName
                    if (name.toLowerCase().includes(searchValue.toLowerCase())) {
                      return row;
                    }
                });
                sortedTasks=[...searchedTasks]
            }
        }
        if(sortedTasks){
            setRows(
                sortedTasks.map((_obj) => {
                    const { id, taskNumber, taskTitle, firstName, lastName, status, startDate, endDate, comments, documents } = _obj;
                    return {
                        assignedTaskId: id,
                        taskId: taskNumber,
                        taskDesc: taskTitle,
                        periodStart: moment(startDate).format('DD-MMM-YYYY'),
                        periodEnd: moment(endDate).format('DD-MMM-YYYY'),
                        member: firstName + ' ' + lastName,
                        status: status,
                        comment: comments,
                        documents
                    }
                })
            )
        }
        if(searchValue){
            let type ;
            if(selectedUser){
                type = 'DOA_SELECTED_USER_LOG'
            }else if (value === 1){
                type = 'DOA_MY_TASK'
            }else if (value === 2){
                type = 'DOA_COMPLETED_TASK'
            }
            dispatch(updatePagination({tableType:type,page:0,rowsPerPage}))
        }
    }, [searchValue, value, allAssignedTasks, userAssignedTasks,isPILoggedin])

    const reloadData = async () => {
        if (value !== 2 && (selectedUser?.id || loggedinUserId)) {
            const result = await fetchUserAssigngedTasks({ trialId, siteId, userId: selectedUser ? selectedUser.id : loggedinUserId })
            return result.data;
        } else if(selectedUser?.id || loggedinUserId){
            const result = await fetchAllAssigngedTasks({ trialId, siteId })
            return result.data;
        }
    }

    const handleConfirmatioDialogOpen = (statusType, row, documentType) => {
        setChangeStatusType(statusType);
        setSelectedAssignedTaskId(row.assignedTaskId);
        setselectedDocumentType(documentType)
        setOpenManageTask(true);
    }
    const saveCommentData = async (comment) => {
        await addDOAComment({
            assignedTaskId: selectedResourceTask.assignedTaskId,
            payload: {
                comment
            }
        });
        const _data = await reloadData();
        const rec = _data.find((_obj) => _obj.id == selectedResourceTask.assignedTaskId)
        setSelectedResourceTask(prevState => ({
            ...prevState,
            comment: rec?.comments,
        }));
    }

    // const handleConfirmDialogClose = () => {
    //     setConfirmDialog({
    //         open: false,
    //         message: '',
    //         buttonLabel: 'Confirm',
    //     });
    // }
    const handleConfirmation = async () => {
        // handleConfirmDialogClose();
        await changeDOATaskStatus({ assignedTaskId: selectedAssignedTaskId, status: changeStatusType });
        setChangeStatusType(null);
        setSelectedAssignedTaskId(null);
        setselectedDocumentType(null)
        reloadData();
    }
    const handleUploadDocument = async (acceptedFiles) => {
      const formData = new FormData();
      const DOA_TASK_DOCUMENT = 'DOA_TASK_DOCUMENT';
      formData.append('file', acceptedFiles[0]);
      formData.append('documentType', DOA_TASK_DOCUMENT);
      const result = await uploadDoaTaskDocument({
        assignedTaskId: selectedResourceTask?.assignedTaskId,
        payload: formData,
      });
      if (result.data) {
        const _data = await reloadData();
          const rec = _data.find((_obj) => _obj.id == selectedResourceTask.assignedTaskId);
          dispatch(
            openMessage({
              messageSeverity: 'success',
              message: "Document uploaded successfully!",
            })
          );
        setSelectedResourceTask((prevState) => ({
          ...prevState,
          documents: rec?.documents,
        }));
      } else if (result.error) {
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: result?.error?.data?.message,
          })
        );
      }
    };

    const handleDeleteDocument = async (s3Key) => {
        const result = await deleteDOADocuments(s3Key);
          if (!result?.error) {
            const _data = await reloadData();
            const rec = _data.find((_obj) => _obj.id == selectedResourceTask.assignedTaskId);
            dispatch(
                openMessage({
                messageSeverity: 'success',
                message: "Document deleted successfully!",
                })
            );
            setSelectedResourceTask((prevState) => ({
                ...prevState,
                documents: rec?.documents,
                
            }));
          } else {
            dispatch(
                openMessage({
                  messageSeverity: 'error',
                  message: result?.error?.data?.message,
                })
              );
          }
      };

    const ButtonWrapper=()=>{
        return(
                isPILoggedin && 
                <Box display={"flex"} justifyContent={"flex-end"} columnGap={2} alignItems={"center"}>
                    <Box>
                    {selectTask && 
                    <CustomButton variant="outlined" onClick={handCancelClick}>
                        <Typography variant="subtitle1">
                            Cancel
                        </Typography>
                    </CustomButton>
                    }
                    </Box>
                    <Box>
                    {!selectTask  ? 
                    <CustomButton variant="contained" onClick={onSelectTask}>
                        <Typography variant="subtitle1">
                            Select Task
                        </Typography>
                    </CustomButton>:
                    <CustomButton onClick={handleVerifyTasks}>
                        <Typography variant="subtitle1">
                            Approve
                        </Typography>
                    </CustomButton>
                    }
                    </Box>
                </Box>
        )
    }

    const PISignSection = (row) => {
        if (row.status.toUpperCase() == 'COMPLETED') {
            if (isPILoggedin) {
                return (<SignatureSection disabled={selectTask} row={row} documentType={'APPROVE_TASK_SIGNATURE'} reloadData={reloadData} access={false}/>)
            } else {
                return <PendingActionsIcon color="primary" />
            }
        } else if (row.status.toUpperCase() == 'PENDING') {
            return <PendingActionsIcon color="primary" />
        } else {
            return (<SignatureSection row={row} documentType={'APPROVE_TASK_SIGNATURE'} reloadData={reloadData} />)
        }
    };
    const UserSignSection = (row) => {
        if (row.status === 'COMPLETED') {
            return (<SignatureSection row={row} documentType={'COMPLETE_TASK_SIGNATURE'} reloadData={reloadData} access={!selectedUser || selectedUser?.id === String(loggedinUserId)}/>)
        } else if (row.status.toUpperCase() == 'PENDING') {
            if (!selectedUser || selectedUser?.id === String(loggedinUserId)) {
                return (<SignatureSection row={row} documentType={'COMPLETE_TASK_SIGNATURE'} reloadData={reloadData} access={false}/>)
            } else {
                return <PendingActionsIcon color="primary" />
            }
        } else {
            return (<SignatureSection row={row} documentType={'COMPLETE_TASK_SIGNATURE'} reloadData={reloadData} />)
        }
    };
    const actionSection = (row) => {
        if (trialSiteData && trialSiteData.users) {
            const loggedInUserInfo = trialSiteData.users.find((_obj) => _obj.userId == String(loggedinUserId));
            isPILoggedin = loggedInUserInfo.role == 'PI'
        }
        if (row.status == 'COMPLETED') {
            if (isPILoggedin) {
                return (<Button variant="outlined" color="success" onClick={() => handleConfirmatioDialogOpen('APPROVED', row, 'APPROVE_TASK_SIGNATURE')} disabled={selectTask}>Approve</Button>)
            }
        } else if (row.status.toUpperCase() == 'PENDING') {
            if (!selectedUser || selectedUser?.id == String(loggedinUserId)) {
              return (
                <Button
                  variant="outlined"
                  onClick={() => handleConfirmatioDialogOpen('COMPLETED', row, 'COMPLETE_TASK_SIGNATURE')}
                >
                  Mark as Completed
                </Button>
              );
            }
        } else {
            return (<Tooltip title={'Approved'}><TaskAltIcon color="success" /></Tooltip>)
        }
    };
    const closeResources = async (refreshData = false) => {
        setSelectedResourceTask(null);
        setOpenResources(false);
    }
    const manageResource = (row) => {
        setSelectedResourceTask(row);
        setOpenResources(true);
    }
    const onSelectTask=()=>{
        if(selectTask){
            setSelectTask(false)
        }else{
            setSelectTask(true)
        }
    }
    const handelToggleOne=(value)=>{
        const currentIndex = checkedTask.indexOf(value);
        const newChecked = [...checkedTask];
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setCheckedTask(newChecked);
    }

    const allTasksToVerify = useMemo(() => rows.filter(row => row.status === 'COMPLETED'), [rows]) 

    const handleToggleAll = () => {
        const checkedItems = allTasksToVerify.map(task => task.assignedTaskId);
        if (checkedTask.length == checkedItems.length) {
            setCheckedTask([]);
        } else {
            setCheckedTask(checkedItems);
        }
    };

    const handleVerifyTasks = () => {
        if(checkedTask.length==0){
            dispatch(
                openMessage({
                    messageSeverity: 'error',
                    message: 'Atleast one task should be selected',
                })
            );
        }else{
        setOpenSignatureBox(true);
        }
    };

    const handCancelClick = () => {
        setSelectTask(false)
        setCheckedTask([]);
    }
    const base64ImageToBlob = (base64) => {
        const base64Data = base64.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: 'image/png' });
    };

    const saveSignature = async (data) => {
        if(data==null){
            dispatch(
                openMessage({
                    messageSeverity: 'error',
                    message: 'Signature Required',
                })
            );
        }else{
            const imageBlob = base64ImageToBlob(data);
            const formData = new FormData();
        
            formData.append('file', imageBlob, 'image.png');
            formData.append('taskIds',checkedTask );
        
            await bulkVerifyTask(formData);
            setOpenSignatureBox(false);
            setSelectTask(false);
            setCheckedTask([])
        }
    };

    const complete = async (data) => {
        if(data==null){
            dispatch(
                openMessage({
                    messageSeverity: 'error',
                    message: 'Signature Required',
                })
            );
        }else{
            const formData = new FormData();
            formData.append('file', base64ImageToBlob(data));
            formData.append('documentType', selectedDocumentType);
            await saveDOASign({ payload: formData, assignedTaskId: selectedAssignedTaskId });
            handleConfirmation();
            setOpenManageTask(false)
            reloadData();
        }        
    };

    const onNewPage = (page, rowsPerPage) =>{
        let type ;
        if(selectedUser){
            type = 'DOA_SELECTED_USER_LOG'
        }else if (value === 1){
            type = 'DOA_MY_TASK'
        }else if (value === 2){
            type = 'DOA_COMPLETED_TASK'
        }
        dispatch(updatePagination({tableType:type,page,rowsPerPage}));
    }

    const memoizedColumn = useMemo(() => {
      return rows && rows.length > 0 ? (
        (rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
        ).map((row) => {
          return (
            <CustomTableRow ref={hilightedTask==row.assignedTaskId?focusRowRef:null} hilight={hilightedTask==row.assignedTaskId?'red':null} key={row.assignedTaskId}>
              <TableCell align="center" sx={{ width: '1%' }}>
                {isPILoggedin && selectTask && row.status == 'COMPLETED' ? (
                  <Checkbox
                    color="primary"
                    onClick={() => handelToggleOne(row.assignedTaskId)}
                    checked={checkedTask.indexOf(row.assignedTaskId) !== -1}
                    tabIndex={-1}
                    inputProps={{
                      'aria-label': 'Select all Tasks',
                    }}
                  />
                ) : null}
              </TableCell>
              {value===2 ? 
                <TableCell align="center">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{row.member}</Typography>
                </Box>
                </TableCell>
              : ""  
              }
              <TableCell align="center" sx={{ width: value===2 ? '20%' : '24%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>{row.taskId}</Typography>
                  <Typography>{row.taskDesc}</Typography>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{row.periodStart} -</Typography>
                  <Typography>{row.periodEnd}</Typography>
                </Box>
              </TableCell>
              <TableCell align="center" sx={{ width: '20%' }}>
                {PISignSection(row)}
              </TableCell>
              <TableCell align="center" sx={{ width: '20%' }}>
                {UserSignSection(row)}
              </TableCell>
              <TableCell align="center" sx={{ width: '15%' }}>
                {actionSection(row)}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Tooltip title={'Manage comments and documents'}>
                    <IconButton onClick={() => manageResource(row)}>
                      <FilePresentIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </CustomTableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={8} align="center">
            <Typography variant="subtitle1" color="initial">
              No Data Found
            </Typography>
          </TableCell>
        </TableRow>
      );
    }, [rows, selectTask, checkedTask, allTasksToVerify , page , rowsPerPage]);
    return (
        <>{
        rows && rows.length > 0 || isSearching?
        <Box mt={2}>    
            {selectedUser &&
            <Box sx={{ display: 'flex', columnGap: 1, alignItems:'center' , mb:2}}>
                <Button
                    type="text"
                    onClick={() => { setSelectedUser(null); navigate('/doaLogs'); setOpenTaskList(false)}}
                    startIcon={<RightArrow leftArrow />}
                >
                </Button>
                <Typography variant="h6">{userAssignedTasks && userAssignedTasks[0]?.firstName +' '+userAssignedTasks[0]?.lastName}</Typography>
            </Box>}     
            <Box display="flex" alignItems={'center'} justifyContent={"space-between"} mb={2}>
            <FormControl variant="outlined">
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    placeholder= {value===2?"Search by member": "Search by task"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    endAdornment={
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                    }
                />
            </FormControl>        
            <ButtonWrapper/>
            </Box>
            <Paper sx={{ width: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: 3,
                        backgroundColor: theme.palette.primary.light,
                        borderRadiusTop: 5,
                    }}
                />
                <TableContainer>
                    <Table stickyHeader>
                        <CustomTableHead>
                            <TableRow>
                                <CustomTableHeadCell align="center" sx={{ width: '1%' }}>
                                {(isPILoggedin && selectTask) ?
                                <Tooltip title={checkedTask.length === rows.length ? "" : "Select-All"}>
                                <Checkbox
                                    color="primary"
                                    onClick={()=>handleToggleAll(rows)}
                                    checked={checkedTask.length === allTasksToVerify.length && allTasksToVerify.length !== 0}
                                    indeterminate={checkedTask.length !== allTasksToVerify.length && checkedTask.length !== 0}
                                    disabled={allTasksToVerify.length==0}
                                    inputProps={{
                                      'aria-label': 'Select all Tasks',
                                    }}
                                />
                                </Tooltip>
                                : null}
                                </CustomTableHeadCell>
                                {value===2 ?
                                    <CustomTableHeadCell align="center" sx={{ width: '15%' }}>
                                        Delegate
                                    </CustomTableHeadCell>
                                : <></>}
                                <CustomTableHeadCell align="center" sx={{ width: value===2 ? '15%' : '22%' }}>
                                    Task
                                </CustomTableHeadCell>
                                <CustomTableHeadCell align="center" sx={{ width: '10%' }}>
                                    Effective Period
                                </CustomTableHeadCell>
                                <CustomTableHeadCell align="center" sx={{ width: '20%' }}>
                                    PI Signature
                                </CustomTableHeadCell>
                                <CustomTableHeadCell align="center" sx={{ width: '20%' }}>
                                    User Signature
                                </CustomTableHeadCell>
                                <CustomTableHeadCell align="center" sx={{ width: '15%' }}>
                                    Status
                                </CustomTableHeadCell>
                                <CustomTableHeadCell align="center" sx={{ width: '2%' }}>
                                    Actions
                                </CustomTableHeadCell>
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                            {memoizedColumn}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={rows.length} onNewPage={onNewPage}/>
            </Paper>
            {openResources && <ManageTaskResources
                onClose={closeResources}
                saveCommentData={saveCommentData}
                selectedTask={selectedResourceTask}
                open={openResources}
                uploadDocument={handleUploadDocument}
                deleteDocument={handleDeleteDocument}
                trialId={trialId}
                siteTrialId={siteTrialId}
            />}
            {/* <ConfirmationDialog
                open={confirmDialog.open}
                buttonLabel={confirmDialog.buttonLabel}
                message={confirmDialog.message}
                handleClose={handleConfirmDialogClose}
                handleConfirm={handleConfirmation}
            /> */}
            {(openSignatureBox) &&
                <SignatureBox bulkApprove={true} open={openSignatureBox} handleClose={()=>setOpenSignatureBox(false)} saveSign={saveSignature}/>
            }
            {(openManageTask) &&
                <SignatureBox type={changeStatusType} open={openManageTask} handleClose={()=>setOpenManageTask(false)} saveSign={complete}/>
            }
        </Box>:
        <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3}}>
            <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>No {value===1?'delegated':'completed'} tasks available.</Typography>
        </Stack>
        }</>
    )
}

export default TaskList;