import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {  useAddSponsorConfigurationMutation, useGetAllConfigurationsQuery, useGetAllSponserConfigurationsQuery } from '../../store/slices/apiSlice';
import { Box, Typography } from '@mui/material';
import CustomButton from '../../components/@extended/CustomButton';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function AssignConfiguration({handleClose,sponsorId}) {
  const [assignSponsorConfiguration] = useAddSponsorConfigurationMutation();
  const { data: configurations, isLoading } = useGetAllConfigurationsQuery();
  const { data: sponserConfigurations, isLoadingg } = useGetAllSponserConfigurationsQuery(sponsorId);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    if (sponserConfigurations) {
      const configs = sponserConfigurations.map(config => config.configuration);
      setRight(configs)
      console.log('sponserConfigurations',configs);

      if(configurations){
        const rightIds = configs.map(config => config.id);
        const leftConfigs = configurations.filter(config => !rightIds.includes(config?.id));
        setLeft(leftConfigs);
      }
    }else if(configurations){
      setLeft(configurations);
      console.log('configurations',configurations);
    }
  }, [configurations , sponserConfigurations]);

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  async function assignConfiguration() {
    if (right.length !== 0) {
      const ids = right.map((config) => config.id);
      const payload = { configurationIds: ids };
      try {
        const result = await assignSponsorConfiguration({ payload, sponsorId });
        if (result.data) {
          openMessageNotification({
            message: result.data.message,
            type: 'success',
          });
        }else{
          openMessageNotification({
            message: result?.error?.data?.message,
            type: 'error',
          });
        }
      } catch (error) {
        console.error("Error assigning configuration:", error);
      }
      handleClose()
    }else{
      openMessageNotification({
        message: 'At least one configuration needs to be assigned.',
        type: 'error',
      });
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.label}-label`;

          return (
            <ListItemButton
              key={value.label}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId}
              primary={
                <Typography noWrap>
                  {value.label}
                </Typography>
              } 
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
  <React.Fragment>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>{customList('Configuration', left)}</Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>{customList('Assigned Configuration', right)}</Grid>
    </Grid>
    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mt={2}>
    <Box paddingRight={1.5}>
        <CustomButton variant="outlined" onClick={() => handleClose()}>
            Cancel
        </CustomButton>
    </Box>
    <Box>
        <CustomButton variant="contained" onClick={() => assignConfiguration()}>
            Assign
        </CustomButton>
    </Box>
    </Grid>
    
    </React.Fragment>
  );
}
