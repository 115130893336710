import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Box, IconButton, Paper, useTheme,Tooltip } from '@mui/material';
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap"
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ListManager } from 'react-beautiful-dnd-grid';
import CustomCard from '../../components/@extended/CustomCard';
import { useSelector } from 'react-redux';
import { useUpdateDashboardPreferenceMutation } from '../../store/slices/apiSlice';
import {useMediaQuery} from '@mui/material';

const swapElements = (array, index1, index2) => {
  // Create a copy of the original array
  const newArray = [...array];
  // Store the element at index1 in a temporary variable
  const temp = newArray[index1];
  // Replace the element at index1 with the element at index2
  newArray[index1] = newArray[index2];
  // Replace the element at index2 with the element stored in the temporary variable
  newArray[index2] = temp;

  const _newArray = newArray.map((_widget, index) => {
    return {
      ..._widget,
      order: index,
    };
  });

  return _newArray;
};

const DragContainer2 = (props) => {
  const { widgets, maximizeWidget, userPreferences } = props;
  const userId = useSelector((state) => state.userDetails?.loggedInUser?.id);
  const componentItems = [];
  const [updateDashboardPreference] = useUpdateDashboardPreferenceMutation();
  const theme = useTheme();
  let isMobile=useMediaQuery(theme.breakpoints.down('sm'))
  let isTablet=useMediaQuery(theme.breakpoints.between('sm', 'md'))
  useEffect(() => {
    if (widgets) {
      _.map(widgets, (preference) => {
        componentItems.push({ id: preference.id, order: preference.order, preferenceId: preference.preferenceId });
      });
    }
  }, [widgets]);

  const [localItems, setLocalItems] = useState([]);
  useEffect(() => {
    if (widgets && componentItems) {
      const filteredList = componentItems?.filter((_obj) => {
        const itemFound = Object.values(widgets).find((_item) => _item.id == _obj.id);
        return itemFound?.display;
      });
      setLocalItems(filteredList);
    }
  }, [widgets]);

  const reorderList = async (sourceIndex, destinationIndex) => {
    let updatedList;
    setLocalItems((prev) => {
      const list = swapElements([...prev], sourceIndex, destinationIndex);
      updatedList = list;
      return list;
    });

    if (updatedList) {
      const _objList = updatedList.map((_list) => {
        return {
          id: _list.preferenceId,
          order: _list.order,
        };
      });
      const payload = {
        userId,
        preferences: _objList,
      };
      await updateDashboardPreference(payload);
    }
  };
  function ListElement({ item: { id } }) {
    const _selectedKey = Object.keys(widgets).find((key) => widgets[key].id === id);
    const _comp = widgets[_selectedKey];
    return (
      <Box sx={{ padding: 1.5 }}>
        {_comp?.label ? (
          <Box
            sx={{
              width: '100%',
              '& .MuiCardContent-root': { padding: 0 },
              '& .MuiCardContent-root:last-child ': { padding: 0 },
            }}
          >
            <CustomCard
              action={
                   (_comp?.id=="TRIAL_SUBJECT_ENROLLMENT_TREND" &&
                    <Tooltip title="Maximise Widget" placement="bottom-start">
                      <ZoomOutMapIcon  style={{color:"#999999",width:30,height:30,marginRight: "6px",cursor:"pointer"}} onClick={() =>maximizeWidget(_selectedKey)}/>
                    </Tooltip>
                   )
              }
              sx={{ height: 320 }}
              title={_comp.label}
            >
              {_comp?.content}
            </CustomCard>
          </Box>
        ) : (
          <Paper sx={{ width: '100%', height: 380 }}>{_comp?.content}</Paper>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ '& > *': { width: '100%' }, '& > * > *': { width:isMobile ? '100%':isTablet? '50%' : '33%' } }}>
      <ListManager
        items={localItems}
        direction="horizontal"
        maxItems={isMobile ? 1 :isTablet ? 2 :3}
        render={(item) => <ListElement item={item} />}
        onDragEnd={reorderList}
      />
    </Box>
  );
};

export default DragContainer2;
