import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
    useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useDispatch } from "react-redux";


const SubjectEvents = ({
    handleClose,
    open,
    event
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const onCloseModal = () => {
        handleClose();
    };  

    const adminParticipant = useMemo(() => {
        return _.filter(event.users, (user) => user.role !== "subject");
    }, [event]);

    return (
        <>
            <Dialog scroll={"paper"} open={open} onClose={onCloseModal} >
                <DialogTitle>{event.title}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box>
                            <Typography color={'grey'}>Trial</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>{event?.trial?.protocolNumber}</Typography>
                        </Box>
                        <Divider sx={{ marginY:1 }}/>
                        <Box>
                            <Typography color={'grey'}>Site</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>{event.site?.siteName}</Typography>
                        </Box>
                        <Divider sx={{ marginY:1 }}/>
                        <Box>
                            <Typography color={'grey'}>Users</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
                                {adminParticipant &&
                                    adminParticipant
                                        .map((participant) => {
                                            return `${participant.user?.firstName} ${participant.user?.lastName}`;
                                        })
                                        .join(", ")}
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY:1 }}/>
                        <Box>
                            <Typography color={'grey'}>Start Date Time</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>{dayjs(event?.start).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                        </Box>
                        <Divider sx={{ marginY:1 }}/>
                        <Box>
                            <Typography color={'grey'}>End Date Time</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>{dayjs(event?.end).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseModal}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SubjectEvents;
