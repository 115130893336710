import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import CustomButton from '../../../components/@extended/CustomButton';
import FocusError from '../../../components/common/FocusError';
import FormikField from '../../visitScheudle/FormikTextField';
import { saveBudgetForecastForSite, selectStudySiteDetails } from '../../../store/slices/financeSlice';

const milestoneOptions = [ 'Visit Completed', 'Site Initiated', 'Invoice Approved', 'Lab Tests Performed', 'Others']

const SiteBudgetForecast = ({ selectedSite, handleTabChange, setSelectedSite }) => {
  const { sponsorId, studyId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const siteDetails = useSelector((state) => selectStudySiteDetails(state, sponsorId, studyId, selectedSite?.id));
  const [initialValues, setInitialValues] = useState({
    fixedCost: {
      irbFees: '',
      regulatoryFees: '',
      courierFees: '',
      personnelCost: '',
      milesStonePayment: '',
      milesStoneCosts: '',
    },
    variableCost: {
      perPatientCost: '',
      asSaeUpCost: '',
      travelCost: '',
      diagnositcsCost: '',
      imaging: '',
      courierCost: '',
      milestonePayment: '',
      milesStoneCosts: '',
      visitSchedule: '',
    },
  });

  useEffect(() => {
    if (siteDetails) {
      setInitialValues({
        fixedCost: {
          ...siteDetails.fixedCost,
        },
        variableCost: {
          ...siteDetails.variableCost,
        },
      });
    }
  }, [siteDetails]);

  const handleFormSubmit = (values) => {
    dispatch(saveBudgetForecastForSite({ sponsorId, studyId, siteId: siteDetails.id, budget: values }));
    setSelectedSite(null);
  };

  const validationSchema = Yup.object().shape({
    fixedCost: Yup.object().shape({
      irbFees: Yup.string().required('IRB Fees are required'),
      regulatoryFees: Yup.string().required('Regulatory Fees are required'),
      courierFees: Yup.string().required('Courier Fees are required'),
      personnelCost: Yup.string().required('Personnel Cost is required'),
      milesStonePayment: Yup.string().required('Milestone Payment is required'),
      milesStoneCosts: Yup.string().required('Milestone Cost is required'),
    }),
    variableCost: Yup.object().shape({
      perPatientCost: Yup.string().required('Per Patient Cost is required'),
      asSaeUpCost: Yup.string().required('SAE Up Cost is required'),
      travelCost: Yup.string().required('Travel Cost is required'),
      diagnositcsCost: Yup.string().required('Diagnostics Cost is required'),
      imaging: Yup.string().required('Imaging is required'),
      courierCost: Yup.string().required('Courier Cost is required'),
      milestonePayment: Yup.string().required('Milestone Payment is required'),
      milesStoneCosts: Yup.string().required('Milestone Cost is required'),
    }),
  });

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
          <Typography variant="h6" sx={{ alignSelf: 'center' }}>
            Budget Forecast
          </Typography>
          <Box>
            <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
              <Typography variant="subtitle1" color={theme.palette.common.white}>
                Upload Details
              </Typography>
            </CustomButton>
            <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
                Note: Upload file with CSV/Excel format.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, resetForm, values, touched, errors }) => (
          <Grid item xs={12}>
            <>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 3 }}>
                  <Box>
                    <Typography sx={{ alignSelf: 'center', fontSize: 18 }}>Fixed Costs</Typography>
                    <Typography sx={{ alignSelf: 'center', fontSize: 14 }}>(all costs are in USD)</Typography>
                  </Box>
                </Box>
              </Grid>
              <Form onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.fixedCost?.irbFees && Boolean(errors.fixedCost?.irbFees)}>
                        <Field component={FormikField} name="fixedCost.irbFees" label="IRB Fees" type="number" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.fixedCost?.regulatoryFees && Boolean(errors.fixedCost?.regulatoryFees)}
                      >
                        <Field
                          component={FormikField}
                          name="fixedCost.regulatoryFees"
                          label="Regulatory Fees"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.fixedCost?.irbFees && Boolean(errors.fixedCost?.irbFees)}>
                        <Field
                          component={FormikField}
                          name="fixedCost.courierFees"
                          label="Courier Cost"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.fixedCost?.personnelCost && Boolean(errors.fixedCost?.personnelCost)}
                      >
                        <Field
                          component={FormikField}
                          name="fixedCost.personnelCost"
                          label="Personnel Cost"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{ py: 1 }}>
                      <FormControl fullWidth error={touched.fixedCost?.milesStonePayment && Boolean(errors.fixedCost?.milesStonePayment)}>
                        <InputLabel>Milestone Payments</InputLabel>
                        <Field
                          // disabled={editSlotDetails ? true : false}
                          component={Select}
                          onBlur={handleBlur}
                          label="Milestone Payments"
                          onChange={handleChange}
                          value={values.milesStonePayment}
                          inputProps={{
                            id: 'fixedCost.milesStonePayment',
                            name: 'fixedCost.milesStonePayment',
                          }}
                        >
                          <MenuItem disabled>Select Milestone Payment</MenuItem>
                          {milestoneOptions &&
                            milestoneOptions?.map((op, index) => (
                              <MenuItem key={op} value={op}>
                                {op}
                              </MenuItem>
                            ))}
                        </Field>
                        {touched.fixedCost?.milesStonePayment && errors.fixedCost?.milesStonePayment ? (
                          <FormHelperText>{errors.fixedCost?.milesStonePayment}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.fixedCost?.milesStoneCosts && Boolean(errors.fixedCost?.milesStoneCosts)}
                      >
                        <Field
                          component={FormikField}
                          name="fixedCost.milesStoneCosts"
                          label="Milestone Cost"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    {/* Add similar fields for other fixedCost attributes */}
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={12}>
                      <Typography sx={{ alignSelf: 'center', fontSize: 18 }} color="initial">
                        Variable Costs
                      </Typography>
                      <Typography sx={{ alignSelf: 'center', fontSize: 14 }}>(all costs are in USD)</Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.perPatientCost && Boolean(errors.variableCost?.perPatientCost)}
                      >
                        <Field
                          component={FormikField}
                          name="variableCost.perPatientCost"
                          label="Patient Cost"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.asSaeUpCost && Boolean(errors.variableCost?.asSaeUpCost)}
                      >
                        <Field
                          component={FormikField}
                          name="variableCost.asSaeUpCost"
                          label="AE/SAE/UP cost"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.travelCost && Boolean(errors.variableCost?.travelCost)}
                      >
                        <Field component={FormikField} name="variableCost.travelCost" label="Travel Cost" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.diagnositcsCost && Boolean(errors.variableCost?.diagnositcsCost)}
                      >
                        <Field
                          component={FormikField}
                          name="variableCost.diagnositcsCost"
                          label="Diagnostics cost"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.imaging && Boolean(errors.variableCost?.imaging)}
                      >
                        <Field component={FormikField} name="variableCost.imaging" label="Imaging cost" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.courierCost && Boolean(errors.variableCost?.courierCost)}
                      >
                        <Field
                          component={FormikField}
                          name="variableCost.courierCost"
                          label="Courier cost"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{ py: 1 }}>
                      <FormControl fullWidth error={touched.variableCost?.milestonePayment && Boolean(errors.variableCost?.milestonePayment)}>
                        <InputLabel>Milestone Payments</InputLabel>
                        <Field
                          // disabled={editSlotDetails ? true : false}
                          component={Select}
                          onBlur={handleBlur}
                          label="Milestone Payments"
                          onChange={handleChange}
                          value={values.milestonePayment}
                          inputProps={{
                            id: 'variableCost.milestonePayment',
                            name: 'variableCost.milestonePayment',
                          }}
                        >
                          <MenuItem disabled>Select Milestone Payment</MenuItem>
                          {milestoneOptions &&
                            milestoneOptions?.map((op, index) => (
                              <MenuItem key={op} value={op}>
                                {op}
                              </MenuItem>
                            ))}
                        </Field>
                        {touched.variableCost?.milestonePayment && errors.variableCost?.milestonePayment ? (
                          <FormHelperText>{errors.variableCost?.milestonePayment}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.variableCost?.milesStoneCosts && Boolean(errors.variableCost?.milesStoneCosts)}
                      >
                        <Field
                          component={FormikField}
                          name="variableCost.milesStoneCosts"
                          label="Milestone Cost"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Box sx={{ pt: 3 }}>
                      <CustomButton
                        variant="outlined"
                        onClick={resetForm}
                        color="primary"
                        sx={{ padding: '8px 30px', textTransform: 'none' }}
                      >
                        Reset
                      </CustomButton>
                      <CustomButton onClick={handleSubmit} style={{ marginLeft: 15 }}>
                        Save & Continue
                      </CustomButton>
                    </Box>
                  </Grid>
                  <FocusError />
                </Grid>
              </Form>
            </>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default SiteBudgetForecast;
