// material-ui
import { Box, useMediaQuery } from "@mui/material";

// project import
import Profile from "./profile/Profile";
import Notification from "./Notification";
import { subjectDashboardDetails } from "../../../../store/slices/subjectSlice";
import MobileSection from "./MobileSection";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { base64ToArrayBuffer } from "../../../../components/common/DocumentUpload";
import { bloqcibeApi, useGetSubjectDetailQuery } from "../../../../store/slices/apiSlice";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const subjectDashboardInfo = useSelector(subjectDashboardDetails);
  const userDetails = useSelector((state) => state.userDetails.loggedInUser)
  const subjectLoggedIn = useSelector((state) => state.auth.subjectLoggedIn);
  const [imageSrc, setImageSrc] = useState(null);
  
  const [downloadSubjectProfilePic] =
        bloqcibeApi.endpoints.downloadSubjectProfilePic.useLazyQuery();

  const [downloadUserProfilePic] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();

  useEffect(() => {
    (async () => {
        if(subjectLoggedIn && subjectDashboardInfo?.subjectDetail?.profilePic) {
            const result = await downloadSubjectProfilePic({
                s3Key: subjectDashboardInfo?.subjectDetail?.profilePic,
            });
            if (result.data) {
                const byteArray = base64ToArrayBuffer(result.data);
                const rr = new Blob([byteArray], { type: "application/octet-stream" });
                const reader = new FileReader();
                reader.readAsDataURL(rr);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setImageSrc(base64data);
                };
            }
        }
        else{
          if(userDetails?.profileS3Key){
            const result = await downloadUserProfilePic({
              s3Key: userDetails?.profileS3Key,
            })
            if(result.data){
              const byteArray = base64ToArrayBuffer(result.data);
                const rr = new Blob([byteArray], { type: "application/octet-stream" });
                const reader = new FileReader();
                reader.readAsDataURL(rr);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setImageSrc(base64data);
              };
            }
          }
        }
    })();
  }, [subjectDashboardInfo, userDetails])

  return (
    <>
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <Notification />
      {!matchesXs && <Profile s3Key={imageSrc}/>}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
