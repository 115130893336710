import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import UpdateProfile from './UpdateProfile';
// import SetPassword from './SetPassword';
import EmailVarification from './EmailVarification';
import MobileVarification from './MobileVarification';
import { useTheme } from '@emotion/react';
import { useResponsive } from '../../hooks/ResponsiveProvider';


export default function ProfileSetup({userData, openDialog ,handlePasswordDialogClose}){
  const {isSmallScreen} = useResponsive();
    return(
      <Dialog fullScreen={isSmallScreen} open={openDialog}>
        <DialogTitle>Profile Setup</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handlePasswordDialogClose}
            sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
          <CloseIcon />
        </IconButton>
          <Stack alignItems='center' height={isSmallScreen?'700':700} width={isSmallScreen?'700':700} p={isSmallScreen?3:5} >
            <HorizontalNonLinearStepper handlePasswordDialogClose={handlePasswordDialogClose} userData={userData}/>
          </Stack>
      </Dialog>
    )
}


function HorizontalNonLinearStepper({userData,handlePasswordDialogClose}) {
  const theme = useTheme();


  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if(step < activeStep || activeStep===0){
      setActiveStep(step);
    }
    else if(userData?.isEmailVerified && step===2){
      setActiveStep(step);
    }
    else if(userData?.isPhoneVerified && step===3){
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const steps = ['Profile Setup', 'Email Verification', 'Mobile Number Verification'];

  const displayForm = [
  <UpdateProfile handlePasswordDialogClose={handlePasswordDialogClose} userData={userData} handleComplete={handleComplete} handleNext={handleNext} steps={steps} completedSteps={completedSteps}/>,
  <EmailVarification userData={userData} handleComplete={handleComplete} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} completedSteps={completedSteps}/>,
  <MobileVarification userData={userData} handleComplete={handleComplete} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} completedSteps={completedSteps}/>
]

  return (
    <Box textAlign='center' sx={{ width: '100%'}}>
<Stepper
        activeStep={activeStep+1}
        alternativeLabel
        sx={{
          "& .MuiStepConnector-root": {
            top: "9px",
            left: "calc(-50% + 12px)",
            right: "calc(50% + 12px)",
          },
          "& .MuiStepConnector-line": {
            borderRadius: 1,
            borderWidth: 6,
          },
          "& .MuiStepLabel-iconContainer": {
            height: "0.7rem",
            width: "0.7rem",
            border: `7px solid ${theme.palette.grey[500]}`,
            borderRadius: "50%",
          },
          "& .Mui-completed": {
            "& .MuiStepConnector-line": {
              borderRadius: 1,
              borderColor: theme.palette.primary.light,
              borderWidth: 6,
            },
            "& .MuiStepLabel-iconContainer": {
              backgroundColor: theme.palette.common.white,
              border: "7px solid green",
            },
          },
        }}
      >
        {steps?.map((label, index) => (
          <Step key={label} completed={completed[index]}>
              <Box>
                <StepButton
                  color="inherit"
                  // disabled={step.status == "Pending"}
                  onClick={handleStep(index)}
                  icon={<Box></Box>}
                >
                  {label}
                </StepButton>
              </Box>
          </Step>
        ))}
</Stepper>



    <React.Fragment>
      {displayForm[activeStep]}
    </React.Fragment>
    </Box>
  );
}
