import React, { useState } from 'react';
import CustomButton from '../@extended/CustomButton';
import { useDownloadAuditFileMutation } from '../../store/slices/apiSlice';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { openBackdropLoading } from '../../store/slices/applicationSlice';
import { Grid } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';
import { openMessage } from '../../store/slices/showMessageSlice';

const AuditExportButton = (props) => {
  const { siteName, subjectNumber, stepKey, protocolNumber, style } = props;
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    message: (
      <>
        <span>Are you sure you want to download this file?</span>
      </>
    ),
    buttonLabel: "Download",
  });

  const [downloadAuditFile] = useDownloadAuditFileMutation();
  const handleDownloadAudit = async () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
    const queryParams = new URLSearchParams();
    dispatch(openBackdropLoading({ backdropLoading: true }));
    if (siteName) queryParams.append('siteName', siteName);
    if (subjectNumber) queryParams.append('subjectNumber', subjectNumber);
    if (stepKey) queryParams.append('crfStepKey', stepKey);

    const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';
    try {
      const _protocolNumber = [];
      if (protocolNumber) {
        _protocolNumber.push(protocolNumber);
      }
      const response = await downloadAuditFile({
        payload: { protocolNumber: _protocolNumber },
        query: queryString,
      }).unwrap();

      // Assuming response.data contains the CSV text
      const csvData = response;

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const now = new Date();
      const date = now.toLocaleDateString().replace(/\//g, '-');
      const time = now.toLocaleTimeString().replace(/:/g, '-');
      const dateTime = `${date}_${time}`;
      const fileTypeExtension = blob.type.split('/')[1];
      a.download = `${
        protocolNumber ? protocolNumber : subjectNumber ? subjectNumber : 'export'
      }_audit_${dateTime}.${fileTypeExtension}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      dispatch(openBackdropLoading({ backdropLoading: false }));
      dispatch(
        openMessage({
          messageSeverity: "success",
          message: "Document downloaded successfully!",
        })
      );
    } catch (error) {
      dispatch(openBackdropLoading({ backdropLoading: false }));
      dispatch(
        openMessage({
          messageSeverity: "error",
          message: "Download Failed!",
        })
      );
      console.error('Error downloading audit file:', error);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  return (
    <Grid display={"flex"} alignItems={"center"}>
      <CustomButton variant={'contained'} size="small" 
        onClick={()=>setConfirmDialog({
          ...confirmDialog,
          open: true
        })} 
        style={{ ...style }}>
        <Typography variant="subtitle2" color="inherit" fontWeight={600}>
          Audit Trial
        </Typography>
      </CustomButton>
      
      <ConfirmationDialog
            open={confirmDialog.open}
            buttonLabel={confirmDialog.buttonLabel}
            message={confirmDialog.message}
            handleClose={handleConfirmDialogClose}
            handleConfirm={handleDownloadAudit}
      />
    </Grid>
  );
};

export default AuditExportButton;
