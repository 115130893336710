import React, { useCallback, useMemo, useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useDashboradDataQuery } from '../../../store/slices/apiSlice';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import AnimatedBloqcubeLogo from '../../../components/Logo/AnimatedBloqcubeLogo';

const GMapContainer = (props) => {
  // Define your Google Maps API key
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;;
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser?.sponsorId);
  const defaultCenter = { lat: 18.5559, lng: 177.77673577 };
  const { data: dashboardData, isLoading: dataLoading } = useDashboradDataQuery({
    data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

   const markers = useMemo(() => {
     if (!dashboardData) return [];
     return (
       dashboardData[props.preference]?.sites?.map((site) => ({
         id: site.id,
         name: site.orgname,
         orgname: site.orgname,
         address: site.address,
         fax: site.fax,
         position: {
           lat: parseFloat(site.latitude),
           lng: parseFloat(site.longitude),
         },
       })) 
     );
   }, [dashboardData, props.preference]);


   const onLoad = useCallback(
     (mapInstance) => {
       setMap(mapInstance);

       if (markers?.length >= 1) {
         const bounds = new window.google.maps.LatLngBounds();
         markers.forEach(({ position }) => {
           bounds.extend(position);
         });
         mapInstance.fitBounds(bounds);
       }
     },
     [markers]
   );

  return (
    <>
      {dataLoading && !isLoaded && (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ height: 250, width: 250 }}>
            <AnimatedBloqcubeLogo />
          </Box>
        </Box>
      )}
      {!dataLoading && isLoaded && (
        <Box onMouseDown={(e)=>e.stopPropagation()}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          onClick={() => setActiveMarker(null)}
          center={mapCenter}
          zoom={50}
          onLoad={onLoad}
        >
          {markers?.length >= 1 && markers?.map(({ id, name, position, orgname, address, fax }) => (
            <Marker key={id} position={position} onClick={() => handleActiveMarker(id)}>
              {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <h2>{name}</h2>
                    <p>
                      <strong>Organization:</strong> {orgname}
                    </p>
                    <p>
                      <strong>Address:</strong> {address}
                    </p>
                    <p>
                      <strong>Fax:</strong> {fax}
                    </p>
                  </div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
        </Box>
      )}
    </>
  );
};

export default GMapContainer;
