import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import {  useSelector } from "react-redux";
import avatar from "../../../../../assets/images/users/avatar-3.png"

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// project import
import MainCard from "../../../../../components/MainCard";
import Transitions from "../../../../../components/@extended/Transitions";

// assets
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { convertToTitleCase } from "../../../../../pages/util";
import { useResponsive } from "../../../../../hooks/ResponsiveProvider";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({s3Key}) => {
  const theme = useTheme();
  const user = useSelector((state) => state?.auth?.user);
  const userDetails = useSelector((state) => state?.userDetails);
    const { isSmallScreen } = useResponsive();

  const role = user?.details?.[0]?.[0]?.role
    ? convertToTitleCase(user?.details?.[0]?.[0]?.role)
    : "";
  const handleLogout = async () => {
    localStorage.clear();
    window?.location.replace(`/login`);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    if (!isSmallScreen) setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "secondary.lighter" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            alt="profile user"
            //src={avatar3}
            src={s3Key? s3Key : avatar}
            sx={{ width: 32, height: 32 }}
          />
          <Stack direction="column" alignItems="center">
            <Typography variant="subtitle1">Welcome, {role==='System Admin'? 'System Admin' : (userDetails?.loggedInUser?.firstName ) ? userDetails?.loggedInUser?.firstName+" "+userDetails?.loggedInUser?.lastName : ''}</Typography>
            <Typography variant="caption">
              {userDetails?.user?.orgnizationName
                ? `${userDetails?.user?.orgnizationName} `
                : ""}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 350,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={5} border={false} content={false}>
                    <CardContent>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                          >
                            <Avatar
                              alt="profile user"
                              src={s3Key? s3Key : avatar}
                              sx={{ width: 40, height: 40 }}
                            />
                            <Box>
                              <Stack direction="column" alignItems="center">
                                <Typography variant="subtitle1" py={0.2}>
                                  Welcome, {role==='System Admin'? 'System Admin' : userDetails?.loggedInUser?.firstName+" "+userDetails?.loggedInUser?.lastName}
                                </Typography>
                                <Typography variant="caption" py={0.2}>
                                  {userDetails.user?.orgnizationName
                                    ? `${userDetails.user?.orgnizationName} `
                                    : ""}
                                </Typography>
                                <Typography variant="body1" pt={0.2}>
                                  {user?.email}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: "divider", display:'flex', justifyContent:'center',py:2, pt:1 }}>
                      <Button onClick={handleLogout} startIcon={<LogoutOutlinedIcon />} variant="text"><Typography variant="subtitle1" color={theme.palette.primary.main}>Logout</Typography></Button>
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
