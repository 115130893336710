import { useDispatch, useSelector } from "react-redux";
import { bloqcibeApi, useGetSubjectDetailQuery, useGetTrialSiteInfoQuery } from "../../store/slices/apiSlice";
import FormDetails from "../subjectDetails/subjectDetailCRF/FormDetails";
import { findObjectByValue, getUserRole } from "../util";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import { Box, Typography } from "@mui/material";
import { mergeCustomFields } from "../../util/util";
import { base64ToArrayBuffer } from "../../components/common/DocumentUpload";
import _ from 'lodash'

const SubjectEnrollFormDetails = (props) => {
    const dispatch = useDispatch();
    const [getLibraryForm] = bloqcibeApi.endpoints.getLibraryForm.useLazyQuery();
    const [selectedForm, setSelectedForm] = useState();
    const enrolmentDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.eConsentForm);
    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const { trialId, siteId, trialSiteId, subjectMasterId, stepKey } = useParams();
    const [formConfig, setFormConfig] = useState(null);
    const [userSiteTrialRole, setUserSiteTrialRole] = useState();
    const loggedInUser = useSelector((state) => state.auth.user);
    const [getEConsentFormConfig, { data: formData }] =
        bloqcibeApi.endpoints.getEConsentFormConfig.useLazyQuery();
    const [downloadSubjectFile] =
        bloqcibeApi.endpoints.downloadSubjectFile.useLazyQuery();
    const [downloadSubjectSignature] =
        bloqcibeApi.endpoints.downloadSubjectSignature.useLazyQuery();
    const [getSubjectAnswers, { data: subjectDetails }] =
        bloqcibeApi.endpoints.getSubjectAnswers.useLazyQuery();
    const { data: subjectDetailWithSteps } = useGetSubjectDetailQuery(subjectMasterId);
    const [stepSatus, setStepSatus] = useState({
        status: '', title: ''
    })
    const stepStatusData = useMemo(() => {
        if (subjectDetailWithSteps) {
            const steps = _.sortBy(subjectDetailWithSteps?.stepStatus, 'order');
            return steps;
        }
        return [];
    }, [subjectDetailWithSteps]);
    const { data: trialSiteData } = useGetTrialSiteInfoQuery({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: trialSiteId,
    });
    useEffect(() => {
        (async () => {
            if (subjectDetailWithSteps && enrolmentDocumentKey && stepKey) {
                const stepStatusData = subjectDetailWithSteps?.stepStatus.find((_obj) => _obj.stepKey === stepKey);
                const _formDetailsData = await getLibraryForm(
                    `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`
                );
                const stepData = _formDetailsData?.data?.steps.find((step) => step?.key === stepKey)
                setStepSatus({ status: stepStatusData?.status, title: stepData?.lableAbrivation });
            }
        })()

    }, [subjectDetailWithSteps, stepKey, enrolmentDocumentKey]);
    useEffect(() => {
        (async () => {
            if (enrolmentDocumentKey) {
                const _formDetailsData = await getLibraryForm(
                    `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`
                );
                const formDetailsData = await getEConsentFormConfig({
                    sponsorId,
                    trialId,
                    siteId,
                    documentKey: enrolmentDocumentKey,
                    payload: {
                        subjectMasterId: subjectMasterId,
                    },
                });
                if (formDetailsData.data) {
                    let _tempConfig = { ..._formDetailsData.data };
                    const mergedConfig = mergeCustomFields(_tempConfig, formDetailsData.data);
                    setFormConfig(mergedConfig);
                } else if (formDetailsData.error) {
                    console.error('UNABLE TO GET FORM DETAILS');
                } else {
                    setFormConfig(_formDetailsData.data);
                }
            }
        })();
    }, [dispatch, enrolmentDocumentKey]);
    useEffect(() => {
        (async () => {
            if (trialSiteData) {
                const userRole = getUserRole(loggedInUser, trialId, trialSiteData?.siteTrialData?.site?.id);
                setUserSiteTrialRole(userRole);
            }
        })();
    }, [trialSiteData]);
    useEffect(() => {
        if (formConfig && stepKey) {
            let step = formConfig.steps.find((step) => step.key === stepKey)
            setSelectedForm(step?.sections[0])
        }
    }, [formConfig, stepKey])
    useEffect(() => {
        (async () => {
            await getSubjectAnswers({
                sponsorId,
                trialId,
                siteId,
                payload: {
                    subjectMasterId: subjectMasterId,
                },
            });
        })();
    }, [subjectDetailWithSteps]);
    const handleDownloadSignature = async (s3Key, setSign) => {
        const result = await downloadSubjectSignature({
            s3Key: s3Key,
        });
        if (result.data) {
            const byteArray = base64ToArrayBuffer(result.data);
            const rr = new Blob([byteArray], { type: "application/octet-stream" });
            const reader = new FileReader();
            reader.readAsDataURL(rr);
            reader.onloadend = function () {
                const base64data = reader.result;
                setSign(base64data);
            };
        } else {
            console.log("Failed to load signature");
        }
    };
    const handleDownloadFile = async (s3Key, fileName) => {
        const result = await downloadSubjectFile({
            s3Key: s3Key,
        });
        if (result.data) {
            let byteArray = base64ToArrayBuffer(result.data);
            let a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
                new Blob([byteArray], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        } else {
            console.log("Failed to load signature");
        }
    }
    // const selectedFormStatus = useMemo(() => {
    //     if (stepKey && stepStatusData) {
    //         let statusData = findObjectByValue(stepStatusData, stepKey)
    //         return statusData?.status
    //     }
    //     return null
    // }, [stepStatusData])
    if (stepSatus && stepSatus.status === "Pending" && !selectedForm?.ignoreStatusCheck
    ) {
        return (<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
            <PendingActionsRoundedIcon sx={{ height: 100, width: 100, color: '#FF9933' }} />
            <Typography variant="h6" color={'grey'}>{`${stepSatus.title} is pending`}</Typography>
        </Box>)
    }
    return (<>
        <FormDetails
            details={selectedForm}
            sectionKey={selectedForm?.key}
            handleDownload={handleDownloadFile}
            stepKey={stepKey}
            //formStatus={selectedFormStatus}
            editAllowed={false}
            handleDownloadSignature={handleDownloadSignature}
            userSiteTrialRole={userSiteTrialRole}
            answers={subjectDetails}
        />
    </>)
}

export default SubjectEnrollFormDetails;