import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Typography,
  useTheme,
  Box,
  Grid
} from '@mui/material';
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';
import { Columns2 } from './SponsorAdminColumns';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetSponsorAdminByIdQuery } from '../../store/slices/apiSlice';
import CreateAdmin from '../CreateSponsor/CreateAdmin';
const SponsorAdmin = ({open}) => {
  const theme = useTheme();
  const columns = Columns2();
  const {sponsorId}=useParams()
  const {data:data1,isLoading}=useGetSponsorAdminByIdQuery(sponsorId);
  console.log(data1)
  if(isLoading){
    return(<></>);
  }

  return (
    <Box>
      <Box display={"flex"} justifyContent={'space-between'} alignItems={"center"} pt={2} pb={4}>
        <Typography variant="h6" color="initial" >
          Sponsor Admin
        </Typography>
        <CreateAdmin open={open} sponsorId={sponsorId}/>
      </Box>

      <Paper sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            backgroundColor: theme.palette.primary.light,
            borderRadiusTop: 5,
          }}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => (
                  <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data1 && data1.length > 0 ? (
                data1.map((row) => (
                  <CustomTableRow key={row?.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell>{row?.user?.firstName}{" "}{row?.user?.lastName}</TableCell>
                    <TableCell>{row?.user?.email}</TableCell>
                    <TableCell>{row?.user?.contactNumber}</TableCell>
                    <TableCell>{moment(row?.createdAt).format('DD/MM/YYYY')}</TableCell>
                  </CustomTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography variant="subtitle1" color="initial">
                      No Admins Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default SponsorAdmin;
