import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import CustomButton from '../../components/@extended/CustomButton';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSiteBasicDetailsQuery, useGetTrialSiteInfoQuery } from '../../store/slices/apiSlice';

const ManageComments = (props) => {
  const theme = useTheme();
  const { commentHist, saveCommentData, trialId, siteTrialId } = props;
    const [comment, setComment] = useState('');
    const [commentList, setCommentList] = useState()
    const dispatch = useDispatch();
    const [error, setError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setComment(newValue);
  };

  const { data: trialSiteData } = useGetTrialSiteInfoQuery({
    sponsorId: sponsorId,
    trialId: trialId,
    siteTrialId: siteTrialId,
  });

  const users = trialSiteData?.users?.map((userObj, index) => ({
    id: index,
    display: userObj.user.firstName + " " + userObj.user.lastName
  }));

  useEffect(() => {
    if(commentHist){
    const _commentList = commentHist.map((_obj) => {
      let comm = _obj.comment;
      const matches = comm.match(/\@\[(.*?)\]\([^)]+\)/g);
      if (matches) {
        for (let i = 0; i < matches?.length; i++) {
          const match = matches[i].match(/\@\[(.*?)\]\((.*?)\)/);
          if (match) {
            const username = match[1];
            const userEmail = match[2];
            comm = comm.replace(
              match[0],
              `<span style='background-color: #e8edff; font-style: italic;'>${username}</span>`
            );
          }
        }
      }
      return {
        ..._obj,
        comment: comm,
      };
    });
    setCommentList(_commentList);
  }
  }, [commentHist]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

const handleSaveComment = () => {
  if (comment.trim() === '') {
    setError('Comment cannot be blank');
    return;
  }
  saveCommentData(comment);
  setComment('');
  setError('');
};
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1 }}>
        <List
          sx={{
            width: '100%',
            // bgcolor: "background.paper",
            height: 'calc(100vh - 260px)',
            overflow: 'auto',
            padding: 2,
            '&::-webkit-scrollbar': {
              width: '0.3em',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#999',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#888',
            },
          }}
        >
          {commentList && commentList.length > 0 ? (
            commentList.map((_commentObj, index) => (
              <>
                <ListItem key={`comment${index}`} style={{ paddingTop: 1, paddingBottom: 0 }}>
                  <ListItemText
                    primary={
                      <Tooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: _commentObj.comment,
                            }}
                          />
                        }
                        placement="left-start"
                      >
                        <Box
                          style={{
                            width: '100%',
                            maxWidth: '100%',
                            whiteSpace: 'balance',
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                          }}
                          sx={{
                            overflow: 'auto',
                            '&::-webkit-scrollbar': {
                              height:'0.1em'
                            },
                            '&::-webkit-scrollbar-track': {
                              background: '#f1f1f1',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#999',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                              background: '#888',
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: _commentObj.comment.trim().replace(/\n/g, '<br/>'),
                          }}
                        />
                      </Tooltip>
                    }
                    secondary={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>{_commentObj.user.userName}</Typography>
                        <Box sx={{ display: 'flex', columnGap: 1 }}>
                          <Typography>{moment(_commentObj.createdAt).format('MM/DD/YYYY hh:mm a')}</Typography>
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
                {/* <Divider /> */}
              </>
            ))
          ) : (
            <Box sx={{ textAlign: 'center', paddingY: 10 }}>
              <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                No comments available.
              </Typography>
              <Typography variant="body2" color={theme.palette.text.secondary} sx={{ pb: 0.5, fontStyle: 'italic' }}>
                Note: You can add new comment by writing in field below.
              </Typography>
            </Box>
          )}
        </List>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: 'flex',
            padding: 3,
            pb: 0,
            justifyContent: 'end',
            flex: 1,
            alignItems: 'flex-end',
          }}
        >
          <MentionsInput
            style={{
              width: '100%',
              height: 50,
              border: `1px solid ${isFocused ? theme.palette.primary.main : 'silver'}`,
              borderRadius: 4,
              input: {
                padding: 9,
                border:'none',
                outline: 'none',
                maxHeight: 70,
                overflowY:'auto'
              },
              suggestions: {
                list: {
                  backgroundColor: 'white',
                  border: '1px solid rgba(0,0,0,0.15)',
                  fontSize: 14,
                  textAlign:"left",
                  height: 'fit-content',
                  maxHeight:'240px',
                  overflowY:'auto',
                },
                item: {
                  padding: '5px 15px',
                  borderBottom: '1px solid rgba(0,0,0,0.15)',
                  '&focused': {
                    backgroundColor: theme.palette.primary.lighter,
                  },
                },
              },
            }}
            value={comment}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            forceSuggestionsAboveCursor
            allowSpaceInQuery
            allowSuggestionsAboveCursor
            placeholder={`Enter comment`}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleSaveComment();
              }
            }}
          >
            <Mention trigger="@" data={users} appendSpaceOnAdd />
          </MentionsInput>
          <IconButton
            variant="contained"
            onClick={() => {
              handleSaveComment();
              // saveCommentData(comment);
              // setComment('');
            }}
          >
            <SendIcon
              sx={{
                fontSize: 37,
                ':hover': { color: theme.palette.primary.main },
              }}
            />
          </IconButton>
        </Box>
        {error && (
          <Typography variant="body2" color="error" sx={{ textAlign: 'left', ml: 3, pt: 0.5, mb: 1 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ManageComments;
