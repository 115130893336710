import { useField } from 'formik';
import React from 'react'
import CustomPhoneInput from '../../pages/dynamicFormEngine/FormFields/CustomPhoneInput';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber as isValidNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const isValidPhoneNumber = (value) => {
  if (!value){return true;}  
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber && isValidNumber(value);
};

export const phoneValidationSchema = Yup.string()
  .test('isValidPhoneNumber', 'Please enter valid number', value => isValidPhoneNumber(value));

const Phone = ({ form, ...props }) => {
    const [field, meta, helpers] = useField(props.field.name);

    const handleKeyPress = (event) => {
      // Prevent space from being entered
      if (event.charCode === 32) {
        event.preventDefault();
      }
    };

    return (
      <PhoneInput
        {...form}
        {...field}
        placeholder={props.placeholder}
        value={field.value}
        // defaultCountry=""
        size={props.size  ? props.size : "small"}
        onKeyPress={handleKeyPress}
        onChange={(value) => {
          if (!form.touched[field.name]) form.setFieldTouched(field.name);
          form.setFieldValue(field.name, value || '');
        }}
        label={props?.label}
        inputComponent={CustomPhoneInput}
      />
    );
}

export default Phone
