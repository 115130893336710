import React, { useState } from 'react';
import FormikField from '../visitScheudle/FormikTextField';
import { Button, Box, Grid, Typography, Divider, FormControl, IconButton, InputAdornment, styled } from '@mui/material';
import { FieldArray, Formik, useFormik, useFormikContext } from 'formik';
import { Field, Form } from 'formik';

import * as Yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from '../../components/@extended/CustomButton';
import { useAddConfigurationMutation, useUpdateConfigurationTableMutation } from '../../store/slices/apiSlice';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import AddIcon from '@mui/icons-material/Add';
import { openBackdropLoading } from '../../store/slices/applicationSlice';
import { useUploadConfigurationFileMutation } from '../../store/slices/apiSlice';
import { MuiFileInput } from 'mui-file-input';
import { options } from '../trialSiteDetails/TrialSiteCurrentExpenditure';

const findDuplicates = (array, key) => {
  const optionstraversed = new Map();
  const duplicates = new Set();

  array.forEach((item, index) => {
    const keyValue = item[key];
    if (optionstraversed.has(keyValue)) {
      duplicates.add(optionstraversed.get(keyValue));
      duplicates.add(index);
    } else {
      optionstraversed.set(keyValue, index);
    }
  });

  return duplicates;
};
const customValidation = (values) => {
  const errors = {};
  const options = values.options || [];

  const duplicateOptionKeys = findDuplicates(options, 'optionKey');
  const duplicateOptions = findDuplicates(options, 'option');

  if (duplicateOptionKeys.size > 0) {
    errors.options = errors.options || [];
    duplicateOptionKeys.forEach((index) => {
      errors.options[index] = errors.options[index] || {};
      errors.options[index].optionKey = 'Option Keys must be unique';
    });
  }

  if (duplicateOptions.size > 0) {
    errors.options = errors.options || [];
    duplicateOptions.forEach((index) => {
      errors.options[index] = errors.options[index] || {};
      errors.options[index].option = 'Options must be unique';
    });
  }

  return errors;
};

const validationSchema = Yup.object().shape({
  labelKey: Yup.string()
    .required('Configuration Type is required')
    .trim()
    .matches(/^\S+$/, 'Type cannot contain whitespace'),
  label: Yup.string().required('Configuration name is required')
  .trim(),
  options: Yup.array().of(
    Yup.object().shape({
      optionKey: Yup.string().required('Option Key is required')
      .trim()
      .matches(/^\S+$/, 'Type cannot contain whitespace'),
      option: Yup.string().required('Configuration Option is required')
      .trim(),
    })
  ),
});

function CreateConfiguration(props) {
  const { toggleDrawer, anchor, initialValue, isEditing } = props;
  const [newConfiguration] = useAddConfigurationMutation();
  const [updateConfigurationTable] = useUpdateConfigurationTableMutation();
  const dispatch = useDispatch();
  const [addedFields, setAddedFields] = useState([]);
  const [fileName, setFileName] = useState({});
  const [file, setFile] = useState({});
  const [uploadConfigurationFile, { isLoading, isError }] = useUploadConfigurationFileMutation();


  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  const handleCancel = () => {
    toggleDrawer(anchor, false);
  };

  const handleSubmit = async (values) => {

    if(isEditing && addedFields.length!== Object.keys(file).length){
      dispatch(openMessage({ messageSeverity: 'warning', message: 'Please upload a file....' }));
      return;
    }else if (!isEditing && Object.keys(file).length !== values.options.length) {
      dispatch(openMessage({ messageSeverity: 'warning', message: 'Please upload a file.' }));
      return;
    }

    let result;
    if (isEditing) {
      result = await updateConfigurationTable({
        id: values.id,
        labelKey: values.labelKey,
        label: values.label,
        options: values.options,
      });
    } else {
      result = await newConfiguration({
        id: values.id,
        label: values.label,
        labelKey: values.labelKey,
        options: values.options,
      });
    }

    if (!result?.error) {
      for (const option of values.options) {
        if(!option.id && file[option.optionKey]){
          const formData = new FormData();
          formData.append(
            'data',
            JSON.stringify({
              sponsorId: 'bloqcube',
              documentKey: option.optionKey,
              documentLabel: option.option,
            })
          );
          formData.append('document', file[option.optionKey]);
          const result2 = await uploadConfigurationFile(formData);
          if (result2.error) {
            openMessageNotification({
              message: result2?.error?.data?.message,
              type: 'error',
            });
          }
        }else if(!option.id && !file[option.optionKey]){
          dispatch(openMessage({ messageSeverity: 'warning', message: 'Please upload a file.' }));
          return;
        }
      }

      openMessageNotification({
        message: `Configuration ${isEditing ? 'Updated' : 'Added'} successfully`,
        type: 'success',
      });
      toggleDrawer(anchor, false);
    } else {
      openMessageNotification({
        message: result?.error?.data?.message,
        type: 'error',
      });
    }
  };

  const AddOptionButton = ({ push }) => {
    const { values } = useFormikContext();

    const areAllFieldsFilled = values.options.every(
      option => option.optionKey && option.option
    );

    const handleAddOption = () => {
      const newIndex = values.options.length;
      push({ id: '', optionKey: '', option: '', version: '' });
      setAddedFields([...addedFields, newIndex]);
    };

    return (
      <Button
        variant="text"
        sx={{ color: 'primary' }}
        onClick={handleAddOption}
        disabled={!areAllFieldsFilled}
        startIcon={<AddIcon />}
      >
        Add Option
      </Button>
    );
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileChange = (event, row) => {
    const file = event.target.files[0];
    if (!file) {
      dispatch(
        openMessage({
          messageSeverity: 'warning',
          message: 'Please select a file to upload.',
        })
      );
      return;
    }
  
    if (file.type !== 'application/json') {
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: 'Please select a JSON file to upload.',
        })
      );
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        JSON.parse(e.target.result);
        setFileName((prev) => ({
          ...prev,
          [row.optionKey]: file.name,
        }));
  
        setFile((prev) => ({
          ...prev,
          [row.optionKey]: file,
        }));
      } catch (error) {
        event.target.value = null;
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: 'The file is not a valid JSON.',
          })
        );
      }
    };
    reader.readAsText(file);
  };
  

  function removeFile(option){
    const CopyFileName = {...fileName}
    delete CopyFileName[option.optionKey]
    setFileName(CopyFileName);

    const CopyFile = {...file}
    delete CopyFile[option.optionKey]
    setFile(CopyFile);
  }
  return (
    <Grid item sm={12}>
      <Box sx={{ padding: 3 }}>
        <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>{isEditing ? 'Edit Configuration' : 'Add Configuration'}</Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: 3 }}>
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          validate={customValidation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container width={'100%'} gap={2}>
                <Grid item sm={10}>
                  <FormControl fullWidth>
                    <Field component={FormikField} name="label" type="text" label="Configuration Name" />
                  </FormControl>
                </Grid>
                <Grid item sm={10}>
                  <FormControl fullWidth>
                    <Field component={FormikField} name="labelKey" type="text" label="Configuration Type" disabled={isEditing}/>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
                <Grid item sm={12}>
                  <FieldArray name="options">
                    {({ remove, push }) => (
                      <Grid item sm={12}>                     
                        {values.options.length > 0 &&
                          values.options.map((option, index) => (
                            <Box key={index} mb={4}>        
                                <FormControl fullWidth>
                                  <Grid container sx={{ width: '100%' }}>
                                    <Grid item sm={10}>
                                      <Field
                                        fullWidth
                                        component={FormikField}
                                        name={`options[${index}].optionKey`}
                                        label="Option Key"
                                        type="text"
                                        disabled={isEditing && !addedFields.includes(index)}
                                      />
                                      <Box mt={2}></Box>
                                      <Field
                                        fullWidth
                                        component={FormikField}
                                        name={`options[${index}].option`}
                                        label="Option"
                                        type="text"
                                        disabled={isEditing && !addedFields.includes(index)}
                                      />  
                                      <Box mt={2}></Box>
                                      <Grid item sm={12}  sx={{border:'1px dotted gray' , borderRadius:'5px'}}>
                                      <Button
                                        fullWidth
                                        disableRipple
                                        component="label"
                                        role={undefined}
                                        variant="text"
                                        tabIndex={-1}
                                        sx={{justifyContent:'flex-start' ,padding:'15px 15px'}}
                                        disabled={!(option.optionKey && option.option) || isEditing && !addedFields.includes(index)}
                                        startIcon={<CloudUploadIcon />}
                                      >
                                        {fileName?.[option.optionKey]?fileName?.[option.optionKey]:"Upload JSON file"}
                                        <VisuallyHiddenInput type="file"  accept='.json' onChange={(e)=>{handleFileChange(e ,option)}}/>
                                      </Button>  
                                      </Grid>                                                   
                                  </Grid>
                                  <Grid item sm={2} sx={{display: 'flex',alignItems:"center", color: 'gray' }}>
                                      {((!(isEditing) && (values.options.length > 1)) || !(isEditing && !addedFields.includes(index))) && (
                                        <IconButton disabled={values.options.length===1} variant='contained' fontSize="small" onClick={() => {remove(index); removeFile(option)}} >
                                          <RemoveOutlinedIcon />                                   
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </Grid>
                                  
                                  
                                </FormControl>                            
                            </Box>
                          ))}
                          <Grid container sx={{width: '100%'}}>
                          <Grid item sm={12} sx={{display: 'flex',justifyContent: 'flex-start'}}>
                              <AddOptionButton push={push} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                <Grid container width={'100%'} justifyContent="flex-end">
                  <Grid item sm={10}>
                    <Box
                      sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: 2, columnGap: 2 }}
                    >
                      <CustomButton size="small" color="primary" variant="outlined" onClick={handleCancel}>
                        Cancel
                      </CustomButton>
                      <CustomButton type="submit" size="small" variant="contained" color="primary">
                      {isEditing ? 'Update' : 'Add'}
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Grid>
  );
}

export default CreateConfiguration;
