import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AddSlot from "./AddSlot";
import CustomButton from "../../components/@extended/CustomButton"
import PublishSlot from "./publishSlot";
import { useDeleteTrialBookingMutation, useGetTrialBookingSlotsQuery } from "../../store/slices/apiSlice";
import { openMessage } from "../../store/slices/showMessageSlice";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import AvailableAppointmentTimings from "./AvailableAppointmentTable";

const AvailableSlots = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {data:rows} = useGetTrialBookingSlotsQuery();
    const [openAddSlot, setOpenAddSlot] = useState(false);
    const [openPublishSlot, setOpenPublishSlot] = useState(false);
    const [editSlotDetails, setEditSlotDetails] = useState(null)
    const [copySlotDetails, setCopySlotDetails] = useState(null)
    const [deleteSlotBooking] = useDeleteTrialBookingMutation();
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        message: (
          <>
            <span>Are you sure you want to delete this slot?</span>
            <br />
            <span>
              This action cannot be undone. Please confirm whether you want to
              proceed with the deletion.
            </span>
          </>
        ),
        buttonLabel: "Delete",
    });
    const [deleteUser,setDeleteUser]=useState()

    const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };

    const deleteSlot = async (slot) => {
        setDeleteUser(rows[rows.findIndex((row)=>row.id===slot.id)]);
        setConfirmDialog({
            ...confirmDialog,
            open: true,
            message: (
              <>
                <span>
                  Are you sure you want to delete this slot?
                </span>
              </>
            ),
        }); 
    };

    const handleDeleteSlot = async () => {
        if (deleteUser) {
        const result = await deleteSlotBooking({
            slotId: deleteUser?.id,
            trialId:deleteUser.trial.id,
            visitId: deleteUser.visitId,
            siteId: deleteUser.site.id,
        });
        if (!result?.error) {
            openMessageNotification({
                message: "slot deleted successfully",
                type: "success",
            });
          } else {
            openMessageNotification({
              message: result?.error?.data?.error_description,
              type: "error",
            });
        }
        setConfirmDialog({
            ...confirmDialog,
            open: false,
        });
        }
    }

    const onCloseAddSlot = () => {
        setOpenAddSlot(false);
        setEditSlotDetails(null)
        setCopySlotDetails(null)
    }

    const openAddSlotPanel = () => {
        setEditSlotDetails(null)
        setOpenAddSlot(true);
    }

    const onEditSlot = (slot) =>{
        setEditSlotDetails(rows[rows.findIndex((row)=>row.id===slot.id)])
        setOpenAddSlot(true);
    }

    const onCopySlot = (values) =>{
        const copySlotRow = rows[rows.findIndex((row)=>row.id===values.id)]
        const slots = rows.filter((slot)=>{
            return(
                slot.date===copySlotRow.date && 
                slot.trial.id === copySlotRow.trial.id && 
                slot.site.id === copySlotRow.site.id && 
                slot.visitId === copySlotRow.visitId && 
                slot.createdAt === copySlotRow.createdAt
            )
        })
        setCopySlotDetails(slots)
        setOpenAddSlot(true);
    }

    const onClosePublishSlot = () => {
        setOpenPublishSlot(false);
    }

    const onOpenPublishSlot = () => {
        setOpenPublishSlot(true);
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialog({
          ...confirmDialog,
          open: false,
        });
    };

    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, alignItems:'center' }}>
            <Typography variant="h6" color="initial">
                Available Appointment Timings
            </Typography>
            <Box>
                <CustomButton variant="contained" sx={{marginRight:2}} onClick={()=>{onOpenPublishSlot()}}>Publish Slots</CustomButton>
                <CustomButton variant="contained" onClick={openAddSlotPanel}>Add Slot</CustomButton>
            </Box>
        </Box>

        <AvailableAppointmentTimings 
            rows = {rows} 
            deleteSlot={deleteSlot} 
            onEditSlot={onEditSlot} 
            onCopySlot={onCopySlot}
        />

        <AddSlot
            onClose={onCloseAddSlot}
            open={openAddSlot} 
            editSlotDetails={editSlotDetails}
            copySlotDetails = {copySlotDetails}
        />
        
        <PublishSlot
            onClose={onClosePublishSlot}
            open={openPublishSlot}
            availableSlots = {rows}
        />

        <ConfirmationDialog
            open={confirmDialog.open}
            buttonLabel={confirmDialog.buttonLabel}
            message={confirmDialog.message}
            handleClose={handleConfirmDialogClose}
            handleConfirm={handleDeleteSlot}
        />
        
        </>)
}

export default AvailableSlots;