import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery, Typography, Backdrop, CircularProgress,} from "@mui/material";

// project import
import Drawer from "./drawer/Drawer";
import Header from "./header/Header";

// types
import { openDrawer } from "../../store/slices/applicationSlice";

import { bloqcibeApi, useGetSponsorDetailsQuery, useGetMeUserQuery } from "../../store/slices/apiSlice";
import ShowMessage from "../../components/showMessage/ShowMessage";
import { WebSocketProvider } from "../../hooks/WebsocketProvider";
import { setSubjectLoggedIn } from "../../store/slices/authSlice";
import ResetPasswordDialog from "../../components/common/resetPasswordDialog";
import LoggedOutDialog from  "../../components/common/LoggedOutDialog";
import ProfileSetup from "../../pages/profileSetup/ProfileSetup"
import AnimatedBloqcubeLogo from "../../components/Logo/AnimatedBloqcubeLogo";
import WithdrawalSubjectDialog from "../../components/common/WithdrawalSubjectDialog";
import { getUserRole } from "../../pages/util";
import RemovedUserDialog from "../../components/common/RemovedUserDialog";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useSelector((state) => state.auth.user);
  const { data } = useGetSponsorDetailsQuery(user?.details?.[0]?.[0]?.sponsorId);
  const drawerOpen = useSelector((state) => state.application.drawerOpen);

  const userRole = getUserRole(user);

  const [openDialog, setOpenDialog] = React.useState(null);


  const {data:userData} = useGetMeUserQuery()
  const [getSubjectDashboardDetail] =
    bloqcibeApi.endpoints.getSubjectDashboardDetail.useLazyQuery();

  // backdrop toggler
  const backdropLoading = useSelector(state => state.application.backdropLoading)

  // drawer toggler

  const handlePasswordDialogClose = ()=>{
    setOpenDialog(null);
  }

  const [open, setOpen] = useState(drawerOpen);
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const [trial, setTrial] = useState('');


  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    if (user && user.details?.[0]?.[0]?.['role'] == 'subject') {
      getSubjectDashboardDetail(user.details?.[0]?.[0]?.['userId']).then(response => {
        if(response?.data?.hcDetail.status === 'Withdrawal' || response?.data?.icDetail.status === 'Withdrawal'){
          setIsWithdrawal(true);
          setTrial(response?.data?.trialDetail.trialTitle);
        }
      });
      dispatch(setSubjectLoggedIn(true));
    }
  }, [user]);
  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  React.useEffect(()=>{
    if(userData?.tempPassword && (!userData?.isEmailVerified || !userData?.isPhoneVerified))setOpenDialog(true)
    else if(userData?.tempPassword && userData?.isEmailVerified && userData?.isPhoneVerified)setOpenDialog(false)
    else setOpenDialog(null)
  },[userData])

  return (
    <WebSocketProvider>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          '@media (max-width:600px)': {
            backgroundColor: '#F1F2F5',
          },
        }}
      >
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundColor: '#FAFAFA',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Box>
            <Toolbar />
            <Outlet />
          </Box>
          <Box sx={{ marginTop: 'auto' }}>
            <Box height={10} sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
              <Typography variant="caption" color={theme.palette.grey[500]}>
                Copyright &copy; Bloqcube 2023. All rights reserved.
              </Typography>
            </Box>
          </Box>
          <ShowMessage />
        </Box>
      </Box>
      <LoggedOutDialog />
      {isWithdrawal && 
        <WithdrawalSubjectDialog isWithdrawal={isWithdrawal} trial={trial}/>
      }
      <LoggedOutDialog/>
      {
        userRole==='' &&
        <RemovedUserDialog isRemoved={userRole===''}/>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <AnimatedBloqcubeLogo />
          </Box>
          <Typography variant="subtitle2" color="inherit">
            Please wait, We are loading your data...
          </Typography>
        </Box>
      </Backdrop>

      {
        !isWithdrawal && openDialog!=null && 
        (
          openDialog?
        <ProfileSetup userData={userData} openDialog={openDialog} handlePasswordDialogClose={handlePasswordDialogClose}/>:
        <ResetPasswordDialog openDialog={!openDialog} handlePasswordDialogClose={handlePasswordDialogClose}/>
        ) 
      }
       
      
      
    </WebSocketProvider>
  );
};

export default MainLayout;
