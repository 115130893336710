import { Box, Card, CardContent, CircularProgress } from "@mui/material";
import CountCard from "../chartComponents/CountCards";
import { useSelector } from "react-redux";
import { useDashboradDataQuery } from "../../../store/slices/apiSlice";
import { SvgSpinnersBlocksShuffle2 } from "../../../components/icons/ReportingDashboardLoadingIcon";
import AnimatedBloqcubeLogo from "../../../components/Logo/AnimatedBloqcubeLogo";

const EntityCount = (props) => {
    const sponsorId = useSelector((state)=>state?.userDetails?.loggedInUser.sponsorId)

    const { data: dashboardData, isLoading } = useDashboradDataQuery({
      data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
    });

    return (
      <Box
        onMouseDown={(e)=>e.stopPropagation()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-around',
          padding: 2,
          pt: 1,
        }}
      >
        {dashboardData ? (
          <>
            <CountCard
              title={'Total'}
              maintitle={'AE/SAE'}
              count={`${dashboardData[props.preference]?.aeSubjects}/${dashboardData[props.preference]?.saeSubjects}`}
            />
            <CountCard title={'Total'} maintitle={'Sites'} count={dashboardData[props.preference]?.numberOfSites} />
            <CountCard
              title={'Total'}
              maintitle={'Enrollments'}
              count={dashboardData[props.preference]?.enrolledSubjects}
            />
            <CountCard
              title={'Total'}
              maintitle={'Dropouts'}
              count={dashboardData[props.preference]?.discontinuedSubjects}
            />
          </>
        ) : (
          ''
        )}
        {isLoading && (
          <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ height: 250, width: 250 }}>
              <AnimatedBloqcubeLogo />
            </Box>
          </Box>
        )}
      </Box>
    );
}

export default EntityCount;