import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SelectWithAddOption from './SelectWithAddOption';
import CustomButton from '../../../components/@extended/CustomButton';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addVendorToStudySite, selectStudyDetails, selectStudySiteDetails } from '../../../store/slices/financeSlice';
import { useParams } from 'react-router-dom';
import { openMessage } from '../../../store/slices/showMessageSlice';

const AccSetup = ({ handleTabChange, selectedSite }) => {
  const { sponsorId, studyId } = useParams();
  const theme = useTheme();
  const [formValues, setFormValues] = useState({
    vendor: '',
    screenFails: '',
    enrollees: '',
    percentOfRevenue: '',
  });
  const dispatch = useDispatch();
  const studyDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));
  const siteDetails = useSelector((state) => selectStudySiteDetails(state, sponsorId, studyId, selectedSite?.id));
  const [errors, setErrors] = useState({});
  const [subjectStatus, setSubjectStatus] = useState({
    enrolled: studyDetails.totalSubjectNumber,
    failed: 0
  });
  const [vendorDetails, setVendorDetails] = useState([]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const calculateTotalRevenueAmout = () => {
    let totalPercentAmt = 0;
    siteDetails?.vendors.forEach(vendor => {
      console.log("vendor", vendor);
      const rowTotal = calculateScreenFails(vendor.screenFails) + calculateEnrolledAmount(vendor.enrollees);
      totalPercentAmt += rowTotal * (Number(vendor.percentOfRevenue)/100);
    });
    return totalPercentAmt;
    //return siteDetails?.vendors?.reduce((acc, row) => acc + Number(row[field] ? row[field] : 0), 0);
  }
  const calculateTotal = (field) => {
    //return siteDetails?.vendors?.reduce((acc, row) => acc + Number(row[field] ? row[field] : 0), 0);
    return calculateColTotal('enrollees') + calculateColTotal('screenFails');
  };
  const calculateColTotal = (field) => {
    const subjectCount = field == 'enrollees' ? subjectStatus.enrolled : subjectStatus.failed;
    return siteDetails?.vendors?.reduce((acc, row) => acc + Number(row[field] ? row[field] * subjectCount : 0), 0);
  };
  const calculateScreenFails = (amount) => {
    return subjectStatus.failed ? Number(amount) * subjectStatus.failed : 0;
  };
  const calculateEnrolledAmount = (amount) => {
    return subjectStatus.enrolled ? Number(amount) * subjectStatus.enrolled : 0;
  };
  const calculateRowTotal = (enrollAmount, failedAmount) => {
    return calculateEnrolledAmount(enrollAmount) + calculateScreenFails(failedAmount);
  };
  const validateForm = (values) => {
    const errors = {};

    if (!values.vendor) {
      errors.vendor = 'Vendor/Category is required';
    }
    if (!values.screenFails) {
      errors.screenFails = 'Screen Fails is required';
    }
    if (!values.enrollees) {
      errors.enrollees = 'Enrollees is required';
    }
    if (!values.percentOfRevenue) {
      errors.percentOfRevenue = '% of Revenue is required';
    }

    return errors;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const totalAmount =
        Number(formValues.screenFails ? formValues.screenFails : 0) +
        Number(formValues.enrollees ? formValues.enrollees : 0);
      const revenueAmount = totalAmount * (formValues.percentOfRevenue / 100);
      const vendorNames = vendorDetails.map(vendor => vendor.vendor);
      if (vendorNames.includes(formValues.vendor)) {
        const vendorIndex = vendorDetails.findIndex(vendor => vendor.vendor === formValues.vendor)
        dispatch(
          addVendorToStudySite({
            sponsorId,
            studyId,
            siteId: selectedSite?.id,
            vendor: { ...formValues, ...vendorDetails[vendorIndex], total: totalAmount, revenueAmount: revenueAmount },
          })
        );
        setVendorDetails((vendors) => vendors.filter((_, index) => index !== vendorIndex));
      } else {
        dispatch(
          addVendorToStudySite({
            sponsorId,
            studyId,
            siteId: selectedSite?.id,
            vendor: { ...formValues, total: totalAmount, revenueAmount: revenueAmount },
          })
        );
      }
      setFormValues({
        vendor: '',
        screenFails: '',
        enrollees: '',
        percentOfRevenue: '',
      });
    }
  };

  const handleSaveVendors = (values) => {
    setVendorDetails([...vendorDetails, values])
  };


  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
        <Box sx={{ border: '1px dashed #E0E0E0', padding: 2, paddingBottom: 0, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Projected Revenue: </Typography>
            <Box sx={{ padding: 1, border: '1px solid lightgrey', borderRadius: 2, minWidth: 100, textAlign: 'center' }}>
              <Typography sx={{ fontSize: 24 }}>$ {calculateTotalRevenueAmout()}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3, paddingY: 2 }}>
            <Typography variant="subtitle1" sx={{ alignSelf: 'center' }}>
              UoQ (number of subject): <span style={{ fontWeight: 'bold' }}>{studyDetails?.totalSubjectNumber}</span>
            </Typography>
            <TextField
              label="Total Enrollees"
              size='small'
              sx={{ width: 150 }}
              value={subjectStatus.enrolled}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  if ((Number(event.target.value) + Number(subjectStatus.failed)) > Number(studyDetails?.totalSubjectNumber)) {
                    dispatch(openMessage({ messageSeverity: 'error', message: 'Count exceeds number of subjects.' }));
                  } else {
                    setSubjectStatus({
                      ...subjectStatus,
                      enrolled: event.target.value
                    });
                  }
                }
              }}
            />
            <TextField
              label="Total screen fails"
              size='small'
              sx={{ width: 150 }}
              value={subjectStatus.failed}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  if ((Number(event.target.value) + Number(subjectStatus.enrolled)) > Number(studyDetails?.totalSubjectNumber)) {
                    dispatch(openMessage({ messageSeverity: 'error', message: 'Count exceeds number of subjects.' }));
                  } else {
                    setSubjectStatus({
                      ...subjectStatus,
                      failed: event.target.value
                    });
                  }
                }
              }}
            />
          </Box>
        </Box>
        <Box>
          <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => { }}>
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              Upload Details
            </Typography>
          </CustomButton>
          <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
            Note: Upload file with CSV/Excel format.
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ paddingY: 3 }}>
        <Grid item md={3}>
          <SelectWithAddOption
            fieldName="vendor"
            sponsorId={sponsorId}
            studyId={studyId}
            selectedSite={selectedSite}
            onChange={handleChange}
            handleSaveVendors={handleSaveVendors}
            val={formValues.vendor}
            error={errors.vendor}
          />
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth error={!!errors.screenFails}>
            <InputLabel htmlFor="outlined-adornment-amount">Screen Fails</InputLabel>
            <OutlinedInput
              value={formValues.screenFails}
              onChange={handleChange}
              name="screenFails"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Screen Fails"
            />
            <FormHelperText>{errors.screenFails}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth error={!!errors.enrollees}>
            <InputLabel htmlFor="outlined-adornment-amount">Enrollees</InputLabel>
            <OutlinedInput
              value={formValues.enrollees}
              onChange={handleChange}
              name="enrollees"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Enrollees"
            />
            <FormHelperText>{errors.enrollees}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth error={!!errors.percentOfRevenue}>
            <InputLabel htmlFor="outlined-adornment-amount">Revenue</InputLabel>
            <OutlinedInput
              value={formValues.percentOfRevenue}
              onChange={handleChange}
              name="percentOfRevenue"
              endAdornment={<InputAdornment position="start">%</InputAdornment>}
              label="Revenue"
            />
            <FormHelperText>{errors.percentOfRevenue}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <CustomButton variant="outlined" onClick={handleSubmit}>
          Add
        </CustomButton>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Paper sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            backgroundColor: theme.palette.primary.light,
          }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <CustomTableHead>
              <TableRow>
                <CustomTableHeadCell>Vendors/Categories</CustomTableHeadCell>
                <CustomTableHeadCell align="right">Screen Fails</CustomTableHeadCell>
                <CustomTableHeadCell align="right">Enrollees</CustomTableHeadCell>
                <CustomTableHeadCell align="right">Total</CustomTableHeadCell>
                <CustomTableHeadCell align="right">% of Revenue</CustomTableHeadCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {siteDetails &&
                siteDetails.vendors.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.vendor}
                    </TableCell>
                    {/* <TableCell align="right">$ {row.screenFails}</TableCell>
                    <TableCell align="right">$ {row.enrollees}</TableCell>
                    <TableCell align="right">$ {row.total}</TableCell> */}
                    <TableCell align="right"><Typography>$ {calculateScreenFails(row.screenFails)}  <span style={{ color: 'grey', fontSize: 14, paddingLeft: 1 }}>(${row.screenFails} * {subjectStatus.failed})</span></Typography></TableCell>
                    <TableCell align="right"><Typography>$ {calculateEnrolledAmount(row.enrollees)} <span style={{ color: 'grey', fontSize: 14, paddingLeft: 1 }}>(${row.enrollees} * {subjectStatus.enrolled})</span></Typography></TableCell>
                    <TableCell align="right">$ {calculateRowTotal(row.enrollees, row.screenFails)}</TableCell>
                    <TableCell align="right">{row.percentOfRevenue}%</TableCell>
                  </TableRow>
                ))}
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <CustomTableHeadCell component="th" scope="row">
                  Total
                </CustomTableHeadCell>
                <CustomTableHeadCell align="right">$ {calculateColTotal('screenFails')}</CustomTableHeadCell>
                <CustomTableHeadCell align="right">$ {calculateColTotal('enrollees')}</CustomTableHeadCell>
                <CustomTableHeadCell align="right">$ {calculateTotal('total')}</CustomTableHeadCell>
                <CustomTableHeadCell align="right"></CustomTableHeadCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}>
        <CustomButton onClick={(e) => handleTabChange(1)}>Save & Continue</CustomButton>
      </Box>
    </Box>
  );
};

export default AccSetup;
