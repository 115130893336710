export const Columns3 = () => {
  const columns = [
    {
      id: 'trialTitle',
      label: `Trial Title`,
      style: {
        minWidth: 120,
        textAlign: 'justify',
      },
      align: 'left',
    },
    {
      id: 'protocolNumber',
      label: 'Protocol Number',
      style: {
        minWidth: 150,
      },
      align: 'left',
    },
    {
        id: 'protocolVersion',
        label: 'Protocol Version',
        style: {
          minWidth: 100,
        },
        align: 'center',
      },
    {
      id: 'numberOfSubjects',
      label: 'Number of Subjects',
      style: {
        minWidth: 150,
      },
      align: 'center',
    },
    {
      id: 'action',
      label: '',
      style: {
        minWidth: 150,
      },
      align: 'left',
    },
  ];

  return columns;
};