import { Box, Dialog, DialogContent, DialogTitle, FormControl, Grid } from '@mui/material';
import { Field, Formik, useField } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CustomButton from '../../components/@extended/CustomButton';
import { openMessage } from '../../store/slices/showMessageSlice';
import { phoneValidationSchema } from '../dynamicFormEngine/FormFields/PhoneInputField';
import FormikField from '../visitScheudle/FormikTextField';
import { addComplianceOfficer } from '../../store/slices/pfasSlice';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneInput from '../dynamicFormEngine/FormFields/CustomPhoneInput';

const Phone = ({ form, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);
  return (
    <PhoneInput
      {...form}
      {...field}
      placeholder={props.placeholder}
      value={field.value}
      defaultCountry=""
      size="medium"
      onChange={(value) => {
        if (!form.touched[field.name]) form.setFieldTouched(field.name);
        form.setFieldValue(field.name, value);
      }}
      label={props?.label}
      inputComponent={CustomPhoneInput}
    />
  );
};

const CreateComplianceOfficer = ({ open, handleClose }) => {
  const {sponsorId} = useParams()
  const dispatch = useDispatch();
  const onCloseModal = () => {
    handleClose();
  };
    
  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  const docSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Please enter a valid email')
      .matches(
        /^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        'Email address can only contain lowercase letters, numbers, and periods.'
      ),
    firstName: Yup.string()
      .trim()
      .required('First name is required')
      .min(2, 'Minimum 2 characters are required')
      .max(50, 'Maximum 50 characters are allowed'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required')
      .min(2, 'Minimum 2 characters are required')
      .max(50, 'Maximum 50 characters are allowed'),
    address: Yup.string()
      .trim()
      .required('Adress is required')
      .min(2, 'Minimum 2 characters are required')
      .max(500, 'Maximum 500 characters are allowed'),
    pincode: Yup.string().trim().required('Pincode is required').length(6, 'Pincode should be 6 digits.'),
    contactNumber: phoneValidationSchema.concat(Yup.string().required('Contact number is required')),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        contactNumber: '',
        pincode:'',
      }}
      validationSchema={docSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatch(
          addComplianceOfficer({
            data: values,
            orgId: sponsorId,
          })
        );
        openMessageNotification({
          message: 'Compliance Officer Created Successfully',
          type: 'success',
        });
        resetForm();
        handleClose();
      }}
    >
      {({ handleSubmit, resetForm, touched, errors }) => (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          //sx={{minHeight: '50%'}}
          PaperProps={{ sx: { minHeight: '50%' } }}
          scroll={'paper'}
          open={open}
          onClose={() => {
            resetForm();
            onCloseModal();
          }}
        >
          <DialogTitle>Create Compliance Officer</DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <Grid container spacing={3} sx={{ pt: 1 }}>
              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.firstName && Boolean(errors.firstName)}>
                  <Field component={FormikField} name="firstName" label="First Name" type="text" />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.lastName && Boolean(errors.lastName)}>
                  <Field component={FormikField} name="lastName" label="Last Name" type="text" />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.email && Boolean(errors.email)}>
                  <Field component={FormikField} name="email" label="Email" type="text" />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.contactNumber && Boolean(errors.contactNumber)}>
                  <Field component={Phone} name="contactNumber" label="Contact Number" type="text" />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.address && Boolean(errors.address)}>
                  <Field component={FormikField} name="address" label="Address" type="text" />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl fullWidth error={touched.pincode && Boolean(errors.pincode)}>
                  <Field component={FormikField} name="pincode" label="Pincode" type="number" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                  <CustomButton
                    variant={'outlined'}
                    size="small"
                    onClick={() => {
                      resetForm();
                      onCloseModal();
                    }}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton variant={'contained'} size="small" onClick={() => handleSubmit()}>
                    Save
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateComplianceOfficer;
