import { Box, Button, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDashboradDataQuery } from '../../../store/slices/apiSlice';
import AnimatedBloqcubeLogo from '../../../components/Logo/AnimatedBloqcubeLogo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function SitePerformanceMetricsTable(props) {
  const { showMore = false, maximizeWidget } = props;

  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);
  const { data: dashboardData, isLoading } = useDashboradDataQuery({
    data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
  });

  const columns = React.useMemo(() => {
    if (dashboardData) {
      let _columns = [
        {
          key: 'siteName',
          label: 'Site',
          minWidth: 150,
          align: 'center',
          expandedView: true,
        },
        {
          key: 'totalSubjectEnrollments',
          label: 'Total Enrolments',
          minWidth: 150,
          align: 'center',
          expandedView: true,
        },
        {
          key: 'trialOngoingSubjects',
          label: 'Ongoing Visit Subjects',
          minWidth: 150,
          align: 'center',
          expandedView: true,
        },
      ];
      dashboardData[props.preference][0]?.visits?.map((_visit) => {
        _columns.push({
          key: _visit.stepKey,
          label: _visit.label,
          minWidth: 150,
          align: 'center',
          expandedView: false,
        });
      });
      _columns.push({
        key: 'trialCompletedSubjects',
        label: 'Trial Completed Subjects',
        minWidth: 150,
        align: 'center',
        expandedView: false,
      });
      return _columns;
    }
    return [];
  }, [dashboardData]);

  const rows = React.useMemo(() => {
    if (dashboardData) {
      const _rows = [];
      dashboardData[props.preference]?.map((_site) => {
        let _obj = {
          siteName: _site?.site?.orgname,
          totalSubjectEnrollments: _site.totalSubjectEnrollments,
          trialOngoingSubjects: _site.trialOngoingSubjects,
          trialCompletedSubjects: _site.trialCompletedSubjects,
        };
        _site?.visits?.map((_visit) => {
          _obj[_visit.stepKey] = _visit.count;
        });
        _rows.push(_obj);
      });
      return _rows;
    }
    return [];
  }, [dashboardData]);

  return (
    <Box
      onMouseDown={(e)=>e.stopPropagation()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: showMore ? 0 : 1,
      }}
    >
      {dashboardData && (
        <TableContainer>
          <Table>
            <TableHead>
              {showMore ? (
                <TableRow>
                  {columns &&
                    columns.map((col) => (
                      <StyledTableCell
                        key={col.key}
                        align={col.align}
                        sx={{
                          minWidth: col?.minWidth,
                          maxWidth: col?.maxWidth,
                        }}
                      >
                        {col.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              ) : (
                <TableRow>
                  {columns &&
                    columns
                      .filter((col) => col.expandedView === true)
                      .map((col) => (
                        <StyledTableCell
                          key={col.key}
                          align={col.align}
                          sx={{
                            minWidth: col?.minWidth,
                            maxWidth: col?.maxWidth,
                          }}
                        >
                          {col.label}
                        </StyledTableCell>
                      ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {rows.map((row) =>
                showMore ? (
                  <StyledTableRow key={row.site}>
                    {columns &&
                      columns.map((col) => {
                        return (
                          <StyledTableCell
                            key={col.key}
                            align={col.align}
                            sx={{
                              minWidth: col?.minWidth,
                              maxWidth: col?.maxWidth,
                            }}
                          >
                            {row[col.key]}
                          </StyledTableCell>
                        );
                      })}
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key={row.site}>
                    {columns &&
                      columns
                        .filter((col) => col.expandedView === true)
                        .map((col) => {
                          return (
                            <StyledTableCell
                              key={col.key}
                              align={col.align}
                              sx={{
                                minWidth: col?.minWidth,
                                maxWidth: col?.maxWidth,
                              }}
                            >
                              {row[col.key]}
                            </StyledTableCell>
                          );
                        })}
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && !showMore && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={() => maximizeWidget('sitePerformanceMetrics')}
            size="small"
            sx={{ textDecoration: 'underline' }}
          >
            Show more
          </Button>
        </Box>
      )}
      {isLoading && (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{height:250, width:250}}>
            <AnimatedBloqcubeLogo  />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SitePerformanceMetricsTable;
