import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import CustomButton from '../../components/@extended/CustomButton';
import FormikField from '../visitScheudle/FormikTextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch } from 'react-redux';
import { addSponsor } from '../../store/slices/financeSlice';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import { useField } from 'formik';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneInput from '../dynamicFormEngine/FormFields/CustomPhoneInput';
import { openMessage } from '../../store/slices/showMessageSlice';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { addOrganization } from '../../store/slices/pfasSlice';

const Phone = ({ form, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);
  return (
    <PhoneInput
      {...form}
      {...field}
      placeholder={props.placeholder}
      value={field.value}
      defaultCountry=""
      size="medium"
      onChange={(value) => {
        if (!form.touched[field.name]) form.setFieldTouched(field.name);
        form.setFieldValue(field.name, value);
      }}
      label={props?.label}
      inputComponent={CustomPhoneInput}
    />
  );
};



const CreateOrganization = ({ open, onClose, type }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(null);
  const [accountVerified, setAccountVerified] = useState(false);

  const accountDetailsValidationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required('Account Holder Name is required'),
    account_holder_email: Yup.string()
      .email('Invalid email')
      .required('Account Holder Email is required')
      .matches(
        /^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        'Email address can only contain lowercase letters, numbers, and periods.'
      ),
    account_holder_number: Yup.string().required('Account Number is required'),
    confirm_account_holder_number: Yup.string()
      .oneOf([Yup.ref('account_holder_number'), null], 'Confirm Account Number must match Account Number')
      .required('Confirm Account Number is required'),
    routingNumber: Yup.string().required('Routing Number is required'),
    confirm_routing_Number: Yup.string()
      .required('Confirm Routing Number is required')
      .oneOf([Yup.ref('routingNumber'), null], 'Confirm Routing Number must match Routing Number'),
    abaNumber: Yup.string()
      .required('ABA number is required')
      .matches(/^[0-9]+$/, 'ABA number must be numeric'),
    swiftNumber: Yup.string()
      .required('Swift Number is required')
      .matches(/^[0-9]+$/, 'Swift Number must be numeric'),
    country: Yup.string().required('Country is required'),
    currency: Yup.string().required('Currency is required'),
  });

  const validationSchema = Yup.object().shape({
    orgName: Yup.string().required('Organization Name is required'),
    orgCategory: Yup.string().required('Organization Category is required'),
    contactEmail: Yup.string()
      .required('Contact Email is required')
      .email('Invalid email')
      .matches(
        /^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        'Email address can only contain lowercase letters, numbers, and periods.'
      ),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    postalcode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
    accountDetails: accountDetailsValidationSchema,
  });

  const [orgCategory, setOrgCategory] = useState([
    { label: 'Pharmaceutical', value: 'Pharmaceutical' },
    { label: 'Biotechnology', value: 'Biotechnology' },
    { label: 'Academic', value: 'Academic' },
    { label: 'Non-Profit', value: 'Non-Profit' },
    { label: 'Government', value: 'Government' },
    { label: 'Contract Research Organization (CRO)', value: 'Contract Research Organization (CRO)' },
    { label: 'Medical Device', value: 'Medical Device' },
    { label: 'Healthcare Provider', value: 'Healthcare Provider' },
    { label: 'Consulting', value: 'Consulting' },
    { label: 'Other', value: 'Other' },
  ]);

  const handleFormSubmit = (values, { resetForm }) => {
    if (accountVerified) {
      if (type === 'PFAS') {
        dispatch(addOrganization({ ...values, accountStatus: 'Verified' }));
      } else {
        dispatch(addSponsor({ ...values, accountStatus: 'Verified' }));
      }
    } else {
      if (type === 'PFAS') {
        dispatch(addOrganization({ ...values, accountStatus: 'Unverified' }));
      } else {
        dispatch(addSponsor({ ...values, accountStatus: 'Unverified' }));
      }
    }
    resetForm();
    onClose();
    setAccountVerified(false);
  };

  const validateAccountDetails = async (values, setFieldTouched, setFieldError) => {
    try {
      await accountDetailsValidationSchema.validate(values.accountDetails, { abortEarly: false });
      setAccountVerified(true);
      dispatch(openMessage({ messageSeverity: 'success', message: 'Bank Account Details Validated.' }));
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          setFieldTouched(`accountDetails.${err.path}`, true);
          setFieldError(`accountDetails.${err.path}`, err.message);
        });
      }
      setAccountVerified(false);
    }
  };

  const handleLogoChange = () => {};

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton component="label" variant="outlined" onClick={(e) => onClose()} startIcon={<ArrowBackIcon />}>
            Back
          </CustomButton>
          <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              Upload Details
            </Typography>
          </CustomButton>
        </Box>
        <Typography variant="subtitle1" color="black" fontSize={'12px'} textAlign={'right'} mt={1}>
          Note: Upload file with CSV/Excel format.
        </Typography>
      </Grid>

      <Formik
        initialValues={{
          orgName: '',
          orgCategory: '',
          contactInfo: '',
          contactEmail: '',
          addressLine1: '',
          state: '',
          city: '',
          postalcode: '',
          country: '',
          accountDetails: {
            account_holder_name: '',
            account_holder_email: '',
            account_holder_number: '',
            confirm_account_holder_number: '',
            routingNumber: '',
            confirm_routing_Number: '',
            abaNumber: '',
            swiftNumber: '',
            country: '',
            currency: '',
          },
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldError,
        }) => (
          <Grid item xs={12}>
            <Form onSubmit={handleSubmit}>
              <Card sx={{ mb: 3, px: 1 }}>
                <CardHeader title={'Organization Details'} titleTypographyProps={{ variant: 'h6' }} />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={12}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label htmlFor="icon-button-file">
                          {/* <Input accept="image/*" id="icon-button-file" type="file" onChange={handleLogoChange} /> */}
                          <IconButton color="primary" aria-label="upload logo" component="span">
                            <Box
                              component="div"
                              sx={{
                                width: 100,
                                height: 100,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #ddd',
                                overflow: 'hidden',
                              }}
                            >
                              {logo ? (
                                <Box
                                  component="img"
                                  src={logo}
                                  alt="Organization Logo"
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'fill',
                                  }}
                                />
                              ) : (
                                <PhotoCamera fontSize="large" />
                              )}
                            </Box>
                          </IconButton>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.orgName && Boolean(errors.orgName)}>
                        <Field component={FormikField} name="orgName" label="Organization Name" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.orgCategory && Boolean(errors.orgCategory)}>
                        <InputLabel>Organization Category</InputLabel>
                        <Field
                          component={Select}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          label="Organization Category"
                          inputProps={{
                            id: 'orgCategory',
                            name: 'orgCategory',
                          }}
                        >
                          {orgCategory &&
                            orgCategory.map((cat) => (
                              <MenuItem key={cat.value} value={cat.value}>
                                {cat.label}
                              </MenuItem>
                            ))}
                        </Field>
                        {touched.orgCategory && errors.orgCategory && (
                          <FormHelperText>{errors.orgCategory}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.contactEmail && Boolean(errors.contactEmail)}>
                        <Field component={FormikField} name="contactEmail" label="Contact Email" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.contactInfo && Boolean(errors.contactInfo)}>
                        <Field name="contactInfo" component={Phone} label="Contact Number" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.addressLine1 && Boolean(errors.addressLine1)}>
                        <Field component={FormikField} name="addressLine1" label="Address Line 1" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.state && Boolean(errors.state)}>
                        <Field component={FormikField} name="state" label="State" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.city && Boolean(errors.city)}>
                        <Field component={FormikField} name="city" label="City" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.postalcode && Boolean(errors.postalcode)}>
                        <Field component={FormikField} name="postalcode" label="Postal Code" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.country && Boolean(errors.country)}>
                        <Field component={FormikField} name="country" label="Country" type="text" />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" mr={2}>
                        Bank Account Information
                      </Typography>
                      {accountVerified ? (
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.light,
                            fontSize: 20,
                          }}
                        />
                      ) : (
                        <WatchLaterIcon
                          sx={{
                            color: '#FFCB00',
                            fontSize: 20,
                          }}
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: accountVerified ? theme.palette.success.light : ' #FFCB00',
                          width: 'fit-content',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginLeft: theme.spacing(0.5),
                        }}
                      >
                        {accountVerified ? 'Verified' : 'Unverified'}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.accountDetails?.account_holder_name &&
                          Boolean(errors.accountDetails?.account_holder_name)
                        }
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.account_holder_name"
                          label="Account Holder Name"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.accountDetails?.account_holder_email &&
                          Boolean(errors.accountDetails?.account_holder_email)
                        }
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.account_holder_email"
                          label="Account Holder Email"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.accountDetails?.account_holder_number &&
                          Boolean(errors.accountDetails?.account_holder_number)
                        }
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.account_holder_number"
                          label="Account Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.accountDetails?.confirm_account_holder_number &&
                          Boolean(errors.accountDetails?.confirm_account_holder_number)
                        }
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.confirm_account_holder_number"
                          label="Confirm Account Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.accountDetails?.routingNumber && Boolean(errors.accountDetails?.routingNumber)}
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.routingNumber"
                          label="Routing Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.accountDetails?.confirm_routing_Number &&
                          Boolean(errors.accountDetails?.confirm_routing_Number)
                        }
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.confirm_routing_Number"
                          label="Confirm Routing Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.accountDetails?.abaNumber && Boolean(errors.accountDetails?.abaNumber)}
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.abaNumber"
                          label="ABA number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.accountDetails?.swiftNumber && Boolean(errors.accountDetails?.swiftNumber)}
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.swiftNumber"
                          label="Swift Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.accountDetails?.country && Boolean(errors.accountDetails?.country)}
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.country"
                          label="Country"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        fullWidth
                        error={touched.accountDetails?.currency && Boolean(errors.accountDetails?.currency)}
                      >
                        <Field
                          disabled={accountVerified ? true : false}
                          component={FormikField}
                          name="accountDetails.currency"
                          label="Currency"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <CustomButton
                        variant="outlined"
                        onClick={() => {
                          accountVerified
                            ? setAccountVerified(false)
                            : validateAccountDetails(values, setFieldTouched, setFieldError);
                        }}
                        color="primary"
                        sx={{ padding: '8px 30px', textTransform: 'none' }}
                      >
                        {!accountVerified ? 'Validate' : 'Edit'}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
            <Grid item xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box sx={{ pt: 3 }}>
                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    resetForm();
                    setAccountVerified(false);
                  }}
                  color="primary"
                  sx={{ padding: '8px 30px', textTransform: 'none' }}
                >
                  Reset
                </CustomButton>
                <CustomButton onClick={handleSubmit} style={{ marginLeft: 15 }}>
                  Save & Continue
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default CreateOrganization;
