import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/@extended/CustomButton';
import RightArrow from '../../components/icons/RightArrow';
import TrialInfo from './TrialInfo';
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';
import DeligationTasks from './DeligationTasks';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomCard from '../../components/@extended/CustomCard';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDeleteDoaTaskMutation } from '../../store/slices/apiSlice';
import { getUserRole } from '../util';
import CustomTablePagination from '../../components/common/TablePagination';
import { updatePagination } from '../../store/slices/applicationSlice';

const DeligationConfiguration = (props) => {
  const { trialId, siteId, onBackClick, allTasks } = props;
  const navigate = useNavigate();
  const theme = useTheme()
  const dispatch = useDispatch();
  const [openDeligationTasks, setOpenDeligationtasks] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const userRole = getUserRole(loggedInUser)
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.DOA_DELIGATION_CONFIGURATION);
  const closeDelegationTasks = () => {
    setSelectedTask(null);
    setOpenDeligationtasks(false);
  };
  const [deleteDoaTask] = useDeleteDoaTaskMutation()

    const [confirmDialog, setConfirmDialog] = useState({
      open: false,
      message: (
        <>
          <span>Are you sure you want to delete this Task?</span>
          <br />
          <span>This action cannot be undone. Please confirm whether you want to proceed with the deletion.</span>
        </>
      ),
      buttonLabel: 'Delete',
    });
  
  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };
  const deleteTask = async (task) => {
    setSelectedTask(task);
    setConfirmDialog({
      ...confirmDialog,
      open: true,
      message: (
        <>
          <span>Are you sure you want to delete this task?</span>
        </>
      ),
    });
  };

  const handleDeleteTask = async () => {
      const result = await deleteDoaTask(selectedTask?.id);
      if (!result?.error) {
        openMessageNotification({
          message: 'Task deleted successfully !',
          type: 'success',
        });
      } else {
        openMessageNotification({
          message: result?.error?.data?.message,
          type: 'error',
        });
      }
    setSelectedTask(null);
      setConfirmDialog({
        ...confirmDialog,
        open: false,
      });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'DOA_DELIGATION_CONFIGURATION',page,rowsPerPage}));
  }

  return (
    <Grid container pt={3} rowGap={2}>
      <Grid item xs={12}>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems:"center", pt: 2 }}>
          <Button variant="text" onClick={onBackClick} startIcon={<RightArrow leftArrow />}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Back
            </Typography>
          </Button>
          <Box>
          <CustomButton
            variant="contained"
            onClick={() => {
              setOpenDeligationtasks(true);
            }}
            size="small"
          >
            Add Task
          </CustomButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {allTasks && allTasks.length > 0 ?
        <CustomCard
          // title={"Subjects"}
          // subHeader={`Total: ${rows?.length}`}
          sx={{ p: 1, pt: 2 }}
        >
          <TableContainer
            sx={{
              maxHeight: 600,
              border: `1px ${theme.palette.grey[100]} solid `,
            }}
          >
            <Table stickyHeader>
              <CustomTableHead>
                <TableRow>
                  <CustomTableHeadCell align="left" sx={{ width: '15%' }}>
                    Task Number
                  </CustomTableHeadCell>
                  <CustomTableHeadCell align="left" sx={{ width: '25%' }}>
                    Title
                  </CustomTableHeadCell>
                  <CustomTableHeadCell align="left" sx={{ width: '45%' }}>
                    Description
                  </CustomTableHeadCell>
                  <CustomTableHeadCell align="center" sx={{ width: '15%' }}>
                    Action
                  </CustomTableHeadCell>
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {allTasks && allTasks.length > 0 ? (
                  (rowsPerPage > 0
                    ? allTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : allTasks
                  ).map((row, index) => {
                    return (
                      <CustomTableRow key={index}>
                        <TableCell align="left" s>
                          <Typography>{row?.taskNumber}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{row?.title}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography align='justify'>{row?.description}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {(row.createdBy===loggedInUser.email || userRole==='PI') ?
                          <> 
                          <Tooltip title={'Edit Task'}>
                            <IconButton
                              color={theme.palette.grey[500]}
                              sx={{ fontSize: 10, mr: 1 }}
                              onClick={() => {
                                setSelectedTask(row);
                                setOpenDeligationtasks(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Delete Task'}>
                            <IconButton
                              onClick={() => {
                                deleteTask(row);
                              }}
                              color={theme.palette.grey[500]}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          </>
                          : ""}
                        </TableCell>
                      </CustomTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="subtitle1" color="initial">
                        No Data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={allTasks.length} onNewPage={onNewPage}/>
        </CustomCard>:
        <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3}}>
            <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>No configured tasks available.</Typography>
        </Stack>
        }
      </Grid>
      <DeligationTasks
        onClose={closeDelegationTasks}
        open={openDeligationTasks}
        trialId={trialId}
        siteId={siteId}
        task={selectedTask}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        buttonLabel={confirmDialog.buttonLabel}
        message={confirmDialog.message}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleDeleteTask}
      />
    </Grid>
  );
};

export default DeligationConfiguration;
