import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import originalConfig from './testData.json';
import { bloqcibeApi, useAddFieldSiteInitiationMutation, useGetTrialSiteInfoQuery, useRemoveFieldSiteInitiationMutation, useSubmitSiteInitAnswersMutation, useUpdateSiteInitAnswersMutation, useUploadInitiationFileMutation, useUploadSignatureMutation, useVerifySiteInitAnswersMutation } from "../../store/slices/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { base64ToArrayBuffer } from "../../components/common/DocumentUpload";
import { base64ImageToBlob, createNewFields, getUserRole } from "../util";
import _ from 'lodash'
import RightArrow from "../../components/icons/RightArrow";
import DynamicFormEngine from "../dynamicFormEngine";
import { mergeCustomFields } from "../../util/util";
import { openBackdropLoading } from "../../store/slices/applicationSlice";
import DynamicFormAction from "../dynamicFormEngine/FormEntity/DynamicFormAction";

const SiteInitiationForm = () => {
  const navigate = useNavigate();
  let { trialId, trialSiteId, siteInitStep } = useParams();
  const dispatch = useDispatch()
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const [formConfig, setFormConfig] = useState(null);
  const siteInitianDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.siteInitiationForm);
  // const [formConfig, setFormConfig] = useState(originalConfig.steps.find((_obj) => _obj.key == siteInitStep));
  const [readOnly, setReadOnly] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [userSiteTrialRole, setUserSiteTrialRole] = useState();
  const [formAnswers, setFormAnswers] = useState();
  const [originalFormConfig, setOriginalFormConfig] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const siteId = useSelector((state) => state.trial?.trialSiteDetail?.siteTrialData?.siteId);
  const [selectedSectionKey, setSelectedSectionKey] = useState();
  const [selectedSubSectionKey, setSelectedSubSectionKey] = useState();
  const [getLibraryForm] = bloqcibeApi.endpoints.getLibraryForm.useLazyQuery();
  const [downloadSignature] =
    bloqcibeApi.endpoints.downloadSignature.useLazyQuery();
  const [getTrialSiteAnswers] = bloqcibeApi.endpoints.getTrialSiteAnswers.useLazyQuery();
  const [submitSiteInittAnswers] = useSubmitSiteInitAnswersMutation();
  const [updateSiteInittAnswers] = useUpdateSiteInitAnswersMutation();
  const [verifySiteInittAnswers] = useVerifySiteInitAnswersMutation();
  const [uploadSignature] = useUploadSignatureMutation();
  const [trialSiteData, setTrialSiteData] = useState(null)
  const [getTrialSiteData] = bloqcibeApi.endpoints.getTrialSiteInfo.useLazyQuery()
  const [stepStatusData, setStepStatusData] = useState([])
    const [dynamicFormAction, setDynamicFormAction] = useState(DynamicFormAction.NA);
  const [uploadInitiationFile]=useUploadInitiationFileMutation()
  const [downloadInitiationFile] = bloqcibeApi.endpoints.downloadInitiationFile.useLazyQuery();
  const [getSiteInitiationConfig, {data : siteInitCustomFields}] = bloqcibeApi.endpoints.getSiteInitiationConfig.useLazyQuery();
  const [addFieldSiteInitiation] = useAddFieldSiteInitiationMutation()
  const [ removeFieldSiteInitiation ] = useRemoveFieldSiteInitiationMutation()
  const [customFieldConfig, setCustomFieldConfig] = useState(null);
  const [stepStatusCount, setStepStatusCount] = useState(0)
  useEffect(() => {
    (async () => {
      if (siteInitStep && siteInitianDocumentKey) {
        const formDetailsData = await getLibraryForm(
          `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${siteInitianDocumentKey}`
        );
        if (formDetailsData.data) {
          setOriginalFormConfig(formDetailsData.data);
        } else if (formDetailsData.error) {
          console.error('UNABLE TO GET FORM DETAILS');
        }
      }
    })();
  }, [siteInitStep, siteInitianDocumentKey]);
  useEffect(() => {
    (async () => {
      const _trialSiteData = await getTrialSiteData({
            sponsorId: sponsorId,
            trialId: trialId,
            siteTrialId: trialSiteId,
      });
      setTrialSiteData(_trialSiteData.data);
        const userRole = getUserRole(loggedInUser, trialId, trialSiteData?.siteTrialData?.site?.id);
        setUserSiteTrialRole(userRole);
        setStepProperty(selectedSectionKey, selectedSubSectionKey, _trialSiteData.data);
        const stepStatusData = _trialSiteData.data?.siteTrialData.siteInitiationMaster.stepStatus.find((_obj) => _obj.stepKey === siteInitStep);
        handleStepStatusCount(stepStatusData, userRole);
    })();
  }, []);

    useEffect(() => {
      (async () => {
        if (siteId && originalFormConfig && siteInitianDocumentKey) {
          const formDetailsData = await getSiteInitiationConfig({
            sponsorId,
            trialId,
            siteId,
            documentKey: siteInitianDocumentKey,
          });
          if (formDetailsData.data) {
            setCustomFieldConfig(formDetailsData.data);
            let _tempConfig = { ...originalFormConfig };
            const mergedConfig = mergeCustomFields(_tempConfig, formDetailsData.data);
            setFormConfig(mergedConfig.steps.find((_obj) => _obj.key === siteInitStep));
          } else if (formDetailsData.error) {
            console.error('UNABLE TO GET FORM DETAILS');
          } else {
            setFormConfig(originalFormConfig.steps.find((_obj) => _obj.key === siteInitStep));
          }
        }
      })();
    }, [siteId, originalFormConfig, siteInitianDocumentKey]);

    const handleStepStatusCount = (stepStatusData, userRole) =>{
      const userAccess = JSON.parse(stepStatusData.userAccess);
      let count = 0;
      const verifyAccess = userAccess.verify;
      const editAccess = userAccess.edit;
      let isVerifyAccess = _.find(verifyAccess, (access) => {
        return access == userRole;
      });
      const isEditAccess = _.find(editAccess, (access) => {
        return access == userRole;
      });

      stepStatusData.sectionStatuses?.map((step)=>{
        if(isVerifyAccess){
          if(step.status === "Completed"){
            count += 1;
          }
        }
        else if(isEditAccess){
          if(step.status !== "Pending"){
            count += 1;
          }
        }
        else{
          if(step.status === "Completed"){
            count += 1;
          }
        }
      })
      setStepStatusCount(count);
    }

  const onFormChanged = async (sectionKey, subSectionKey) => {
    setReadOnly(false);
    setButtonLabel('');
    setSelectedSectionKey(sectionKey)
    setSelectedSubSectionKey(subSectionKey)
    const _answers = await getTrialSiteAnswers({
      sponsorId: sponsorId,
      trialId: trialId,
      trialSiteId: trialSiteId,
      payload: {
        siteInitiationMasterId: trialSiteData?.siteTrialData?.siteInitiationMaster?.id,
        stepKey: siteInitStep,
        sectionKey: sectionKey,
        subSectionKey: subSectionKey
      }
    })
    setFormAnswers(_answers.data);
    const _trialSiteData = await getTrialSiteData({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: trialSiteId,
      });
    setTrialSiteData(_trialSiteData.data);
    setStepProperty(sectionKey, subSectionKey, _trialSiteData.data);
  }

  const setStepProperty = (sectionKey, subSectionKey, trialSiteData) => {
    if (trialSiteData) {
      const orderedSteps = _.sortBy(trialSiteData?.siteTrialData?.siteInitiationMaster?.stepStatus, 'order');
      const userRole = getUserRole(loggedInUser, trialId, trialSiteData?.siteTrialData?.site?.id);
      let buttonLabel = '';
      let stepStatusData;
      if (orderedSteps) {
        stepStatusData = orderedSteps.find((_obj) => _obj.stepKey === siteInitStep);
        console.log('stepStatusData', stepStatusData);
        setStepStatusData(stepStatusData);
      }
      const siteInitStatus = trialSiteData?.siteTrialData?.status;
      if (siteInitStatus == 'Completed') {
        setReadOnly(true);
        return;
      }

      if (stepStatusData) {
        const userAccess = JSON.parse(stepStatusData.userAccess);
        let sectionStatus = _.filter(stepStatusData.sectionStatuses, (statusData) => {
          return statusData.sectionKey == sectionKey;
        })[0]?.status;
        //If step doesn't have any tab
        if (!sectionStatus) {
          sectionStatus = stepStatusData.status;
        }
        if (sectionStatus == 'Pending') {
          const editAccess = userAccess.edit;
          const isAccess = _.find(editAccess, (access) => {
            return access == userRole;
          });
          if (isAccess) {
            buttonLabel = 'Submit';
          } else {
            setReadOnly(true);
          }
        } else if (sectionStatus == 'Verification_Pending') {
          const verifyAccess = userAccess.verify;
          const editAccess = userAccess.edit;
          let isAccess = _.find(verifyAccess, (access) => {
            return access == userRole;
          });
          const isEditAccess = _.find(editAccess, (access) => {
            return access == userRole;
          });
          if (isAccess) {
            buttonLabel = `Verify`;
          } else if (isEditAccess) {
            buttonLabel = `Update`;
          } else {
            setReadOnly(true);
          }
        } else if (sectionStatus == 'Completed') {
          const editAccess = userAccess.edit;
          const isAccess = _.find(editAccess, (access) => {
            return access == userRole;
          });
          if (isAccess) {
            buttonLabel = 'Update';
          } else {
            setReadOnly(true);
          }
        }
        setButtonLabel(buttonLabel);
        handleStepStatusCount(stepStatusData, userRole);
      }
    }
  };

  const findObjectByValue = async (obj, targetValue, path = [], keys) => {
    for (const key in obj) {
      if (obj[key] === targetValue) {
        let configFields = obj?.addFields;
      
        if (configFields) {
          const newFields = createNewFields(
            configFields,
            obj.fields.length + 1
          );
          const _formConfig = await addFieldSiteInitiation({
            sponsorId,
            trialId,
            siteId,
            payload: {
              documentKey: keys.documentKey,
              stepKey: siteInitStep,
              sectionKey: keys.sectionKey,
              subSectionKey: keys.subSectionKey,
              categoryKey: keys.categoryKey,
              fields: newFields,
            },
          });
          setCustomFieldConfig(_formConfig.data);
          let _tempConfig = { ...originalFormConfig };
          const mergedConfig = mergeCustomFields(_tempConfig, _formConfig.data);
          setFormConfig(
            mergedConfig.steps.find((_obj) => _obj.key === siteInitStep)
          );
          break;
        } else {
          console.error("'newFields' key not found ");
        }
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Continue searching in the nested object
        const result = await findObjectByValue(
          obj[key],
          targetValue,
          [...path, key],
          keys
        );
        if (result) {
          return result;
        }
      }
    }
    // Value not found in the current object
    return null;
  };

  const addNewField = (parentKey, keys) => {
    const valChange = JSON.parse(JSON.stringify(formConfig));
    findObjectByValue(valChange, parentKey, [], {
      documentKey: originalFormConfig?.documentKey,
      ...keys,
    });
  };

  const handleSaveSignature = async (keys, fieldName, setFieldValueFun) => {
    const formData = new FormData();
    formData.append("file", base64ImageToBlob(keys.file));
    formData.append("siteInitiationMasterId", trialSiteData?.siteTrialData?.siteInitiationMaster?.id);
    formData.append("stepKey", keys.stepKey);
    formData.append("sectionKey", keys.sectionKey);
    formData.append("subSectionKey", keys.subSectionKey);
    formData.append("categoryKey", keys.categoryKey);
    formData.append("fieldKey", keys.fieldKey);
    const result = await uploadSignature({
      payload: formData,
      sponsorId: sponsorId,
      trialId: trialId,
      trialSiteId: trialSiteId,
    });
    setFieldValueFun(fieldName, result.data.s3Key);
  }
  const handleDownloadSignature = async (s3Key, setSign) => {
    const result = await downloadSignature({
      s3Key: s3Key,
    });
    if (result.data) {
      const byteArray = base64ToArrayBuffer(result.data);
      const rr = new Blob([byteArray], { type: "application/octet-stream" });
      const reader = new FileReader();
      reader.readAsDataURL(rr);
      reader.onloadend = function () {
        const base64data = reader.result;
        setSign(base64data);
      };
    } else {
      console.log("Failed to load signature");
    }
  }
  const handleSave = async (data) => {
    try {
      const payload = {
        siteInitiationMasterId: trialSiteData?.siteTrialData?.siteInitiationMaster?.id,
        stepKey: data.stepKey,
        sectionKey: data.sectionKey,
        subSectionKey: data.subSectionKey ? data.subSectionKey : '',
        answers: data.answers,
        documentKey: siteInitianDocumentKey,
      };

      dispatch(openBackdropLoading({ backdropLoading: true }));

      if (buttonLabel == 'Update') {
        await updateSiteInittAnswers({ sponsorId, trialId, trialSiteId, payload });
      } else if (buttonLabel == 'Verify') {
        await verifySiteInittAnswers({ sponsorId, trialId, trialSiteId, payload });
      } else {
        await submitSiteInittAnswers({ sponsorId, trialId, trialSiteId, payload });
      }
      setDynamicFormAction(DynamicFormAction.FORM_SAVE_SUCCESS);
    } catch (error) {
      console.error('Failed to save:', error);
      setDynamicFormAction(DynamicFormAction.FORM_SAVE_FAILURE);
      return Promise.reject(error);
    }
  
  }

  const handleUploadFile = async (keys, fieldName, setFieldValueFun) => {
    const formData = new FormData();
    formData.append("file", keys.file);
    formData.append("siteInitiationMasterId", trialSiteData?.siteTrialData?.siteInitiationMaster?.id);
    formData.append("stepKey", keys.stepKey);
    formData.append("sectionKey", keys.sectionKey);
    formData.append("subSectionKey", keys.subSectionKey ? keys.subSectionKey : '');
    formData.append("categoryKey", keys.categoryKey);
    formData.append("fieldKey", keys.fieldKey);
    const result = await uploadInitiationFile({
      payload: formData,
      sponsorId: sponsorId,
      trialId: trialId,
      siteId: trialSiteData?.siteTrialData?.site?.id,
    });
    setFieldValueFun(fieldName, result.data.s3Key);
  }

  const handleDownloadFile = async (s3Key, fileName, setSelectedFile) => {
    const result = await downloadInitiationFile({
      s3Key: s3Key,
    });
    if (result.data) {
      let byteArray = base64ToArrayBuffer(result.data);
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/octet-stream" })
      );
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setSelectedFile && setSelectedFile(new Blob([byteArray], { type: "application/octet-stream" }))
    } else {
      console.log("Failed to load file");
    }
  }


    const removeCustomField = async (field, keys) => {
      if (customFieldConfig) {
        dispatch(openBackdropLoading({ backdropLoading: true }));
        const _customFieldConfig = _.filter(
          customFieldConfig,
          (config) =>
            config?.stepKey === siteInitStep &&
            config?.sectionKey === keys?.sectionKey &&
            config?.subSectionKey === keys?.subSectionKey &&
            config?.categoryKey === keys?.categoryKey
        );
        const deleteFieldData = _.find(_customFieldConfig, (fieldConfig) =>
          _.some(fieldConfig?.customFields, (_field) => _field.key === field.key)
        );
        const updatedConfig = await removeFieldSiteInitiation(deleteFieldData?.id);
        setCustomFieldConfig(updatedConfig.data);
        let _tempConfig = { ...originalFormConfig };
        const mergedConfig = mergeCustomFields(_tempConfig, updatedConfig.data);
        setFormConfig(mergedConfig.steps.find((_obj) => _obj.key === siteInitStep));
        dispatch(openBackdropLoading({ backdropLoading: false }));
      }
    };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          flex: "grow",
          display: "flex",
          height: 70,
        }}
      >
        <Button type="text" onClick={() => navigate(-1)} startIcon={<RightArrow leftArrow />}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Back
            </Typography>
        </Button>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="initial" ml={"auto"} mr={"auto"}>
            {stepStatusData.stepLabel} {stepStatusData.sectionStatuses?.length !== 0 && stepStatusCount  ? `(${stepStatusCount}/${stepStatusData.sectionStatuses?.length})` : ""}
          </Typography>
        </Box>
      </Box>
      {originalConfig && trialSiteData && (
        <DynamicFormEngine
          formConfig={formConfig}
          userSiteTrialRole={userSiteTrialRole}
          readOnly={readOnly}
          buttonLabel={buttonLabel}
          showButton={true}
          AddNewField={addNewField}
          onFormChanged={onFormChanged}
          handleSave={(payload) => {
            handleSave(payload);
          }}
          handleSaveSignature={handleSaveSignature}
          handleDownloadSignature={handleDownloadSignature}
          formAnswers={formAnswers}
          handleUploadFile={handleUploadFile}
          handleDownloadFile={handleDownloadFile}
          stepStatusData={stepStatusData}
          removeCustomField={removeCustomField}
          dynamicFormAction={dynamicFormAction}
          setDynamicFormAction={setDynamicFormAction}
        />
      )}
    </>
  );
};

export default SiteInitiationForm;