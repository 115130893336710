import React from 'react';
import { Field, useField } from 'formik';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const FormikDatePickerField = ({ form,setFieldValue, ...other }) => {
  const [field, meta, helpers] = useField(other.field.name);
  const currentError = form.errors[field.name];
  const touched = form.touched[field.name];
  const handleChange = (date) => {
    helpers.setValue(date, true);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...other}
        {...field}
        //value={field.value || null}
        // value={dayjs(
        //   new Date(moment(field.value))
        // )}
        onChange={(value) =>
          //setFieldValue(other.field.name, dayjs(value).format("MM/DD/YYYY"), true)
          helpers.setValue(dayjs(value).format("MM/DD/YYYY"), true)
        }
        value={dayjs(
          new Date(moment(field.value && field.value, "MM/DD/YYYY"))
        )}
        disablePast
        //format="DD/MM/YYYY"
        error={touched && Boolean(currentError)}
        slotProps={{
            textField: {
              error: touched && currentError && true,
              helperText: touched && currentError && currentError,
              name: field.name,
            },
          }}
        //onChange={handleChange}
        // onChange={(value) =>
        //   setFieldValue(name, dayjs(value).format("DD/MM/YYYY"), true)
        // }
      />
    </LocalizationProvider>
  );
};

export default FormikDatePickerField;


