import React, { useState } from 'react';
import FormikField from '../visitScheudle/FormikTextField';
import { Box, Grid, Typography, Divider, FormControl, IconButton, Button, styled } from '@mui/material';
import { Formik} from 'formik';
import { Field, Form } from 'formik';

import * as Yup from 'yup';
import CustomButton from '../../components/@extended/CustomButton';
import { useGetAllConfigurationsQuery, useGetLibraryFormQuery, useUpdateConfigurationOptionMutation, useUploadConfigurationFileMutation } from '../../store/slices/apiSlice';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const validationSchema = Yup.object().shape({
  optionKey: Yup.string()
    .required('Key is required')
    .trim()
    .matches(/^\S+$/, 'Type cannot contain whitespace'),
  option: Yup.string().required('Option is required')
          .trim(),
  
});

function EditConfigurationOption(props) {
  const { toggleDrawer, anchor, updateOption, setupdateOption } = props;

  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();


  const [updateConfigurationOption] = useUpdateConfigurationOptionMutation();
  const [uploadConfigurationFile, { isLoading, isError }] = useUploadConfigurationFileMutation();

  const {data:ConfigurationList} = useGetAllConfigurationsQuery();

  
  const ConfigurationOptionObject = ConfigurationList.find(row=>row.id === updateOption.configurationTypeId)
  const initialValues = {
    configName : ConfigurationOptionObject.label,
    configKey : ConfigurationOptionObject.key,
    option: updateOption.label,
    optionKey: updateOption.key,
  };
  const { data: dynamicForm } = useGetLibraryFormQuery(`form-library/library/bloqcube/${updateOption.key}`);
  console.log(dynamicForm);

  const dispatch = useDispatch();

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  const handleCancel = () => {
    toggleDrawer(anchor, false);
    setupdateOption(null);
  };

  const handleUpdate = async (values) => {
    
    const result = await updateConfigurationOption({
      optionId : updateOption.id,
      payload : {
        label: values.option,
        key: values.optionKey,
      }
    });
    if (!result?.error) {
      if(file){
        const formData = new FormData();
        formData.append(
          'data',
          JSON.stringify({
            sponsorId: 'bloqcube',
            documentKey: values.optionKey,
            documentLabel: values.option,
          })
        );
        formData.append('document', file);
        const result2 = await uploadConfigurationFile(formData);
        if (result2.error) {
          openMessageNotification({
            message: result2?.error?.data?.message,
            type: 'error',
          }); 
          return;
        }
      }
      

      openMessageNotification({
        message: 'Configuration Option Updated successfully',
        type: 'success',
      });
      toggleDrawer(anchor, false);
    } else {

      if(file){
        const formData = new FormData();
        formData.append(
          'data',
          JSON.stringify({
            sponsorId: 'bloqcube',
            documentKey: values.optionKey,
            documentLabel: values.option,
          })
        );
        formData.append('document', file);
        const result2 = await uploadConfigurationFile(formData);
        if (result2.error) {
          openMessageNotification({
            message: result2?.error?.data?.message,
            type: 'error',
          }); 
          return;
        }else{
          openMessageNotification({
            message: 'file uploded successfully',
            type: 'success',
          });
          toggleDrawer(anchor, false);
        }
      }else{
        openMessageNotification({
          message: result?.error?.data?.message,
          type: 'error',
        });
      }
    }
  };


  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      dispatch(
        openMessage({
          messageSeverity: 'warning',
          message: 'Please select file to upload.',
        })
      );
      return;
    }
    if(file.type != 'application/json'){
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: 'Please select JSON file to upload.',
        })
      );
      return;
    }
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        JSON.parse(e.target.result);
        setFileName(file.name);
        setFile(file);
      } catch (error) {
        event.target.value = null;
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: 'The file is not a valid JSON.',
          })
        );
      }
    };
    reader.readAsText(file);
  };

  return (
    <Grid item sm={12}>
      <Box sx={{ padding: 3 }}>
        <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>{'Edit Configuration Option'}</Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: 3 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleUpdate(values);
          }}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container width={'100%'} gap={2}>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Field component={FormikField} name="configName" type="text" label="Configuration Name" disabled />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Field component={FormikField} name="configKey" type="text" label="Configuration Type" disabled />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Field component={FormikField} name="optionKey" label="optionKey" type="text" disabled />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Field fullWidth component={FormikField} name="option" label="option" type="text" />
                  </FormControl>
                </Grid>
                <Grid item sm={12}  sx={{border:'1px dotted gray' , borderRadius:'5px'}}>
                <Button
                  fullWidth
                  disableRipple
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  sx={{justifyContent:'flex-start' ,padding:'15px 15px'}}
                  disabled={!(values.optionKey && values.option)}
                  startIcon={<CloudUploadIcon />}
                >
                  {fileName?fileName:"Upload JSON file"}
                  <VisuallyHiddenInput type="file"  accept='.json' onChange={handleFileChange}/>
                </Button>  
                </Grid> 
              </Grid>
              <Grid container width={'100%'} justifyContent="flex-end">
                <Grid item sm={12}>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: 4, columnGap: 2 }}>
                    <CustomButton size="small" color="primary" variant="outlined" onClick={handleCancel}>
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="small" variant="contained" color="primary">
                      Update
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Grid>
  );
}

export default EditConfigurationOption;
