import { Box, Divider, Step, StepButton, StepLabel, Stepper, useTheme, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import AccSetup from './AccSetup';
import VendorCost from './VendorCost';
import ProcedureCost from './ProcedureCost';
import SiteBudgetForecast from './SiteBudgetForecast';
import CustomButton from '../../../components/@extended/CustomButton';
import RightArrow from '../../../components/icons/RightArrow';

const steps = ['Vendor Onboarding', 'Visit Configuration', 'Procedure Costs', 'Forecast Budget'];
const SiteBudgetForm = ({ selectedSite, setSelectedSite }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const handleTabChange = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            position: 'relative',
            flex: 'grow',
            display: 'flex',
            height: 70,
          }}
        >
          <Button
            style={{ position: 'absolute', top: 12 }}
            onClick={() => setSelectedSite(null)}
            // startIcon={<RightArrow leftArrow />}
          >
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }} color={theme.palette.primary.light}>
              <u>Back</u>
            </Typography>
          </Button>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }} ml={'auto'} mr={'auto'}>
              Site Name : <span style={{ fontWeight: 500 }}> {selectedSite.orgName}</span>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            nonLinear
            sx={{
              '& .MuiStepConnector-root': {
                top: '9px',
                left: 'calc(-50% + 12px)',
                right: 'calc(50% + 12px)',
              },
              '& .MuiStepConnector-line': {
                borderRadius: 1,
                borderWidth: 6,
              },
              '& .MuiStepLabel-iconContainer': {
                height: '0.7rem',
                width: '0.7rem',
                border: `7px solid ${theme.palette.grey[500]}`,
                borderRadius: '50%',
              },
              '& .Mui-active': {
                '& .MuiStepConnector-line': {
                  borderRadius: 1,
                  borderColor: theme.palette.primary.light,
                  borderWidth: 6,
                }
              },
              '& .Mui-completed': {
                '& .MuiStepConnector-line': {
                  borderRadius: 1,
                  borderColor: theme.palette.primary.light,
                  borderWidth: 6,
                },
                '& .MuiStepLabel-iconContainer': {
                  backgroundColor: theme.palette.common.white,
                  border: '7px solid green',
                },
              },
            }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)} icon={<></>}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Divider />
        </Box>
        <Box sx={{ pt: 3 }}>
          {activeStep === 0 && (
            <>
              <AccSetup handleTabChange={handleTabChange} selectedSite={selectedSite} />
            </>
          )}
          {activeStep === 1 && (
            <>
              <VendorCost handleTabChange={handleTabChange} selectedSite={selectedSite} />
            </>
          )}
          {activeStep === 2 && (
            <>
              <ProcedureCost handleTabChange={handleTabChange} selectedSite={selectedSite} />
            </>
          )}
          {activeStep === 3 && (
            <>
              <SiteBudgetForecast
                handleTabChange={handleTabChange}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SiteBudgetForm;
