import React from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormikField from '../../visitScheudle/FormikTextField';
import CustomButton from '../../../components/@extended/CustomButton';
import { saveStudyBasicDetails } from '../../../store/slices/financeSlice';

const StudyBasicDetailsSection = () => {
    const theme = useTheme();
    const navigate = useNavigate()
  const dispatch = useDispatch();
    const { sponsorId, studyId } = useParams();
    const sponsor = useSelector((state) => state.finance.sponsors.find((_sp) => String(_sp.id) === sponsorId));
  const validationSchema = Yup.object().shape({
    protocolNumber: Yup.string()
      .required('Protocol Number is required')
      .matches(/^[A-Za-z0-9-]+$/, 'Protocol Number can only contain alphanumeric characters and dashes'),
    protocolVersion: Yup.string()
      .required('Protocol Version is required')
      .matches(/^[A-Za-z0-9.]+$/, 'Protocol Version can only contain alphanumeric characters and periods'),
    totalSubjectNumber: Yup.number()
      .required('Total Subject Number is required')
      .positive('Total Subject Number must be a positive number')
      .integer('Total Subject Number must be an integer'),
    studyTitle: Yup.string().required('Study Title is required'),
  });

  const initialValues = {
    protocolNumber: '',
    protocolVersion: '',
    totalSubjectNumber: '',
    studyTitle: '',
  };

  const handleFormSubmit = (values) => {
      dispatch(saveStudyBasicDetails({ sponsorId, studyDetails: values }));
      navigate(window.location.pathname + '/'+String(sponsor.studies.length + 1));
  };
  return (
    <Grid container rowGap={2} sx={{ mt: 2 }}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
        {({ handleSubmit, handleChange, handleBlur, resetForm, touched, errors }) => (
          <Grid item xs={12}>
            <Form onSubmit={handleSubmit}>
              <Card sx={{ mb: 3, px: 1 }}>
                <CardHeader
                  title="Study Details"
                  titleTypographyProps={{ variant: 'h6' }}
                  action={
                    <Box>
                      <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => { }}>
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                          Upload Details
                        </Typography>
                      </CustomButton>
                      <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
                          Note: Upload file with CSV/Excel format.
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.protocolNumber && Boolean(errors.protocolNumber)}>
                        <Field component={FormikField} name="protocolNumber" label="Protocol Number" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.protocolVersion && Boolean(errors.protocolVersion)}>
                        <Field component={FormikField} name="protocolVersion" label="Protocol Version" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.totalSubjectNumber && Boolean(errors.totalSubjectNumber)}>
                        <Field
                          component={FormikField}
                          name="totalSubjectNumber"
                          label="Total Subject Number"
                          type="text"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth error={touched.studyTitle && Boolean(errors.studyTitle)}>
                        <Field component={FormikField} name="studyTitle" label="Study Title" type="text" />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CustomButton
                        variant="contined"
                        //   onClick={}
                        type="save"
                        color="primary"
                        sx={{ padding: '8px 30px', textTransform: 'none' }}
                      >
                        Create Study
                      </CustomButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default StudyBasicDetailsSection;
