import { Box, Button, Card, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RightArrow from '../../../components/icons/RightArrow';
import { useTheme } from '@emotion/react';
import StyledTabs from '../../../components/@extended/StyledTabs';
import AssignmentDetailsForm from './AssignmentDetailsForm';
import AssignmentBasicDetailsSection from './AssignmentBasicDetailsSection';
import AssignmentVendorSetup from './AssignmentVendorSetup';
import AssignmentSummary from './AssignmentSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const AssignmentSetup = () => {
  const { sponsorId, assignmentId } = useParams();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (assignmentId) {
      setTabValue(1);
    }
  }, [assignmentId]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
    return (
      <>
        <Box
          sx={{
            position: 'relative',
            flex: 'grow',
            display: 'flex',
            height: 70,
          }}
        >
          <Button
            style={{ position: 'absolute', top: 12 }}
            onClick={() => navigate(-2)}
            startIcon={<RightArrow leftArrow />}
          >
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }} color={theme.palette.primary.light}>
              Organization Details
            </Typography>
          </Button>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="initial" ml={'auto'} mr={'auto'}>
              PFAS Assignment Setup
            </Typography>
          </Box>
        </Box>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          sx={{
            top: 2,
          }}
        >
          <Tab label="1. Assignment Details" {...a11yProps(0)} />
          <Tab label="2. Vendor Details" {...a11yProps(1)} />
          <Tab label="3. Summary" {...a11yProps(2)} />
        </StyledTabs>
        <TabPanel value={tabValue} index={0}>
          <AssignmentBasicDetailsSection handleTabChange={handleTabChange} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card sx={{ padding: 3, mt: 2 }}>
            <AssignmentVendorSetup handleTabChange={handleTabChange} />
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box sx={{ mt: 2 }}>
            <AssignmentSummary handleTabChange={handleTabChange} />
          </Box>
        </TabPanel>
      </>
    );
};

export default AssignmentSetup;
