import { Box, IconButton, Paper, TextField } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import EditNoteIcon from '@mui/icons-material/EditNote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CustomButton from '../../../components/@extended/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudyDetails, selectStudySiteDetails, updateVendorCost } from '../../../store/slices/financeSlice';
import { useParams } from 'react-router-dom';

const VendorCost = ({ selectedSite, handleTabChange }) => {
  const theme = useTheme();
  const [updatedCell, setUpdatedCell] = useState([]);
  const refs = useRef([]);
  const { sponsorId, studyId } = useParams();
  const studyDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));
  const siteDetails = useSelector((state) => selectStudySiteDetails(state, sponsorId, studyId, selectedSite?.id));
  const [columns, setColumns] = useState([
    {
      key: 'col1',
      label: '',
      minWidth: 150,
      align: 'left',
    },
    {
      key: 'View_Visit_Details',
      label: 'Visit-1 (Screening)',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_2',
      label: 'Visit-2',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_3',
      label: 'Visit-3',
      minWidth: 100,
      align: 'center',
    },
  ]);

  const dispatch = useDispatch();

  const subjectCount = studyDetails?.totalSubjectNumber;
  const updateEditedCell = (rowIndex, colIndex) => {
    setUpdatedCell([rowIndex, colIndex]);
  };
  const updateCellValue = (rowKey, colKey, rowIndex, colIndex) => {
    const textFieldValue = refs.current[rowIndex + ',' + colIndex].value;
    dispatch(
      updateVendorCost({
        sponsorId,
        studyId,
        siteId: selectedSite.id,
        column: colKey,
        vendorKey: rowKey,
        value: textFieldValue,
      })) 
    setUpdatedCell([]);
  };

  const calculateCategoryCost = (cat, val, calculateTotal) => {
    const catRec = siteDetails?.vendors?.find((_obj) => _obj?.vendor == cat);
    if (catRec) {
      let subjectLevelCost = Number(catRec.total) * val;
      if (calculateTotal) {
        subjectLevelCost = subjectLevelCost * subjectCount;
      }
      return subjectLevelCost;
    }
    return 0;
  };
  return (
    <Box>
      <Box sx={{ pb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ alignSelf: 'center' }}>
          Vendor Cost
        </Typography>
        <Box>
          <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              Upload Details
            </Typography>
          </CustomButton>
          <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
              Note: Upload file with CSV/Excel format.
          </Typography>
        </Box>
      </Box>
      <Paper sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            backgroundColor: theme.palette.primary.light,
          }}
        />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns &&
                  columns.map((col) => (
                    <CustomTableHeadCell
                      key={col.key}
                      align={col.align}
                      sx={{
                        minWidth: col?.minWidth,
                        maxWidth: col?.maxWidth,
                      }}
                    >
                      <Tooltip title={col?.lableAbrivation} arrow placement="top" enterDelay={1400}>
                        {col.label}
                      </Tooltip>
                    </CustomTableHeadCell>
                  ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              <Box sx={{ padding: 1, color: 'grey' }}>
                <Typography>Vendors/Categories</Typography>
              </Box>
              {siteDetails?.vendors?.length === 0 ? (
                <TableRow>
                  <TableCell align={'center'} colSpan={columns?.length}>
                    <Typography variant="subtitle1" color="initial">
                      No Available Data.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                columns &&
                siteDetails?.vendors?.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index} hover>
                      {columns?.map((column, colIndex) => {
                        const value = row[column.key];
                        return (
                          <TableCell key={`${column.key}${index}`} align={column.align}>
                            {(() => {
                              if (value && colIndex == 0) {
                                return <Typography>{value}</Typography>;
                              } else if (updatedCell[0] == index && updatedCell[1] == colIndex) {
                                return (
                                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <TextField
                                      sx={{ width: 100 }}
                                      size="small"
                                      defaultValue={value}
                                      inputRef={(el) => (refs.current[index + ',' + colIndex] = el)}
                                    />
                                    <IconButton
                                      onClick={() => updateCellValue(row.key, column.key, index, colIndex)}
                                      size="small"
                                    >
                                      <CheckIcon sx={{ color: 'green' }} />
                                    </IconButton>
                                    <IconButton onClick={() => setUpdatedCell([])} size="small">
                                      <CloseIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                  </Box>
                                );
                              } else if (value) {
                                return (
                                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography>{value}</Typography>
                                    <IconButton onClick={() => updateEditedCell(index, colIndex)} size="small">
                                      <EditIcon sx={{ color: theme.palette.grey[500] }} />
                                    </IconButton>
                                    <Tooltip
                                      title={
                                        <Box>
                                          <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Typography>No of Subject:</Typography>
                                            <Typography>{subjectCount}</Typography>
                                          </Box>
                                          <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Typography>Subject level Cost:</Typography>
                                            <Typography>$ {calculateCategoryCost(row.key, value, false)}</Typography>
                                          </Box>
                                          <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Typography>Total Cost:</Typography>
                                            <Typography>$ {calculateCategoryCost(row.key, value, true)}</Typography>
                                          </Box>
                                        </Box>
                                      }
                                    >
                                      <InfoOutlinedIcon sx={{ color: '#598CD4' }} />
                                    </Tooltip>
                                  </Box>
                                );
                              } else {
                                return (
                                  <IconButton onClick={() => updateEditedCell(index, colIndex)} size="small">
                                    <EditNoteIcon sx={{ color: theme.palette.grey[400] }} />
                                  </IconButton>
                                );
                              }
                            })()}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}>
        <CustomButton onClick={()=>handleTabChange(2)}>Save & Continue</CustomButton>
      </Box>
    </Box>
  );
};

export default VendorCost;
