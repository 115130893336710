import { Box, Button, Stack, Tabs, Typography } from "@mui/material";
import TrialInfo from "./TrialInfo";
import LogInfo from "./LogInfo";
import TaskList from "./TaskList";
import Tab from '@mui/material/Tab';
import { useEffect, useState } from "react";
import RightArrow from "../../components/icons/RightArrow";
import DeligationConfiguration from "./DeligationConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectCurrentUser } from "../../store/slices/authSlice";
import { getUserRole } from "../util";
import { useGetOngoingTrialsQuery } from "../../store/slices/apiSlice";
import { openBackdropLoading } from "../../store/slices/applicationSlice";
import { useTheme } from "@emotion/react";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DOA = (props) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [selectedUser, setSelectedUser] = useState();
    const [allTasks, setAllTasks] = useState([]);
    const [trialId, setTrialId] = useState();
    const [siteId, setSiteId] = useState();
    const [siteTrialId, setSiteTrialId] = useState()
    const [openConfigureTasks, setOpenConfigureTasks] = useState(false)
    const [openTaskList, setOpenTaskList] = useState(false);
    const loggedInUser = useSelector((state) => state.userDetails?.loggedInUser);
    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const {data, error, isLoading} = useGetOngoingTrialsQuery(sponsorId);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = location;
    const { userId } = useParams();
    const user = useSelector(selectCurrentUser)
    const userRole = getUserRole(user)

    const handleChange = (event, newValue) => {
        if(newValue==1)navigate('/doaLogs/myTasks');
        else if(newValue==2)navigate('/doaLogs/completedTasks');
        else navigate('/doaLogs');
    };
    const handleOpenDoaConfiguration = () => {
        // setOpenConfigureTasks(true);
        navigate('/doaLogs/configureTasks');
    }
    useEffect(() => {
        if (pathname == "/doaLogs/myTasks") {
            setValue(1);
            setOpenConfigureTasks(false);
            setOpenTaskList(false);
        } else if (pathname == "/doaLogs/completedTasks") {
            setValue(2);
            setOpenConfigureTasks(false);
            setOpenTaskList(false);
        } else if (pathname === "/doaLogs/configureTasks") {
            setOpenConfigureTasks(true);
        } else if (pathname === "/doaLogs"){
            setValue(0);
            setOpenConfigureTasks(false);
            setOpenTaskList(false);
        }
        if (userId) {
            setSelectedUser({ id: userId});
            setOpenTaskList(true);
        }
    }, [pathname, userId ,location]);

    // useEffect(() => {
    //   if (loggedInUser && value === 1) {
    //       setSelectedUser({ id: String(loggedInUser?.id), name: loggedInUser?.firstName + ' ' + loggedInUser?.lastName });
    //       setOpenTaskList(false);
    //   }
    // }, [value, loggedInUser]);

    const handleTaskClick = (userId) => {
        navigate(`/doaLogs/${userId}`);
    }
    useEffect(()=>{
        navigate('/doaLogs')
    },[trialId,siteId,siteTrialId])
    
    return (
        <>
        {data && data.length>0?
        <Box>
            <TrialInfo setAllTasks={setAllTasks} setTrialId={setTrialId} setSiteId={setSiteId} setSiteTrialId={setSiteTrialId}/>
            {(!openTaskList && !openConfigureTasks )  ? <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 2 }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Log" sx={{ fontWeight: 600 }} {...a11yProps(0)} />
                        <Tab label="My Tasks" sx={{ fontWeight: 600 }} {...a11yProps(1)} />
                        {userRole==="PI" ? <Tab label="Completed Tasks" sx={{ fontWeight: 600 }} {...a11yProps(2)} /> : ""}
                    </Tabs>
                </Box>  
                {
                    value == 0 && siteTrialId &&
                    <Box><LogInfo setSelectedUser={handleTaskClick} allDOATasks={allTasks} trialId={trialId} siteId={siteId} siteTrialId={siteTrialId} openDoaConfiguration={handleOpenDoaConfiguration} /></Box>
                }
                {
                    (value == 1 || value == 2) &&
                    <Box><TaskList trialId={trialId} siteId={siteId} siteTrialId={siteTrialId} value={value}/></Box>
                }
            </Box> : openConfigureTasks ? <>
                    <DeligationConfiguration trialId={trialId} siteId={siteId} onBackClick={() => navigate('/doaLogs')} allTasks={allTasks} />
                </> : 
                <Box>
                    <TaskList trialId={trialId} siteId={siteId} siteTrialId={siteTrialId} selectedUser={selectedUser} setSelectedUser={setSelectedUser} setOpenTaskList={setOpenTaskList} value={value}/>
                </Box>
            }
        </Box>:
        <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3}}>
            <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>There are currently no ongoing trials at this site.</Typography>
            <Typography variant='body2' sx={{color:theme.palette.grey[500]}}><em> Note : Please check back later for updates or contact the administration for further information.</em></Typography>
        </Stack>
        }
        </>
    )
}

export default DOA;