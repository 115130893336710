import { Box, CircularProgress, Button, Tooltip } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useDashboradDataQuery } from '../../../store/slices/apiSlice';
import { SvgSpinnersBlocksShuffle2 } from '../../../components/icons/ReportingDashboardLoadingIcon';
import AnimatedBloqcubeLogo from '../../../components/Logo/AnimatedBloqcubeLogo';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const chartColourConfig = [
  { 
    borderColor: 'rgb(53, 162, 235)', 
    backgroundColor: 'rgba(53, 162, 235, 0.5)' 
  },
  {
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
  {
    borderColor: 'rgb(60, 179, 113)',
    backgroundColor: 'rgba(60, 179, 113, 0.5)',
  },
  {
    borderColor: 'rgb(106, 90, 205)',
    backgroundColor: 'rgba(106, 90, 205, 0.5)',
  },
  {
    borderColor: 'rgb(255, 165, 0)',
    backgroundColor: 'rgba(255, 165, 0, 0.5)',
  },
  {
    borderColor: 'rgb(255,255,0)',
    backgroundColor: 'rgba(255,255,0, 0.5)',
  },
];

function formatDate(date) {
  return moment(date).format('MM/DD/YYYY');
}

const SubjectEnrollmentTrend = (props) => {
  const { showMore = false } = props;

  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);
  const { data: dashboardData, isLoading } = useDashboradDataQuery({
    data: { preference: props.preference, sponsorId: sponsorId, siteId: props.site, trialId: props.trial },
  });

  const _data = useMemo(() => {
    if (dashboardData) {
      const datasets = [];

      // Initialize an array to hold unique dates
      const uniqueDates = [];

      // Iterate over each site's enrollment data
      dashboardData[props.preference]?.forEach((siteData) => {
        // Extract site name and enrollment data for the current site
        const siteName = Object.keys(siteData)[0];
        const enrollmentData = siteData[siteName];
        let enrollmentDataToShow = Object.entries(enrollmentData);
        // Iterate through the enrollment data and extract dates and counts
        if (!showMore && enrollmentDataToShow.length > 10) {
          enrollmentDataToShow = Object.entries(enrollmentData).slice(0, 10)
        }

        enrollmentDataToShow.forEach(([date, count]) => {
          const formattedDate = moment(new Date(date)).format('MM/DD/YYYY');

          if (!uniqueDates.includes(formattedDate)) {
            uniqueDates.push(formattedDate);
          }
        });
      });

      // Sort uniqueDates array in ascending order
      uniqueDates.sort((a, b) => a - b);

      // Iterate over each site's enrollment data again to populate counts for each date
      dashboardData[props.preference]?.forEach((siteData, index) => {
        const siteName = Object.keys(siteData)[0];
        const enrollmentData = siteData[siteName];

        const counts = [];

        // Iterate through uniqueDates and populate counts array
        uniqueDates.forEach((date) => {
          const dateString = formatDate(date);
          counts.push(enrollmentData[dateString] || 0);
        });

        // Create a dataset object for the current site
        const dataset = {
          label: siteName,
          data: counts,
          backgroundColor: chartColourConfig[index].backgroundColor,
          borderColor: chartColourConfig[index].borderColor,
          fill: false,
        };

        datasets.push(dataset);
      });

      // Return the prepared chart data
      return {
        labels: uniqueDates.map(formatDate), // Use uniqueDates as x-axis labels
        datasets: datasets,
      };
    }
    return {
      labels: [''],
      datasets: [''],
    };
  }, [dashboardData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            if (Number.isInteger(value) || value == 0) {
              return value;
            }
          },
        },
      },
    }
  };

  return (
    <Box onMouseDown={(e)=>e.stopPropagation()} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', p: 1 }}>
      {dashboardData && <Line options={options} data={_data} />}
      {isLoading && (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <CircularProgress /> */}
          <Box sx={{ height: 250, width: 250 }}>
            <AnimatedBloqcubeLogo />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubjectEnrollmentTrend;
