import { useSelector } from "react-redux";
import { useMemo } from "react";
import { selectCurrentUser } from "../../store/slices/authSlice";
import { getUserRole } from "../util";

export const AvailableAppointmentTimingsColumns = () => {

  const columns = useMemo(() => {
    return [
        {
          id: "protocolNumber",
          label: `Protocol Number`,
          style: {
            minWidth: 150,
            textAlign:'justify'
          },
          align: "left",
        },
        {
          id: "visit",
          label: "Visit",
          style: {
            minWidth: 80,
          },
          align: "center",
        },
        {
          id: "date",
          label: "Date",
          style: {
            minWidth: 80,
          },
          align: "center",
        },
        {
          id: "startTime",
          label: "Start Time",
          style: {
            minWidth: 80,
          },
          align: "center",
        },
        {
            id: "endTime",
            label: "End Time",
            style: {
              minWidth: 80,
            },
            align: "center",
        },
        {
            id: "capacity",
            label: "Capacity",
            style: {
              minWidth: 80,
            },
            align: "center",
        },
        {
          id: "status",
          label: "Status",
          style: {
            minWidth: 80,
          },
          align: "center",
        },
        {
            id: "action",
            label: "Actions",
            style: {
              minWidth: 90,
            },
            align: "center",
        },
      ];
    
  }, []);

  return columns;
};