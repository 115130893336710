import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
  } from "@mui/material";
  import CustomButton from "../@extended/CustomButton";
  import * as Yup from "yup";
  
  import { useFormik } from "formik";
  import { useDispatch } from "react-redux";
  import { openMessage } from "../../store/slices/showMessageSlice";
  import { useState } from "react";
  import { useResponsive } from "../../hooks/ResponsiveProvider";
  import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useResetPasswordMutation } from "../../store/slices/apiSlice";


const ResetPasswordForm=({handleCloseResetForm, handleClose, tempPasswordLabel})=>{
    const theme = useTheme();
    const dispatch = useDispatch();
    
    const { isSmallScreen } = useResponsive();
    const [tempShowPassword, setTempShowPassword] = useState(false);
    const [newShowPassword, setNewShowPassword] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);

    const [resetPassword, { error }] = useResetPasswordMutation();
    
    const docSchema = Yup.object().shape({
      tempPassword: Yup.string().required(`${tempPasswordLabel} is required`),
      newPassword: Yup.string()
        .required('New password is required')
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .min(8, 'Password must be 8 to 16 characters long')
        .max(16, 'Password must be 8 to 16 characters long')
        .matches(/(?=.*[0-9])/, 'Password requires a number')
        .matches(/(?=.*[A-Z])/, 'Password requires a uppercase letter')
        .matches(/(?=.*[a-z])/, 'Password requires a lowercase letter')
        .matches(/(?=.*\W)/, 'Password requires a special character')
        .test('not-same-as-old', `New password must be different from the ${tempPasswordLabel}`, function (value) {
          return value !== this.parent.tempPassword;
        }),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
    });

    const formik = useFormik({
        initialValues: {
            tempPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: docSchema,
        onSubmit: async (values) => {
        const result = await resetPassword({
          payload:{
            oldPassword: values.tempPassword,
            newPassword: values.newPassword,
          }
        });
        if (!result?.error) {
          openMessageNotification({
            message: "Password Reset Successfully",
            type: "success",
          }); 
          handleClose();
        } else {
            if(result?.error?.status === 500){
              openMessageNotification({
                message: "Failed to Reset Password",
                type: "error",
              });
            }
            else{
              openMessageNotification({
                message: result?.error?.data?.error_description,
                type: "error",
              });
            }
        }
        },
    });

    const handleClickTempShowPassword = () => {
      setTempShowPassword(!tempShowPassword)
  };

    const handleClickNewShowPassword = () => {
        setNewShowPassword(!newShowPassword)
    };

    const handleClickConfirmShowPassword = () => {
        setConfirmShowPassword(!confirmShowPassword)
    };

    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        formik.handleSubmit();
    };

    const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };


    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: isSmallScreen ? 4 : 0 }}>
          <Typography variant="h5" color="initial">
            Reset Password
          </Typography>
        </Box>

        <form onSubmit={handleFormSubmit}>
          <Grid container rowSpacing={0}>
            <Grid item xs={12} px={3}>
              <Typography variant="body1" color="#5d5d5d" pb={1}>
                {tempPasswordLabel}
              </Typography>
              <TextField
                size="small"
                sx={{ height: '65px' }}
                name="tempPassword"
                fullWidth
                placeholder={tempPasswordLabel}
                type={tempShowPassword ? 'text' : 'password'}//"password"
                onChange={formik.handleChange}
                value={formik.values.tempPassword}
                onBlur={formik.handleBlur}
                helperText={formik.touched.tempPassword && formik.errors.tempPassword}
                error={formik.touched.tempPassword && Boolean(formik.errors.tempPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickTempShowPassword}
                        edge="end"
                      >
                        {tempShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} px={3}>
              <Typography variant="body1" color="#5d5d5d" pb={1}>
                New Password
              </Typography>
              <TextField
                size="small"
                sx={{ height: '65px' }}
                name="newPassword"
                fullWidth
                placeholder="New Password"
                type={newShowPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                onBlur={formik.handleBlur}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickNewShowPassword}
                        edge="end"
                      >
                        {newShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} px={3}>
              <Typography variant="body1" color="#5d5d5d" pb={1}>
                Confirm Password
              </Typography>
              <TextField
                size="small"
                sx={{ height: '65px' }}
                name="confirmPassword"
                fullWidth
                placeholder="Confirm Password"
                type={confirmShowPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        edge="end"
                      >
                        {confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right', px: 3, columnGap: 2, pt: 2 }}>
              <Button
                sx={{ width: 100, color: theme.palette.primary.light }}
                onClick={() => {
                  handleCloseResetForm();
                }}
                variant="text"
              >
                Cancel
              </Button>

              <CustomButton
                type="submit"
              >
                Submit
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </>
    );
}

export default ResetPasswordForm;