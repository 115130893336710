import * as React from 'react';

import { bloqcibeApi, useGetTrialDocumentsQuery } from '../../store/slices/apiSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Breadcrumbs, Button, FormControl, FormHelperText, Grid, IconButton, Menu, MenuItem, OutlinedInput, Select, Stack, styled, Tab, Typography } from '@mui/material';
import RightArrow from "../../components/icons/RightArrow";
import { useTheme } from '@emotion/react';
import CustomButton from '../../components/@extended/CustomButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DocumentUploadWithCategory from '../../components/common/DocumentUploadWithCategory';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import StyledTabs from '../../components/@extended/StyledTabs';

import folder from '../../assets/images/Icons/folder.png'
import file from '../../assets/images/Icons/google-docs.png'


const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
});

export default function ETMFsDocument() {
  const [documents, setDocuments] = React.useState('');
  const [subDocuments, setSubDocuments] = React.useState('');
  const [uploadDocModal, setUploadDocModal] = React.useState(false);
  let { trialId , siteId } = useParams();
  const navigate = useNavigate();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const data = { siteId: parseInt(siteId) };
  const { data: docList } = useGetTrialDocumentsQuery({
    trialId,
    sponsorId,
    data,
  });
  const [getDownloadTrialDoc] =
  bloqcibeApi.endpoints.getDownloadTrialDoc.useLazyQuery();
  const [documentslist, setDocumentslist] = React.useState({});
  const [selectedDocument , setSelectedDocument] = React.useState({});
  const theme = useTheme();

  React.useEffect(() => {
    if (docList) {
      const tempDocumentslist = {};
      docList.forEach((doc) => {
        if (!tempDocumentslist[doc.category]) {
          tempDocumentslist[doc.category] = { category: [], subCategory: {} };
        }
        if (doc.subCategory) {
          if (!tempDocumentslist[doc.category].subCategory[doc.subCategory]) {
            tempDocumentslist[doc.category].subCategory[doc.subCategory] = [];
          }
          tempDocumentslist[doc.category].subCategory[doc.subCategory].push(doc);
        }else{
          tempDocumentslist[doc.category].category.push(doc);
        }
      });
      setDocumentslist(tempDocumentslist);
    }
  }, [docList]);
  

  const handleClick = (docs) => {
    // const queryString = new URLSearchParams({
    //   category: docs,
    //   subCategory : ''
    // }).toString();
    // navigate(`?${queryString}`)

    setDocuments(docs);
    setSubDocuments('');
  };

  const handleClickSubDoc = (docs) => {
    // const queryString = new URLSearchParams({
    //   category: documents,
    //   subCategory : docs
    // }).toString();
    // navigate(`?${queryString}`)
    setSubDocuments(docs);
  };

  const handleOpenDocumentModal = () => {
    setUploadDocModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickfile = (event , doc) => {
    setSelectedDocument(doc)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedDocument({})
    setAnchorEl(null);
  };
  const extractFilenameFromKey = (key) => {
    const parts = key.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  };
  const downloadDoc = async () => {
    const s3Key = selectedDocument.docS3Key
    await getDownloadTrialDoc({
      trialId,
      s3Key,
      fileName: extractFilenameFromKey(s3Key),
      sponsorId: sponsorId,
    });
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = React.useState(0);
  const location = useLocation();


  const [getTrialDetails, { data: trialDetails }] =
  bloqcibeApi.endpoints.getTrialDetails.useLazyQuery();
  const [getTrialSiteDetails, { data: trialSiteData }] =
  bloqcibeApi.endpoints.getTrialSiteDetails.useLazyQuery();

  const handleTabChange = (event ,newValue) => {
    // const queryString = new URLSearchParams({
    //   category: '',
    //   subCategory : ''
    // }).toString();

    setDocuments('')
    setSubDocuments('')
    if(newValue===0){
      navigate(`/eTMFs-eISF/doc/${trialId}`)
    }else if(newValue===1){
      if(trialSiteData){
        if(trialSiteData.length > 0 ){
          navigate(`/eTMFs-eISF/${trialId}/doc/${trialSiteData[0].site.id}`)
        }else{
          navigate(`/eTMFs-eISF/${trialId}/doc/${'noSite'}`)
        }
      }
    }
  };

  // React.useEffect(()=>{
  //   const queryString = location.search;
  //   const params = new URLSearchParams(queryString); 
  //   const doc = params.get('category');
  //   const SubDoc = params.get('subCategory');

  //   setDocuments(doc);
  //   setSubDocuments(SubDoc);
  //   console.log('location',SubDoc);
  // },[location.search])

  React.useEffect(() => {
    (async () => {
      if (trialId) {
        await getTrialDetails({
          id: trialId,
          sponsorId: sponsorId,
        });
        await getTrialSiteDetails({ trialId, sponsorId });
      }
    })()
  }, []);

  React.useEffect(() => {
    if(siteId){
      const selectedSite = trialSiteData?.find(item => 
        item.site.id == siteId
      );
      setsiteName(selectedSite?.site)
      setTabValue(1)
    }else{
      setTabValue(0)
    }
  }, [trialId , siteId , trialSiteData]);

  const [siteName, setsiteName] = React.useState('');
  const handleSite = (e) =>  {
    clearField()
    navigate(`/eTMFs-eISF/${trialId}/doc/${e.target.value.id}`)
  }

  // console.log('trialDetails',trialDetails);
  // console.log('trialSiteData',trialSiteData);
  const clearField = () => {
      setDocuments('')
      setSubDocuments('')
  }

  return (
    <>
      <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
        <Button type="text" onClick={() => navigate('/eTMFs-eISF')} startIcon={<RightArrow leftArrow />}>
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
            Back
          </Typography>
        </Button>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{
              top: 0
          }}
          >
          <Tab label="1. eTMF Documents" {...a11yProps(0)} />
          <Tab label="2. eISF Documents" {...a11yProps(1)}/>
        </StyledTabs>
        <Box sx={{p:1 , pr:0}}>
          <CustomButton disabled={siteId==='noSite'} size="small" onClick={handleOpenDocumentModal} startIcon={<CloudUploadIcon />}>
            Upload Documents
          </CustomButton>
        </Box>
      </Stack>

      <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} height={'50px'} sx={{my:2}}>
        <Box sx={{display:'flex' , alignItems:'center'}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Button onClick={()=>{handleClick('')}}>
              <HomeIcon/>
              <Typography variant="subtitle1" sx={{ textTransform: 'none',ml:1 }}>
              {trialDetails?.trialData?.protocolNumber}
              </Typography>
            </Button>
            {documents && subDocuments &&
            <Button onClick={()=>{handleClick(documents)}}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              {documents}
              </Typography>
            </Button>
            }
            {
              !subDocuments && documents &&
              <Typography variant="subtitle1" sx={{ textTransform: 'none' ,padding : '6px 8px'}}>
              {documents}
              </Typography>
            }
            {subDocuments && 
              <Typography variant="subtitle1" sx={{ textTransform: 'none' ,padding : '6px 8px' }}>
              {subDocuments}
              </Typography>
            }
          </Breadcrumbs>
        </Box>
        {siteId && siteId!='noSite'?
          <FormControl
            sx={{ width: '201px' }}
          >
            <Select
              name={"sites"}
              value={siteName}
              onChange={handleSite}
              placeholder="sites"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              input={<OutlinedInput size="small" />}
            >
              {trialSiteData &&  trialSiteData.map((option) => {
                return (
                  <MenuItem key={option?.site?.orgname} value={option?.site}>
                    {option?.site?.orgname}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>:
          <></>
        }
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ maxHeight: "80vh" }}>
        <Grid container spacing={2} sx={{ overflow: 'auto' }}>

          {
            siteId!='noSite' && documentslist && !subDocuments && documents && Object.keys(documentslist[documents].subCategory).length > 0 && 
            Object.keys(documentslist[documents].subCategory).map((doc, index) => (
              <Grid item xs={3} sm={2} key={doc.docId || index}>
                <CustomWidthTooltip disableInteractive title={doc}>
                <Button sx={{ width: '100%', color: 'black' ,overflow:'hidden' ,textOverflow: 'ellipsis' ,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}} onClick={() => handleClickSubDoc(doc)}>
                  <img src={folder} alt="Folder" style={{ height: '100px'}} />
                  <Typography textAlign={'center'} >{doc}</Typography>
                </Button>
                </CustomWidthTooltip>
              </Grid>
            ))
          }

          {documentslist && !documents && siteId!='noSite' && 
            Object.keys(documentslist).map((doc) => (
              <Grid item xs={3} sm={2} key={doc}>
                <CustomWidthTooltip disableInteractive title={doc}>
                <Button sx={{ width: '100%', color: 'black' ,overflow:'hidden' ,textOverflow: 'ellipsis' ,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}} onClick={() => handleClick(doc)}>
                  <img src={folder} alt="Folder" style={{ height: '100px'}} />
                  <Typography textAlign={'center'} >{doc}</Typography>
                </Button>
                </CustomWidthTooltip>
              </Grid>
            ))
          }

          {documentslist && documents && !subDocuments && siteId!='noSite' && 
            documentslist[documents].category.map((doc, index) => (
              <Grid item xs={3} sm={2} key={doc.docId || index} >
                <CustomWidthTooltip disableInteractive title={doc?.docName}>
                <Button sx={{ width: '100%', color: 'black' ,overflow:'hidden' ,textOverflow: 'ellipsis' ,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}} onClick={(e)=>{handleClickfile(e,doc)}}> 
                  <img src={file} alt="file" style={{ height: '100px'}} />
                  <Typography textAlign={'center'} >{doc?.docName}</Typography>
                </Button>
                </CustomWidthTooltip>
              </Grid>
            )) 
          }

          {documentslist && documents && subDocuments && siteId!='noSite' && 
            documentslist[documents].subCategory[subDocuments].map((doc, index) => (
              <Grid item xs={3} sm={2} key={doc.docId || index} >
                <CustomWidthTooltip disableInteractive title={doc?.docName}>
                <Button sx={{ width: '100%', color: 'black' ,overflow:'hidden' ,textOverflow: 'ellipsis' ,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}} onClick={(e)=>{handleClickfile(e,doc)}}> 
                  <img src={file} alt="file" style={{ height: '100px'}} />
                  <Typography textAlign={'center'} >{doc?.docName}</Typography>
                </Button>
                </CustomWidthTooltip>
              </Grid>
            )) 
          }
          
          {
            !documents && Object.keys(documentslist).length === 0  && siteId!=='noSite' &&
            <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3 , width:'100%'}}>
                <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>No documents available at the moment.</Typography>
                <Typography variant='body2' sx={{color:theme.palette.grey[500]}}><em> Note : You can upload documents by clicking on 'Upload Documents'.</em></Typography>
            </Stack>
          }
          {
            siteId==='noSite' &&
            <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3 , width:'100%'}}>
                <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>No Sites available at the moment.</Typography>
                <Typography variant='body2' sx={{color:theme.palette.grey[500]}}><em> Note : You can add the site by 'Add Site' in Create Trail.</em></Typography>
            </Stack>
          }
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {/* <MenuItem onClick={handleClose} sx={{gap:2}} disableRipple>
              <EditIcon />
              Rename
            </MenuItem> */}
            <MenuItem onClick={downloadDoc} sx={{gap:2}} disableRipple>
              <DownloadIcon />
              Download
            </MenuItem>
          </Menu>
        </Grid>
      </Stack>
      <DocumentUploadWithCategory
        dialogTitle={`Upload ${siteId?'eISF':'eTMF'} Documents`}
        open={uploadDocModal}
        category={documents}
        subCategory={subDocuments}
        siteId={siteId}
        handleClose={() => setUploadDocModal(false)}
        trialId={trialId}
        clearField={clearField}
      />
    </>
  );
}
