import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { bloqcibeApi, useGetSponsorByIdQuery, useUploadOrganizationLogoMutation } from '../../store/slices/apiSlice';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';
import { useParams } from 'react-router-dom';

const Input = styled('input')({
  display: 'none',
});

function OrganizationLogo({organizationDetails}) {
  const [logo, setLogo] = useState(null);
  const [uploadOrganizationLogo] = useUploadOrganizationLogoMutation();
  const { sponsorId } = useParams();

  const [downloadOrganizationLogo] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();

  useEffect(() => {
    (async () => {
      if (organizationDetails?.logoS3Key) {
        const result = await downloadOrganizationLogo({
          s3Key: organizationDetails?.logoS3Key,
        });
        if (result.data) {
          const byteArray = base64ToArrayBuffer(result.data);
          const rr = new Blob([byteArray], { type: 'application/octet-stream' });
          const reader = new FileReader();
          reader.readAsDataURL(rr);
          reader.onloadend = function () {
            const base64data = reader.result;
            setLogo(base64data);
          };
        }
      }
    })();
  }, [organizationDetails]);

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setLogo(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('file', file);
      await uploadOrganizationLogo({
        payload: formData,
        sponsorId: sponsorId
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" onChange={handleLogoChange} />
        <IconButton color="primary" aria-label="upload logo" component="span">
          {logo ? (
            <Box
              component="img"
              src={logo}
              alt="Organization Logo"
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: 220,
                maxHeight: 220,
                borderRadius: '8px',
                objectFit: 'cover',
                border: '1px solid #ddd'
              }}
            />
          ) : (
            <Box
              sx={{
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ddd',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <PhotoCamera fontSize="large" />
            </Box>
          )}
        </IconButton>
      </label>
    </Box>
  );
}

export default OrganizationLogo;
