import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText,
  Select,
  FormControl,
  MenuItem,
  Divider,
  Button,
  Stack
} from "@mui/material";
import React, { useState } from "react";
import TextFieldContainer from "../../../components/inputContainers/TextFieldContainer";
import { getIn } from "formik";
import {  RemoveOutlined } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useUpdateTrialStudyDetailMutation } from "../../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/@extended/CustomButton";
import { useEffect } from "react";

const StudyDetailsSection = ({
  formik,
  testArticles,
  openMessageNotification,
  trialDetails
}) => {
  const theme = useTheme();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const [updateTrialStudyDetail] = useUpdateTrialStudyDetailMutation();

  const [OtherTextDosageUnit,setOtherTextDosageUnit]=useState('');
  const [routesOfAdministationOtherText,setRoutesOfAdministationOtherText]=useState('');
  const [regimenOfAdministrationOtherText,setRegimenOfAdministrationOtherText]=useState('');
  
  const[toggleForOtherInUnit,settoggleForOtherInUnit]=useState(false);
  const[toggleForOtherInRouts,settoggleForOtherInRouts]=useState(false);
  const[toggleForOtherInRegamin,settoggleForOtherInRegamin]=useState(false);

  const handleUpdate = () => {
    let studyDetail = formik.values.studyDetail;
    updateTrialStudyDetail({
      sponsorId,
      trialId: studyDetail.trialId,
      studyDetail: { studyDetail: studyDetail },
    });
    openMessageNotification({
      message: "Submitted Successfully",
      type: "success",
    });
  };
  
  const[dosageUnit,setDosageUnit]=useState([
    {
      label: "gm (Gram)",
      value: "gm",
    },
    {
      label: "mg (Mliligram)",
      value: "mg",
    },
    {
      label: "μg (Microgram)",
      value: "μg",
    },
    {
      label: "ng (Nanogram)",
      value: "ng",
    },
    {
      label: "IU (International Units)",
      value: "IU",
    },
    {
      label: "mL (Millilitre)",
      value: "mL",
    },
    {
      label: "mL/Hr (Millilitre / Hour)",
      value: "mL/Hr",
    },
    {
      label: "mg/Min (Milligram / Minute)",
      value: "mg/Min",
    },
    {
      label: "mg/Hr (Milligram / Hour)",
      value: "mg/Hr",
    },
    {
      label: "mL/Min (Millilitre / Minute)",
      value: "mL/Min",
    },
  ]);

  const [routesOfAdministation,setRoutesOfAdministation]= useState([
    {
      label: "PO (Orally/By Mouth)",
      value: "PO",
    },
    {
      label: "SL (Oral - Sublingual)",
      value: "SL",
    },
    {
      label: "IV (Intravenous)",
      value: "IV",
    },
    {
      label: "SC (Subcutaneous)",
      value: "SC",
    },
    {
      label: "IM (Intra-muscular)",
      value: "IM",
    },
    {
      label: "IA (Intra-arterial)",
      value: "IA",
    },
    {
      label: "IP (Intraperitoneal)",
      value: "IP",
    },
    {
      label: "IT (Intrathecal)",
      value: "IT",
    },
    {
      label: "IV-Inf (Intravenous as Infusion)",
      value: "IV-Inf",
    },
    {
      label: "Topical (Onto a Surface (Skin, Eyelids etc.))",
      value: "Topical",
    },
  ])

  const [frequencyOfAdministration,setFrequencyOfAdministration] = useState([
    {
      label: "QOD (Every Other Day)",
      value: "QOD",
    },
    {
      label: "QD (Once a Day)",
      value: "QD",
    },
    {
      label: "BID (Twice a Day)",
      value: "BID",
    },
    {
      label: "TID (Three times a Day)",
      value: "TID",
    },
    {
      label: "QID (Four times a Day)",
      value: "QID",
    },
    {
      label: "PRN (As Needed)",
      value: "PRN",
    },
    {
      label: "Q4H (Every 4 Hours)",
      value: "Q4H",
    },
    {
      label: "Q8H (Every 8 Hours)",
      value: "Q8H",
    },
    {
      label: "Q12H (Every 12 Hours)",
      value: "Q12H",
    },
    {
      label: "Q24H (Every 24 Hours)",
      value: "Q24H",
    },
  ])

  let [trialDetailsChange,setTrialDetailsChange]=useState(false)

  const handleSubmitOtherOfDosageUnit = ()=>{
    let other = []
    if(OtherTextDosageUnit)
    for (let i of OtherTextDosageUnit?.split(" ")){
      if(i!== '')other.push(i)
    }
    other = other.join(" ")

    if(other!==''){
      let clonedDosageUnit=[...dosageUnit];

      clonedDosageUnit.push({
        label:other,
        value:other
      })
      setDosageUnit(clonedDosageUnit);
      settoggleForOtherInUnit(false);
    }
    setOtherTextDosageUnit('')
  }

  const handleSubmitOtherOfRoutesOfAdministation = ()=>{
    let other = []
    if (routesOfAdministationOtherText){
      for (let i of routesOfAdministationOtherText?.split(' ')) {
        if (i !== '') other.push(i);
      }
        other = other.join(" ")

        if(other!==''){
          let clonedRoutesOfAdministation=[...routesOfAdministation];

          clonedRoutesOfAdministation.push({
            label:other,
            value:other
          })
          setRoutesOfAdministation(clonedRoutesOfAdministation);
          settoggleForOtherInRouts(false);
        }
      setRoutesOfAdministationOtherText('')
    }
  }

  const handleSubmitOtherOffrequencyOfAdministration = ()=>{
    let other = []
    if (regimenOfAdministrationOtherText){
      for (let i of regimenOfAdministrationOtherText?.split(' ')) {
        if (i !== '') other.push(i);
      }
      other = other.join(' ');

      if (other !== '') {
        let clonedfrequencyOfAdministration = [...frequencyOfAdministration];

        clonedfrequencyOfAdministration.push({
          label: other,
          value: other,
        });
        setFrequencyOfAdministration(clonedfrequencyOfAdministration);
        settoggleForOtherInRegamin(false);
      }
      setRegimenOfAdministrationOtherText('');
    }
  }

  useEffect(() => {
    if (trialDetails) {
      trialDetails?.trialData?.studyDetail?.testArticles.forEach((article) => {
        if (!dosageUnit.find((unit) => unit.value === article?.dosageUnit)) {
          setOtherTextDosageUnit(article?.dosageUnit);
        }
        if (!routesOfAdministation.find((unit) => unit.value === article?.routesOfAdministration)) {
          setRoutesOfAdministationOtherText(article?.routesOfAdministration);
        }
        if (!dosageUnit.find((unit) => unit.value === article?.frequencyOfAdministration)) {
          setRegimenOfAdministrationOtherText(article?.frequencyOfAdministration);
        }
        setTrialDetailsChange(true);
      });
    }
  }, [trialDetails]);

  useEffect(() => {
    if (OtherTextDosageUnit !== '') {
      handleSubmitOtherOfDosageUnit();
    }if (routesOfAdministationOtherText !== '') {
      handleSubmitOtherOfRoutesOfAdministation();
    }if (regimenOfAdministrationOtherText !== '') {
      handleSubmitOtherOffrequencyOfAdministration();
    }
  }, [trialDetailsChange]);

  return (
    <Card>
      <CardHeader
        title={"Study Details"}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        <Grid container>
          <Grid item sm={12} md={4} sx={{ pb: 2, pr: 5 }}>
            <TextFieldContainer
              name={"studyDetail.studyType"}
              placeholder={"Enter study type"}
              label={"Study Type"}
              formik={formik}
            />
          </Grid>
          <Grid item sm={12} md={4} sx={{ pb: 2, pr: 5 }}>
            <TextFieldContainer
              name={"studyDetail.treatmentNumber"}
              placeholder={"Enter number of treatments"}
              label={"Number of Treatments"}
              formik={formik}
              type={"number"}
            />
          </Grid>
          <Grid item sm={12} md={4} sx={{ pb: 2, pr: 5 }}>
            <TextFieldContainer
              name={"studyDetail.totalTreatmentDuration"}
              placeholder={"Enter treatment duration"}
              label={"Total Treatment Duration (months)"}
              formik={formik}
              type={"number"}
            />
          </Grid>
          <Grid item sm={12} pt={3}>
            <Typography fontSize={18} color={theme.palette.text.primary}>
            Investigational Products
            </Typography>
          </Grid>
          {formik.values.studyDetail.testArticles.map((_, index) => {
            return (
              <Grid container sx={{ py: 1.5 }} key={index}>
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <TextFieldContainer
                    name={`studyDetail.testArticles.${index}.drugName`}
                    placeholder={'Enter name of drug'}
                    label={'Name of Drug'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <TextFieldContainer
                    name={`studyDetail.testArticles.${index}.drugClass`}
                    placeholder={'Enter class of drug'}
                    label={'Class of Drug'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <TextFieldContainer
                    name={`studyDetail.testArticles.${index}.dosage`}
                    placeholder={'Enter dosage'}
                    label={'Dosage'}
                    formik={formik}
                    type={'number'}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>

                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                    Unit of Dosage
                  </Typography>
                  <FormControl
                    sx={{ width: '100%' }}
                    error={
                      getIn(formik.touched, `studyDetail.testArticles.${index}.dosageUnit`) &&
                      Boolean(getIn(formik.errors, `studyDetail.testArticles.${index}.dosageUnit`))
                    }
                  >
                    <Select
                      name={`studyDetail.testArticles.${index}.dosageUnit`}
                      value={getIn(formik.values, `studyDetail.testArticles.${index}.dosageUnit`)}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      placeholder={'Unit of Dosage'}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      input={<OutlinedInput size="small" />}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: '#aaa', fontStyle: 'normal' }}>Unit of Dosage</em>
                      </MenuItem>
                      {dosageUnit.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                      {toggleForOtherInUnit ? (
                        <Stack direction="row" spacing={1} sx={{ pl: 2, pr: 2, pt: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            placeholder="Other"
                            value={OtherTextDosageUnit}
                            onChange={(e) => {
                              e.stopPropagation();
                              setOtherTextDosageUnit(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <CustomButton sx={{ height: 39 }} variant="contained" onClick={handleSubmitOtherOfDosageUnit}>
                            submit
                          </CustomButton>
                        </Stack>
                      ) : (
                        <Button
                          fullWidth
                          disableRipple
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                            },
                            justifyContent: 'flex-start',
                            pl: 2,
                            color: 'black',
                            fontSize: '16px',
                          }}
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            settoggleForOtherInUnit(true);
                          }}
                        >
                          Other
                        </Button>
                      )}
                    </Select>
                    <FormHelperText>
                      {getIn(formik.touched, `studyDetail.testArticles.${index}.dosageUnit`) &&
                        getIn(formik.errors, `studyDetail.testArticles.${index}.dosageUnit`)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={0} md={1}></Grid>

                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                    Routes of Administation
                  </Typography>
                  <FormControl
                    sx={{ width: '100%' }}
                    error={getIn(formik.touched, `studyDetail.testArticles.${index}.routesOfAdministration`)&&Boolean(getIn(formik.errors, `studyDetail.testArticles.${index}.routesOfAdministration`))}
                  >
                    <Select
                      name={`studyDetail.testArticles.${index}.routesOfAdministration`}
                      value={getIn(formik.values, `studyDetail.testArticles.${index}.routesOfAdministration`)}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      placeholder={'Routes of Administation'}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      input={<OutlinedInput size="small" />}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: '#aaa', fontStyle: 'normal' }}>Routes of Administation</em>
                      </MenuItem>
                      {routesOfAdministation.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                      {toggleForOtherInRouts ? (
                        <Stack direction="row" spacing={1} sx={{ pl: 2, pr: 2, pt: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            placeholder="Other"
                            value={routesOfAdministationOtherText}
                            onChange={(e) => {
                              e.stopPropagation();
                              setRoutesOfAdministationOtherText(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <CustomButton
                            sx={{ height: 39 }}
                            variant="contained"
                            onClick={handleSubmitOtherOfRoutesOfAdministation}
                          >
                            submit
                          </CustomButton>
                        </Stack>
                      ) : (
                        <Button
                          fullWidth
                          disableRipple
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                            },
                            justifyContent: 'flex-start',
                            pl: 2,
                            color: 'black',
                            fontSize: '16px',
                          }}
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            settoggleForOtherInRouts(true);
                          }}
                        >
                          Other
                        </Button>
                      )}
                    </Select>
                    <FormHelperText>
                      {getIn(formik.touched, `studyDetail.testArticles.${index}.routesOfAdministration`) &&
                        getIn(formik.errors, `studyDetail.testArticles.${index}.routesOfAdministration`)}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={0} md={1}></Grid>
                {/* <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <TextFieldContainer
                    name={`studyDetail.testArticles.${index}.duration`}
                    placeholder={"Enter Duration"}
                    label={"Duration (In hours)"}
                    formik={formik}
                    type={"number"}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid> */}
                <Grid item sm={12} md={5} sx={{ py: 1 }}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                    Dosing Regimen
                  </Typography>
                  <FormControl
                    sx={{ width: '100%' }}
                    error={getIn(formik.touched, `studyDetail.testArticles.${index}.frequencyOfAdministration`) && Boolean(getIn(formik.errors, `studyDetail.testArticles.${index}.frequencyOfAdministration`))}
                  >
                    <Select
                      name={`studyDetail.testArticles.${index}.frequencyOfAdministration`}
                      value={getIn(formik.values, `studyDetail.testArticles.${index}.frequencyOfAdministration`)}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      placeholder={'Dosing Regimen'}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      input={<OutlinedInput size="small" />}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: '#aaa', fontStyle: 'normal' }}>Dosing Regimen</em>
                      </MenuItem>
                      {frequencyOfAdministration.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}

                      {toggleForOtherInRegamin ? (
                        <Stack direction="row" spacing={1} sx={{ pl: 2, pr: 2, pt: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            placeholder="Other"
                            value={regimenOfAdministrationOtherText}
                            onChange={(e) => {
                              e.stopPropagation();
                              setRegimenOfAdministrationOtherText(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <CustomButton
                            sx={{ height: 39 }}
                            variant="contained"
                            onClick={handleSubmitOtherOffrequencyOfAdministration}
                          >
                            submit
                          </CustomButton>
                        </Stack>
                      ) : (
                        <Button
                          fullWidth
                          disableRipple
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                            },
                            justifyContent: 'flex-start',
                            pl: 2,
                            color: 'black',
                            fontSize: '16px',
                          }}
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            settoggleForOtherInRegamin(true);
                          }}
                        >
                          Other
                        </Button>
                      )}
                    </Select>
                    <FormHelperText>
                      {getIn(formik.touched, `studyDetail.testArticles.${index}.frequencyOfAdministration`) &&
                        getIn(formik.errors, `studyDetail.testArticles.${index}.frequencyOfAdministration`)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} sx={{ py: 2 }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {formik.values.studyDetail.testArticles.length === index + 1 && (
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={() => {
                          if (formik.values.studyDetail.testArticles.length < 3) {
                            formik.values.studyDetail.testArticles.push(testArticles);
                            formik.setFieldValue('studyDetail.testArticles', formik.values.studyDetail.testArticles);
                          }
                        }}
                        startIcon={<AddOutlinedIcon fontSize={'18'} color={theme.palette.common.white} />}
                        sx={{ mr: 2 }}
                      >
                        <Typography variant="subtitle1">Add Product</Typography>
                      </Button>
                    )}
                    {index > 0 && (
                      <Button
                        aria-label="delete"
                        size="large"
                        variant="outlined"
                        onClick={() => {
                          formik.values.studyDetail.testArticles.splice(index, 1);
                          formik.setFieldValue('trialDetails.theraputicAgent', formik.values.studyDetail.testArticles);
                        }}
                        sx={{ ml: 2 }}
                      >
                        <RemoveOutlined fontSize="inherit" color={theme.palette.common.white} />
                        <Typography variant="subtitle1">Remove Product</Typography>
                      </Button>
                    )}
                  </Box>
                </Grid>

                <Grid item sm={12}>
                  {formik.values.studyDetail.testArticles.length !== index + 1 && <Divider />}
                </Grid>
              </Grid>
            );
          })}

          {formik.values.studyDetail.id && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  pr: 5,
                }}
              >
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleUpdate}
                  sx={{ color: "white" }}
                >
                  Update
                </CustomButton>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudyDetailsSection;
