import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  List,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import CustomButton from '../../components/@extended/CustomButton';
import { useCreateDoaTaskMutation, useUpdateTaskMutation } from '../../store/slices/apiSlice';
import FormikField from '../visitScheudle/FormikTextField';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';

const DeligationTasks = ({ open, onClose, trialId, siteId, task}) => {
  const theme = useTheme();
  const [addNewTask, setAddNewTask] = useState(false);
  const dispatch = useDispatch()
  const [createDoaTask] = useCreateDoaTaskMutation();
  const [updateTask] = useUpdateTaskMutation();

  const validationSchema = Yup.object().shape({
    taskNumber: Yup.string()
      .matches(/^\S*$/, 'Please enter valid number')
      .required('Task Number is required')
      .test(
        'is-valid-number',
        'Task number should only contain digits.',
        value => !isNaN(value) && Number.isInteger(Number(value))
      )
      .test(
        'is-valid-length',
        'Task Number must be at most 3 digits long',
        value => value.length <= 3
      ),
    description: Yup.string().required('Description is required'),
    title: Yup.string()
      .required('Title is required')
  });
  const initialValues = {
    title: task ? task?.title : '',
    description: task ? task?.description : '',
    taskNumber: task ? task?.taskNumber : '',
  };
  const handleSubmit = async (values) => {
    values = { description:values.description, title:values.title.trim(), taskNumber:values.taskNumber}
    if (task) {
      const result = await updateTask({ payload: { ...values }, taskId: task.id, trialId, siteId });
      if (result.error) {
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: result?.error?.data?.message,
          })
        );
      } else {
        onClose();
      }
    } else {
      const result = await createDoaTask({ payload: { ...values, trialId, siteId } });
      if (result.error) {
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: result?.error?.data?.message,
          })
        );
      } else {
        onClose();
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 400, // Set the width of the Drawer paper
          height: '100%',
          paddingTop: 8,
          backgroundColor: '#F5F5F5',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{ padding: 3 }}>
        <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>
          {task ?  'Edit Delegation Tasks':'Add Delegation Task' }
        </Typography>
      </Box>
      <Divider />
      <>
        <Box sx={{ padding: 3 }}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field component={FormikField} name="taskNumber" label="Task Number" type="text" height={75}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field component={FormikField} name="title" label="Title" type="text" minheight={75} height='auto' maxlength={150} multilined={true}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field component={FormikField} name="description" label="Description" height={values.description.length>40 ? 'auto' : 55} type="text" maxlength={250} maxLineLength={4} multilined={true}/>
                      <Typography align='right'>{values.description.length}/250</Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2, columnGap: 2 }}>
                  <Button color="primary" onClick={onClose}>
                    Cancel
                  </Button>
                  <CustomButton type="submit" size="small" variant="contained" color="primary">
                    Submit
                  </CustomButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    </Drawer>
  );
};

export default DeligationTasks;
