import { Avatar, Box, Button, Collapse, Divider, FormControl, IconButton, InputAdornment, Link, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from "../../components/@extended/CustomTable";
import CustomButton from "../../components/@extended/CustomButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import DelegationForm from "./DelegationForm";
import { tasks } from "./util";
import { bloqcibeApi, useDeleteAssignedTaskMutation, useGetTrialSiteInfoQuery, useLazyDownloadDelegationLogsQuery } from "../../store/slices/apiSlice";
import _ from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeligationTasks from "./DeligationTasks";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import CustomCard from "../../components/@extended/CustomCard";
import CircleIcon from '@mui/icons-material/Circle';
import { openMessage } from "../../store/slices/showMessageSlice";
import { openBackdropLoading, updatePagination } from "../../store/slices/applicationSlice";
import CustomTablePagination from "../../components/common/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import { getUserRole } from "../util";

function TaskRow(props) {
    const { row, editDelegation, setSelectedUser, deleteDelegation } = props;
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const loggedInUser = useSelector((state) => state.auth.user);
    const userRole = getUserRole(loggedInUser)

    const getStatusBackground = (_status) => {
      if (_status.toUpperCase() == 'PENDING') {
        return {
          background: 'linear-gradient(45deg, #ffcb00 17%, #ffefb3 91%)',
          statusColor: '#FFCB00',
          _color: theme.palette.getContrastText('#d0d0d0'),
        };
      } else if (_status.toUpperCase() == 'COMPLETED') {
        return {
          background: 'linear-gradient(45deg, #ff6d00 22%, #ffc9a0 90%)',
          statusColor: '#ff9933',
          _color: theme.palette.getContrastText('#E6B11C'),
        };
      } else {
        return {
          background:  'linear-gradient(45deg, #4caf50 22%, #c9e7cb 90%);',
          statusColor: '#4caf50',
          _color: theme.palette.getContrastText('#8bc34a'),
        };
      }
    };
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ width: '10%', alignContent:'start' }}>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ width: '30%', alignContent:'start' }}>
                    <Link variant="body2" component="button" onClick={() => setSelectedUser(row.userId)} color="inherit">
                        <Typography color={'#000'}>{row.delegate}</Typography>
                    </Link>
                </TableCell>
                <TableCell sx={{ width: '55%' }}>
                    <Box sx={{ display: 'flex', columnGap: 1.5, width: '95%', flexWrap: 'wrap', gap: 1 }}>
                        {
                            row.tasks && row.tasks.map((_obj, index) => {
                                const _status = getStatusBackground(_obj.status);
                                return (
                                    <Tooltip key={`${index}re`} title={
                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                                                <Avatar sx={{
                                                    width: theme.spacing(1), // Set width to the desired size
                                                    height: theme.spacing(1), // Set height to the desired size
                                                    backgroundColor: _status.statusColor, // Set background color of the dot
                                                    borderRadius: '50%',
                                                }}><Box></Box></Avatar>
                                                <Typography>{_obj.status}</Typography>
                                            </Box>
                                            <Typography>{_obj.taskNumber}: {_obj.taskTitle}</Typography>
                                            <Typography align="justify">Description: {_obj.taskDescription}</Typography>
                                        </Box>
                                    }><Avatar key={`${index}d`} sx={{
                                        background: _status.background,
                                        color: _status._color,
                                        width: theme.spacing(5), // Adjust size as needed
                                        height: theme.spacing(5), // Adjust size as needed
                                        fontSize: theme.typography.body1.fontSize,
                                        fontWeight:'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                    }}>
                                            {_obj.taskNumber}
                                        </Avatar>
                                    </Tooltip>
                                )
                            })
                        }
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography sx={{ marginBottom: 2, textDecoration:'underline' }}>
                                Task Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Task</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.tasks.map((historyRow) => {
                                        const _status = getStatusBackground(historyRow.status);
                                        return (
                                            <TableRow key={historyRow.id}>
                                                <TableCell component="th" scope="row">
                                                    {historyRow.taskNumber}: {historyRow.taskTitle}
                                                </TableCell>
                                                <TableCell><Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                                                    <Avatar sx={{
                                                        width: theme.spacing(1), // Set width to the desired size
                                                        height: theme.spacing(1), // Set height to the desired size
                                                        backgroundColor: _status.statusColor, // Set background color of the dot
                                                        borderRadius: '50%',
                                                    }}><Box></Box></Avatar>
                                                    <Typography>{historyRow.status}</Typography>
                                                </Box></TableCell>
                                                <TableCell>{moment(historyRow.startDate).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{moment(historyRow.endDate).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', columnGap: 2, justifyContent: 'center', visibility: historyRow.status.toUpperCase() == 'PENDING' && (historyRow.createdBy===loggedInUser.email || userRole==='PI') ? 'visible' : 'hidden' }}>
                                                        <IconButton onClick={() => editDelegation(historyRow)}>
                                                            <ModeEditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => deleteDelegation(historyRow)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


const LogInfo = (props) => {
    const { setSelectedUser, allDOATasks, trialId, siteId, siteTrialId, openDoaConfiguration } = props;
    const theme = useTheme();
    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const dispatch = useDispatch();
    const [fetchAllAssigngedTasks, { data: allAssignedTasks }] =
        bloqcibeApi.endpoints.fetchAllAssigngedTasks.useLazyQuery();
    const { data: trialSiteData } = useGetTrialSiteInfoQuery({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: siteTrialId,
    });
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [rows, setRows] = useState([]);
    const {page,rowsPerPage} = useSelector((state) => state.application.pagination.DOA_LOG_TABLE);
    const [openDelegationForm, setOpenDelegationForm] = useState(false);
    const [selectedDelegation, setSelectedDelegation] = useState();
    const [downloadLogReport, setDownloadLogReport] = useState(false);
    const [deleteAssignedTask] = useDeleteAssignedTaskMutation();
    const [downloadDelegationLogs] = useLazyDownloadDelegationLogsQuery();
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        message: '',
        buttonLabel: 'Confirm',
    });
    useEffect(() => {
        (async () => {
            if (trialId && siteId) {
                await fetchAllAssigngedTasks({ trialId, siteId })
            }
        })();
        setIsSearching(false);
        setSearchValue('');
    }, [trialId, siteId])
    useEffect(() => {
        if (allAssignedTasks && allAssignedTasks.length > 0) {
            const userTasks = _.groupBy(allAssignedTasks, 'userId');
            const _assignedTasks = [];
            _.each(_.keys(userTasks), (_user, index) => {
                const taskArr = userTasks[_user];
                _assignedTasks.push({
                    userId: _user,
                    delegate: taskArr[0].firstName + ' ' + taskArr[0].lastName,
                    tasks: taskArr,
                })
            })

            if(!searchValue){
                setIsSearching(false);
                setRows(_assignedTasks);
            }else if(searchValue){
                setIsSearching(true);
                const searchedTasks = _assignedTasks.filter(function (row) {
                    const name = row.delegate
                    if (name.toLowerCase().includes(searchValue.toLowerCase())) {
                      return row;
                    }
                });
                setRows(searchedTasks);
                dispatch(updatePagination({tableType:'DOA_LOG_TABLE',page:0,rowsPerPage}));
            }
        }else{
            setRows([]);
        }
    }, [allAssignedTasks,searchValue])
    const addDelegation = () => {
        setOpenDelegationForm(true)
    }
    const closeDelegation = async (refreshData = false) => {
        setSelectedDelegation(null);
        setOpenDelegationForm(false);
        if (refreshData) {
            await fetchAllAssigngedTasks({ trialId, siteId })
        }
    }
    const editDelegation = (data) => {
        setSelectedDelegation(data);
        setOpenDelegationForm(true);
    }
    const deleteDelegation = (data) => {
        setSelectedDelegation(data);
        handleConfirmatioDialogOpen();
    }
    const handleConfirmatioDialogOpen = () => {
        setConfirmDialog({
            open: true,
            message: (
                <span>Are you sure you want to delete this assigned task?</span>
            ),
            buttonLabel: 'Confirm',
        });
    }
    const handleConfirmDialogClose = () => {
        setConfirmDialog({
            ...confirmDialog,
            open: false,
        });
        setDownloadLogReport(false)
    }
    const handleDelete = async () => {
        handleConfirmDialogClose();
        await deleteAssignedTask({ assignedTaskId: selectedDelegation.id });
        setSelectedDelegation(null);
    }

    const handleDownload = async () => {
        handleConfirmDialogClose();
        try {
            dispatch(openBackdropLoading({ backdropLoading: true }));
            const response = await downloadDelegationLogs({
              trialId:trialId, 
              siteId:siteId, 
            });

            if(response.isError){
                dispatch(
                    openMessage({
                    messageSeverity: "error",
                    message: response?.error?.data?.message,
                    })  
                );
            }else{
                const url = response.data;
                const blob = new Blob([url], { type: 'application/pdf' });
                // const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.setAttribute('download', 'DelegationLogs.pdf');
                document.body.appendChild(a);
                a.click();
                
                dispatch(
                  openMessage({
                    messageSeverity: "success",
                    message: "Document downloaded successfully!",
                  })
                );
            }
            dispatch(openBackdropLoading({ backdropLoading: false }));
            
          } catch (error) {
            dispatch(openBackdropLoading({ backdropLoading: false }));
            dispatch(
              openMessage({
                messageSeverity: "error",
                message: error,
              })
            );
          }
    }

    const onNewPage = (page, rowsPerPage) =>{
        dispatch(updatePagination({tableType:'DOA_LOG_TABLE',page,rowsPerPage}));
    }

    return (
        <Box mt={2}>
            <Box sx={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', mb:2}}>
                {rows && rows.length > 0 || isSearching?
                <FormControl variant="outlined">
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={'text'}
                        placeholder= {"Search by member"}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                        }
                    />
                </FormControl>:
                <Box></Box>
                }

                <Box sx={{ display: 'flex', columnGap: 2 }}>
                    <CustomButton variant="contained" 
                        onClick={()=>{
                            setDownloadLogReport(true)
                            setConfirmDialog({
                                open: true,
                                message: (
                                    <span>Are you sure you want to download this file?</span>
                                ),
                                buttonLabel: 'Download',
                            });
                        }}
                    >
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                            Export
                        </Typography>
                    </CustomButton>
                    <CustomButton variant="contained" onClick={openDoaConfiguration}>
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                            Configure Tasks
                        </Typography>
                    </CustomButton>
                    <CustomButton variant="contained" onClick={() => addDelegation()}>
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                            Add Delegation
                        </Typography>
                    </CustomButton>
                </Box>
            </Box>
            {rows && rows.length > 0  || isSearching?
            <Paper sx={{ width: '100%' }}>
                <CustomCard 
                    // title={"Subjects"}
                    // subHeader={`Total: ${rows?.length}`} sx={{ p: 1, pt: 2 }}
                    action={
                    <Box style={{ display: 'flex', alignItems: 'center', paddingRight: 15 }}>
                        <CircleIcon
                            sx={{
                            color: theme.palette.success.light,
                            fontSize: 20,
                            }}
                        />
                        <Typography>Approved</Typography>
                        <Divider orientation="vertical" width={5} flexItem sx={{mx:1}}/>
                        <CircleIcon
                            sx={{
                            color: theme.palette.primary.light,
                            fontSize: 20,
                            }}
                        />
                        <Typography>Completed</Typography>
                        <Divider orientation="vertical" width={5} flexItem sx={{mx:1}}/>
                        <CircleIcon
                            sx={{
                            color: '#FFCB00',
                            fontSize: 20,
                            }}
                        />
                        <Typography>Pending</Typography>
                    </Box>}>
                <TableContainer sx={{ width: '100%' }}>
                    <Table stickyHeader sx={{ width: '100%' }}>
                        <CustomTableHead>
                            <TableRow>
                                <CustomTableHeadCell sx={{ width: '10%' }} />
                                <CustomTableHeadCell sx={{ width: '30%' }}>
                                    Delegate
                                </CustomTableHeadCell>
                                <CustomTableHeadCell sx={{ width: '55%' }}>
                                    Delegated Tasks
                                </CustomTableHeadCell>
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                            {rows && rows.length > 0 ? (
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row, index) => {
                                    return (
                                        <TaskRow key={row.userId} row={row} editDelegation={editDelegation} setSelectedUser={setSelectedUser} deleteDelegation={deleteDelegation}/>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <Typography variant="subtitle1" color="initial">
                                            No Data Found
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={rows.length} onNewPage={onNewPage}/>
                </CustomCard>
            </Paper>:
            <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3}}>
                <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>No delegated tasks available</Typography>
                <Typography variant='body2' sx={{color:theme.palette.grey[500]}}><em>Note: To start configuring the task, click on 'Configure Tasks'.</em></Typography>
            </Stack>
            }
            <ConfirmationDialog
                open={confirmDialog.open}
                buttonLabel={confirmDialog.buttonLabel}
                message={confirmDialog.message}
                handleClose={handleConfirmDialogClose}
                handleConfirm={!downloadLogReport? handleDelete : handleDownload}
            />
            <DelegationForm
                onClose={closeDelegation}
                allDOATasks={allDOATasks}
                trialId={trialId}
                siteId={siteId}
                users={trialSiteData?.users}
                open={openDelegationForm}
                delegationDetails={selectedDelegation}
            />
        </Box>
    )
}

export default LogInfo;