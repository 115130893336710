import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';

const slice = createSlice({
  name: 'pfas',
  initialState: {
    organizations: [
      {
        id: 1,
        orgName: 'General Automotive',
        logoPath: '',
        orgCategory: 'Automotive',
        contactInfo: '+1 123-456-7890',
        contactEmail: 'contact@generalautomotives.com',
        addressLine1: '123 Automotive Street',
        state: 'California',
        city: 'Los Angeles',
        postalcode: '90001',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'John Doe',
          confirm_account_holder_name: 'John Doe',
          account_holder_number: '234234234',
          confirm_account_holder_number: '234234234',
          abaNumber: '9876',
          account_holder_email: 'john.doe@pharmaco.com',
          routingNumber: '123456789',
          confirm_routing_Number: '123456789',
          country: 'USA',
          currency: 'USD',
          swiftNumber: '9876',
        },
        createdAt: '2024-01-01',
        assignments: [
          {
            assignmentNumber: 'BQW-123',
            assignmentVersion: '1.0',
            assignmentDate: '07-24-2024',
            assignmentTitle: 'BQW-123',
            complianceOfficer: 1,
            id: 1,
            createdAt: '2024-07-24',
            status: 'Created',
            vendors: [
              {
                vendorName: 'HUD Pvt. Ltd',
                vendorType: 'Regular',
                contactNumber: '+1 80182018029',
                fax: '+1 80182018029',
                contactEmail: 'hud@gmail.com',
                addressLine1: 'W Flamingo Road',
                state: 'NV',
                city: 'LA',
                postalcode: '121212',
                country: 'USA',
                accountDetails: {
                  account_holder_name: 'Jhon Doe',
                  account_holder_email: 'hud@gmail.com',
                  account_holder_number: '89999999999',
                  confirm_account_holder_number: '89999999999',
                  routingNumber: '1234',
                  confirm_routing_Number: '1234',
                  abaNumber: '8923984092803',
                  swiftNumber: '9073074082',
                  country: 'USA',
                  currency: 'USD',
                },
                id: 1,
                createdAt: '2024-07-24',
                products: [
                  {
                    productDetails: {
                      productNumber: 'lube-0123',
                      productName: 'Car Lubricant',
                      productCategory: 'Automobile',
                      productModel: 'lube-0123',
                      productSNO: 'lube-0123',
                      productReference: 'lube-0123',
                      productType: 'Consumable',
                    },
                    locationLatitude: 18.5563642,
                    locationLongitude: 73.7765331,
                    createdTime: '2024-07-24T13:30:44.509Z',
                    updating: false,
                    id: 1,
                  },
                  {
                    productDetails: {
                      productNumber: 'paint-#ff00ff',
                      productName: 'Car Paint',
                      productCategory: 'Automobile',
                      productModel: 'paint-0123-#ff00ff',
                      productSNO: 'Some Product',
                      productReference: 'Some Product',
                      productType: 'Paint',
                    },
                    locationLatitude: 18.5563642,
                    locationLongitude: 73.7765331,
                    createdTime: '2024-07-24T13:37:08.837Z',
                    updating: false,
                    id: 2,
                  },
                ],
              },
            ],
          },
        ],
        complianceOfficers: [
          {
            email: 'jhondoe@gmail.com',
            firstName: 'Jhon',
            lastName: 'Doe',
            address: 'Blue Street, New Jersey',
            pincode: 121212,
            contactNumber: '+1 8298918291',
            id: 1,
            createdAt: '2024-07-24',
          },
        ],
        vendors: [
          {
            vendorName: 'HUD Pvt. Ltd',
            vendorType: 'Regular',
            contactNumber: '+1 80182018029',
            fax: '+1 80182018029',
            contactEmail: 'jhondoe@gmail.com',
            contactEmail: 'hud@gmail.com',
            addressLine1: 'W Flamingo Road',
            state: 'NV',
            city: 'LA',
            postalcode: '121212',
            country: 'USA',
            accountDetails: {
              account_holder_name: 'Jhon Doe',
              account_holder_email: 'jhondoe@gmail.com',
              account_holder_number: '89999999999',
              confirm_account_holder_number: '89999999999',
              routingNumber: '1234',
              confirm_routing_Number: '1234',
              abaNumber: '8923984092803',
              swiftNumber: '9073074082',
              country: 'India',
              currency: 'INR',
            },
            id: 1,
            createdAt: '2024-07-24',
          },
        ],
      },
    ],
  },
  reducers: {
    addOrganization: (state, action) => {
      state.organizations.push({
        id: state.organizations.length + 1,
        ...action.payload,
        createdAt: moment().format('YYYY-MM-DD'),
        assignments: [],
        complianceOfficers: [],
        vendors: [],
      });
    },
    addComplianceOfficer: (state, action) => {
      const { orgId, data } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        state.organizations[orgIndex].complianceOfficers.push({
          ...data,
          id: state.organizations[orgIndex].complianceOfficers.length + 1,
          createdAt: moment().format('YYYY-MM-DD'),
        });
      }
    },
    addAssignmentForOrg: (state, action) => {
      const { orgId, data } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        state.organizations[orgIndex].assignments.push({
          ...data,
          id: state.organizations[orgIndex].assignments.length + 1,
          createdAt: moment().format('YYYY-MM-DD'),
          status: 'Drafted',
          vendors: [],
        });
      }
    },
    addVendorForOrg: (state, action) => {
      const { orgId, data } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        state.organizations[orgIndex].vendors.push({
          ...data,
          id: state.organizations[orgIndex].vendors.length + 1,
          createdAt: moment().format('YYYY-MM-DD'),
        });
      }
    },
    assignVendorToAssignment: (state, action) => {
      const { orgId, assignmentId, vendorId } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        const assignmentIndex = state.organizations[orgIndex].assignments.findIndex(
          (_asg) => String(_asg.id) === String(assignmentId)
        );
        const vendor = state.organizations[orgIndex].vendors.find((_ven) => String(_ven.id) === String(vendorId));
        if (assignmentIndex !== -1) {
          state.organizations[orgIndex].assignments[assignmentIndex].vendors.push({ ...vendor, products: [] });
        }
      }
    },
    removeVendorFromAssignment: (state, action) => {
      const { orgId, assignmentId, vendorId } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        const assignmentIndex = state.organizations[orgIndex].assignments.findIndex(
          (_asg) => String(_asg.id) === String(assignmentId)
        );
        if (assignmentIndex !== -1) {
          const filteredVendors = state.organizations[orgIndex].assignments[assignmentIndex].vendors.filter(
            (ven) => String(ven.id) !== String(vendorId)
          );
          state.organizations[orgIndex].assignments[assignmentIndex].vendors = filteredVendors;
        }
      }
    },
    updateAssignmentStatus: (state, action) => {
      const { orgId, assignmentId, status } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        const assignmentIndex = state.organizations[orgIndex].assignments.findIndex(
          (_asg) => String(_asg.id) === String(assignmentId)
        );
        if (assignmentIndex !== -1) {
          state.organizations[orgIndex].assignments[assignmentIndex].status = status;
        }
      }
    },
    createProductForVendor: (state, action) => {
      const { orgId, assignmentId, vendorId, data } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        const assignmentIndex = state.organizations[orgIndex].assignments.findIndex(
          (_asg) => String(_asg.id) === String(assignmentId)
        );
        if (assignmentIndex !== -1) {
          const vendorIndex = state.organizations[orgIndex].assignments[assignmentIndex].vendors.findIndex(
            (_ven) => String(_ven.id) === String(vendorId)
          );

          state.organizations[orgIndex].assignments[assignmentIndex].vendors[vendorIndex].products.push({
            ...data,
            id: state.organizations[orgIndex]?.assignments[assignmentIndex]?.vendors[vendorIndex].products?.length,
          });
        }
      }
    },
    completeProductForVendor: (state, action) => {
      const { orgId, assignmentId, vendorId, productId, data } = action.payload;
      const orgIndex = state.organizations.findIndex((_org) => String(_org.id) === String(orgId));
      if (orgIndex !== -1) {
        const assignmentIndex = state.organizations[orgIndex].assignments.findIndex(
          (_asg) => String(_asg.id) === String(assignmentId)
        );
        if (assignmentIndex !== -1) {
          const vendorIndex = state.organizations[orgIndex].assignments[assignmentIndex].vendors.findIndex(
            (_ven) => String(_ven.id) === String(vendorId)
          );

          const productIndex = state.organizations[orgIndex].assignments[assignmentIndex].vendors[
            vendorIndex
          ].products.findIndex((_product) => String(_product.id) === String(productId));

          state.organizations[orgIndex].assignments[assignmentIndex].vendors[vendorIndex].products[productIndex] = {
            ...state.organizations[orgIndex].assignments[assignmentIndex].vendors[vendorIndex].products[productIndex],
            ...data,
          };
        }
      }
    },
  },
});

export const selectAssignmentDetails = (state, orgId, assignmentId) => {
  const org = state.pfas?.organizations?.find((_org) => String(_org?.id) === String(orgId));

  if (org) {
    return org.assignments?.find((_study) => String(_study.id) === String(assignmentId));
  }
  return null;
};

export const selectCmpOffDetails = (state, orgId, officerId) => {
  const org = state.pfas?.organizations?.find((_org) => String(_org?.id) === String(orgId));

  if (org) {
    return org.complianceOfficers?.find((_officer) => String(_officer.id) === String(officerId));
  }
  return null;
};

export const getAssignmentVendor = (state, orgId, assignmentId, vendorId) => {
  const org = state.pfas?.organizations?.find((_org) => String(_org?.id) === String(orgId));

  if (org) {
    const assignment = org.assignments?.find((_study) => String(_study.id) === String(assignmentId));
    if (assignment) {
      return assignment.vendors?.find((_ven) => String(_ven.id) === String(vendorId));
    }
  }
  return null;
};

export const getAssignmentVendorProduct = (state, orgId, assignmentId, vendorId, productId) => {
  const org = state.pfas?.organizations?.find((_org) => String(_org?.id) === String(orgId));

  if (org) {
    const assignment = org.assignments?.find((_study) => String(_study.id) === String(assignmentId));
    if (assignment) {
      const vendor = assignment.vendors?.find((_ven) => String(_ven.id) === String(vendorId));
      return vendor.products.find((_product) => String(_product.id) === String(productId));
    }
  }
  return null;
};

export const {
  addOrganization,
  addComplianceOfficer,
  addAssignmentForOrg,
  addVendorForOrg,
  updateAssignmentStatus,
  assignVendorToAssignment,
  removeVendorFromAssignment,
  createProductForVendor,
  completeProductForVendor,
} = slice.actions;

export default slice.reducer;
