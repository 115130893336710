import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, IconButton, styled } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { bloqcibeApi, useGetMeUserQuery, useUploadUserProfilePicMutation } from '../../store/slices/apiSlice';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';

const Input = styled('input')({
  display: 'none',
});

function ProfilePic() {
  const [profilePic, setProfilePic] = useState(null);
  const [uploadUserProfilePic] = useUploadUserProfilePicMutation();
  const { data: userData } = useGetMeUserQuery();
  const [downloadUserProfilePic] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();

  useEffect(() => {
    (async () => {
      if (userData?.profileS3Key) {
        const result = await downloadUserProfilePic({
          s3Key: userData?.profileS3Key,
        });
        if (result.data) {
          const byteArray = base64ToArrayBuffer(result.data);
          const rr = new Blob([byteArray], { type: 'application/octet-stream' });
          const reader = new FileReader();
          reader.readAsDataURL(rr);
          reader.onloadend = function () {
            const base64data = reader.result;
            setProfilePic(base64data);
          };
        }
      }
    })();
  }, [userData]);

  const handlePicChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setProfilePic(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('file', file);
      const result = await uploadUserProfilePic({
        payload: formData,
      });
      
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <label htmlFor="icon-button-file">
          <Input accept="image/*" id="icon-button-file" type="file" onChange={handlePicChange} />
          <IconButton color="primary" aria-label="upload picture" component="span">
            <Avatar alt="Profile Picture" src={profilePic ? profilePic : ''} sx={{ width: 100, height: 100 }}>
              <PhotoCamera fontSize="large" />
            </Avatar>
          </IconButton>
        </label>
      </Box>
    </Box>
  );
}
export default ProfilePic;
