import _ from 'lodash';
import moment from 'moment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

export const UserRole = [
  {
    name: "Principle Investigator",
    value: "PI",
  },
  {
    name: "Site Admin",
    value: "site_admin",
  },
  {
    name: "Site Co-ordinator",
    value: "site_coordinator",
  },
  {
    name: "Site Monitor",
    value: "site_monitor",
  },
];

export const extractFilenameFromKey = (key) => {
  if (key) {
    // Split the key by '/'
    const parts = key.split("/");
    // Get the last part (filename)
    const filename = parts[parts.length - 1];
    return filename;
  }
  return;
};
export const getSeriesColor = (index) => {
  const colorArr = ["#2E63DF", "#00928F", "#F765A3", "#A155B9", "#FF897D", "#F2994A", "#219653", "#2289C2", "#6C1CED", "#64CC26", "#EC7E2D"];
  if (index > colorArr.length - 1) {
    const clr = colorArr[(index - 1) % (colorArr.length - 1)];
    return clr;
  }
  return colorArr[index];
}
export const getLeftNavCategoryIcon = (category) => {
  switch (category) {
    case "AccVerification":
      return AccountBalanceIcon;
    case "MH":
      return MedicalServicesIcon;
  }
}

export const mergeCustomFields = (subjectEnrollConfiguration, customFields) => {
  const _tempConfiguration = JSON.parse(JSON.stringify(subjectEnrollConfiguration));
  _.each(customFields, (customField) => {
    const stepKey = customField.stepKey;
    const sectionKey = customField.sectionKey;
    const subSectionKey = customField.subSectionKey;
    const categoryKey = customField.categoryKey;

    const stepData = _.filter(_tempConfiguration?.steps, (step) => {
      return step.key == stepKey;
    })
    const sectionData = _.filter(stepData[0]?.sections, (section) => {
      return section.key == sectionKey;
    });
    let categoryData = [];
    if (subSectionKey) {
      const subSectionData = _.filter(sectionData[0]?.subTabs, (subSection) => {
        return subSection.key == subSectionKey;
      });
      categoryData = _.filter(subSectionData[0]?.categories, (category) => {
        return category.key == categoryKey;
      });
    } else {
      categoryData = _.filter(sectionData[0]?.categories, (category) => {
        return category.key == categoryKey;
      });
    }


    if (categoryData[0]) {
      let categoryObject = categoryData[0];
      categoryObject.fields = categoryObject.fields?.concat(customField?.customFields);
    }
  })
  return _tempConfiguration;
}

export function maskString(str) {
  // Replace each character with a masking character
  const str1 = str + '';
  return str1.replace(/./g, '*');
}


export function combineDateTime(dateString, timeString) {
  const date = moment(dateString);

  // Parse the time string using Moment.js
  const time = moment(timeString, 'HH:mm:ss');

  // Set the time component of the date to the parsed time
  date.set({
    hour: time.hours(),
    minute: time.minutes(),
    second: time.seconds(),
  });

  // Format the result as a UTC string
  const utcDateString = date.format('MM-DD-YYYYTHH:mm');
  return utcDateString;
}