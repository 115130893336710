import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  CardContent,
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import CustomCard from '../../components/@extended/CustomCard';
import OrganizationLogo from '../Sponsors/OrganizationLogo';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RightArrow from '../../components/icons/RightArrow';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../components/@extended/CustomButton';
import { CustomTableHead, CustomTableHeadCell } from '../../components/@extended/CustomTable';
import CreateComplianceOfficer from './CreateComplianceOfficer';
const LabelContainer = ({ label, value }) => {
  return (
    <Grid container py={1}>
      <Grid item xs={4} md={5}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={7}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const studyTableColumns = [
  {
    id: 'assignmentNumber',
    label: 'Assignment Number',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'assignmentTitle',
    label: 'Assignment Title',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'assignmentVersion',
    label: 'Assignment Version',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'right',
  },
];

const complianceOffColumns = [
  {
    id: 'srNo',
    label: 'Sr. No.',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'fullName',
    label: 'Officer Name',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'email',
    label: 'Email',
    style: {
      minWidth: 200,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'contactNumber',
    label: 'Phone Number',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'center',
  },
];

const PfasOrgDetails = () => {
  const navigate = useNavigate();
  const { sponsorId } = useParams();
  const theme = useTheme();
  const [openCreateOfficer, setOpenCreateOfficer] = useState(false);
  // Access sponsor details from Redux store
  const sponsor = useSelector((state) => state.pfas.organizations.find((org) => String(org.id) === String(sponsorId)));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button type="text" onClick={() => navigate('/pfasTrack')} startIcon={<RightArrow leftArrow />} sx={{}}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Back
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomCard title="Organization Details">
            <CardContent>
              {/* Add onClick handler to trigger navigation */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight={160}>
                    <OrganizationLogo />
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <LabelContainer label={'Organization Name'} value={sponsor?.orgName || 'N/A'} />
                  <LabelContainer label={'Organization Category'} value={sponsor?.orgCategory || 'N/A'} />
                  <LabelContainer label={'Contact Info.'} value={sponsor?.contactInfo || 'N/A'} />
                  <LabelContainer label={'Contact Number'} value={sponsor?.contactEmail || 'N/A'} />
                  <LabelContainer
                    label={'Address'}
                    value={
                      sponsor?.addressLine1 +
                        ', ' +
                        sponsor?.city +
                        ', ' +
                        sponsor?.state +
                        ', ' +
                        sponsor?.country +
                        ' - ' +
                        sponsor?.postalcode || 'N/A'
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item sm={12} md={12}>
          <CustomCard
            title="Compliance officers"
            action={
              <Box>
                <CustomButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setOpenCreateOfficer(true);
                  }}
                >
                  <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                    Create Officer
                  </Typography>
                </CustomButton>
              </Box>
            }
          >
            <TableContainer sx={{ maxHeight: 550 }}>
              <Table stickyHeader aria-label="sticky table">
                <CustomTableHead>
                  <TableRow>
                    {complianceOffColumns?.map((column) => (
                      <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                        {column.label}
                      </CustomTableHeadCell>
                    ))}
                  </TableRow>
                </CustomTableHead>
                {sponsor?.complianceOfficers?.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={complianceOffColumns?.length}>
                      <Typography variant="subtitle1" color="initial">
                        No Officers Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sponsor?.complianceOfficers.map((officer, rowIndex) => {
                    return (
                      <TableRow key={officer?.id}>
                        {complianceOffColumns?.map((col, index) => {
                          let vlaue = officer[col.id];
                          switch (col.id) {
                            case 'srNo':
                              vlaue = rowIndex + 1;
                              break;
                            case 'fullName':
                              vlaue = officer?.firstName + ' ' + officer?.lastName;
                              break;
                            default:
                              vlaue = officer[col.id];
                              break;
                          }
                          return (
                            <TableCell key={col.id + index} align={col?.align}>
                              {vlaue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </Table>
            </TableContainer>
          </CustomCard>
        </Grid>
        <Grid item sm={12} md={12}>
          <CustomCard
            title="PFAS Assignments"
            action={
              <Box>
                <CustomButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(`/pfasTrack/${sponsorId}/create/assignment`);
                  }}
                >
                  <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                    Create Assignment
                  </Typography>
                </CustomButton>
              </Box>
            }
          >
            <TableContainer sx={{ maxHeight: 550 }}>
              <Table stickyHeader aria-label="sticky table">
                <CustomTableHead>
                  <TableRow>
                    {studyTableColumns?.map((column) => (
                      <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                        {column.label}
                      </CustomTableHeadCell>
                    ))}
                  </TableRow>
                </CustomTableHead>
                {sponsor?.assignments?.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={studyTableColumns?.length}>
                      <Typography variant="subtitle1" color="initial">
                        No Assignments Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sponsor?.assignments.map((assignment, rowIndex) => {
                    return (
                      <TableRow
                        key={assignment?.id}
                        onClick={() => {
                          assignment.status == 'Drafted'
                            ? navigate(`/pfasTrack/${sponsorId}/create/assignment/${assignment.id}`)
                            : navigate(`/pfasTrack/${sponsorId}/assignment/${assignment.id}`);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        {studyTableColumns?.map((col, index) => {
                          let value = assignment[col.id];
                          let style = {};
                          switch (col.id) {
                            case 'srNo':
                              value = rowIndex + 1;
                              break;
                            case 'status': {
                              style.fontWeight = 600;
                              if (assignment.status == 'Created') {
                                style.color = 'green';
                              } else {
                                style.color = 'orange';
                              }
                              value = assignment.status;
                              break;
                            }
                            default:
                              value = assignment[col.id];
                              break;
                          }
                          return col.id !== 'rightArrow' ? (
                            <TableCell key={col.id + index} align={col?.align} sx={style}>
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <RightArrow />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </Table>
            </TableContainer>
          </CustomCard>
        </Grid>
      </Grid>
      <CreateComplianceOfficer open={openCreateOfficer} handleClose={() => setOpenCreateOfficer(false)} />
    </>
  );
};

export default PfasOrgDetails;
