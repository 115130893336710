import React, { useEffect, useMemo, useState } from "react";
import CustomCard from "../../components/@extended/CustomCard";
import CustomTablePagination from "../../components/common/TablePagination";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import RightArrow from "../../components/icons/RightArrow";
import { useNavigate } from "react-router-dom";
import {
  CustomTableHead,
  CustomTableHeadCell,
} from "../../components/@extended/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../util";
import {
  useGetSubjectListByFieldMutation,
  useGetSubjectListQuery,
} from "../../store/slices/apiSlice";
import _ from "lodash";
import { updatePagination } from "../../store/slices/applicationSlice";
import AnimatedBloqcubeLogo from "../../components/Logo/AnimatedBloqcubeLogo";

const columns = [
  {  id: "srno",
    label: "Sr. No.",
    minWidth: 50,
    align: "left",},
  {
    id: "subjectId",
    label: "Subject Id",
    minWidth: 170,
    align: "center",
  },
  {
    id: "aeObserved",
    label: "AE Observed",
    minWidth: 170,
    align: "center",
  },
  {
    id: "saeObserved",
    label: "SAE Observed",
    minWidth: 170,
    align: "center",
  },
  {
    id: "completedStage",
    label: "Completed Stage",
    minWidth: 170,
    align: "center",
  },
  { id: "action", label: "", minWidth: 100 },
];

const SubjectAEListCard = ({ trialId, siteId, trialSiteId , searchValue }) => {
  useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const [rows, setRows] = useState([]);
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.SUBJECT_AE_LIST);
  const { data: subjectList,isLoading } = useGetSubjectListQuery({
    sponsorId: sponsorId,
    trialId: trialId,
    siteId: siteId,
  });
  const [getSubjectListByField,{isLoading:isSubjectLisyByFieldLoading}] = useGetSubjectListByFieldMutation();

  const getAESubjects = async (siteId) => {
    const AE = await getSubjectListByField({
      trialId,
      sponsorId,
      siteId,
      payload: {
        stepKey: "Create_Visit_2",
        sectionKey: "adverse_Events",
        categoryKey: "adverseEventsDetails",
        subSectionKey: "",
        fieldKey: "newAdvEvent",
        value: "Yes",
      },
    });
    if (AE.data) {
      return AE?.data;
    } else {
      return 0;
    }
  };

  const getSAESubjects = async (siteId) => {
    const sae = await getSubjectListByField({
      trialId,
      sponsorId,
      siteId,
      payload: {
        stepKey: "Create_Visit_2",
        sectionKey: "adverse_Events",
        categoryKey: "SeriousAdverseEventsDetails",
        subSectionKey: "",
        fieldKey: "newSeriousAdvEvent",
        value: "Yes",
      },
    });
    if (sae.data) {
      return sae?.data;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    (async () => {
      const _rows = [];
      if (subjectList) {
        const aeSubjects = await getAESubjects(siteId);
        const saeSubjects = await getSAESubjects(siteId);
        await Promise.all(
          _.map(subjectList, (subject, index) => {
            const row = {};
            _.map(columns, (column) => {
              switch (column.id) {
                case "srno":
                  return (row[column.id] = index + 1);
                case "subjectId":
                  return (row[column.id] = subject?.subjectNumber);
                case "aeObserved":
                  let val = "-";
                  if (_.some(aeSubjects, (_aeSub) => _aeSub.id == subject.id)) {
                    val = "Visit 2";
                  }
                  return (row[column.id] = val);
                case "saeObserved":
                  let val1 = "-";
                  if (
                    _.some(saeSubjects, (_saeSub) => _saeSub.id == subject.id)
                  ) {
                    val1 = "Visit 2";
                  }
                  return (row[column.id] = val1);
                case "completedStage":
                  return (row[column.id] = getCompletedStep(subject));
              }
            });
            row["subjectMasterId"] = subject?.id;
            _rows.push(row);
          })
        );
      }
      if (!searchValue) {
        setRows(_rows);
      } else {
        dispatch(updatePagination({tableType:'SUBJECT_AE_LIST',page:0,rowsPerPage}));
        const searchedSubjects = _rows.filter(function (row) {
          console.log('row',row);
          if ( row['subjectId'] &&
            row['subjectId'].toLowerCase().includes(searchValue.toLowerCase()) 
          ) {
            return row;
          }
        });
        setRows(searchedSubjects);
      }
    })();
  }, [subjectList , searchValue]);

  const getCompletedStep = (subject) => {
    let stepLabel = "-";
    if (subject.stepStatus) {
      const orderedEnrollmentSteps = _.sortBy(subject?.stepStatus, "order");
      let stepIndex = _.findIndex(
        orderedEnrollmentSteps,
        (_step) => _step.status === "Pending"
      );
      if (stepIndex !== -1) {
        stepLabel = orderedEnrollmentSteps[stepIndex - 1]?.stepLabel;
      } else if (subject.crfStatus) {
        const orderedCRFSteps = _.sortBy(subject.crfStatus?.stepStatus, "order");
        let crfStepIndex = _.findIndex(
          orderedCRFSteps,
          (_step) => _step.status === "Pending"
        );
        if (crfStepIndex !== -1) {
          stepLabel =
            crfStepIndex === 0
              ? orderedEnrollmentSteps[orderedEnrollmentSteps.length - 1]
                  ?.stepLabel
              : orderedCRFSteps[crfStepIndex - 1]?.stepLabel;
        } else if (crfStepIndex === -1) {
          stepLabel = orderedCRFSteps[orderedCRFSteps.length - 1]?.stepLabel;
        }
      }
    }
    return stepLabel;
  };

  const handleNavigate = (subjectRow) => {
    navigate(
      `/eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectRow.subjectMasterId}/details`,
      {}
    );
  };

  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'SUBJECT_AE_LIST',page,rowsPerPage}));
  }
  return (
    <CustomCard
      title={"Subjects"}
      subHeader={`Total: ${rows?.length}`}
      sx={{
        p: 1,
        pt: 2,
        paddingBottom: 0,
        "&:last-child": {
          paddingBottom: 0,
        }
      }}
    >
      <TableContainer
        sx={{
          border: `1px ${theme.palette.grey[100]} solid `,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <TableRow>
              {columns.map((column) => (
                <CustomTableHeadCell key={column.id} align={column.align}>
                  {column.label}
                </CustomTableHeadCell>
              ))}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {rows &&
              (rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index} onClick={() => handleNavigate(row)} sx={{cursor:'pointer'}}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Typography variant="subtitle2" color="initial">
                            {row[column.id]}
                          </Typography>
                          {column.id === 'action' && <RightArrow onClick={() => handleNavigate(row)} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {rows.length === 0 && !isLoading ? (
              <TableRow tabIndex={-1}>
                <TableCell colSpan={6} align="center">
                    <Typography variant="subtitle2" color="initial">
                      No subjects found
                    </Typography>
                </TableCell>
              </TableRow>
            ): isLoading &&
            <TableRow tabIndex={-1}>
                <TableCell colSpan={6} align="center">
                <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ height: 250, width: 250 }}>
                    <AnimatedBloqcubeLogo />
                  </Box>
                </Box>
                </TableCell>
              </TableRow>
          }

          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={rows.length} onNewPage={onNewPage}/>
    </CustomCard>
  );
};

export default SubjectAEListCard;
