import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import MainLayout from '../layout/mainLayout/MainLayout';
import AccountDetails from '../pages/AccountDetails/AccoutDetails';
import DOA from '../pages/DOA';
import ReportingDashboard from '../pages/ReportingDashboard';
import Sponsor from '../pages/Sponsors/Sponsor';
import SponsorShow from '../pages/Sponsors/SponsorShow';
import Booking from '../pages/booking';
import BookedVisitSlotDetails from '../pages/booking/BookedVisitSlotDetails';
import ConfirmVisit from '../pages/booking/ConfirmVisit';
import SlotBooking from '../pages/booking/SlotBooking';
import CreateTrial from '../pages/createTrial/CreateTrial';
import ManageConfiguration from '../pages/dashboard/ManageConfiguration';
import SiteAdminDashboard from '../pages/dashboard/SiteAdminDashboard';
import SponsorDashboard from '../pages/dashboard/SponsorDashboard';
import SubjectDashboard from '../pages/dashboard/SubjectDashboard';
import SystemAdminDashboard from '../pages/dashboard/SystemAdminDashboard';
import SubjectEnrollFormDetails from '../pages/eConsent/SubjectEnrollFormDetails';
import SubjectProfile from '../pages/eConsent/SubjectProfile';
import EnrollSubject from '../pages/enrollSubject/EnrollSubject';
import FinanceModule from '../pages/financeModule/FinanceModule';
import SponsorDetails from '../pages/financeModule/SponsorDetails';
import StudySetup from '../pages/financeModule/studySetup/StudySetup';
import Login from '../pages/login/Login';
import ForgotPassword from '../pages/login/forgotPassword';
import SiteInitiationForm from '../pages/siteIinitiation/SiteInitiationForm';
import SubjectCRFForm from '../pages/subjectCRF/SubjectCRFForm';
import SubjectDetails from '../pages/subjectDetails/SubjectDetails';
import SubjectVisitDetailCRFInfo from '../pages/subjectDetails/visitDetailCRF/SubjectVisitDetailCRFInfo';
import VisitDetailCRFInfo from '../pages/subjectDetails/visitDetailCRF/VisitDetailCRFInfo';
import SubjectWithdrawForm from '../pages/subjectWithdraw/SubjectWithdrawForm';
import TrialDetails from '../pages/trialDetails/TrialDetails';
import TrialSiteDetails from '../pages/trialSiteDetails/TrialSiteDetails';
import { getUserRole } from '../pages/util';
import VisitSchedule from '../pages/visitScheudle';
import ScheduleCalendar from '../pages/visitScheudle/ScheduleCalendar';
import SubjectSchedule from '../pages/visitScheudle/SubjectSchedule';
import { selectCurrentUser } from '../store/slices/authSlice';
import CreateTrials from '../pages/createTrialSystemAdmin/CreateTrails';
import PageNotFound from '../pages/PageNotFound';
import PfasTrack from '../pages/pfasTrack/PfasTrack';
import PfasOrgDetails from '../pages/pfasTrack/PfasOrgDetails';
import AssignmentSetup from '../pages/pfasTrack/assignmentSetup/AssignmentSetup';
import AssignmentDetails from '../pages/pfasTrack/assignmentDetails';
import VendorDetails from '../pages/pfasTrack/assignmentDetails/VendorDetails';
import ProductCrf from '../pages/pfasTrack/assignmentDetails/ProductCrf';
import ETMFs from '../pages/eTMFsDocument';
import ETMFsDocument from '../pages/eTMFsDocument/ETMFsDocument';

const ProtectedRoute = ({ user, roles, redirectPath = '/login' }) => {
  //trialSiteDetail
  const loggedInUser = useSelector((state) => state.auth.user);
  const trialSiteDetail = useSelector((state) => state.trial.trialSiteDetail);
  const currentUserRoleOfTrailSite = useMemo(() => {
    if (trialSiteDetail) {
      const users = trialSiteDetail.users;
      const logginUserEmail = loggedInUser.email;
      const userObject = _.find(users, (user) => {
        return user.user.email?.toLowerCase() == logginUserEmail?.toLowerCase();
      });

      if (userObject) {
        return userObject.role;
      }

      return 'sponsor';
    }
    const userRole = getUserRole(user);
    return userRole;
  }, [trialSiteDetail]);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  // const canAccess = _.find(roles, (role) => {
  //   return role == currentUserRoleOfTrailSite;
  // })
  if (true) {
    return <Outlet />;
  }
  return <Navigate to={'/unauthorized'} />;
};

const AppRoutes = () => {
  const user = useSelector(selectCurrentUser);

  const userRole = getUserRole(user);
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/forgotPassword" element={<ForgotPassword />} />

      <Route path="/" element={<MainLayout />}>
        <Route
          element={
            <ProtectedRoute user={user} roles={['site_admin', 'sponsor', 'PI', 'site_coordinator', 'system_admin']} />
          }
        >
          <Route
            path="/"
            element={
              userRole == 'subject' ? (
                <SubjectDashboard />
              ) : userRole == 'site_admin' ||
                userRole == 'PI' ||
                userRole == 'site_coordinator' ||
                userRole == 'site_monitor' ? (
                <SiteAdminDashboard />
              ) : userRole == 'sponsor' ? (
                <SponsorDashboard />
              ) : userRole == 'system_admin' ? (
                <SystemAdminDashboard />
              ) : null
            }
          />
          <Route path="/trial/:trialId?" element={<TrialDetails />} />
          <Route path="/reportingDashboard" element={<ReportingDashboard />} />
          <Route exact path="/trial/:trialId/trial-site/:trialSiteId" element={<TrialSiteDetails />} />
        </Route>
        <Route element={<ProtectedRoute user={user} roles={['site_admin', 'sponsor', 'PI', 'site_coordinator']} />}>
          <Route exact path="/trial/:trialId/trial-site/:trialSiteId/:siteInitStep" element={<SiteInitiationForm />} />
          <Route path="/schedule" element={<VisitSchedule />}>
            <Route exact path="/schedule/:scheduleId" element={<ScheduleCalendar />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute user={user} roles={['system_admin']} />}>
          <Route path="/finance" element={<FinanceModule />} />
          <Route path="/finance/:sponsorId" element={<SponsorDetails />} />
          <Route path="/finance/:sponsorId/setup/:studyId?" element={<StudySetup />} />
          <Route path="/sponsor" element={<Sponsor />} />
          <Route path="/configuration" element={<ManageConfiguration />} />
          <Route path="/sponsorDisplay/:sponsorId?" element={<SponsorShow />} />
          <Route path="/sponsor/createTrial/:sponsorId?" element={<CreateTrials />} />
          <Route path="/pfasTrack" element={<PfasTrack />} />
          <Route path="/pfasTrack/:sponsorId?" element={<PfasOrgDetails />} />
          <Route path="/pfasTrack/:sponsorId/create/assignment/:assignmentId?" element={<AssignmentSetup />} />
          <Route path="/pfasTrack/:sponsorId?/assignment/:assignmentId?" element={<AssignmentDetails />} />
          <Route path="/pfasTrack/:sponsorId/assignment/:assignmentId/vendor/:vendorId?" element={<VendorDetails />} />
          <Route
            exact
            path="/pfasTrack/:sponsorId/assignment/:assignmentId/vendor/:vendorId/product/:productStepKey/:productId?"
            element={<ProductCrf />}
          />
        </Route>
        <Route element={<ProtectedRoute user={user} roles={['sponsor']} />}>
          <Route path="/trialDetails" element={<TrialDetails />} />
          <Route path="/createTrial/:trialId?" element={<CreateTrial />} />
          <Route path="/eTMFs-eISF" element={<ETMFs />} />
          <Route path="/eTMFs-eISF/:trialId/doc/:siteId" element={<ETMFsDocument />} />
          <Route path="/eTMFs-eISF/doc/:trialId" element={<ETMFsDocument />} />
        </Route>
        <Route element={<ProtectedRoute user={user} roles={['subject']} />}>
          <Route
            exact
            path="/eConcent/:trialId/trial-site/:trialSiteId/site/:siteId/subject/:subjectMasterId/crf/:crfMasterId/:visitStepKey/subject"
            element={<SubjectVisitDetailCRFInfo />}
          />
          <Route path="/profile" element={<SubjectProfile />} />
          <Route path="/followup" element={<SlotBooking />} />
          <Route path="/followup/booked-slots/:slotId" element={<BookedVisitSlotDetails />} />
          <Route path="/followup/confirm/:slotId" element={<ConfirmVisit />} />
          <Route
            path="/eConcent/:trialId/trial-site/:trialSiteId/site/:siteId/subject/:subjectMasterId/enrollDetails/:stepKey"
            element={<SubjectEnrollFormDetails />}
          />
          <Route path="/trial/:trialId/site/:siteId/subject/:subjectId/schedule" element={<SubjectSchedule />} />
        </Route>

        <Route element={<ProtectedRoute user={user} roles={['PI', 'site_coordinator']} />}>
          <Route path="/booking" element={<Booking />} />
          {/* <Route path="/finance" element={<Finance />} /> */}
          <Route path="/doaLogs" element={<DOA />} />
          <Route path="/doaLogs/myTasks" element={<DOA />} />
          <Route path="/doaLogs/completedTasks" element={<DOA />} />
          <Route path="/doaLogs/:userId" element={<DOA />} />
          <Route path="/doaLogs/configureTasks" element={<DOA />} />
          <Route
            path="/eConcent/:trialId/trial-site/:trialSiteId/:siteInitStep/:subjectMasterId?"
            element={<EnrollSubject />}
          />
          <Route path="/accountDetails" element={<AccountDetails />} />
          <Route
            path="/eConcent/:trialId/trial-site/:trialSiteId/:siteInitStep/:subjectMasterId/withdraw"
            element={<SubjectWithdrawForm />}
          />
          <Route
            path="/eConcent/:trialId/trial-site/:trialSiteId/:siteInitStep/:subjectMasterId/crf/:crfMasterId"
            element={<SubjectCRFForm />}
          />
          <Route
            path="/eConcent/:trialId/trial-site/:trialSiteId/site/:siteId/subject/:subjectMasterId/details"
            element={<SubjectDetails />}
          />
          <Route
            exact
            path="/eConcent/:trialId/trial-site/:trialSiteId/site/:siteId/subject/:subjectMasterId/crf/:crfMasterId/:visitStepKey"
            element={<VisitDetailCRFInfo />}
          />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
