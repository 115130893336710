import { Box, Typography } from "@mui/material";

const LabelField = (props) => {
    const { label, textFontWeight, verticalAlignCenter } = props;
    return (<Box sx={{
        height: verticalAlignCenter ? '100%' : 'auto',
        display: verticalAlignCenter ? 'flex' : 'block',
        alignItems: verticalAlignCenter ? 'center' : 'initial',
      
     }}>
        <Typography variant="subtitle1" color="initial" sx={{ fontWeight: textFontWeight ? textFontWeight : 400 }}>{label}</Typography>
    </Box>);
}

export default LabelField;