import { Grid, Typography } from '@mui/material';
import React from 'react';

const SystemAdminDashboard = () => {
  return (
    <Grid sm={12}>
      <Typography fontSize={'30px'} color={'#ff6d00'}>
        Welcome to Bloqcube !!
      </Typography>
    </Grid>
  );
};

export default SystemAdminDashboard;