import { Box, Button, Card, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { bloqcibeApi } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TrialInfo = (props) => {
    const { setAllTasks, setTrialId, setSiteId, setSiteTrialId } = props;
    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const [protocolInfo, setProtocolInfo] = useState({});
    const [trialInfo, setTrialInfo] = useState([]);
    const [selectedTrial, setSelectedTrial] = useState();
    const [sponsorData , setSponsorData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [fetchDAO] =
        bloqcibeApi.endpoints.fetchDAO.useLazyQuery();
    const [fetchAllDAOTasks, { data: allDOATasks }] =
        bloqcibeApi.endpoints.fetchAllDAOTasks.useLazyQuery();
    useEffect(() => {
        (async () => {
            const result = await fetchDAO({
                sponsorId: sponsorId,
            })
            const trialsData = result.data.trials.filter((trial)=> trial.trial.status === 'Ongoing')
            setTrialInfo(trialsData)
            setSelectedTrial(trialsData[0])
            setSponsorData(result?.data?.sponsor)
        })()
    }, [fetchDAO, sponsorId]);

    useEffect(() => {
        if(selectedTrial){
        const trialData = selectedTrial?.trial;
        const siteData = selectedTrial?.site;
        const userData = selectedTrial?.users;
        setProtocolInfo({
            sponsor: sponsorData?.orgnizationName,
            protocolNo: trialData?.protocolNumber,
            trialTitle: trialData?.trialTitle,
            site: siteData?.orgname,
            PI: userData.length>0? (userData[0]?.firstName+ " " +userData[0]?.lastName) : ' ---- ----'
        })
        setTrialId(trialData.id);
        setSiteId(siteData.id);
        setSiteTrialId(siteData.trialSiteId);
        (async () => {
            await fetchAllDAOTasks({
                trialId: trialData.id,
                siteId: siteData.id
            })
        })()
        }
        
    }, [fetchAllDAOTasks, selectedTrial, setSiteId, setSiteTrialId, setTrialId, sponsorData.orgnizationName])

    useEffect(() => {
        setAllTasks(allDOATasks);
    }, [allDOATasks, setAllTasks])
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickTrial = (trial) => {
        setSelectedTrial(trial)
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 3}}>
            <Card sx={{ padding: 3, width: '24%', borderRadius: 4 }}>
                <Typography sx={{ fontSize: 16, color: 'rgb(130, 130, 130)', fontWeight: 500 }}>
                    Sponsor
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {protocolInfo?.sponsor}
                </Typography>
            </Card>
            <Card sx={{ padding: 3, width: '24%', borderRadius: 4 }}>
                
                <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 16, color: 'rgb(130, 130, 130)', fontWeight: 500 }}>
                        Protocol No
                    </Typography>
                    <Tooltip title={
                        <Box sx={{ display:'flex', flexDirection:'column', rowGap: 0.5, padding:1 }}>
                            <Typography variant="subtitle2">Name of Study:</Typography>
                            <Typography>{protocolInfo?.trialTitle}</Typography>
                        </Box>
                    }><InfoOutlinedIcon color="primary" /></Tooltip>
                </Box>
                <Box sx={{ display: 'flex'}}>
                    <Tooltip title={protocolInfo?.protocolNo}>
                        <Button
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon sx={{color:'black'}}/>}
                            size="small"
                            fullWidth
                            disableRipple
                            sx={{ 
                                ml: 2,
                                marginLeft:0,
                                justifyContent:'space-between' ,
                                padding:'5px 0px' ,
                                borderBottom: '2px solid transparent',
                                borderRadius:0,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    borderBottom: '2px solid',
                                }
                            }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Typography variant="h6" sx={{color:'black',fontWeight: 600 ,textOverflow:'ellipsis',overflow:'hidden',display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'}}>
                                {protocolInfo?.protocolNo}
                            </Typography>
                        </Button>
                        
                    </Tooltip>
                </Box>
                {trialInfo && trialInfo.length>1 && 
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                    },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    {trialInfo.map((trial) => (
                        <MenuItem key={trial.trial.protocolNumber} onClick={()=>handleClickTrial(trial)}>
                            {trial.trial.protocolNumber}
                        </MenuItem>
                    ))}
                </Menu>}
            </Card>
            <Card sx={{ padding: 3, width: '24%', borderRadius: 4 }}>
                <Typography sx={{ fontSize: 16, color: 'rgb(130, 130, 130)', fontWeight: 500 }}>
                    Site
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {protocolInfo?.site}
                </Typography>
            </Card>
            <Card sx={{ padding: 3, width: '24%', borderRadius: 4 }}>
                <Typography sx={{ fontSize: 16, color: 'rgb(130, 130, 130)', fontWeight: 500 }}>
                    Principle Investigator
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {protocolInfo?.PI}
                </Typography>
            </Card>
        </Box>
    )
}

export default TrialInfo;