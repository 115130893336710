import React from 'react';
import { Field, useField } from 'formik';
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, FormHelperText } from '@mui/material';

const FormikTimePickerField = ({ form, ...other }) => {
  const [field, meta, helpers] = useField(other.field.name);
  const touched = meta.touched;
  const currentError = meta.error;

  const handleChange = (time) => {
    helpers.setValue(time, true);
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        {...other}
        {...field}
        value={field.value || null}
        onChange={handleChange}
        disablePast={other.disablePast}
        ampm={false}
        slotProps={{
          popper:{
            sx: {
              ".MuiMultiSectionDigitalClockSection-root": {
                '&::-webkit-scrollbar': {
                  width: '0.3em',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius:'5px'
                },
              },
            },
          },
          textField: {
            error: touched && currentError && true,
            helperText: touched && currentError && currentError,
            name: field.name,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default FormikTimePickerField;
