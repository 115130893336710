import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomButton from '../../../components/@extended/CustomButton';
import * as Yup from 'yup';
import { Field, Formik, useField } from 'formik';
import FormikField from '../../visitScheudle/FormikTextField';
import Phone, {phoneValidationSchema} from '../../../components/common/Phone'
import { useDispatch, useSelector } from 'react-redux';
import { selectStudySiteDetails } from '../../../store/slices/financeSlice';
import { openMessage } from '../../../store/slices/showMessageSlice';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useTheme } from '@emotion/react';

const SelectWithAddOption = ({ sponsorId, studyId, selectedSite, fieldName, onChange, val, error, handleSaveVendors }) => {
    const siteDetails = useSelector((state) => selectStudySiteDetails(state, sponsorId, studyId, selectedSite?.id));
    const dispatch = useDispatch();
    const theme = useTheme();
    const [options, setOptions] = useState(['Primary Investigator Fee', 'X-Ray Fee - Facility','X-Ray Fee - Professional', 'Subject Stipends']);
    const [open, setOpen] = useState(false);
    const [accountVerified, setAccountVerified] = useState(false);
    useEffect(()=>{
        if(siteDetails.vendors){
            const vendorNames = siteDetails.vendors.map(vendor => vendor.vendor);
            const filteredOptions = options.filter(option => !vendorNames.includes(option));
            setOptions(filteredOptions);
        }
    },[siteDetails.vendors])

    const accountDetailsValidationSchema = Yup.object().shape({
        account_holder_name: Yup.string().required('Account Holder Name is required'),
        account_holder_email: Yup.string().email('Invalid email').required('Account Holder Email is required'),
        account_holder_number: Yup.string().required('Account Number is required'),
        abaNumber: Yup.string()
          .required('ABA number is required')
          .matches(/^[0-9]+$/, 'ABA number must be numeric'),
        swiftNumber: Yup.string()
          .required('Swift Number is required')
          .matches(/^[0-9]+$/, 'Swift Number must be numeric'),
        confirm_routing_Number: Yup.string()
          .required('Confirm Routing Number is required')
          .oneOf([Yup.ref('routingNumber'), null], 'Routing Numbers must match'),
        confirm_account_holder_number: Yup.string()
          .oneOf([Yup.ref('account_holder_number'), null], 'Confirm Account Number must match Account Number')
          .required('Confirm Account Number is required'),
        routingNumber: Yup.string().required('Routing Number is required'),
        country: Yup.string().required('Country is required'),
        currency: Yup.string().required('Currency is required'),
    });
    const validationSchema = Yup.object().shape({
        vendor: Yup.string().required('Vendor/Category is required'),
        contactInfo: phoneValidationSchema.concat(Yup.string().required("Contact number is requied")),
        contactEmail: Yup.string().email('Invalid email').required('Contact Email is required'),
        addressLine1: Yup.string().required('Address Line 1 is required'),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        postalcode: Yup.string().required('Postal Code is required'),
        country: Yup.string().required('Country is required'),
        accountDetails: accountDetailsValidationSchema,
      });
      
    const handleSelectChange = (event) => {
        onChange(event);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validateAccountDetails = async (values, setFieldTouched, setFieldError) => {
        try {
          await accountDetailsValidationSchema.validate(values.accountDetails, { abortEarly: false });
          setAccountVerified(true);
          dispatch(openMessage({ messageSeverity: 'success', message: 'Bank Account Details Validated.' }));
        } catch (error) {
          if (error.inner) {
            error.inner.forEach((err) => {
              setFieldTouched(`accountDetails.${err.path}`, true);
              setFieldError(`accountDetails.${err.path}`, err.message);
            });
          }
          setAccountVerified(false);
        }
    };

    const handleFormSubmit = (values, resetForm) => {
        if (values.vendor.trim()) {
            setOptions([...options, values.vendor]);
        }
        if(accountVerified){
            handleSaveVendors({...values, accountStatus:'Verified'});
        }else{
            handleSaveVendors({...values, accountStatus:'Unverified'});
        }
        handleClose();
        resetForm();
        setAccountVerified(false)
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <FormControl fullWidth error={!!error}>
                <InputLabel id="demo-simple-select-label">Vendors/Categories</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={val}
                    name={fieldName}
                    label="Vendors/Categories"
                    onChange={handleSelectChange}
                >
                    <MenuItem value="" disabled>
                        Select an option
                    </MenuItem>
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
            <IconButton onClick={handleOpen}>
                <AddCircleOutlineIcon />
            </IconButton>
            <Dialog scroll={'paper'} open={open} onClose={()=>{handleClose(); setAccountVerified(false);}} maxWidth={'md'} fullWidth>
                <Formik
                    initialValues={{
                        vendor: '',
                        contactInfo: '',
                        contactEmail: '',
                        addressLine1: '',
                        state: '',
                        city: '',
                        postalcode: '',
                        country: '',
                        accountDetails: {
                            account_holder_name: '',
                            account_holder_email: '',
                            account_holder_number: '',
                            confirm_account_holder_number: '',
                            routingNumber: '',
                            confirm_routing_Number: '',
                            abaNumber: '',
                            swiftNumber: '',
                            country: '',
                            currency: '',
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleFormSubmit(values, resetForm);
                    }}
                >
                    {({ isSubmitting, handleSubmit, handleChange, handleBlur, resetForm, values, touched, errors, setFieldTouched, setFieldError }) => (
                        <>
                            {/* <DialogTitle>Add a Vendor/Category</DialogTitle> */}
                            <DialogTitle>
                                Add a Vendor/Category
                                <IconButton
                                    aria-label="close"
                                    onClick={()=>{handleClose(); setAccountVerified(false);}}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{ width: '100%', pb: 3 }}>
                                    <Grid container spacing={4} sx={{ pt: 2 }}>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.vendor && Boolean(errors.vendor)}>
                                                <Field 
                                                component={FormikField} 
                                                name="vendor" 
                                                label="Vendor/Category" 
                                                type="text" 
                                                // value={newOption} 
                                                // onChange={(e) => {handleChange(e.target.value); setNewOption(e.target.value)}}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.contactEmail && Boolean(errors.contactEmail)}>
                                                <Field component={FormikField} name="contactEmail" label="Contact Email" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.contactInfo && Boolean(errors.contactInfo)}>
                                                <Field name="contactInfo" component={Phone} size='medium' label="Contact Number" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.addressLine1 && Boolean(errors.addressLine1)}>
                                                <Field component={FormikField} name="addressLine1" label="Address Line 1" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.state && Boolean(errors.state)}>
                                                <Field component={FormikField} name="state" label="State" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.city && Boolean(errors.city)}>
                                                <Field component={FormikField} name="city" label="City" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.postalcode && Boolean(errors.postalcode)}>
                                                <Field component={FormikField} name="postalcode" label="Postal Code" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl fullWidth error={touched.country && Boolean(errors.country)}>
                                                <Field component={FormikField} name="country" label="Country" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="h6" mr={2}>Bank Account Information</Typography>
                                                {accountVerified ?
                                                    <CheckCircleRoundedIcon
                                                        sx={{
                                                            color: theme.palette.success.light,
                                                            fontSize: 20,
                                                        }}
                                                    /> :
                                                    <WatchLaterIcon
                                                        sx={{
                                                            color: '#FFCB00',
                                                            fontSize: 20,
                                                        }}
                                                    />
                                                }
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{
                                                        color: accountVerified ? theme.palette.success.light : ' #FFCB00',
                                                        width: 'fit-content',
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        marginLeft: theme.spacing(0.5),
                                                    }}
                                                >
                                                    {accountVerified ? "Verified" : "Unverified"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={
                                                    touched.accountDetails?.account_holder_name &&
                                                    Boolean(errors.accountDetails?.account_holder_name)
                                                }
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.account_holder_name"
                                                    label="Account Holder Name"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={
                                                    touched.accountDetails?.account_holder_email &&
                                                    Boolean(errors.accountDetails?.account_holder_email)
                                                }
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.account_holder_email"
                                                    label="Account Holder Email"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={
                                                    touched.accountDetails?.account_holder_number &&
                                                    Boolean(errors.accountDetails?.account_holder_number)
                                                }
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.account_holder_number"
                                                    label="Account Number"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={
                                                    touched.accountDetails?.confirm_account_holder_number &&
                                                    Boolean(errors.accountDetails?.confirm_account_holder_number)
                                                }
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.confirm_account_holder_number"
                                                    label="Confirm Account Number"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={touched.accountDetails?.routingNumber && Boolean(errors.accountDetails?.routingNumber)}
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.routingNumber"
                                                    label="Routing Number"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={
                                                    touched.accountDetails?.confirm_routing_Number &&
                                                    Boolean(errors.accountDetails?.confirm_routing_Number)
                                                }
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.confirm_routing_Number"
                                                    label="Confirm Routing Number"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={touched.accountDetails?.abaNumber && Boolean(errors.accountDetails?.abaNumber)}
                                            >
                                                <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.abaNumber" label="ABA number" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={touched.accountDetails?.swiftNumber && Boolean(errors.accountDetails?.swiftNumber)}
                                            >
                                                <Field
                                                    disabled= {accountVerified ? true : false}
                                                    component={FormikField}
                                                    name="accountDetails.swiftNumber"
                                                    label="Swift Number"
                                                    type="text"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={touched.accountDetails?.country && Boolean(errors.accountDetails?.country)}
                                            >
                                                <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.country" label="Country" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                error={touched.accountDetails?.currency && Boolean(errors.accountDetails?.currency)}
                                            >
                                                <Field disabled= {accountVerified ? true : false} component={FormikField} name="accountDetails.currency" label="Currency" type="text" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <CustomButton
                                                variant="outlined"
                                                onClick={() => {
                                                    accountVerified ? 
                                                        setAccountVerified(false) :
                                                        validateAccountDetails(values, setFieldTouched, setFieldError);
                                                }}
                                                color="primary"
                                                sx={{ padding: '8px 30px', textTransform: 'none' }}
                                            >
                                                {!accountVerified ? "Validate" : "Edit"}
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 3, py: 1 }}>
                                    <CustomButton variant="outlined" onClick={()=>{handleClose(); setAccountVerified(false);}}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton variant="contained" onClick={handleSubmit}>
                                        Add
                                    </CustomButton>
                                </Box>
                            </DialogContent>
                        </>
                    )}
                </Formik>
            </Dialog>
        </Box>
    );
};

export default SelectWithAddOption;
