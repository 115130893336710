import { Padding, TableRows } from "@mui/icons-material";
import { TableBody, TableCell, TableFooter, TableHead, TableRow, styled } from "@mui/material";

export const CustomTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette.primary.lighter
  },
  fontSize:14,
  textTransform: "none"
 
}));

export const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  color: "#222322",
  fontSize: 14,
  fontWeight: 600,
  wordWrap: "break-word",
  textTransform: "none",
}));

export const CustomTableFoot = styled(TableFooter)(({ theme }) => ({
  "& .MuiTableCell-footer": {
    backgroundColor: theme.palette.primary.lighter,
    padding:'0px'    
  },
  fontSize:14,
  textTransform: "none"
 
}));

export const CustomTableRow = styled(TableRow)(({ theme ,hilight}) => ({
  fontSize:14,
  backgroundColor:hilight?'#fff5e6' : '',
  '&:nth-of-type(even)': {
    backgroundColor: hilight?'#fff5e6' : theme.palette.grey[50],
  },
  "& .MuiTableRow-hover": {
    backgroundColor: theme.palette.primary.lighter
  }
}));
