import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
const slice = createSlice({
  name: 'finance',
  initialState: {
    sponsors: [
      {
        id: 1,
        orgName: 'PharmaCo A',
        logoPath: '',
        orgCategory: 'Pharmaceutical',
        contactInfo: '123-456-7890',
        contactEmail: 'contactA@pharmaco.com',
        addressLine1: '123 Pharma Street',
        state: 'California',
        city: 'Los Angeles',
        postalcode: '90001',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'John Doe',
          confirm_account_holder_name: 'John Doe',
          account_holder_number: '234234234',
          confirm_account_holder_number: '234234234',
          abaNumber: '9876',
          account_holder_email: 'john.doe@pharmaco.com',
          routingNumber: '123456789',
          confirm_routing_Number: '123456789',
          country: 'USA',
          currency: 'USD',
          swiftNumber: '9876',
        },
        createdAt: '2024-01-01',
        studies: [],
      },
      {
        id: 2,
        orgName: 'Biotech Inc',
        orgCategory: 'Biotechnology',
        logoPath: '',
        contactInfo: '987-654-3210',
        contactEmail: 'info@biotechinc.com',
        addressLine1: '456 Biotech Avenue',
        state: 'Massachusetts',
        city: 'Boston',
        postalcode: '02101',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'Jane Smith',
          confirm_account_holder_name: 'Jane Smith',
          account_holder_email: 'jane.smith@biotechinc.com',
          routingNumber: '987654321',
          country: 'USA',
          currency: 'USD',
        },
        createdAt: '2024-01-02',
        studies: [],
      },
      {
        id: 3,
        orgName: 'Health Solutions',
        orgCategory: 'Healthcare Provider',
        contactInfo: '555-123-4567',
        logoPath: '',
        contactEmail: 'support@healthsolutions.com',
        addressLine1: '789 Health Blvd',
        state: 'New York',
        city: 'New York',
        postalcode: '10001',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'Alice Johnson',
          confirm_account_holder_name: 'Alice Johnson',
          account_holder_email: 'alice.johnson@healthsolutions.com',
          routingNumber: '555555555',
          country: 'USA',
          currency: 'USD',
        },
        createdAt: '2024-01-03',
        studies: [],
      },
    ],
    sites: [
      {
        id: 1,
        orgName: 'PharmaCo A',
        orgCategory: 'Pharmaceutical',
        contactInfo: '123-456-7890',
        contactEmail: 'contactA@pharmaco.com',
        addressLine1: '123 Pharma Street',
        state: 'California',
        city: 'Los Angeles',
        postalcode: '90001',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'John Doe',
          confirm_account_holder_name: 'John Doe',
          account_holder_email: 'john.doe@pharmaco.com',
          routingNumber: '123456789',
          country: 'USA',
          currency: 'USD',
        },
        createdAt: '2024-01-01',
      },
      {
        id: 2,
        orgName: 'Biotech Inc',
        orgCategory: 'Biotechnology',
        contactInfo: '987-654-3210',
        contactEmail: 'info@biotechinc.com',
        addressLine1: '456 Biotech Avenue',
        state: 'Massachusetts',
        city: 'Boston',
        postalcode: '02101',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'Jane Smith',
          confirm_account_holder_name: 'Jane Smith',
          account_holder_email: 'jane.smith@biotechinc.com',
          routingNumber: '987654321',
          country: 'USA',
          currency: 'USD',
        },
        createdAt: '2024-01-02',
      },
      {
        id: 3,
        orgName: 'Health Solutions',
        orgCategory: 'Healthcare Provider',
        contactInfo: '555-123-4567',
        contactEmail: 'support@healthsolutions.com',
        addressLine1: '789 Health Blvd',
        state: 'New York',
        city: 'New York',
        postalcode: '10001',
        country: 'USA',
        accountDetails: {
          account_holder_name: 'Alice Johnson',
          confirm_account_holder_name: 'Alice Johnson',
          account_holder_email: 'alice.johnson@healthsolutions.com',
          routingNumber: '555555555',
          country: 'USA',
          currency: 'USD',
        },
        createdAt: '2024-01-03',
      },
    ],
    invoiceTemplates: [
      {
        id: 1,
        label: 'Sponsor Invoice',
        data: {
          sections: [
            {
              key: 'recepientInformation',
              label: 'Recepient Information',
              data: [
                { key: 'name', label: 'Name', value: 'PharmaCo A' },
                { key: 'address', label: 'Address', value: '123 Pharma Street, Los Angeles, California, USA - 90001' },
                { key: 'contactInfo', label: 'Contact Info.', value: '123-456-7890' },
                { key: 'email', label: 'Email', value: 'contactA@pharmaco.com' },
              ],
            },
            {
              key: 'sponsorInformation',
              label: 'Sponsor Information',
              data: [
                { key: 'name', label: 'Name', value: 'PharmaCo A' },
                { key: 'address', label: 'Address', value: '123 Pharma Street, Los Angeles, California, USA - 90001' },
                { key: 'contactInfo', label: 'Contact Info.', value: '123-456-7890' },
                { key: 'email', label: 'Email', value: 'contactA@pharmaco.com' },
              ],
            },
            {
              key: 'invoiceDetails',
              label: 'Invoice Details',
              data: [
                { key: 'invoiceNumber', label: 'Invoice Number', value: 'Health Solutions' },
                { key: 'invoiceDate', label: 'Invoice Date', value: '23-05-2024' },
                { key: 'duaDate', label: 'Due Date', value: '23-05-2024' },
              ],
            },
          ],

        },
      },
    ],
  },
  reducers: {
    addSponsor: (state, action) => {
      state.sponsors.push({
        id: state.sponsors.length + 1,
        createdAt: moment().format('YYYY-MM-DD'),
        studies: [],
        vendors: [],
        ...action.payload,
      });
    },
    updateStudyBasicDetails: (state, action) => {
      const { sponsorId, studyId, studyDetails } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyindex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        state.sponsors[sponsorIndex].studies[studyindex] = {
          ...state.sponsors[sponsorIndex].studies[studyindex],
          ...studyDetails,
        };
      }
    },
    saveStudyBasicDetails: (state, action) => {
      const { sponsorId, studyDetails } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        state.sponsors[sponsorIndex].studies.push({
          id: state.sponsors[sponsorIndex].studies.length + 1,
          createdAt: moment().format('YYYY-MM-DD'),
          sites: [],
          vendors: [],
          ...studyDetails,
        });
      }
    },
    addSiteCroToStudy: (state, action) => {
      const { sponsorId, studyId, siteId } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const site = state.sites.find((_site) => _site.id === siteId);
          if (site) {
            state.sponsors[sponsorIndex].studies[studyIndex].sites.push({
              ...site,
              vendors: [],
              vendorCosts: [],
              procedureCosts: [
                {
                  key: 'Informed Consent',
                  col1: 'Informed Consent',
                  View_Visit_Details: 50,
                  cost: 100,
                  Create_Visit_2: '',
                  Create_Visit_3: '',
                },
                {
                  key: 'AE/SAE',
                  col1: 'AE/SAE',
                  cost: 10,
                  View_Visit_Details: '',
                  Create_Visit_2: 5,
                  Create_Visit_3: '',
                },
              ],
            });
          }
        }
      }
    },
    updateVendorCost: (state, action) => {
      const { sponsorId, studyId, siteId, column, vendorKey, value } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const sideIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
            (_site) => String(_site.id) === String(siteId)
          );
          if (sideIndex !== -1) {
            const rowIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].vendors.findIndex(
              (_vendor) => _vendor.key === vendorKey
            );
            state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].vendors[rowIndex][column] = value;
          }
        }
      }
    },
    updateProcedureCost: (state, action) => {
      const { sponsorId, studyId, siteId, column, procedureKey, value } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const sideIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
            (_site) => String(_site.id) === String(siteId)
          );
          if (sideIndex !== -1) {
            const rowIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].procedureCosts.findIndex(
              (_procedure) => _procedure.key === procedureKey
            );
            state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].procedureCosts[rowIndex][column] = value;
          }
        }
      }
    },
    createSiteCro: (state, action) => {
      state.sites.push({
        id: state.sites.length + 1,
        createdAt: moment().format('YYYY-MM-DD'),
        ...action.payload,
      });
    },
    addVendorToStudySite: (state, action) => {
      const { sponsorId, studyId, siteId, vendor } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const sideIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
            (_site) => String(_site.id) === String(siteId)
          );
          if (sideIndex !== -1) {
            state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].vendors.push({
              ...vendor,
              key: vendor.vendor,
              col1: vendor.vendor,
              View_Visit_Details: '',
              Create_Visit_2: '',
              Create_Visit_3: '',
            });
          }
        }
      }
    },
    addProcedureToStudySite: (state, action) => {
      const { sponsorId, studyId, siteId, procedure } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const sideIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
            (_site) => String(_site.id) === String(siteId)
          );
          if (sideIndex !== -1) {
            state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex].procedureCosts.push({
              ...procedure,
              key: procedure.procedure,
              col1: procedure.procedure,
            });
          }
        }
      }
    },
    saveBudgetForecastForSite: (state, action) => {
      const { sponsorId, studyId, siteId, budget } = action.payload;
      const sponsorIndex = state.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
      if (sponsorIndex !== -1) {
        const studyIndex = state.sponsors[sponsorIndex].studies.findIndex(
          (_study) => String(_study.id) === String(studyId)
        );
        if (studyIndex !== -1) {
          const sideIndex = state.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
            (_site) => String(_site.id) === String(siteId)
          );
          if (sideIndex !== -1) {
            const site = state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex];
            state.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex] = { ...site, ...budget };
          }
        }
      }
    },
  },
});

export const {
  addSponsor,
  saveStudyBasicDetails,
  updateStudyBasicDetails,
  createSiteCro,
  addSiteCroToStudy,
  addVendorToStudySite,
  addProcedureToStudySite,
  updateVendorCost,
  updateProcedureCost,
  saveBudgetForecastForSite,
} = slice.actions;

export const selectStudyDetails = (state, sponsorId, studyId) => {
  const sponsor = state.finance.sponsors.find((_sponsor) => String(_sponsor.id) === String(sponsorId));
  if (sponsor) {
    return sponsor.studies.find((_study) => String(_study.id) === String(studyId));
  }
  return null;
};

export const selectStudySiteDetails = (state, sponsorId, studyId, siteId) => {
  // const { sponsorId, studyId, siteId, vendor } = action.payload;
  const sponsorIndex = state.finance.sponsors.findIndex((_sponsor) => String(_sponsor.id) === String(sponsorId));
  if (sponsorIndex !== -1) {
    const studyIndex = state.finance.sponsors[sponsorIndex].studies.findIndex(
      (_study) => String(_study.id) === String(studyId)
    );
    const sideIndex = state.finance.sponsors[sponsorIndex].studies[studyIndex].sites.findIndex(
      (_site) => String(_site.id) === String(siteId)
    );
    if (sideIndex !== -1) {
      return state.finance.sponsors[sponsorIndex].studies[studyIndex].sites[sideIndex];
    }
  }
  return null;
};

export default slice.reducer;
