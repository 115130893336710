import { Box, Button, Typography } from "@mui/material";
import AEIncidentsAcrossSites from "./AEIncidentsAcrossSites";
import SitePerformanceMetricsTable from "./SitePerformanceMetricsTable";
import CustomCard from "../../../components/@extended/CustomCard";
import RightArrow from "../../../components/icons/RightArrow";
import SubjectEnrollmentTrend from "./SubjectEnrollmentTrend";

const MaximizeWidget = (props) => {
    const { selectedWidget, backToDashboard, trial, site } = props;
    console.log(selectedWidget,"sele")
    return (
      <>
        <Box>
          <Button type="text" onClick={backToDashboard} sx={{ marginBottom: 1 }} startIcon={<RightArrow leftArrow />}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Back
            </Typography>
          </Button>
          {selectedWidget == 'sitePerformanceMetrics' ? (
            <CustomCard title={'Site Performance'} sx={{ height: '70vh' }}>
              <SitePerformanceMetricsTable
                showMore={true}
                trial={trial}
                site={site}
                preference={'TRIAL_SITE_PERFORMANCE_DATA'}
              />
            </CustomCard>
          ) : selectedWidget == 'TRIAL_SUBJECT_ENROLLMENT_TREND' ?(
            <CustomCard title={'Subject Screening Trend'} sx={{ height: '70vh' }}>
              <SubjectEnrollmentTrend
                showMore={true}
                trial={trial}
                site={site}
                preference={'TRIAL_SUBJECT_ENROLLMENT_TREND'}
              />
            </CustomCard>
          ):(
            <CustomCard title={'AE/SAE Incidents Across Sites'} sx={{ height: '70vh' }}>
              <AEIncidentsAcrossSites trial={trial} site={site} preference={'TRIAL_SITE_AE_SAE_COUNT'} />
            </CustomCard>
          )}
        </Box>
      </>
    );
}

export default MaximizeWidget;