import { Avatar, Box, Divider, Typography, useTheme } from "@mui/material";
import visitImg from '../../components/Logo/visit.png';
import { useNavigate } from "react-router-dom";
import { useGetTrialBookingSlotsQuery } from "../../store/slices/apiSlice";
import { useMemo } from "react";
import moment from "moment";
import _ from "lodash";
import { useSelector } from 'react-redux';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';

const SlotBooking = (props) => {
  const navigate = useNavigate();
  const theme = useTheme()
    const { data: trialBookingSlots } = useGetTrialBookingSlotsQuery();
    const crfDetails = useSelector(state => state.subject?.dashboardDetails?.crfDetail)

    const visitSlotConfig = useMemo(() => {
      if (trialBookingSlots && crfDetails) {
        const currentDate = new Date();
        const elegibleStep = _.find(crfDetails.stepStatus, (step) => step.status === 'Pending');
        if (!elegibleStep) {
          return { elegibleStep: { stepLabel : 'Visits Completed'} };
        }
        const _filteredSlotsByVisit = _.filter(
          trialBookingSlots,
          (_slt) => {
            const slotStartTime = new Date(`${_slt.date.split('T')[0]}T${_slt.startTime}`)
            if (slotStartTime > currentDate && _slt.visit?.stepKey === elegibleStep?.stepKey) {
              return true;
            }
            return false;
          }
        );
        const _grpByDateSlots = _.groupBy(_filteredSlotsByVisit, (_slt) => moment(_slt.date).format('MM/DD/YYYY'));
        return { _slots: _grpByDateSlots, elegibleStep };
      } else {
        return {};
      }
    }, [trialBookingSlots, crfDetails]);

    const confirmVisit = (slot) => {
        navigate(`confirm/${slot.id}`);
    }

    const timeConverter = (time) => {
        return moment(time,'HH:mm:ss').format('HH:mm');
    }
    return (
      <>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Book an Appointment
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, paddingY: 2 }}>
            <Avatar
              src={visitImg}
              sx={{ width: 60, height: 60 }} // Customize the size as needed
            />
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>{visitSlotConfig?.elegibleStep?.stepLabel}</Typography>
          </Box>
          <Divider />
          {visitSlotConfig._slots &&
            Object.keys(visitSlotConfig._slots).length === 0 &&
            visitSlotConfig.elegibleStep?.stepLabel !== 'Visits Completed' && (
              <Box key={`dd`} sx={{ paddingTop: 2, height: '60%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: 1,
                    margin: 1.5,
                    padding: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <EventBusyRoundedIcon sx={{ height: 100, width: 100, color: '#FF9933' }} />
                  <Typography sx={{ color: theme.palette.grey[700], fontSize: 18, pt: 3 }}>
                    Appointment Slots are not available.
                  </Typography>
                </Box>
              </Box>
            )}
          {!visitSlotConfig._slots && visitSlotConfig.elegibleStep?.stepLabel === 'Visits Completed' && (
            <Box key={`dd`} sx={{ paddingTop: 2, height: '60%' }}>
              <Box
                sx={{
                  display: 'flex',
                  columnGap: 1,
                  margin: 1.5,
                  padding: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <BeenhereOutlinedIcon sx={{ height: 100, width: 100, color: theme.palette.success.main }} />
                <Typography sx={{ color: theme.palette.grey[700], fontSize: 18, pt: 3 }}>
                  Trial visits are completed.
                </Typography>
              </Box>
            </Box>
          )}
          {visitSlotConfig._slots &&
            Object.keys(visitSlotConfig._slots).length > 0 &&
            Object.keys(visitSlotConfig._slots).map((key, index) => (
              <Box key={`${index}dd`} sx={{ paddingTop: 2 }}>
                <Typography
                  sx={{ fontWeight: 600, textAlign: 'center', color: '#000', textDecoration: 'none', fontSize: 20 }}
                >
                  {key}
                </Typography>
                {visitSlotConfig._slots[key].map((rec, index1) => (
                  <Box
                    onClick={() => confirmVisit(rec)}
                    key={index1}
                    sx={{
                      display: 'flex',
                      columnGap: 1,
                      border: '1px solid #b7b4c5',
                      borderRadius: 3,
                      margin: 1.5,
                      padding: 2,
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{ color: '#0089FF', fontSize: 20 }}>{timeConverter(rec.startTime)}</Typography>
                    <Typography sx={{ color: '#0089FF', fontSize: 20 }}>-</Typography>
                    <Typography sx={{ color: '#0089FF', fontSize: 20 }}>{timeConverter(rec.endTime)}</Typography>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>
      </>
    );
}

export default SlotBooking;