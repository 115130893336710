import React from "react";
import InputField from "../FormFields/InputField";
import SelectField from "../FormFields/SelectField";
import RadioGroupField from "../FormFields/RadioGroupField";
import CheckboxGroupField from "../FormFields/CheckboxGroupField";
import SliderField from "../FormFields/SliderField";
import {
  bloqcibeApi,
  useUploadSignatureMutation,
} from "../../../store/slices/apiSlice";
import { useEffect, useState } from "react";
import PhoneInputField from "../FormFields/PhoneInputField";
import DatePickerField from "../FormFields/DatePickerField";
import DateTimePickerField from "../FormFields/DateTimePickerField";
import SignatureField from "../FormFields/SignatureField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckboxField from "../FormFields/CheckboxField";
import DataLabelField from "../FormFields/DataLabelField";
import moment from "moment";
import { Box, Grid, Typography, useTheme } from '@mui/material';
import HtmlContentField from '../FormFields/HtmlContentField';
import LabelField from '../FormFields/LabelField';
import ButtonField from "../FormFields/ButtonField";
import UploadDocumentField from "../FormFields/UploadDocumentField";
import AudioField from "../FormFields/AudioField";
import TimePickerField from "../FormFields/TimePickerField";
import { getIn } from "formik";
import MultiSelectField from "../FormFields/MultiSelectField";
import SelectSearchField from "../FormFields/SelectSearchField";
import CustomButton from "../../../components/@extended/CustomButton";
import ColourSliderField from "../FormFields/ColourSliderField";
import ResourceLinkField from "../FormFields/ResourceLinkField";
import { useResponsive } from "../../../hooks/ResponsiveProvider";

const DynamicFormField = (props) => {
  const {
    _config,
    parentKey,
    setFieldValue,
    values,
    siteInitiationMasterId,
    touched,
    readOnly,
    errors,
    sectionKey,
    field,
    removeCustomField,
    userSiteTrialRole,
  } = props;
  const [optionValues, setOptionValues] = useState([]);
  const { isSmallScreen } = useResponsive();
  const theme = useTheme();
  const [getDynamicDataValues] =
    bloqcibeApi.endpoints.getDynamicDataValues.useLazyQuery();

  useEffect(() => {
    (async () => {
      if (field && field.options && field.options.endpoint) {
        const result = await getDynamicDataValues({
          url: field.options.endpoint,
        });
        if (result.data && result.data.length > 0) {
          setOptionValues(result.data.map((_obj) => _obj.title));
        }
      }
    })();
  }, [field]);
  return (
    <Box
      sx={{
        columnGap: 2,
        border: field.bordered ? `1.5px solid ${theme.palette.grey.A400}` : '',
        borderRadius: field.bordered ? '25px' : '',
        padding: field.bordered ? 3 : 0,
      }}
    >
      {field.type == 'DATA_LABEL' && <DataLabelField {...props} {...field} />}
      {field.type == 'VIDEO_LINK' && <ResourceLinkField _key={field?.key} {...props} {...field} />}
      {field.type == 'DOCUMENT_LINK' && <ResourceLinkField _key={field?.key} {...props} {...field} />}
      {field.type == 'LABEL' && <LabelField {...props} {...field} />}
      {field.type == 'DATA_HTML' && <HtmlContentField {...props} {...field} />}
      {field.type == 'BUTTON' && !props?.openOTP && <ButtonField {...props} {...field} />}
      {field.type == 'INLINE_TEXT_FIELD' && field.subtype != 'DATE' && (
        <>
          {(field.role || field.showDate) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 2,
              }}
            >
              {field.role && <Typography variant="subtitle1">{field.role}</Typography>}
              {field.showDate && (
                <Typography variant="subtitle1">
                  {parentKey ? values[parentKey][field.currentDateKey] : values[field.currentDateKey]}
                </Typography>
              )}
            </Box>
          )}
          <InputField
            name={parentKey ? `${parentKey}.${field.key}` : field.key}
            label={field.label}
            required={field.required}
            disabled={readOnly ? readOnly : field.disabled}
            justifycontent={field.justifycontent}
            type={field.subtype}
            placeholder={field.placeholder}
            fullWidth
            helperLabel={field.helperLabel}
            unit={field.unit}
            info={field.info}
            valueKey={field.valueKey}
            fieldKey={field.key}
            multiline={field.multiline}
            step={field.step}
          />
        </>
      )}
      {field.type == 'DROP_DOWN' && (
        <SelectField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          data={field.options?.endpoint ? optionValues : field.options?.values}
          disabled={readOnly ? readOnly : field.disabled}
          required={field.required}
          placeholder={field?.placeholder}
          justifycontent={field.justifycontent}
          label={field.label}
          fieldKey={field.key}
          valueKey={field.valueKey}
          fullWidth
        />
      )}
      {field.type == 'MULTISELECT_DROP_DOWN' && (
        <MultiSelectField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          data={field.options?.endpoint ? optionValues : field.options?.values}
          disabled={readOnly ? readOnly : field.disabled}
          required={field.required}
          justifycontent={field.justifycontent}
          label={field.label}
          fieldKey={field.key}
          valueKey={field.valueKey}
          fullWidth
        />
      )}
      {field.type == 'SEARCH_DROP_DOWN' && (
        <SelectSearchField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          data={field.options?.endpoint ? optionValues : field.options?.values}
          disabled={readOnly ? readOnly : field.disabled}
          required={field.required}
          justifycontent={field.justifycontent}
          label={field.label}
          fieldKey={field.key}
          valueKey={field.valueKey}
          fullWidth
          multiple={field.multivalue}
          {...props}
        />
      )}
      {field.type == 'FILE_UPLOAD' && (
        <UploadDocumentField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          disabled={readOnly}
          required={field.required}
          justifycontent={field.justifycontent}
          label={field.label}
          supportedFormats={field.supportedFormats}
          fileSize={field.fileSize}
          fieldKey={field.key}
          parentKey={parentKey}
          fullWidth
          {...props}
        />
      )}
      {field.type == 'RADIO_BUTTONS' && (
        <RadioGroupField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          options={field.options.values}
          optionSize={field.optionSize}
          required={field.required}
          infoText={field.infoText}
          verticalAlignCenter={field.verticalAlignCenter}
          disabled={readOnly}
          label={field.label}
        />
      )}
      {field.type == 'AUDIO' && (
        <AudioField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          disabled={readOnly}
          audioText={field.audioText}
        />
      )}
      {field.type == 'CHECK_BOX' && (
        <CheckboxField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          disabled={readOnly}
          label={field.label}
        />
      )}
      {field.type == 'CHECK_BOX_GROUP' && (
        <CheckboxGroupField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          justifycontent={field.justifycontent}
          disabled={readOnly}
          label={field.label}
          options={field.options.values}
        />
      )}
      {field.type == 'SLIDER' && (
        <SliderField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          justifycontent={field.justifycontent}
          label={field.label}
          disabled={readOnly}
          valueLabelDisplay={field.valueLabelDisplay ? field.valueLabelDisplay : 'off'}
          {...field.defaultProps}
        />
      )}
      {field.type == 'COLOUR_SLIDER' && (
        <ColourSliderField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          justifycontent={field.justifycontent}
          label={field.label}
          disabled={readOnly}
          startLabel={field.startLabel}
          endLabel={field.endLabel}
          valueLabelDisplay={field.valueLabelDisplay ? field.valueLabelDisplay : 'off'}
          {...field.defaultProps}
        />
      )}
      {field.type == 'PHONE_INPUT' && (
        <PhoneInputField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          label={field.label}
          placeholder={field.placeholder}
          touched={touched}
          required={field.required}
          errors={errors}
          setFieldValue={setFieldValue}
          {...field.defaultProps}
        />
      )}
      {field.type == 'INLINE_TEXT_FIELD' && field.subtype == 'DATE' && (
        <>
          <DatePickerField
            label={field.label}
            required={field.required}
            justifycontent={field.justifycontent}
            name={parentKey ? `${parentKey}.${field.key}` : field.key}
            setFieldValue={setFieldValue}
            disabled={readOnly ? readOnly : field.disabled}
            disableFutureDates={field.disableFutureDates}
            disablePastDates={field.disablePastDates}
            valueKey={field.valueKey}
            fieldKey={field.key}
          />
        </>
      )}
      {field.type == 'TIME' && (
        <>
          <TimePickerField
            label={field.label}
            name={parentKey ? `${parentKey}.${field.key}` : field.key}
            setFieldValue={setFieldValue}
            disabled={readOnly}
          />
        </>
      )}
      {field.type == 'DATE_TIME' && (
        <>
          <DateTimePickerField
            label={field.label}
            name={parentKey ? `${parentKey}.${field.key}` : field.key}
            setFieldValue={setFieldValue}
            justifycontent={field.justifycontent}
            disabled={readOnly ? readOnly : field.disabled}
            disableFutureDates={field.disableFutureDates}
            disablePastDates={field.disablePastDates}
            valueKey={field.valueKey}
            fieldKey={field.key}
          />
        </>
      )}
      {field.type == 'SIGNATURE' && (
        <SignatureField
          name={parentKey ? `${parentKey}.${field.key}` : field.key}
          label={field.label}
          label2={field.label2}
          fieldKey={field.key}
          disabled={readOnly}
          justifycontent={field.justifycontent}
          required={field.required}
          parentKey={parentKey}
          role={field.role}
          showDate={field.showDate}
          siteInitiationMasterId={siteInitiationMasterId}
          setFieldValue={setFieldValue}
          sectionKey={sectionKey}
          {...props}
        />
      )}
      {field.type == 'REMOVE_FIELD_BUTTON' && (
        <Box
          sx={{
            height: props.justifycontent ? 'auto' : '100%',
            display: 'flex',
            alignItems: 'flex-end',
            pb: 2.65,
            justifyContent: field.justifycontent ? 'flex-end' : 'flex-start',
          }}
        >
          <CustomButton
            variant="outlined"
            onClick={() => {
              removeCustomField(field, {
                subSectionKey: props.subSectionKey,
                sectionKey: props.sectionKey,
                categoryKey: parentKey,
              });
            }}
          >
            Remove
          </CustomButton>
        </Box>
      )}
      {field?.fields && (
        <Grid container spacing={isSmallScreen ? 0 : 1}>
          {field.fields.map(
            (childField, childIndex) =>
              (!childField.access ||
                (childField.access &&
                  (childField.access == userSiteTrialRole || childField.access.includes(userSiteTrialRole)))) &&
              (!childField.dependsOn ||
                (childField.dependsOn && getIn(values, childField.dependsOn) === childField.dependsValue)) && (
                <Grid
                  key={parentKey ? `${parentKey}.${childIndex}thChild` : `${childField.key}.${childIndex}thChild`}
                  item
                  md={childField.md}
                  lg={childField.lg}
                  sm={childField.sm}
                  xs={childField.sm}
                >
                  <DynamicFormField {...props} field={childField} />
                </Grid>
              )
          )}
        </Grid>
      )}
    </Box>
  );
};

export default React.memo(DynamicFormField);
