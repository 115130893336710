import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditableLabel from './EditableLabel';

const RecipientInformation = ({ editTemplate, type, templateData }) => {
  const [recepientInfo, setRecepientInfo] = useState(null);

  useEffect(() => {
    if (type && templateData) {
      const _secData = templateData?.data?.sections.find((_section) => _section.key === type);
      if (_secData) {
        setRecepientInfo(_secData);
      }
    }
  }, [type, templateData]);

  const handleChange = (obj) => {
    let newState = recepientInfo;
    const objIndex = recepientInfo.findIndex((_obj) => _obj.key === obj._key);
    newState[objIndex] = obj;
    setRecepientInfo(newState);
  };

  return (
    <>
      <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
        {recepientInfo?.label}
      </Typography>
      {recepientInfo &&
        recepientInfo.data.map((_info, index) => {
          return (
            <EditableLabel
              key={_info.key}
              value={_info.value}
              _key={_info.key}
              label={_info.label}
              onChange={(newValue) => {
                handleChange(newValue);
              }}
              editTemplate={editTemplate}
            />
          );
        })}
    </>
  );
};

export default RecipientInformation;
