import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const EditableLabel = ({ label, value, _key, onChange, editTemplate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localeLabel, setLocalLabelLabel] = useState(label);
  const [isHovered, setIsHovered] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    onChange({ label: localeLabel, value, _key });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onChange({ label: localeLabel, value, _key });
    }
  };

  return (
    <Box
      onMouseEnter={() => {
        editTemplate && setIsHovered(true);
      }}
      onMouseLeave={() => {
        editTemplate && setIsHovered(false);
      }}
      onDoubleClick={() => {
        editTemplate && setIsEditing(true);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: isHovered && !isEditing ? '1px solid lightgrey' : 'none',
        borderRadius: '4px',
        padding: isHovered && !isEditing ? '4px' : '0',
      }}
    >
      {isEditing ? (
        <TextField
          value={localeLabel}
          onChange={(e) => setLocalLabelLabel(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          variant="standard"
        />
      ) : (
        <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
          {localeLabel} : <span style={{ fontWeight: 500 }}>{editTemplate ? '- -' : value}</span>
        </Typography>
      )}
      {/* {isHovered && !isEditing && (
        <IconButton size="small" onClick={() => setIsEditing(true)} sx={{ ml: 1 }}>
          <EditIcon fontSize="small" />
        </IconButton>
      )} */}
    </Box>
  );
};

export default EditableLabel;
