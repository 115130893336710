import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/@extended/CustomButton';
import WidgetSetting from './WidgetSetting';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  bloqcibeApi,
  useGetOngoingTrialsQuery,
  useUpdateDashboardPreferenceMutation,
} from '../../store/slices/apiSlice';
import DragContainer2 from './DragContainer2';
import AEIncidentsAcrossSites from './widgets/AEIncidentsAcrossSites';
import EntityCount from './widgets/EntityCount';
import MaximizeWidget from './widgets/MaximizeWidget';
import SubjectDropoutRate from './widgets/SubjectDropoutRate';
import SitePerformanceMetricsTable from './widgets/SitePerformanceMetricsTable';
import SubjectEnrollmentTrend from './widgets/SubjectEnrollmentTrend';
import ScreeningFaliureRate from './widgets/ScreeningFailureRate';
import GMapContainer from './widgets/GMapContainer';

const ReportingDashboard = (props) => {
  const theme = useTheme();
  const [openSetting, setOpenSetting] = useState(false);
  const sponsorId = useSelector((state) => state?.userDetails?.loggedInUser.sponsorId);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const { data: ongoingTrials, isLoading } = useGetOngoingTrialsQuery(sponsorId);
  const [getTrialSiteDetails] = bloqcibeApi.endpoints.getTrialSiteDetails.useLazyQuery();
  const [trial, setTrial] = useState('');
  const [selectedSites, setSelectedSites] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const userPreferences = useSelector((state) => state?.userDetails?.loggedInUser?.dashboardPreferences);
  const userId = useSelector((state) => state.userDetails?.loggedInUser?.id);
  const [updateDashboardPreference] = useUpdateDashboardPreferenceMutation();
  const getContentByType = (type, additionalProps) => {
    const componentMap = {
      TRIAL_BASIC_ENTITY_DATA: EntityCount,
      TRIAL_SITE_AE_SAE_COUNT: AEIncidentsAcrossSites,
      TRIAL_SUBJECT_DROPOUT_RATE: SubjectDropoutRate,
      TRIAL_SITE_PERFORMANCE_DATA: SitePerformanceMetricsTable,
      TRIAL_SUBJECT_ENROLLMENT_TREND: SubjectEnrollmentTrend,
      TRIAL_SUBJECT_SCREEN_FAILURE_RATE: ScreeningFaliureRate,
      TRIAL_SITE_GEOGRAPHIC_DISTRIBUTION: GMapContainer,
    };

    const Component = componentMap[type];
    if (Component) {
      return (
        <Component
          trial={trial}
          site={selectedSites}
          preference={type}
          maximizeWidget={(_widget) => setSelectedWidget(_widget)}
          {...additionalProps}
        />
      );
    } else {
      return null;
    }
  };

  const [widgetList, setWidgetList] = useState({});

  useEffect(() => {
    if (ongoingTrials && ongoingTrials.length > 0) {
      setTrial(ongoingTrials[0]?.id);
    } 
  }, [ongoingTrials]);

  useEffect(() => {
    if (userPreferences && trial) {
      const filteredPreferences = userPreferences.filter((user) => getContentByType(user.preference.type));

      const updatedWidgetList = {};
      _.orderBy(filteredPreferences, (preference) => preference?.order).forEach((preference) => {
        const content = getContentByType(preference.preference.type, { trialId: trial, siteId: selectedSites });
        updatedWidgetList[preference.preference.type] = {
          preferenceId: preference.id,
          id: preference.preference.type,
          label: preference.preference.label,
          content: content,
          display: preference.visibility,
          order: preference.order,
        };
      });
      setWidgetList(updatedWidgetList);
    }
  }, [userPreferences, trial, selectedSites]);

  useEffect(() => {
    (async () => {
      if (trial) {
        const _siteList = await getTrialSiteDetails({
          sponsorId,
          trialId: trial,
        });
        if (_siteList?.data) {
          const _list = _siteList.data.map((_site) => {
            return { siteName: _site.site.orgname, id: _site.siteId };
          });
          setSelectedSites([_list[0].id]);
          setSiteList(_list);
        }
      }
    })();
  }, [trial]);

  const openWidgetSetting = () => {
    setOpenSetting(true);
  };
  const onCloseSetting = () => {
    setOpenSetting(false);
  };
  const onWidgetSelection = async (event, key) => {
    const newFilters = { ...widgetList };
    newFilters[key].display = event.target.checked;
    const payload = {
      userId,
      preferences: [{ id: newFilters[key].preferenceId, visibility: event.target.checked }],
    };
    setWidgetList(newFilters);

    await updateDashboardPreference(payload);
  };
  const onWidgetSelectAll = (event) => {
    const newFilters = { ...widgetList };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key].display = event.target.checked;
    });
    setWidgetList(newFilters);
  };
  return (
    <>
      <Box sx={{ backgroundColor: '#FAFAFA' }}>
        {!selectedWidget && Object.keys(widgetList).length !== 0 && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, alignItems: 'center' }}>
              <Typography variant="h6" color="initial">
                Reporting Dashboard
              </Typography>
              <Box>
                <CustomButton startIcon={<SettingsIcon />} variant="outlined" onClick={openWidgetSetting}>
                  Configure
                </CustomButton>
              </Box>
            </Box>
            <DragContainer2
              userPreferences={userPreferences}
              widgets={widgetList}
              maximizeWidget={(_widget) => setSelectedWidget(_widget)}
            />
          </>
        )}

        {selectedWidget && (
          <MaximizeWidget
            selectedWidget={selectedWidget}
            backToDashboard={() => setSelectedWidget(null)}
            trial={trial}
            site={selectedSites}
          />
        )}
        <WidgetSetting
          onClose={onCloseSetting}
          open={openSetting}
          onWidgetSelectAll={onWidgetSelectAll}
          onWidgetSelection={onWidgetSelection}
          widgets={widgetList}
          trial={trial}
          siteList={siteList}
          selectedSites={selectedSites}
          setTrial={setTrial}
          setSelectedSites={setSelectedSites}
        />
      </Box>
      {(!selectedWidget && !isLoading && Object.keys(widgetList).length === 0) && (
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 2,
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="initial">
              Reporting Dashboard
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center', paddingY: 10 }}>
              <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                Dashboard Unavailable.
              </Typography>
              <Typography variant="body2" color={theme.palette.text.secondary} sx={{ pb: 0.5, fontStyle: 'italic' }}>
                Note: Contact System Admin for assigning dashboard preferences.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ReportingDashboard;
