import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TableBody,
    Typography,
    useTheme,
    Box,
    Button,
    IconButton,
    Menu,
    Divider,
    MenuItem,
    Tooltip
  } from "@mui/material";
  import React, { useState } from "react";
  import {
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableRow,
  } from "../../components/@extended/CustomTable";
  import moment from "moment";
  import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { AvailableAppointmentTimingsColumns } from "./AvailableAppointmentColumns";
  
const AvailableAppointmentTimings = ({rows, onEditSlot, onCopySlot, deleteSlot}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [slotCreationData, setSlotCreationData] = useState()
    const open = Boolean(anchorEl);
    const columns1 = AvailableAppointmentTimingsColumns();
    const theme = useTheme();
    let thresholdDate = moment().subtract(7, 'days');
    let filteredRows = rows?.filter(row => moment(row.date).isSameOrAfter(thresholdDate));

    const data = filteredRows?.map((row)=>(
      {
        "id" : row.id,
        "protocolNumber": row.trial.protocolNumber,
        "visit": row.visit.stepTitle,
        "date" : moment.utc(row.date).format("MM/DD/YYYY"),
        "startTime": moment(row.startTime,"HH:mm:ss").format("HH:mm"),
        "endTime": moment(row.endTime,"HH:mm:ss").format("HH:mm"),
        "capacity": row.maxCapacity,
        "status": row.status,
        "createdAt": moment.utc(row.createdAt).format("MM/DD/YYYY"),
        "createdBy": row.createdBy,
      }
    ))
    
    const handleClick = (event,row) => {
      setAnchorEl(event.currentTarget);
      setSlotCreationData({"createdAt": moment.utc(row.createdAt).format("MM/DD/YYYY"),"createdBy": row.createdBy})
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Paper sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: 3,
              backgroundColor: theme.palette.primary.light,
              borderRadiusTop:5
            }}
          />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <CustomTableHead>
                <TableRow >
                  {columns1.map((column) => {
                    let labelValue = null;
                    switch(column.id) {
                        case "trialTitle": {
                          labelValue = <div>
                                {column.label}<br/>
                                {"Start Date" }
                          </div>
                          break;
                        }
                      default:
                        labelValue = column.label;
                          break;
                    }
                    return(
                      <CustomTableHeadCell key={column.id} align={column.align}  sx={{...column?.style}}>
                      {labelValue}
                    </CustomTableHeadCell>
                    )
                    })}
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((row) => {
                    return (
                      <CustomTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns1.map((column) => {
                         let value = row[column.id];
                          
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Typography variant="subtitle2" color="initial">
                                {value}
                              </Typography>
  
                              {column.id === "action" && (
                                <Box>
                                  <Tooltip title={moment(row.date).isSameOrBefore(moment(), 'day') ? "Cannot edit this task" : ""} >
                                  <span>
                                  <IconButton onClick={()=>{onEditSlot(row)}} disabled={moment(row.date).isSameOrBefore(moment(), 'day')}><EditIcon fontSize="small"/></IconButton>
                                  </span>
                                  </Tooltip>
                                  <IconButton onClick={()=>{onCopySlot(row)}}><ContentCopyIcon fontSize="small"/></IconButton>
                                  <IconButton onClick={()=>{deleteSlot(row)}}><DeleteIcon fontSize="small"/></IconButton>
                                  <IconButton onClick={(event)=>handleClick(event, row)}>
                                    <InfoIcon fontSize="small"/>
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        p:1,
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.04))',
                                        '& .MuiAvatar-root': {
                                          width: 32,
                                          height: 32,
                                          ml: -0.5,
                                          mr: 1,
                                        },
                                        '&::before': {
                                          content: '""',
                                          display: 'block',
                                          position: 'absolute',
                                          top: 18,
                                          right: -5,
                                          width: 10,
                                          height: 10,
                                          bgcolor: 'background.paper',
                                          transform: 'translateY(-50%) rotate(45deg)',
                                          zIndex: 0,
                                        },
                                      },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                                  >
                                    <Box display={'flex'} sx={{pb:1,px:1}}>
                                      <Typography
                                        sx={{color: "#222322",
                                        fontSize: 14,
                                        fontWeight: 600,
                                        wordWrap: "break-word",
                                        textTransform: "none",}}
                                      >
                                        Created By : 
                                      </Typography>
                                      <Typography
                                        sx={{color: "#222322",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: "0.3em",
                                        wordWrap: "break-word",
                                        textTransform: "none",}}
                                      >
                                        {slotCreationData?.createdBy}
                                      </Typography>
                                    </Box>
                                    <Divider sx={{mx:1}}/>
                                    <Divider sx={{mx:1}}/>
                                    <Box display={'flex'} sx={{pt:1,px:1}}>
                                      <Typography
                                        sx={{color: "#222322",
                                        fontSize: 14,
                                        fontWeight: 600,
                                        wordWrap: "break-word",
                                        textTransform: "none",}}
                                      >
                                        Created At : 
                                      </Typography>
                                      <Typography
                                        sx={{color: "#222322",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        wordWrap: "break-word",
                                        marginLeft: "0.3em",
                                        textTransform: "none",}}  
                                      >
                                        {slotCreationData?.createdAt}
                                      </Typography>
                                    </Box>
                                    
                                  
                                  </Menu>
                                </Box>                              
                              )}
                            </TableCell>
                          );
                        })}
                      </CustomTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns1.length} align="center">
                      <Typography variant="subtitle1" color="initial">
                        Slots Not Available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  };
  
  export default AvailableAppointmentTimings;
  