import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import CustomButton from '../../../components/@extended/CustomButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import EditNoteIcon from '@mui/icons-material/EditNote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom';
import { addProcedureToStudySite, selectStudyDetails, selectStudySiteDetails, updateProcedureCost, updateVendorCost } from '../../../store/slices/financeSlice';

const ProcedureCost = ({selectedSite, handleTabChange }) => {
  const { sponsorId, studyId } = useParams();
  const theme = useTheme();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const studyDetails = useSelector((state) => selectStudyDetails(state, sponsorId, studyId));
  const refs = useRef([]);
  const [updatedCell, setUpdatedCell] = useState([]);
  const [formValues, setFormValues] = useState({
    procedure: '',
    procedureCost: '',
  });
    const siteDetails = useSelector((state) => selectStudySiteDetails(state, sponsorId, studyId, selectedSite?.id));
  const updateEditedCell = (rowIndex, colIndex) => {
    setUpdatedCell([rowIndex, colIndex]);
  };
  const [columns, setColumns] = useState([
    {
      key: 'col1',
      label: '',
      minWidth: 150,
      align: 'left',
    },
    {
      key: 'cost',
      label: 'Unit Cost',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'View_Visit_Details',
      label: 'Visit-1 (Screening)',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_2',
      label: 'Visit-2',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Create_Visit_3',
      label: 'Visit-3',
      minWidth: 100,
      align: 'center',
    },
  ]);

  const validateForm = (values) => {
    const errors = {};

    if (!values.procedure) {
      errors.procedure = 'Procedure/Non procedure is required';
    }
    if (!values.cost) {
      errors.cost = 'Procedure Cost is required';
    }
    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const updateCellValue = (rowKey, colKey, rowIndex, colIndex) => {
    const textFieldValue = refs.current[rowIndex + ',' + colIndex].value;
    dispatch(
      updateProcedureCost({
        sponsorId,
        studyId,
        siteId: selectedSite.id,
        column: colKey,
        procedureKey: rowKey,
        value: textFieldValue,
      })
    );
    setUpdatedCell([]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      let data = formValues;
      if (checked) {
        data.View_Visit_Details = formValues.cost;
        data.Create_Visit_2 = formValues.cost;
        data.Create_Visit_3 = formValues.cost;
        
      }
        dispatch(
          addProcedureToStudySite({
            sponsorId,
            studyId,
            siteId: selectedSite.id,
            procedure: { ...formValues },
          })
        );
      setFormValues({
        procedure: '',
        cost: '',
      });
    }
  };

  return (
    <Box>
      <Box sx={{ pb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Box>
        <Typography variant="h6" sx={{ alignSelf: 'center' }}>
          Procedure Costs
        </Typography>
        <Typography variant="subtitle1" sx={{ alignSelf: 'center' }}>
          UoQ (number of subject): <span style={{ fontWeight:'bold' }}>{studyDetails?.totalSubjectNumber}</span>
        </Typography>
        </Box>
        <Box>
          <CustomButton variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={() => {}}>
            <Typography variant="subtitle1" color={theme.palette.common.white}>
              Upload Details
            </Typography>
          </CustomButton>
          <Typography variant="subtitle1" fontSize={'12px'} color={'black'} right={0} mt={1}>
              Note: Upload file with CSV/Excel format.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ paddingY: 3 }}>
        <Grid item md={4}>
          <FormControl fullWidth error={!!errors.screenFails}>
            <InputLabel htmlFor="outlined-adornment-amount">Procedure/Non Procedure</InputLabel>
            <OutlinedInput
              value={formValues.procedure}
              onChange={handleChange}
              name="procedure"
              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Procedure/Non Procedure"
            />
            <FormHelperText>{errors.screenFails}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl fullWidth error={!!errors.enrollees}>
            <InputLabel htmlFor="outlined-adornment-amount">Unit Cost</InputLabel>
            <OutlinedInput
              value={formValues.cost}
              onChange={handleChange}
              name="cost"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Unit Cost"
            />
            <FormHelperText>{errors.enrollees}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControlLabel
            control={<Checkbox defaultChecked checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
            label="Set unit value to visit cost."
          />
        </Grid>
        <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box>
            <CustomButton variant="outlined" onClick={handleSubmit}>
              Add
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Paper sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            backgroundColor: theme.palette.primary.light,
          }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <CustomTableHead>
              <TableRow>
                {columns &&
                  columns.map((col) => (
                    <CustomTableHeadCell
                      key={col.key}
                      align={col.align}
                      sx={{
                        minWidth: col?.minWidth,
                        maxWidth: col?.maxWidth,
                      }}
                    >
                      <Tooltip title={col?.lableAbrivation} arrow placement="top" enterDelay={1400}>
                        {col.label}
                      </Tooltip>
                    </CustomTableHeadCell>
                  ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {siteDetails?.procedureCosts?.length === 0 ? (
                <TableRow>
                  <TableCell align={'center'} colSpan={columns?.length}>
                    <Typography variant="subtitle1" color="initial">
                      No Available Data.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                columns &&
                siteDetails?.procedureCosts?.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index} hover>
                      {columns?.map((column, colIndex) => {
                        const value = row[column.key];
                        return (
                          <TableCell key={`${column.key}${index}`} align={column.align}>
                            {(() => {
                              if (value && colIndex == 0) {
                                return <Typography>{value}</Typography>;
                              } else if (updatedCell[0] == index && updatedCell[1] == colIndex) {
                                return (
                                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <TextField
                                      sx={{ width: 100 }}
                                      size="small"
                                      defaultValue={value}
                                      inputRef={(el) => (refs.current[index + ',' + colIndex] = el)}
                                    />
                                    <IconButton
                                      onClick={() => updateCellValue(row.key, column.key, index, colIndex)}
                                      size="small"
                                    >
                                      <CheckIcon sx={{ color: 'green' }} />
                                    </IconButton>
                                    <IconButton onClick={() => setUpdatedCell([])} size="small">
                                      <CloseIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                  </Box>
                                );
                              } else if (value) {
                                return (
                                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography>{value}</Typography>
                                    <IconButton onClick={() => updateEditedCell(index, colIndex)} size="small">
                                      <EditIcon sx={{ color: theme.palette.grey[500] }} />
                                    </IconButton>
                                  </Box>
                                );
                              } else {
                                return (
                                  <IconButton onClick={() => updateEditedCell(index, colIndex)} size="small">
                                    <EditNoteIcon sx={{ color: theme.palette.grey[400] }} />
                                  </IconButton>
                                );
                              }
                            })()}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}>
        <CustomButton onClick={()=>handleTabChange(3)}>Save & Continue</CustomButton>
      </Box>
    </Box>
  );
};

export default ProcedureCost;
