import {
  Box,
  Button,
  Divider,
  Drawer,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../../components/@extended/CustomButton";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import {
  bloqcibeApi,
  useSaveCommentMutation,
} from "../../../store/slices/apiSlice";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setUserLocation } from "../../../store/slices/userDetailsSlice";
import { openMessage } from "../../../store/slices/showMessageSlice";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { maskString } from "../../../util/util";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CommentHistory = (props) => {
  const {
    trialId,
    siteId,
    trialSiteId,
    subjectMasterId,
    crfMasterId,
    visitStepKey,
  } = useParams();
  const {
    open,
    onCloseHistory,
    field,
    answer,
    sectionKey,
    subSectionKey,
    categoryKey,
    stepKey,
    MASK_FIELD_KEY,
    userSiteTrialRole,
  } = props;
  const dispatch = useDispatch();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const trialUsers = useSelector((state) => state.trial.trialUsers);
  const userDetails = useSelector((state) => state.userDetails);
  const loggedInUser = useSelector((state) => state.userDetails.loggedInUser);
  const [getComments] = bloqcibeApi.endpoints.getComments.useLazyQuery();
  const [getFieldHistory] =
    bloqcibeApi.endpoints.getFieldHistory.useLazyQuery();
  const [getSubjectFieldHistory] =
    bloqcibeApi.endpoints.getSubjectFieldHistory.useLazyQuery();
  const [saveComment] = useSaveCommentMutation();
  const [users, setUsers] = useState([]);
  const [commentHist, setCommentHist] = useState([]);
  const [fieldHist, setFieldHist] = useState([]);
  const [comment, setComment] = useState("");
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (trialUsers) {
      const _users = trialUsers
        .filter(
          (_user) =>
            _user.siteId === Number(siteId) && _user.userId !== loggedInUser?.id && _user.role !== "site_admin"
        )
        .map((_obj) => {
          return {
            id: _obj.user.email,
            display: _obj.user.firstName + " " + _obj.user.lastName,
          };
        });
      setUsers(_users);
    }
  }, [trialUsers, siteId]);

  useEffect(() => {
    (async () => {
      fetchCommentHistory();
      fetchValueHistory();
    })();
  }, [field]);
  useEffect(() => {
    if (userDetails && (!userDetails.locationLat || !userDetails.locationLng)) {
      getGeoLocation();
    }
  }, [userDetails]);
  const getGeoLocation = async (processSubmit) => {
    if ("geolocation" in navigator) {
      await navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          // Do something with the latitude and longitude
          if (latitude && longitude) {
            dispatch(
              setUserLocation({
                locationLatitude: latitude,
                locationLongitude: longitude,
              })
            );
          }
          processSubmit &&
            processSubmit({ locationLat: latitude, locationLng: longitude });
        },
        (error) => {
          dispatch(
            openMessage({
              message: "Please enable location access to add the comment.",
              messageSeverity: "error",
            })
          );
          console.error(`Error getting geolocation: ${error.message}`);
        }
      );
    } else {
      // Geolocation is not supported
      dispatch(
        openMessage({
          message: "Geolocation is not supported!",
          messageSeverity: "error",
        })
      );
      console.log("Geolocation is not supported");
    }
  };
  const fetchValueHistory = async () => {
    const payload = {
      ...(visitStepKey
        ? { crfMasterId: crfMasterId }
        : { subjectMasterId: subjectMasterId }),
      stepKey: visitStepKey ? visitStepKey : stepKey,
      sectionKey: sectionKey,
      subSectionKey: subSectionKey,
      categoryKey: categoryKey,
      fieldKey: field.key,
    };
    let valueHis;
    if (visitStepKey) {
      valueHis = await getFieldHistory({
        payload,
      });
    } else {
      valueHis = await getSubjectFieldHistory({
        payload,
      });
    }

    const _valuesList = valueHis.data.map((_obj) => {
      return {
        user: _obj.createdBy,
        value: MASK_FIELD_KEY.includes(_obj.fieldKey) && userSiteTrialRole === 'site_monitor' ? maskString(_obj.fieldValue) : _obj.fieldValue,
        date: moment(_obj.Timestamp).format("MM/DD/YYYY hh:mm a"),
      };
    });
    setFieldHist(_valuesList);
  };
  const fetchCommentHistory = async () => {
    const commentHis = await getComments({
      sponsorId: sponsorId,
      trialId: trialId,
      trialSiteId: siteId, //trialSiteData?.siteTrialData?.site?.id,
      subjectMasterId: subjectMasterId,
      payload: {
        crfMasterId: crfMasterId,
        stepKey: visitStepKey ? visitStepKey : stepKey,
        sectionKey: sectionKey,
        subSectionKey: subSectionKey,
        categoryKey: categoryKey,
        fieldKey: field.key,
      },
    });
    const _commentList = commentHis.data.map((_obj) => {
      let comm = _obj.comment;
      const matches = comm.match(/\@\[(.*?)\]\([^)]+\)/g);
      if (matches) {
        for (let i = 0; i < matches?.length; i++) {
          const match = matches[i].match(/\@\[(.*?)\]\((.*?)\)/);
          if (match) {
            const username = match[1];
            const userEmail = match[2];
            comm = comm.replace(
              match[0],
              `<span style='background-color: #e8edff; font-style: italic;'>${username}</span>`
            );
          }
        }
      }
      return {
        ..._obj,
        comment: comm,
      };
    });
    setCommentHist(_commentList);
  };
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setComment(newValue);
  };
  const saveCommentData = async () => {
    let _locationObj = { ...userDetails };
    if (!_locationObj.locationLat || !_locationObj.locationLng) {
      _locationObj = await getGeoLocation(processSubmit);
    } else {
      await processSubmit(_locationObj);
    }
  };
  const processSubmit = async (_locationObj) => {
    if(!comment.trim()) {
      return;
    }
    //console.log('value', comment)
    const payload = {
      sponsorId: sponsorId,
      trialId: trialId,
      siteId: siteId,
      trialSiteId: trialSiteId,
      subjectMasterId: subjectMasterId,
      crfMasterId: crfMasterId,
      stepKey: visitStepKey ? visitStepKey : stepKey,
      sectionKey: sectionKey,
      subSectionKey: subSectionKey,
      categoryKey: categoryKey,
      fieldKey: field.key,
      comment: comment,
      latitude: _locationObj.locationLat,
      longitude: _locationObj.locationLng,
      notificationData: {
        fieldLabel: field?.label,
        subjectName: props?.subjectName,
        formLabel: props?.formLabel,
      },
    };
    await saveComment({ payload });
    setComment("");
    fetchCommentHistory();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseHistory}
      sx={{
        width: 500, // Set the width of the Drawer
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 500, // Set the width of the Drawer paper
          height: "100%",
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          paddingTop: 8,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              width: "100%",
              height: 4,
              backgroundColor: theme.palette.primary.main,
            }}
          />
          <CardHeader
            title={`${field.label} (History)`}
            titleTypographyProps={{ variant: "subtitle1", fontWeight: 600 }}
            sx={{ backgroundColor: theme.palette.grey[50] }}
          />
          <CardContent>
            <Box
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box>
                  <Tabs value={value} onChange={handleTabChange}>
                    <Tab label="Comment" {...a11yProps(0)} />
                    <Tab label="History" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      height: "calc(100vh - 300px)",
                      overflow: "auto",
                    }}
                  >
                    {commentHist && commentHist.length > 0 ? (
                      commentHist.map((_commentObj, index) => (
                        <ListItem
                          key={`comment${index}`}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <ListItemText
                            primary={
                              <Tooltip
                                title={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: _commentObj.comment,
                                    }}
                                  />
                                }
                                placement="left-start"
                              >
                                <div
                                  style={{
                                    width: 250,
                                    maxWidth: 280,
                                    whiteSpace: "balance",
                                    overflow: "auto",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: _commentObj.comment,
                                  }}
                                />
                              </Tooltip>
                            }
                            secondary={
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>{_commentObj.createdBy}</Typography>
                                <Box sx={{ display: "flex", columnGap: 1 }}>
                                  <Typography>
                                    {moment(_commentObj.createdAt).format(
                                      "MM/DD/YYYY hh:mm a"
                                    )}
                                  </Typography>
                                  {_commentObj.latitude &&
                                    _commentObj.longitude && (
                                      <Tooltip
                                        title={`${_commentObj.latitude},${_commentObj.longitude}`}
                                      >
                                        <LocationOnIcon color="primary" />
                                      </Tooltip>
                                    )}
                                </Box>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))
                    ) : (
                      <Typography sx={{ color: "grey", paddingY: 2 }}>
                        No comment exist
                      </Typography>
                    )}
                  </List>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      height: "calc(100vh - 300px)",
                      overflow: "auto",
                    }}
                  >
                    {fieldHist && fieldHist.length > 0 ? (
                      fieldHist.map(
                        (_valObj, index) =>
                          fieldHist[index - 1]?.value !== _valObj.value && (
                            <Box key={`val${index}`} sx={{ paddingY: 1 }}>
                              <Typography>
                                {
                                  <>
                                    <span style={{ fontWeight: 600 }}>
                                      {_valObj.user}
                                    </span>
                                    <span> updated the value </span>
                                    <span style={{ fontWeight: 600 }}>
                                      {_valObj.value}
                                    </span>
                                  </>
                                }
                              </Typography>
                              <Typography
                                style={{ color: "grey", fontWeight: 400 }}
                              >
                                {" "}
                                {_valObj.date}
                              </Typography>
                            </Box>
                          )
                      )
                    ) : (
                      <Typography sx={{ color: "grey", paddingY: 2 }}>
                        No history exist
                      </Typography>
                    )}
                  </List>
                </CustomTabPanel>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: "flex",
            padding: 3,
            justifyContent: "end",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <MentionsInput
            style={{
              width: "100%",
              minHeight: 40,
              maxHeight: 100,
              input: {
                padding: 9,
                border: "1px solid silver",
                "&focused": {
                  border: `1px solid ${theme.palette.primary.main}`,
                },
              },

              suggestions: {
                list: {
                  backgroundColor: "white",
                  border: "1px solid rgba(0,0,0,0.15)",
                  fontSize: 14,
                },
                item: {
                  padding: "5px 15px",
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  "&focused": {
                    backgroundColor: theme.palette.primary.lighter,
                  },
                },
              },
            }}
            value={comment}
            onChange={handleChange}
            forceSuggestionsAboveCursor
            allowSpaceInQuery
            allowSuggestionsAboveCursor
            placeholder={`Enter "@" to mention user`}
          >
            <Mention trigger="@" data={users} appendSpaceOnAdd />
          </MentionsInput>
          <CustomButton variant="contained" onClick={saveCommentData}>
            <SendIcon />
          </CustomButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(CommentHistory);
